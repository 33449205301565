import React from 'react';
import Icon from '../../../assets/icons';
import {formatNum, ORDER_LINES} from '../../../constants';

const CustomizeReceiptComponent = React.forwardRef((props, ref) => {
    const {businessLogo, header, footer, businessAddress, businessWebsite, companyRegistry, taxId} = props;
    return (
        <div
            ref={ref}
            style={{
                padding: '20px 10px',
                background: '#ffff',
                height: 'min-content',
                width: '100%',
                fontSize: '12px',
            }}
            className="orderReceipt">
            {businessLogo?.enabled && businessLogo?.value?.img && (
                <div className="textCenter">
                    <img src={businessLogo?.value?.img} style={{width: 60, height: 60, objectFit: 'cover'}} />
                </div>
            )}

            <div>
                {businessAddress?.enabled && <p className="marTop10 textCenter  grayNew">{businessAddress?.value}</p>}
                {header?.enabled && <p className="marTop10 textCenter  grayNew">{header?.value}</p>}
            </div>

            <div
                style={{
                    display: 'flex',
                    justifyContent: 'space-between',
                }}
                className="width100 marTop20">
                <p>Date: 14 Jan, 2025</p>
                <p>Order# 1702307</p>
            </div>

            <div
                style={{
                    display: 'flex',
                    justifyContent: 'space-between',
                }}
                className="width100">
                <p>Time: 12:06 PM</p>
                <p>Cashier: Muhammad Hassan</p>
            </div>

            <div
                style={{
                    display: 'flex',
                    justifyContent: 'space-between',
                }}
                className="width100">
                <p>Customer: Moiz</p>
                <p>Phone: 03456545678</p>
            </div>

            <div className="width100 marTop20 flex">
                <p className="width50  fontWeight600 fontSize12 grayNew">Items</p>
                <p className="width10  fontWeight600 textCenter fontSize12 grayNew">Qty</p>
                <p className="width40  fontWeight600 textRight fontSize12 grayNew">Amount</p>
            </div>
            <div style={{width: '100%', borderBottom: '1px dashed #CECECE', margin: '10px 0px'}} />

            <div>
                {ORDER_LINES?.map((order, ind) => {
                    return (
                        <div className={` width100 flex`}>
                            <p className="width50 grayNew">{order?.name}</p>
                            <p className="width10 textCenter grayNew">{`x${order?.quantity}`}</p>
                            <p className="width40 textRight grayNew">{`Rs. ${formatNum(order?.price)}`}</p>
                        </div>
                    );
                })}
            </div>
            <div style={{width: '100%', borderBottom: '1px dashed #CECECE', margin: '10px 0px 0px 0px'}} />

            <>
                <div
                    style={{
                        width: '100%',
                        display: 'flex',
                        justifyContent: 'space-between',
                        padding: '10px 0px 0px 0px',
                    }}>
                    <p className="grayNew">Subtotal</p>
                    <p className="grayNew">{`Rs. ${formatNum(184)}`}</p>
                </div>
                <div
                    style={{
                        width: '100%',
                        display: 'flex',
                        justifyContent: 'space-between',
                    }}>
                    <p className="grayNew">Discounts</p>
                    <p className="grayNew">{`-Rs. ${formatNum(10)}`}</p>
                </div>
                <div
                    style={{
                        width: '100%',
                        display: 'flex',
                        justifyContent: 'space-between',
                        padding: '5px 0px 0xp 0px',
                    }}>
                    <p className="grayNew">Sales tax</p>
                    <p className="grayNew">{`Rs. ${formatNum(16)}`}</p>
                </div>

                <div style={{width: '100%', borderBottom: '1px dashed #CECECE', margin: '5px 0px 10px 0px'}} />

                <div
                    style={{
                        width: '100%',
                        display: 'flex',
                        justifyContent: 'space-between',
                    }}>
                    <p className="fontSize14 fontWeight700 grayNew">Total</p>
                    <p className="fontSize14 fontWeight700 grayNew">{`Rs. ${formatNum(190)}`}</p>
                </div>

                <div style={{width: '100%', borderBottom: '1px dashed #CECECE', margin: '5px 0px 10px 0px'}} />

                <div
                    style={{
                        width: '100%',
                        display: 'flex',
                        justifyContent: 'space-between',
                        padding: '5px 0px 0xp 0px',
                    }}>
                    <p className=" grayNew">Cash</p>
                    <p className=" grayNew">{`Rs. ${formatNum(200)}`}</p>
                </div>
                <div
                    style={{
                        width: '100%',
                        display: 'flex',
                        justifyContent: 'space-between',
                        padding: '5px 0px 0xp 0px',
                    }}>
                    <p className=" grayNew">Change</p>
                    <p className=" grayNew">{`Rs. ${formatNum(0)}`}</p>
                </div>

                <div
                    style={{
                        width: '100%',
                        display: 'flex',
                        justifyContent: 'space-between',
                        padding: '5px 0px 0xp 0px',
                    }}>
                    {taxId?.enabled && <p className="fontSize10 grayNew">{`Tax ID: ${taxId?.value}`}</p>}
                    {companyRegistry?.enabled && <p className="fontSize10 grayNew">{`Reg No. : ${companyRegistry?.value}`}</p>}
                </div>
            </>

            <div className="width100 marTop40">
                {businessWebsite?.enabled && <p className="grayNew textCenter">{businessWebsite?.value}</p>}
                {footer?.enabled && <p className="grayNew textCenter account">{footer?.value}</p>}
            </div>
        </div>
    );
});

export default CustomizeReceiptComponent;
