import React, {useEffect, useState} from 'react';
import {useAuthContext, useLanguageContext, useThemeContext} from '../../../../../context';
import {DEVICE_HEIGHT} from '../../../../../constants';
import {FormCheck} from 'react-bootstrap';
import {Button, MessageModal, TextField} from '../../../../common';
import {updateSettings} from '../../../../../api';
import {toast} from 'react-toastify';

const LoyaltyProgram = () => {
    const {I18n} = useLanguageContext();
    const {theme} = useThemeContext();
    const {businessSettings, fetchSettings} = useAuthContext();

    const loyaltyProgram = businessSettings?.['loyalty_program'];
    const [loyalty, setLoyalty] = useState({status: false, id: ''});
    const [pointValue, setPointValue] = useState('');
    const [customerSpending, setCustomerSpending] = useState('');
    const [loading, setLoading] = useState(false);
    const [isChanged, setIsChanged] = useState(false);
    const [confirmatiomModal, setConfirmationModal] = useState(false);

    const onChange = (label, val) => {
        setIsChanged(true);
        if (label == I18n.customer_gets_discount_of) setPointValue(val);
        else if (label == I18n.every_time_customer_spends) setCustomerSpending(val);
    };

    const handleSaveLoyalty = async () => {
        try {
            setLoading(true);
            const payload = {
                settings: [
                    {
                        id: loyalty.id,
                        scope: 'business',
                        setting_value: {
                            status: loyalty.status,
                            minimum_spend: customerSpending,
                            points_value: pointValue,
                            unit_point: 1,
                            reward_points: 1,
                        },
                    },
                ],
            };
            const response = await updateSettings(payload);
            if (response.status) {
                toast.success('Changes updated successfully');
                setIsChanged(false);
                fetchSettings();
            }
        } catch (error) {
            console.log('Error', error);
        } finally {
            setLoading(false);
            if (confirmatiomModal) setConfirmationModal(false);
        }
    };

    const handleCancel = () => {
        setInitialValues();
        setIsChanged(false);
    };

    const handleDiscardModal = () => {
        setConfirmationModal(false);
    };

    const handleChange = (e, preference) => {
        setIsChanged(true);
        if (preference.name === 'Loyalty points') {
            setLoyalty(prev => ({...prev, status: e}));
        }
    };

    const handleCheck = preference => {
        if (preference.name === 'Loyalty points') {
            return loyalty.status;
        }
    };

    const setInitialValues = () => {
        if (loyaltyProgram) {
            loyaltyProgram.forEach(loyalty => {
                if (loyalty.name === 'Loyalty points') {
                    setLoyalty({id: loyalty.id, status: loyalty.setting_value.status});
                    setPointValue(loyalty.setting_value?.points_value);
                    setCustomerSpending(loyalty.setting_value?.minimum_spend);
                }
            });
        }
    };

    useEffect(() => {
        setInitialValues();
    }, [loyaltyProgram]);

    return (
        <div className="pad20">
            <div className="settingsWrapper borderRadiusBottomleftBottomRigt pad20" fluid style={{background: theme.blackBg, height: DEVICE_HEIGHT - 80}}>
                <>
                    <div className="spaceBetweenCenter">
                        <p className="marTop10 marBot10 fontSize18 fontWeight600" style={{color: theme.white}}>
                            {I18n.loyalty_program}
                        </p>

                        {isChanged && (
                            <div className="flex gap10 ">
                                <Button title="Cancel" handleClick={e => handleCancel(e)} className={`cancelBtn  fontWeight400 fontSize14 cursorPointer`} />
                                <Button
                                    saveLoad={loading}
                                    disabled={loading}
                                    title="Save"
                                    handleClick={e => handleSaveLoyalty(e)}
                                    className={`saveBtn  fontWeight400 fontSize14 cursorPointer`}
                                />
                            </div>
                        )}
                    </div>

                    <div className="divider width100 marTop10" />
                </>

                {loyaltyProgram?.map(loyaltySetting => {
                    return (
                        <div key={loyaltySetting.id}>
                            <p className="marTop15 marBot0 fontSize16 fontWeight400" style={{color: theme.white}}>
                                {loyaltySetting.name}
                            </p>

                            <p className="marTop3 marBot10 fontSize14 fontWeight400" style={{color: theme.darkGrayTwo}}>
                                {loyaltySetting.description}
                            </p>
                            <div className="flex gap15">
                                <FormCheck
                                    type="switch"
                                    id="stock_available"
                                    onChange={e => handleChange(e.target.checked, loyaltySetting)}
                                    checked={handleCheck(loyaltySetting)}
                                />
                                <p className="marTop3 marBot0 fontSize14 fontWeight400" style={{color: theme.white}}>
                                    {handleCheck(loyaltySetting) ? I18n.enabled : I18n.disabled}
                                </p>
                            </div>

                            {handleCheck(loyaltySetting) && (
                                <div className="loyaltyConversionCard">
                                    <div className="spaceBetweenCenter">
                                        <TextField
                                            onChange={onChange}
                                            label={I18n.every_time_customer_spends}
                                            placeholder={I18n.every_time_customer_spends}
                                            value={customerSpending}
                                            maxLength={8}
                                            onlyNumbers={true}
                                        />
                                        <p className="marRight20 marLeft20 marBot15 fontSize36" style={{color: theme.darkGrayTwo}}>
                                            =
                                        </p>
                                        <TextField
                                            onChange={onChange}
                                            label={I18n.customer_earns}
                                            placeholder={I18n.customer_earns}
                                            value={`${loyaltySetting?.setting_value?.unit_point} point`}
                                            disabled={true}
                                        />
                                    </div>
                                    <div className="spaceBetweenCenter">
                                        <TextField
                                            onChange={onChange}
                                            label={I18n.every_time_customer_uses}
                                            value={`${loyaltySetting?.setting_value?.unit_point} point`}
                                            disabled={true}
                                        />
                                        <p className="marRight20 marLeft20 marBot15 fontSize36" style={{color: theme.darkGrayTwo}}>
                                            =
                                        </p>
                                        <TextField
                                            onChange={onChange}
                                            label={I18n.customer_gets_discount_of}
                                            placeholder={I18n.customer_gets_discount_of}
                                            value={pointValue}
                                            maxLength={8}
                                            onlyNumbers={true}
                                        />
                                    </div>
                                </div>
                            )}
                        </div>
                    );
                })}
            </div>
            <MessageModal
                className="messageModal"
                setToggle={setConfirmationModal}
                toggle={confirmatiomModal}
                description={I18n.you_have_unsaved_changes}
                subDescription={I18n.if_you_leave_without_saving}
                primaryCta={{
                    title: I18n.save_changes,
                    action: handleSaveLoyalty,
                    backgroundColor: theme.brightGreen,
                    borderColor: theme.brightGreen,
                    txtColor: theme.white,
                    loading: loading,
                }}
                secondaryCta={{
                    title: I18n.discard,
                    action: handleDiscardModal,
                }}
            />
        </div>
    );
};

export default LoyaltyProgram;
