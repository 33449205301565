import React from 'react';
import {formatDate, formatNum, formatTime} from '../../../constants';
import {useAuthContext} from '../../../context';
import srbLogo from '.././../../assets/images/srbLogo.svg';
import fbrLogo from '.././../../assets/images/fbrLogo.svg';
import praLogo from '.././../../assets/images/praLogo.svg';
import QRCode from 'react-qr-code';
import Barcode from 'react-barcode';

const OrderCompletionReceipt = React.forwardRef((props, ref) => {
    const {businessSettings} = useAuthContext();
    const {orderReceiptData} = props;
    const {order, orderLines, customerData, user, table, showAmountSection, invoiceNumber, voucherCode, services} = orderReceiptData || {};
    const receiptCustomization = businessSettings?.['receipt_customization'];
    const configuredIntegration = JSON.parse(localStorage.getItem('integrations'));

    const {business_logo, business_address, business_website, company_registry, footer, header, tax_id} =
        receiptCustomization?.reduce((result, obj) => {
            if (obj.name) {
                const key = obj.name.replace(/\s+/g, '_').toLowerCase();
                result[key] = obj;
            }
            return result;
        }, {}) || {};

    return (
        <div
            ref={ref}
            style={{
                padding: '20px 10px',
                background: '#ffff',
                height: 'min-content',
                fontSize: '12px',
            }}
            className="orderReceipt">
            {business_logo?.setting_value?.status && (
                <div className="textCenter">
                    <img src={business_logo?.setting_value?.url} style={{width: 60, height: 60, objectFit: 'cover'}} />
                </div>
            )}

            <div>
                {business_address?.setting_value?.status && <p className="marTop10 textCenter  grayNew">{business_address?.setting_value?.value}</p>}
                {header?.setting_value?.status && <p className="marTop10 textCenter  grayNew">{header?.setting_value?.value}</p>}
            </div>

            <div
                style={{
                    display: 'flex',
                    justifyContent: 'space-between',
                }}
                className="width100 marTop20">
                <p>Date: {formatDate(order?.started_at)}</p>
                <p>Order# {order?.number}</p>
            </div>

            <div
                style={{
                    display: 'flex',
                    justifyContent: 'space-between',
                }}
                className="width100">
                <p>Time: {formatTime(order?.completed_at)}</p>
                <p>
                    Cashier: {user?.first_name} {user?.last_name}
                </p>
            </div>

            {customerData && (
                <div
                    style={{
                        display: 'flex',
                        justifyContent: 'space-between',
                    }}
                    className="width100">
                    <p>Customer: {customerData?.name}</p>
                    <p>Phone#: {customerData?.phone_number}</p>
                </div>
            )}

            {table && (
                <div className="width100 textCenter">
                    <p>Table: {table?.name}</p>
                </div>
            )}
            <div className="width100 marTop20 flex">
                <p className="width50  fontWeight600 fontSize12 grayNew">Items</p>
                <p className="width10  fontWeight600 textCenter fontSize12 grayNew">Qty</p>
                <p className="width40  fontWeight600 textRight fontSize12 grayNew">Amount</p>
            </div>
            <div style={{width: '100%', borderBottom: '1px dashed #CECECE', margin: '10px 0px'}} />

            <div>
                {orderLines?.map((order, ind) => {
                    return (
                        <div className={` width100 flex`}>
                            <p className="width50 grayNew">{order?.name}</p>
                            <p className="width10 textCenter grayNew">{`x${order?.quantity}`}</p>
                            <p className="width40 textRight grayNew">{`Rs. ${formatNum(order?.selling_price)}`}</p>
                        </div>
                    );
                })}
            </div>
            <div style={{width: '100%', borderBottom: '1px dashed #CECECE', margin: '10px 0px 0px 0px'}} />

            {showAmountSection ? (
                <>
                    <div
                        style={{
                            width: '100%',
                            display: 'flex',
                            justifyContent: 'space-between',
                            padding: '10px 0px 0px 0px',
                        }}>
                        <p className="grayNew">Subtotal</p>
                        <p className="grayNew">{`Rs. ${formatNum(order?.sub_total || 0)}`}</p>
                    </div>
                    <div
                        style={{
                            width: '100%',
                            display: 'flex',
                            justifyContent: 'space-between',
                            padding: '5px 0px 0xp 0px',
                        }}>
                        <p className="grayNew">Discounts</p>
                        <p className="grayNew">{`Rs. ${formatNum((order?.total_item_level_discount || 0) + (order?.order_level_discount || 0))}`}</p>
                    </div>

                    {order?.credited_amount && (
                        <div
                            style={{
                                width: '100%',
                                display: 'flex',
                                justifyContent: 'space-between',
                                padding: '5px 0px 0xp 0px',
                            }}>
                            <p className="grayNew">Store credit</p>
                            <p className="grayNew">{`-Rs. ${formatNum(order?.credited_amount)}`}</p>
                        </div>
                    )}
                    <div
                        style={{
                            width: '100%',
                            display: 'flex',
                            justifyContent: 'space-between',
                            padding: '5px 0px 0xp 0px',
                        }}>
                        <p className="grayNew">Sales tax</p>
                        <p className="grayNew">{`Rs. ${formatNum(order?.total_tax || 0)}`}</p>
                    </div>
                    {services?.length > 0 &&
                        services?.map(service => (
                            <div
                                key={service.id}
                                style={{
                                    width: '100%',
                                    display: 'flex',
                                    justifyContent: 'space-between',
                                    padding: '5px 0px 0xp 0px',
                                }}>
                                <p className="grayNew"> {service.name}</p>
                                <p className="grayNew">{`Rs. ${formatNum(service.selling_price)}`}</p>
                            </div>
                        ))}

                    <div style={{width: '100%', borderBottom: '1px dashed #CECECE', margin: '5px 0px 10px 0px'}} />

                    <div
                        style={{
                            width: '100%',
                            display: 'flex',
                            justifyContent: 'space-between',
                            padding: '5px 0px 0xp 0px',
                        }}>
                        <p className="fontSize14 fontWeight700 grayNew">{order?.is_refunded ? 'Total refund' : 'Total'}</p>
                        <p className="fontSize14 fontWeight700 grayNew">{`Rs. ${formatNum(order?.total)}`}</p>
                    </div>
                    <div style={{width: '100%', borderBottom: '1px dashed #CECECE', margin: '5px 0px 10px 0px'}} />

                    {order?.received_amount ? (
                        <>
                            <div
                                style={{
                                    width: '100%',
                                    display: 'flex',
                                    justifyContent: 'space-between',
                                    padding: '10px 0px 0px 0px',
                                }}>
                                <p className="grayNew">{`${order?.payment_method?.charAt(0).toUpperCase() + order?.payment_method?.slice(1)}`}</p>
                                <p className="grayNew">{`Rs. ${formatNum(order?.received_amount)}`}</p>
                            </div>
                            <div
                                style={{
                                    width: '100%',
                                    display: 'flex',
                                    justifyContent: 'space-between',
                                    padding: '0px 0px 10px 0px',
                                }}>
                                <p className="grayNew">Change</p>
                                <p className="grayNew">{`Rs. ${formatNum(order?.change)}`}</p>
                            </div>
                        </>
                    ) : null}
                </>
            ) : null}

            <div
                style={{
                    width: '100%',
                    display: 'flex',
                    justifyContent: 'space-between',
                    padding: '5px 0px 0px 0px',
                }}>
                {tax_id?.setting_value?.status && <p className="fontSize10 grayNew">{`Tax ID: ${tax_id?.setting_value?.value}`}</p>}
                {company_registry?.setting_value?.status && <p className="fontSize10 grayNew">{`Reg No. : ${company_registry?.setting_value?.value}`}</p>}
            </div>

            {order?.payment_method === 'store_credit' && voucherCode && (
                <div className="">
                    <p>Note: This store credit can be used for future purchases at this store only and is non-transferable.</p>
                    <div className="flex horizontalCenter">
                        <Barcode value={voucherCode} height={40} />
                    </div>
                </div>
            )}

            {invoiceNumber && (
                <div className="marTop20" style={{display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column'}}>
                    <img
                        src={configuredIntegration.integration === 'SRB configuration' ? srbLogo : configuredIntegration.integration === 'PRA configuration' ? praLogo : fbrLogo}
                        alt="logo"
                        width={configuredIntegration.integration === 'FBR configuration' ? 80 : 60}
                    />
                    <p className="marTop10 grayNew textCenter">{`Invoice Number: ${invoiceNumber}`}</p>
                    <QRCode style={{width: '100%', textAlign: 'center'}} className="textCenter" value={invoiceNumber} size={60} />
                </div>
            )}

            <div className="width100 marTop40">
                {business_website?.setting_value?.status && <p className="grayNew textCenter">{business_website?.setting_value?.value}</p>}
                {footer?.setting_value?.status && <p className="grayNew textCenter account">{footer?.setting_value?.value}</p>}
            </div>
        </div>
    );
});

export default OrderCompletionReceipt;
