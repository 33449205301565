import {formatNum} from '../../../../../constants';
import {useLanguageContext, useThemeContext} from '../../../../../context';
import useFocus from '../../../../../hooks/useFocus';
import {Button, Calculator, SearchBox} from '../../../../common';
import {toast} from 'react-toastify';

const CashMethod = ({
    handleDone,
    paymentType,
    setAmount,
    amount,
    total,
    change,
    givenPosDiscount,
    order,
    orderLines,
    customer,
    pendingPayment,
    isLastPayment,
    setSuccessModal,
    completed_payments,
}) => {
    const {theme} = useThemeContext();
    const {I18n} = useLanguageContext();

    const {textBoxRef} = useFocus(true);

    const handleChange = (label, value) => {
        setAmount(value);
    };

    const handleKeyPress = event => {
        if (event.keyCode == 13 && amount >= total) {
            handlePayment();
        }
    };

    const handlePayment = async () => {
        if (order?.type === 'delivery' && !customer) {
            let message = 'Customer is needed for delivery order';
            toast.success(message);
            return;
        } else if (paymentType?.name === 'credit' && !customer) {
            let message = 'Add customer if paying with credit flow';
            toast.success(message);
            return;
        } else {
            if (pendingPayment) {
                const paymentParams = {
                    payment_method: paymentType?.name,
                    status: 'complete',
                    received_amount: Number(amount),
                    change: Number(change),
                    completed_at: new Date().getTime(),
                };

                const resp = await pendingPayment.updatePayment(paymentParams);

                if (paymentType?.name === 'credit' && customer) {
                    await customer.updateCredit(Number(pendingPayment?.amount), 'add');
                }

                if (isLastPayment) {
                    handleDone('', true);
                } else {
                    setSuccessModal({
                        total,
                        change,
                        method: paymentType?.name,
                    });
                }
            } else {
                handleDone(paymentType?.name, false);
            }
        }
    };

    return (
        <>
            <div className="cashMainContainer">
                <div className="amountInputBox">
                    <SearchBox
                        type="pos"
                        placeholder={I18n.enter_amount}
                        onlyNumbers={true}
                        value={formatNum(amount || 0)}
                        maxLength={10}
                        prefix={{
                            name: 'rsIcon2',
                            fill: theme.seperatorTwo,
                            height: 35,
                            width: 35,
                            viewBox: '0 0 34 34',
                        }}
                        onChange={handleChange}
                        inputRef={textBoxRef}
                    />
                </div>

                <div className="billScreenCalculator">
                    <Calculator className={`width100`} setState={setAmount} columnFour={true} maxLength={10} />
                </div>
                <div className="billScreenChangeButton">
                    <Button
                        backgroundColor={Number(amount) < Number(total) ? theme.seperatorTwo : theme.brightGreen}
                        txtColor={Number(amount) < Number(total) ? theme.lightGrayTwo : theme.white}
                        className={`width60 fontWeight500 fontSize18 cursorPointer`}
                        disabled={amount >= total && orderLines?.length ? false : true}
                        title={
                            pendingPayment
                                ? `Payment ${completed_payments?.length + 1}  (${I18n.change} Rs. ${formatNum(change)})`
                                : `${I18n.change} Rs. ${formatNum(change)} (Enter)`
                        }
                        handleClick={handlePayment}
                    />
                </div>
            </div>
        </>
    );
};

export default CashMethod;
