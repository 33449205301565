import {useLocation, useNavigate} from 'react-router-dom';
import {useLanguageContext, useThemeContext, useAuthContext} from '../../../../context';
import {Header, Loading, NonIdealScreen} from '../../../common';
import {Col, Container, Row} from 'react-bootstrap';
import {useEffect, useState} from 'react';
import AddKDS from '../../../common/AddKDS';
import CreateDeviceModal from '../../../common/CreateDeviceModal';
import {getAccountDevices} from '../../../../api';
import useFilter from '../../../../hooks/useFilter';
import {useSearch} from '../../../../hooks';
import {getAccountBusiness} from '../../../../constants';

const KitchenDisplay = () => {
    const {theme} = useThemeContext();
    const {I18n} = useLanguageContext();
    const {business, userBusiness, account} = useAuthContext();
    const [loading, setloading] = useState(false);
    const [addDeviceModal, setAddDeviceModal] = useState(false);
    const [deviceData, setDeviceData] = useState(null);
    const [devices, setDevices] = useState([]);
    const [cloneDevices, setCloneDevices] = useState([]);
    const [businesslocations, setBusinessLocation] = useState([]);

    const {text, setText, searchList} = useSearch(handleSearch);
    const {filteredList, setOption, option} = useFilter(handleFilter);

    const filterOption = [
        {
            title: I18n.all_location,
            action: () => handleFilter(I18n.all_location),
            checked: option == I18n.all_location || option === '' ? true : false,
        },
        ...businesslocations.map(location => ({
            title: location.name,
            action: () => handleFilter(location.name),
            checked: option == location.name ? true : false,
        })),
    ];

    function handleSearch(val) {
        return (option !== '' ? filteredList : cloneDevices)?.filter(item => item?.name?.toLowerCase().includes(val.toLowerCase()));
    }

    function handleFilter(selected) {
        if (selected == I18n.all_location) {
            setOption('');
            return cloneDevices;
        } else {
            setOption(selected);
            return devices.filter(x => x.location.toLowerCase() === selected.toLowerCase());
        }
    }
    const onChange = (event, key) => {
        let value = key || event?.target?.value || '';
        setText(value);
    };

    const handleCreateDevice = () => {
        setAddDeviceModal(true);
    };

    const fetchDevices = async () => {
        try {
            setloading(true);
            const response = await getAccountDevices();
            setDevices(response?.data);
            setCloneDevices(response?.data);
        } catch (error) {
            console.log('error', error);
        } finally {
            setloading(false);
        }
    };

    async function getBusinessOfAccount() {
        const singleSelection = true;
        const businessLocations = await getAccountBusiness(account, business, userBusiness, singleSelection);
        setBusinessLocation(businessLocations);
    }

    useEffect(() => {
        fetchDevices();
        getBusinessOfAccount();
    }, [deviceData]);

    return loading ? (
        <Loading />
    ) : (
        <>
            <Header
                type="search"
                search={{
                    type: 'pos',
                    placeholder: I18n.search_kds_by_name,
                    prefix: {
                        name: 'search2Icon',
                        fill: theme.white,
                    },
                    onChange: onChange,
                }}
                leftCta={{
                    title: I18n.all_location,
                    name: 'downIcon',
                    fill: theme.white,
                    width: '12',
                    height: '9',
                    viewBox: '0 0 8 5',
                    option: filterOption,
                }}
                rightCta={{title: I18n.add_kds_device, action: handleCreateDevice}}
                title={I18n.kitchen_display_devices}
            />
            <div className="kdsPageWrapper" style={{background: theme.blackBg}}>
                <Container fluid>
                    <Row>
                        <Col>
                            <p className="headSeperator"></p>

                            {devices.length > 0 ? (
                                <Row>
                                    {(text ? searchList : option ? filteredList : devices)?.map(item => {
                                        return (
                                            <Col lg={2} style={{marginTop: '20px'}}>
                                                <AddKDS setAddDeviceModal={setAddDeviceModal} item={item} setDeviceData={setDeviceData} />
                                            </Col>
                                        );
                                    })}
                                </Row>
                            ) : (
                                <AddKDS setAddDeviceModal={setAddDeviceModal} />
                            )}
                        </Col>
                    </Row>
                </Container>
            </div>

            <CreateDeviceModal toggle={addDeviceModal} setToggle={setAddDeviceModal} deviceData={deviceData} setDeviceData={setDeviceData} />
        </>
    );
};

export default KitchenDisplay;
