import {toast} from 'react-toastify';
import {Loading} from '../../../common';
import Icon from '../../../../assets/icons';
import BarcodeProduct from './BarcodeProduct';
import React, {useState, useEffect, useRef} from 'react';
import BarcodeReader from 'react-barcode-reader';
import {useAuthContext} from '../../../../context';
import {getPriceCheckerProduct} from '../../../../api';
import Scanner from '../../../../assets/images/Scanner.png';
import PriceCheckerFooter from '../../../common/PricecheckerFooter';

const BarcodeScanner = () => {
    const {productData, handelupdateProductData} = useAuthContext();
    const [productDetails, setProductDetails] = useState(null);
    const [ShowLoading, setShowLoading] = useState(false);
    const [timeoutId, setTimeoutId] = useState(null);

    const handleBarcode = barcode => {
        handleScan(barcode);
    };

    const handleScan = barcode => {
        let productFilter = productData.data;
        const product = productFilter.find(item => item.barcode === barcode);

        if (product) {
            setProductDetails(product);
            if (timeoutId) clearTimeout(timeoutId);
            const newTimeoutId = setTimeout(() => {
                setProductDetails(null);
            }, 7000);
            setTimeoutId(newTimeoutId);
        } else {
            setProductDetails('not found');
        }
    };

    const ProductRelod = async () => {
        try {
            setShowLoading(true);
            const LocalUrl = localStorage.getItem('siteUrl');
            const finalUrl = `${JSON.parse(LocalUrl)}/api/v1/price_checker_products`;
            const response = await getPriceCheckerProduct(finalUrl);
            handelupdateProductData(response);
            toast.success('Product updated successfully');
        } catch (error) {
            console.error('Error loading product data:', error);
            toast.error('Something went wrong. Please try again.');
        } finally {
            setShowLoading(false);
        }
    };

    // Cleanup timeout when component unmounts
    useEffect(() => {
        return () => {
            if (timeoutId) clearTimeout(timeoutId);
        };
    }, [timeoutId]);

    useEffect(() => {
        if (!productData) {
            ProductRelod();
        }
    }, []);

    return (
        <>
            {!ShowLoading ? (
                <div className="scanner-main">
                    <div className="shell-logo">
                        <Icon name={'shellIcon'} fill={'white'} viewBox={'0 0 115 110'} width={'195'} height={'140'} />
                    </div>
                    <div
                        onClick={() => {
                            ProductRelod();
                        }}
                        className="refresh-logo">
                        <Icon name={'Small-refresh'} fill={'white'} viewBox={'0 0 10 105'} width={'100'} height={'120'} />
                    </div>
                    <BarcodeReader onScan={handleBarcode} />
                    {productDetails?.product_id ? (
                        <BarcodeProduct productDetails={productDetails} />
                    ) : (
                        <div className="scanner-container">
                            {productDetails == 'not found' ? (
                                <>
                                    <h2>Item not found, Scan again</h2>
                                    <p>We couldn’t find this item. Try scanning again or ask a staff member for help.</p>
                                </>
                            ) : (
                                <>
                                    <h2>Scan the item barcode to check price</h2>
                                    <p>Hold the barcode in front of the scanner. The price will appear automatically.</p>
                                </>
                            )}
                            <div className="scanner-box">
                                <img src={Scanner} alt="Scanner" className="scanner" />
                            </div>
                        </div>
                    )}
                    <PriceCheckerFooter />
                </div>
            ) : (
                <Loading light={true} />
            )}
        </>
    );
};

export default BarcodeScanner;
