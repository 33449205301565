import {useLanguageContext, useThemeContext} from '../../../context';
import {useEffect, useState} from 'react';
import FormCheck from 'react-bootstrap/FormCheck';
import TextField from '../TextField';
import RadioButton from '../RadioButton';

const RolePermission = ({item, HandleToggle, selected, posPin, moduleAccess, createTeamComponent}) => {
    const {I18n} = useLanguageContext();
    const {theme} = useThemeContext();
    const [access, setAccess] = useState('view_only');

    const onChange = (label, value) => {};

    const radioOptions = [
        {
            label: 'View only',
            value: 'view_only',
        },
        {
            label: 'Full access',
            value: 'full_access',
        },
    ];

    useEffect(() => {
        if (moduleAccess) {
            setAccess(moduleAccess);
        }
    }, [moduleAccess]);

    const handleChange = async e => {
        const value = e?.target.value;
        setAccess(value);
        HandleToggle(true, item, value);
    };

    return (
        <div className={`${'permissionOptionNoBorder'} permissionOptionMain`}>
            <div className="teamPermissioncContainer">
                <div className="teamPermissionleft">
                    <FormCheck disabled={createTeamComponent} type="switch" id={item.title} onChange={e => HandleToggle(e.target.checked, item, access)} checked={selected} />
                </div>
                <div className="teamPermissionRight">
                    <h3 className="fontSize18 fontWeight600 marBot5" style={{color: theme.white}}>
                        {item?.title}
                    </h3>
                    <p className="fontSize12 fontWeight500 marBot0" style={{color: theme.white}}>
                        {item?.permisionDetails}
                    </p>
                </div>
            </div>

            {createTeamComponent && ['pos', 'restaurant-pos'].includes(selected?.name) ? (
                <div className="posPinInput">
                    <TextField disabled={true} onChange={onChange} value={posPin} label={I18n.pos_pin} placeholder={I18n.pos_pin} maxLength={4} />
                </div>
            ) : null}

            {selected?.name && (
                <RadioButton disable={createTeamComponent} options={radioOptions} className="selectAccess" selectedSize={access} handleChange={e => handleChange(e)} />
            )}
            <p style={{background: theme.topBarBG}} className="itemSeperator2"></p>
        </div>
    );
};
export default RolePermission;
