import React, { useState } from 'react';
import List from './List';
import { useLanguageContext, useThemeContext } from '../../../context';
import ReportsBoxHeader from '../Header/ReportsBoxHeader';
import { DEVICE_HEIGHT, downloadCsvVReport, firstLetterCaptilize, removeTime, } from '../../../constants';
import NonIdealScreen from '../NonIdeal';
import Loading from '../Loading';
import InfiniteScroll from 'react-infinite-scroll-component';
import { getDashboardGenerlreport } from '../../../api';
import './reportstyles.css';

const GeneralReport = ({ data, totalpages, setCurrentPage, locationString, reportDropDownOption, selectedDateRange, currentPage, loading }) => {
    const { I18n } = useLanguageContext();
    const { theme } = useThemeContext();
    const [isExportLoading, setIsExportLoading] = useState(false);

    const handleExport = async () => {
        try {
            setIsExportLoading(true);
            const params = {
                start_date: removeTime(selectedDateRange?.start_date),
                end_date: removeTime(selectedDateRange?.end_date),
                report_type: reportDropDownOption?.reportKey,
                entity_ids: locationString,
                export: true,
            };

            const response = await getDashboardGenerlreport(params);
            downloadCsvVReport(response, `${reportDropDownOption?.reportKey || 'report'}?.csv`);
            setIsExportLoading(false);
        } catch (error) {
            console.log('Error downloading the CSV file:', error);
            setIsExportLoading(false);
        }
    };

    const fetchMoreData = () => {
        if (currentPage < totalpages) {
            setCurrentPage(prevPage => {
                const nextPage = prevPage + 1;
                return nextPage;
            });
        }
    };

    return data?.data?.length > 0 ? (
        <div className="reportsWrapper">
            <div className="reportsWrapperInner">
                <ReportsBoxHeader
                    Headingtitle={`${firstLetterCaptilize(reportDropDownOption?.name)} report`}
                    primaryCta={{
                        title: 'export_csv',
                        action: handleExport,
                        saveLoad: isExportLoading
                    }}
                />

                <div className="generalReportTable">
                    <table>
                        <thead>
                            <tr>
                                {data?.headers?.map((item, index) => {
                                    return (
                                        <th key={index} className="">
                                            <p title={item} className="fontSize16 marBot0 OneLineTruncate cursorPointer" style={{ color: theme.white }}>
                                                {item}
                                            </p>
                                        </th>
                                    );
                                })}
                            </tr>
                        </thead>
                        <tbody>
                            <div id="scrollableDiv" className="" style={{ height: DEVICE_HEIGHT - 420, overflow: 'auto', }}>
                                <InfiniteScroll
                                    className="infiniteScrollBar"
                                    dataLength={data?.data?.length}
                                    next={fetchMoreData}
                                    hasMore={currentPage < totalpages}
                                    loader={<div className="reportsWrapper">
                                        <div className="reportsWrapperInner">
                                            <Loading />
                                        </div>
                                    </div>}
                                    scrollableTarget="scrollableDiv">
                                    {data?.data?.map((item, index) => {
                                        return <List data={item} key={index} header={data?.headers} />;
                                    })}
                                </InfiniteScroll>
                            </div>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    ) : (
        <div className="onlineNonIdeal reportDashboardBox">
            <NonIdealScreen
                heading={firstLetterCaptilize(reportDropDownOption?.name) || I18n.reports}
                subHeading={I18n.no_activity_zero_transactions_recorded}
                name="itemSummaryIcon"
                fill={theme.white}
                isMultiStep={false}
                viewBox="0 0 100 100"
            />
        </div>
    );
};

export default GeneralReport;
