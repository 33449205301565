import React from 'react';
import { useLanguageContext, useThemeContext } from '../../../context';
import Button from '../Button';
import Icon from '../../../assets/icons';

const DashboardDataBox = ({ title, children, icon, handleView, ViewMoreBtn, className, disableViewButton }) => {
    const { I18n } = useLanguageContext();
    const { theme } = useThemeContext();
    return (
        <div className="salesByPaymentBox">
            <div className="salesByPaymentBoxHeadinBox">
                <p className="flex">
                    {icon?.name && (
                        <span className='marRight5 rowConatainer'>
                            <Icon name={icon?.name} fill={icon.fill || theme.brightGreen} height={icon.height || '20'} width={icon?.width || '20'} viewBox={icon?.viewBox || '0 0 20 20'} />
                        </span>
                    )}
                    <span className="fontSize24 fontWeight600 white">{I18n[title]}</span>
                </p>
                {ViewMoreBtn && (
                    <Button
                        className={`headingViewBtn fontSize14 fontWeight500`}
                        type="secondary"
                        title={I18n.view_full_listing}
                        backgroundColor={theme.white}
                        borderColor={theme.inputBorder}
                        txtColor={theme.blackPearl}
                        disabled={disableViewButton}
                        buttonIcon={true}
                        icon_name="viewBtnIcon"
                        icon_fill={disableViewButton ? theme.darkGrayTwo : theme.blackPearl}
                        icon_width={'13'}
                        icon_height={'13'}
                        icon_viewBox={'0 0 13 13'}
                        handleClick={handleView}
                    />
                )}
            </div>
            <div className={`salesByPaymentBoxInner ${className}`}>{children}</div>
        </div>
    );
};

export default DashboardDataBox;
