import {useEffect, useState} from 'react';
import {Navigate, useLocation} from 'react-router-dom';
import {Loading} from '../../components/common';
import {useAuthContext} from '../../context';
import Cookies from 'js-cookie';

const RequireAuth = ({children}) => {
    const location = useLocation();

    const {user, handleLoginInDB, handleLogoutInDB, fetchSettings} = useAuthContext();

    const [isLoading, setIsLoading] = useState(true);

    useEffect(() => {
        handleAuth();
    }, []);

    const handleAuth = async () => {
        try {
            let token = Cookies.get('access_token');

            if (token) {
                if (!user) {
                    let session_id = localStorage.getItem('session');

                    if (!session_id) {
                        Cookies.remove('access_token');
                    } else {
                        const {account, DBbusiness} = await handleLoginInDB(session_id);
                        await fetchSettings(account.id, DBbusiness.id);
                    }
                }
            } else {
                if (user) {
                    await handleLogoutInDB();
                }
            }
        } catch (error) {
            console.log('Error during authentication:', error);
        } finally {
            setIsLoading(false);
        }
    };

    return isLoading ? <Loading /> : user ? children : <Navigate to="/login" state={{from: location}} replace />;
};

export default RequireAuth;
