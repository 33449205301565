import {useAuthContext, useLanguageContext, useThemeContext} from '../../../context';
import {convertToArray, DEVICE_HEIGHT, firstLetterCaptilize} from '../../../constants';
import {useCustomerOrdersCalculation, useOrderCalculation} from '../../../hooks';
import Button from '../Button';
import BuyerSummary from './BuyerSummary';
import AmountBox from './AmountBox';
import {useEffect, useRef, useState} from 'react';
import {ItemDiscountsModal} from '../V2';
import ToastBox from '../ToastBox';
import CreditHistoryModal from '../CreditHistoryModal';
import ResturantSummary from './ResturantSummary';
import RefundItemModal from '../RefundItemModal';
import OrderCompletionReceipt from '../OrderCompletionReceipt';
import {useReactToPrint} from 'react-to-print';
import {useNavigate} from 'react-router-dom';

const CustomerDetailComponent = ({
    customer,
    buyerSummary = false,
    amountBoxShow = false,
    showRestaurantSummary = false,
    order,
    sales_tax,
    discount,
    order_lines,
    table,
    services,
}) => {
    const {theme} = useThemeContext();
    const {I18n} = useLanguageContext();
    const {business, user} = useAuthContext();
    let navigate = useNavigate();

    const [creditModal, setCreditModal] = useState(false);
    const [creditHistoryModal, setCreditHistoryModal] = useState(false);
    const [showMessage, setShowMessage] = useState({
        visible: false,
        message: '',
    });

    let orders = convertToArray(order);
    const {name, phone_number, email, available_points, total_credit, created_at} = customer || {};
    const {subTotal, total, tax, givenOrderDiscount, givenPosDiscount} = useOrderCalculation({orderLines: order_lines, discount, sales_tax});
    const {totalSpent, averageSpend} = useCustomerOrdersCalculation(orders);
    const componentRef = useRef();
    const [orderReceiptData, setOrderReceiptData] = useState({});
    const [refundModal, setRefundModal] = useState(false);

    const handlePrint = useReactToPrint({
        content: () => componentRef.current,
    });

    useEffect(() => {
        if (showMessage.visible) {
            const timeoutId = setTimeout(() => {
                setShowMessage({
                    visible: false,
                    message: '',
                });
            }, 3000);
            return () => clearTimeout(timeoutId);
        }
    }, [showMessage]);

    const prepareReceiptData = async () => {
        const account = await business?.account?.fetch();
        const customerData = await order?.customer?.fetch();
        const orderLines = await order?.order_line?.fetch();
        let voucher = await order?.voucher?.fetch();

        if (voucher?.length === 1) {
            voucher = voucher[0];
        }

        const orderReceipt = {
            account,
            business,
            order,
            orderLines,
            customerData,
            user,
            table,
            showAmountSection: true,
            services,
            voucherCode: voucher?.barcode,
        };
        setOrderReceiptData(orderReceipt);
    };

    useEffect(() => {
        prepareReceiptData();
    }, [order]);

    const toggleCreditModal = () => setCreditModal(x => !x);
    const toggleCreditHistory = () => setCreditHistoryModal(x => !x);

    const handleCreditHistory = () => {
        toggleCreditHistory();
    };

    const handlePayCredit = () => {
        if (customer?.total_credit) toggleCreditModal();
    };

    const handleRefund = async () => {
        setRefundModal(true);
    };

    const handleAddCreditAmount = async (type, value) => {
        const creditPayload = {
            payment_method: type,
            amount: Number(value),
            status: 'completed',
            completed_at: new Date().getTime(),
        };

        await customer.createCreditTransaction(creditPayload);
        await customer.updateCredit(Number(value), 'subtract');

        toggleCreditModal();
        setShowMessage({
            visible: true,
            message: `Rs. ${value} credit paid successfully`,
        });
    };

    return (
        <>
            <div className={`orderDetailCustomerBox`} style={{height: DEVICE_HEIGHT - 105}}>
                <div className={'orderSummaryContainer'} style={{height: '100%'}}>
                    <div>
                        <div className="rowConatainer garyBorderBottom">
                            <div className="detailBotCol">
                                <p className="fontSize12 marBot0" style={{color: theme.darkGrayTwo}}>
                                    {I18n.customer_details}
                                </p>
                                <p className="fontSize14 marBot0" style={{color: theme.white}}>
                                    {firstLetterCaptilize(name) || '--'}
                                </p>
                            </div>

                            <div className="detailBotCol">
                                <p className="fontSize12 marBot0" style={{color: theme.darkGrayTwo}}>
                                    {I18n.phone_number}
                                </p>
                                <p className="fontSize14 marBot0" style={{color: theme.white}}>
                                    {phone_number || '--'}
                                </p>
                            </div>

                            <div className="detailBotCol">
                                <p className="fontSize12 marBot0" style={{color: theme.darkGrayTwo}}>
                                    {I18n.email_address}
                                </p>
                                <p className="fontSize14 marBot0" style={{color: theme.white}}>
                                    {email || '--'}
                                </p>
                            </div>
                        </div>

                        {order?.is_refunded && (
                            <div className="garyBorderBottom">
                                <div className="spaceBetweenCenter">
                                    <p className="fontSize12 marTop10 marBot0" style={{color: theme.white}}>
                                        {I18n.refund_reason}
                                    </p>
                                    <p className="fontSize14 marTop10 marBot0" style={{color: theme.brightOrange}}>
                                        {order.refunded_reason}
                                    </p>
                                </div>

                                <div className="spaceBetweenCenter">
                                    <p className="fontSize12 marTop10 marBot10" style={{color: theme.white}}>
                                        {I18n.original_order_reference_number}
                                    </p>
                                    <p
                                        className="fontSize14 marTop10 marBot10 cursorPointer"
                                        style={{color: theme.barclaysBlue}}
                                        onClick={() => navigate(`/orders/${order?.parent_number}`)}>
                                        {order?.parent_number}
                                    </p>
                                </div>
                            </div>
                        )}

                        {showRestaurantSummary && <ResturantSummary order={order} />}

                        {!amountBoxShow && (
                            <div className="orderDetailsLoyalityBox">
                                <div className="detailBotCol">
                                    <p className="fontSize12 marBot0" style={{color: theme.darkGrayTwo}}>
                                        {I18n.loyalty_point_available}
                                    </p>
                                    <p className="fontSize18 marBot0" style={{color: theme.mustardYellow}}>
                                        {available_points || 0}
                                    </p>
                                </div>

                                <div className="detailBotCol">
                                    <p className="fontSize12 marBot0" style={{color: theme.darkGrayTwo}}>
                                        {I18n.credit}
                                    </p>
                                    <p className="fontSize18 marBot0" style={{color: theme.red}}>
                                        {`Rs. ${total_credit?.toFixed(2) || 0}`}
                                    </p>
                                </div>
                            </div>
                        )}
                        {buyerSummary && <BuyerSummary orders={order} totalSpent={totalSpent} averageSpend={averageSpend} created_at={created_at} />}
                    </div>

                    <div>
                        {amountBoxShow && (
                            <AmountBox
                                subTotal={subTotal}
                                givenPosDiscount={givenPosDiscount}
                                givenOrderDiscount={givenOrderDiscount}
                                order={order}
                                tax={tax}
                                total={total}
                                services={services}
                            />
                        )}

                        {orders?.length > 0 && (
                            <div className="rowContainer">
                                <Button
                                    className={`marleft10 marRight10 cursorPointer width100`}
                                    title={amountBoxShow ? I18n.refund : I18n.pay_credits}
                                    backgroundColor={amountBoxShow ? theme.seperatorTwo : theme.blackBg}
                                    borderColor={theme.darkSlateBlue}
                                    handleClick={amountBoxShow ? handleRefund : handlePayCredit}
                                    disabled={showRestaurantSummary || order?.is_refunded}
                                />
                                <Button
                                    type={amountBoxShow ? '' : 'primaryButton'}
                                    title={amountBoxShow ? I18n.print_receipt : I18n.credit_history}
                                    handleClick={amountBoxShow ? handlePrint : handleCreditHistory}
                                    borderColor={amountBoxShow ? theme.brightGreen : theme.barclaysBlue}
                                    className={`marleft10 marRight10 cursorPointer width100`}
                                />
                            </div>
                        )}
                    </div>
                </div>
            </div>
            <ToastBox className="addSuccessfullyToast" ToastVisiable={showMessage.visible} bodyPara={showMessage.message} setShowMessage={setShowMessage} showIcon={true} />
            <ItemDiscountsModal
                setToggle={setCreditModal}
                toggle={creditModal}
                handleSave={handleAddCreditAmount}
                title={I18n.credit_amount}
                selectedItem={customer}
                from="customer"
            />
            <CreditHistoryModal setToggle={setCreditHistoryModal} toggle={creditHistoryModal} setShowMessage={setShowMessage} customer={customer} />
            <div style={{display: 'none'}}>
                <OrderCompletionReceipt ref={componentRef} orderReceiptData={orderReceiptData} />
            </div>
            <RefundItemModal order={order} toggle={refundModal} setToggle={setRefundModal} order_lines={order_lines} />
        </>
    );
};

export default CustomerDetailComponent;
