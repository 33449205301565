export const blackTheme = {
    background: '#131721',
    text: '#FFFFFF',
    topBarBG: '#1B1F2B',
    brightGreen: '#4AC600',
    white: '#FFFFFF',
    blackFont: '#1C1C1C',
    blueTxt: '#007AFF',
    highlight: '#D9D9D9',
    wifiOff: '#959595',
    dark: '#00072D',
    secondaryText: '#7F8DAA',
    imageHighlight: '#0E6BA8',
    compImgHighlight: '#E6E6E6',
    jacketColor: '#0A2472',
    headerColor: '#0E6BA877',
    lightGray: '#CECECE',
    darkGrayTwo: '#7D7D7D',
    seperatorBg: '#414143',
    posRightBg: '#252A3D',
    newposRightBg: '#1F2432',
    seperatorTwo: '#3F4659',
    seperatorThree: '#10131B',
    barclaysBlue: '#00AEEF',
    seaGreen: '#4DB6AC',
    mustardYellow: '#F2BC1D',
    mediumBlue: '#E9F6FC',
    red: '#E51A32',
    lightGrayTwo: '#A4A4A4',
    inputBorder: '#2F3651',
    mediumGreen: '#36AA71',
    seaBlue: '#3CC9DC',
    blackBg: '#131721',
    textgray: '#667297',
    darkSlateBlue: '#232C42',
    midnightBlue: '#161B27',
    darkSlateBlue2: '#161B28',
    darkBluishGray: '#232939',
    lightGrayThree: '#555968',
    darkGreen: '#048900',
    brightOrange: '#f96a32',
    purples: '#805CF4',
    newBorderColor: '#3D4253',
    newBorderColorTwo: '#868993',
    lightGreen: '#9ADF71',
    extralightGreen: '#D7F2C6',
    lightGreenNew: '#EBF9E3',
    grayBgTwo: '#555968',
    newBg: '#1D212F',
    lightRed: '#F9E3E3',
    brown: '#B97A69',
    inputText: '#91939b',
    darkgrayBorderbg: '#232736',
    disableGray: '#777682',
    blackPearl: '#1d212d',
    iconBlue: '#44A0FC',
    iconGreen: '#36AA71',
    iconBrown: '#A6878A',
    greenshadeNew: '#D1FAB3',
    peach: '#FFE6C1',
    lightOrange: '#ffe7dc',
    tableBorder: '#191f2d',
    lightGreenTwo: '#F1FCD2',
    nonIdealText: '#555968',
    blueGray: '#1A2236',
    mediumGray: '#565656',
    limeGreen: '#A8F170',
    offWhite: '#F0F1F3',
};

export const whiteTheme = {
    background: '#E5E5E5',
    blackBg: '#131721',
    text: '#131721',
    topBarBG: '#1B1F2B',
    inputBorder: '#2F3651',
    brightGreen: '#4AC600',
    white: '#FFFFFF',
    wifiOff: '#959595',
    blackFont: '#1C1C1C',
    blueTxt: '#007AFF',
    highlight: '#F7FBFA',
    dark: '#00072D',
    secondaryText: '#8D697A',
    imageHighlight: '#E29F95',
    compImgHighlight: '#E6E6E6',
    jacketColor: '#FB6376',
    headerColor: '#E29F9577',
    lightGray: '#CECECE',
    darkGrayTwo: '#7D7D7D',
    lightGrayThree: '#555968',
    seperatorThree: '#10131B',
    seperatorBg: '#414143',
    posRightBg: '#252A3D',
    newposRightBg: '#1F2432',
    seperatorTwo: '#3F4659',
    barclaysBlue: '#00AEEF',
    mediumBlue: '#E9F6FC',
    red: '#E51A32',
    lightGrayTwo: '#A4A4A4',
    mediumGreen: '#36AA71',
    seaBlue: '#3CC9DC',
    darkGreen: '#048900',
    textgray: '#667297',
    darkSlateBlue: '#232C42',
    midnightBlue: '#161B27',
    darkSlateBlue2: '#161B28',
    darkBluishGray: '#232939',
    purples: '#805CF4',
    brightOrange: '#F96A32',
    newBorderColor: '#3D4253',
    newBorderColorTwo: '#868993',
    lightGreen: '#9ADF71',
    extralightGreen: '#D7F2C6',
    lightGreenNew: '#EBF9E3',
    grayBgTwo: '#555968',
    newBg: '#1D212F',
    lightRed: '#F9E3E3',
    brown: '#B97A69',
    inputText: '#91939b',
    darkgrayBorderbg: '#232736',
    disableGray: '#777682',
    blackPearl: '#1d212d',
    iconBlue: '#44A0FC',
    iconGreen: '#36AA71',
    iconBrown: '#A6878A',
    greenshadeNew: '#D1FAB3',
    peach: '#FFE6C1',
    lightOrange: '#ffe7dc',
    tableBorder: '#191f2d',
    lightGreenTwo: '#F1FCD2',
    nonIdealText: '#555968',
    blueGray: '#1A2236',
    mediumGray: '#565656',
    limeGreen: '#A8F170',
    offWhite: '#F0F1F3',
};

export const AVATAR_COLORS = {
    A: blackTheme.mediumGreen,
    B: blackTheme.mustardYellow,
    C: blackTheme.barclaysBlue,
    D: blackTheme.seaBlue,
    E: blackTheme.darkGreen,
    F: blackTheme.mediumGreen,
    G: blackTheme.mustardYellow,
    H: blackTheme.barclaysBlue,
    I: blackTheme.seaBlue,
    J: blackTheme.darkGreen,
    K: blackTheme.mediumGreen,
    L: blackTheme.mustardYellow,
    M: blackTheme.barclaysBlue,
    N: blackTheme.seaBlue,
    O: blackTheme.darkGreen,
    P: blackTheme.mediumGreen,
    Q: blackTheme.mustardYellow,
    R: blackTheme.barclaysBlue,
    S: blackTheme.seaBlue,
    T: blackTheme.darkGreen,
    U: blackTheme.mediumGreen,
    V: blackTheme.mustardYellow,
    W: blackTheme.barclaysBlue,
    X: blackTheme.seaBlue,
    Y: blackTheme.darkGreen,
    Z: blackTheme.mediumGreen,
};
