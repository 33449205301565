import React from 'react';
import { useLanguageContext, useThemeContext } from '../../../context';
import TableBody from '../TableContainer/TableBody';
import { firstLetterCaptilize, formatNum } from '../../../constants';

const ItemsList = ({ data }) => {
    const { theme } = useThemeContext();
    const { I18n } = useLanguageContext();

    const { item_name, low_stock_level, stock_gap, current_stock } = data || {};

    return (
        <TableBody className={`threeColumn cursorPointer`} style={{ borderColor: theme.inputBorder }}>
            <div className="BoxWidth justifyStart">
                <p title={firstLetterCaptilize(item_name || "--")} className="fontSize14 OneLineTruncate cursorPointer" style={{ color: theme.white }}>
                    {firstLetterCaptilize(item_name || "--")}
                </p>
            </div>
            <div className="BoxWidth justifyCenter">
                <p title={formatNum(current_stock) || 0} className="fontSize14 OneLineTruncate cursorPointer" style={{ color: theme.white }}>
                    {formatNum(current_stock) || 0}
                </p>
            </div>
            <div className="BoxWidth justifyCenter">
                <p title={formatNum(low_stock_level || 0)} className="fontSize14 OneLineTruncate cursorPointer" style={{ color: theme.white }}>
                    {formatNum(low_stock_level || 0)}
                </p>
            </div>
            <div className="BoxWidth justifyCenter">
                <p title={formatNum(stock_gap) || 0} className="fontSize14 OneLineTruncate cursorPointer" style={{ color: theme.white }}>
                    {formatNum(stock_gap) || 0}
                </p>
            </div>
        </TableBody >
    );
};

export default ItemsList;
