import {Col, Row} from 'react-bootstrap';
import {useAuthContext, useLanguageContext, useThemeContext} from '../../../context';
import {TextField, Header, CustomContainer, MessageModal, Loading} from '../../common';
import {validateCompanyCreation, validateDraftCreateCompany} from '../../../constants';
import {useEffect, useState} from 'react';
import {getAllCountry, Sync} from '../../../api';
import {useLocation, useNavigate} from 'react-router-dom';

const CreateCompanyComponent = ({company}) => {
    const {I18n} = useLanguageContext();
    const {theme} = useThemeContext();
    const navigate = useNavigate();
    const {state} = useLocation();
    const {user, business, setRenderToast, account} = useAuthContext();

    const [name, setName] = useState(company?.name || '');
    const [email, setEmail] = useState(company?.email || '');
    const [phone, setPhone] = useState(company?.phone_number || '');
    const [country, setCountry] = useState(company?.country || '');
    const [province, setProvince] = useState(company?.province || '');
    const [city, setCity] = useState(company?.city || '');
    const [address, setAddress] = useState(company?.address || '');
    const [postalCode, setPostalCode] = useState(company?.postal_code || '');
    const [errors, setErrors] = useState({});
    const [discardModalToggle, setDiscardModalToggle] = useState(false);
    const [loading, setLoading] = useState(false);
    const [data, setData] = useState([]);
    const [provincesList, setProvincesList] = useState([]);
    const [citiesList, setCitiesList] = useState([]);

    useEffect(() => {
        const fetchCountries = async () => {
            setLoading(true);
            try {
                const res = await getAllCountry();
                setData(res?.data);
            } catch (error) {
                console.log(error.message);
            } finally {
                setLoading(false);
            }
        };

        fetchCountries();
    }, []);

    const onChange = (label, val) => {
        if (label === I18n.company_name) setName(val);
        else if (label === I18n.email_address) setEmail(val);
        else if (label === I18n.phone_number) setPhone(val);
        else if (label === I18n.country) {
            setCountry(val);
            setProvince('');
            setCity('');
            const selectedCountry = data.find(item => item.countryName === val);
            setProvincesList(selectedCountry ? selectedCountry.provinces : []);
        } else if (label === I18n.province) {
            setProvince(val);
            setCity('');
            const selectedProvince = provincesList.find(item => item?.provinceName === val);
            setCitiesList(selectedProvince ? selectedProvince.cities : []);
        } else if (label === I18n.city) setCity(val);
        else if (label === I18n.zip_code) setPostalCode(val);
        else if (label === I18n.address) setAddress(val);
    };

    const creatingCompany = async val => {
        const {draft, id} = company || {};
        let params = {
            name,
            email,
            phone,
            country,
            province,
            city,
            address,
            postalCode,
            draft: val === 'saveDraft',
        };

        let createdCompany;
        const formErrors = await (val === 'save' ? validateCompanyCreation(params) : validateDraftCreateCompany(params));

        setErrors(formErrors);

        if (Object.keys(formErrors).length > 0) return;

        setLoading(true);
        if (company) {
            createdCompany = await company.updateDetails(params);
        } else {
            createdCompany = await account.createCompany(params);
        }

        let toastMessage;
        if (val === 'saveDraft') {
            toastMessage = 'company_saved_into_draft';
        } else {
            toastMessage = draft ? 'company_published_successfully' : id ? 'company_update' : 'company_created';
        }

        setRenderToast(toastMessage);
        await Sync(user.email);
        setLoading(false);
        if (state?.from === 'po_creation') {
            navigate(`/purchaseorder/create?company_id=${createdCompany?.id}`);
        } else if (state?.from === 'po_edit' && state?.id) {
            navigate(`/purchaseorder/${state?.id}?company_id=${createdCompany?.id}`);
        } else {
            navigate(-1);
        }
    };

    const handleEnterPress = event => {
        if (event?.key === 'Enter') {
            creatingCompany('save');
        }
    };

    const toggleDiscardModal = () => {
        setDiscardModalToggle(x => !x);
    };

    const handleDiscardAction = () => {
        navigate(-1);
    };

    return loading ? (
        <Loading />
    ) : (
        <div onKeyDown={handleEnterPress}>
            <Header
                type="draftHeader"
                title={I18n.back}
                backAction={toggleDiscardModal}
                saveCta={{
                    title: I18n.save,
                    action: () => creatingCompany('save'),
                }}
                primaryCta={
                    !company?.id
                        ? {
                              title: I18n.save_draft,
                              action: () => creatingCompany('saveDraft'),
                          }
                        : null
                }
            />
            <CustomContainer reduceHeight={45} className="addScreenMainBox  createCustomerContainer">
                <Row className="justify-content-md-center">
                    <Col md={6}>
                        <div className="signupTextBox">
                            <h3 className="fontSize24  textCenter fontWeight600 marBot20 marTop0" style={{color: theme.text}}>
                                {I18n.create_company}
                            </h3>
                            <h3 className="fontSize20 fontWeight400 marBot15 marLeft15 marTop0" style={{color: theme.text}}>
                                {I18n.company_details}
                            </h3>
                        </div>

                        <div className="itemFields">
                            <div className="intventoryFormGridTwo">
                                <div className="priceSectionInputLeft ">
                                    <TextField
                                        onChange={onChange}
                                        error={errors['name']}
                                        maxLength={50}
                                        label={I18n.company_name}
                                        placeholder={I18n.company_name}
                                        value={name}
                                        required={true}
                                        autoFocus={true}
                                    />
                                </div>
                                <div className="priceSectionInputRight">
                                    <TextField onChange={onChange} error={errors['email']} label={I18n.email_address} placeholder={I18n.enter_email_address} value={email} />
                                </div>
                            </div>
                            <div className="intventoryFormGridTwo">
                                <div className="priceSectionInputLeft">
                                    <TextField
                                        onChange={onChange}
                                        error={errors['phone']}
                                        label={I18n.phone_number}
                                        placeholder={I18n.phone_number}
                                        value={phone}
                                        maxLength={11}
                                        type={'numeric'}
                                    />
                                </div>
                                <div className="priceSectionInputRight">
                                    <TextField
                                        label={I18n.country}
                                        placeholder={I18n.country}
                                        data={data?.map(item => item?.countryName)}
                                        value={country}
                                        onChange={onChange}
                                        inputType="select"
                                        type="text"
                                        suffix="downIcon2"
                                        suffix_fill={theme.white}
                                        suffix_width={'24'}
                                        suffix_height={'24'}
                                        suffix_viewBox={'0 0 18 18'}
                                    />
                                </div>
                            </div>

                            <div className="intventoryFormGridTwo">
                                <div className="priceSectionInputLeft">
                                    <TextField
                                        label={I18n.province}
                                        placeholder={I18n.province}
                                        data={provincesList?.map(item => item.provinceName)}
                                        value={province}
                                        onChange={onChange}
                                        inputType="select"
                                        type="text"
                                        suffix="downIcon2"
                                        suffix_fill={theme.white}
                                        suffix_width={'24'}
                                        suffix_height={'24'}
                                        suffix_viewBox={'0 0 18 18'}
                                    />
                                </div>

                                <div className="priceSectionInputRight">
                                    <TextField
                                        label={I18n.city}
                                        placeholder={I18n.city}
                                        data={citiesList}
                                        value={city}
                                        onChange={onChange}
                                        inputType="select"
                                        type="text"
                                        suffix="downIcon2"
                                        suffix_fill={theme.white}
                                        suffix_width={'24'}
                                        suffix_height={'24'}
                                        suffix_viewBox={'0 0 18 18'}
                                    />
                                </div>
                            </div>

                            <div className="intventoryFormGridTwo">
                                <div className="priceSectionInputLeft">
                                    <TextField onChange={onChange} label={I18n.zip_code} placeholder={I18n.zip_code} value={postalCode} type={'numeric'} />
                                </div>
                                <div className="priceSectionInputRight">
                                    <TextField onChange={onChange} label={I18n.address} placeholder={I18n.address} value={address} />
                                </div>
                            </div>
                        </div>
                    </Col>
                </Row>
                <MessageModal
                    className="messageModal"
                    setToggle={setDiscardModalToggle}
                    toggle={discardModalToggle}
                    description={I18n.do_you_really_want_to_discard}
                    subDescription={I18n.this_action_is_irreversible_once_you_discard_it_its_gone}
                    secondaryCta={{
                        title: I18n.cancel,
                        action: () => toggleDiscardModal(),
                    }}
                    primaryCta={{
                        title: I18n.discard,
                        backgroundColor: theme.white,
                        borderColor: theme.red,
                        txtColor: theme.red,
                        action: () => handleDiscardAction(),
                        isDelete: true,
                    }}
                />
            </CustomContainer>
        </div>
    );
};

export default CreateCompanyComponent;
