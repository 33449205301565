import {formatNum} from '../../../constants';
import {useLanguageContext, useThemeContext} from '../../../context';
import Icon from '../../../assets/icons';

const BillAmountBox = ({className, subTotal, total, tax, givenPosDiscount, giveItemLevelDiscount, redeemAmount, storeCreditAmount, handleRemoveStoreCredit, services}) => {
    const {theme} = useThemeContext();
    const {I18n} = useLanguageContext();

    return (
        <div
            className={`billAmountBoxMain ${className}`}
            style={{
                background: theme.topBarBG,
                height: 'min-content',
            }}>
            <div className="billAmountRow billAmountTotal" style={{borderColor: theme.darkgrayBorderbg}}>
                <p className="marBot0 fontSize20" style={{color: theme.white}}>
                    {I18n.bill_amount}
                </p>
                <p className="marBot0 fontSize24 fontWeight500" style={{color: theme.white}}>
                    Rs. {formatNum(total)}
                </p>
            </div>

            <div className="billAmountRow billAmountBottomRow">
                <p className="marBot0 fontSize14" style={{color: theme.white}}>
                    {I18n.sub_total}
                </p>
                <p className="marBot0 fontSize14 fontWeight500" style={{color: theme.white}}>
                    Rs. {formatNum(subTotal)}
                </p>
            </div>

            {tax ? (
                <div className="billAmountRow billAmountBottomRow">
                    <p className="marBot0 fontSize14" style={{color: theme.white}}>
                        {I18n.taxes}
                    </p>
                    <p className="marBot0 fontSize14 fontWeight500 billAmountInnerRowRight" style={{color: theme.white}}>
                        <span>{`Rs. ${formatNum(tax)}`}</span>
                    </p>
                </div>
            ) : null}

            {givenPosDiscount || giveItemLevelDiscount ? (
                <div className="billAmountRow billAmountBottomRow">
                    <p className="marBot0 fontSize14" style={{color: theme.white}}>
                        {I18n.discount}
                    </p>
                    <p className="marBot0 fontSize14 fontWeight500 billAmountInnerRowRight" style={{color: theme.red}}>
                        <span>{`-Rs. ${formatNum(givenPosDiscount + giveItemLevelDiscount)}`}</span>
                    </p>
                </div>
            ) : null}

            {redeemAmount ? (
                <div className="billAmountRow billAmountBottomRow">
                    <p className="marBot0 fontSize14" style={{color: theme.white}}>
                        {I18n.redeem_points}
                    </p>
                    <p className="marBot0 fontSize14 fontWeight500 billAmountInnerRowRight" style={{color: theme.red}}>
                        <span>{`-Rs. ${formatNum(redeemAmount)}`}</span>
                    </p>
                </div>
            ) : null}

            {storeCreditAmount ? (
                <div className="billAmountRow billAmountBottomRow">
                    <p className="marBot0 fontSize14" style={{color: theme.white}}>
                        {I18n.store_credit}
                        <span style={{marginLeft: '10px', cursor: 'pointer'}}>
                            <Icon name="crossIcon" fill={'#7D7FA7'} width={'15'} height={'15'} onClick={handleRemoveStoreCredit} />
                        </span>
                    </p>

                    <p className="marBot0 fontSize14 fontWeight500 billAmountInnerRowRight" style={{color: theme.white}}>
                        <span>{`-Rs. ${formatNum(storeCreditAmount)}`}</span>
                    </p>
                </div>
            ) : null}
            {services?.length > 0 &&
                services?.map(service => (
                    <div className="billAmountRow billAmountBottomRow" key={service.id}>
                        <p className="marBot0 fontSize14" style={{color: theme.white}}>
                            {service.name}
                        </p>
                        <p className="marBot0 fontSize14 fontWeight500 billAmountInnerRowRight" style={{color: theme.white}}>
                            <span>{`Rs. ${formatNum(service.selling_price)}`}</span>
                        </p>
                    </div>
                ))}
        </div>
    );
};

export default BillAmountBox;
