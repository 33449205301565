import React, {useEffect, useState} from 'react';
import {useAuthContext, useLanguageContext, useThemeContext} from '../../../context';
import RadioButton from '../RadioButton';
import {REFUND_REASON, REFUND_TO_TYPES} from '../../../constants';

const RefundReasonComponent = ({refundType, setRefundType, refundReason, setRefundReason}) => {
    const {I18n} = useLanguageContext();
    const {theme} = useThemeContext();
    const {businessSettings} = useAuthContext();
    const [refundReasons, setRefundReasons] = useState(REFUND_REASON);
    const storeCredit = businessSettings?.['preferences']?.find(pref => pref.name === 'Allow store credit');
    const isStoreCreditAllowed = storeCredit?.setting_value?.status;
    const [refundTypes, setRefundTypes] = useState(isStoreCreditAllowed ? REFUND_TO_TYPES : REFUND_TO_TYPES.filter(type => type.value !== 'store_credit'));

    const addlabelsToRefunds = () => {
        const modifiedRefundTypes = refundTypes?.map(item => ({
            ...item,
            label: (
                <div className="width100">
                    <p className="marLeft20 marBot10 marTop10 fontSize16 fontWeight500 white">{item.title}</p>
                    <div className="divider width100 marTop0 marBot0" />
                </div>
            ),
            value: item.title,
        }));

        const modifiedRefundReasons = refundReasons?.map(item => ({
            ...item,
            label: (
                <div className="width100">
                    <p className="marLeft20 marBot10 marTop10 fontSize16 fontWeight500 white">{item.title}</p>
                    <div className="divider width100 marTop0 marBot0" />
                </div>
            ),
            value: item.title,
        }));

        setRefundTypes(modifiedRefundTypes);
        setRefundReasons(modifiedRefundReasons);
    };

    useEffect(() => {
        addlabelsToRefunds();
    }, []);

    const handleChangeRefundType = async e => {
        const value = e?.target?.value || e;
        setRefundType(value);
    };

    const handleChangeRefundReason = async e => {
        const value = e?.target?.value || e;
        setRefundReason(value);
    };

    return (
        <div>
            <div>
                <p className="fontSize18 marTop20 fontWeight600 marBot20" style={{color: theme.text}}>
                    {I18n.refund_method}
                </p>

                <RadioButton
                    options={refundTypes}
                    className="billTypesWrapper"
                    selectedSize={refundType}
                    handleChange={handleChangeRefundType}
                    showCustomBtn
                    customPosition="before"
                />
            </div>

            <div>
                <p className="fontSize18 marTop30 marBot20 fontWeight600 marBot20" style={{color: theme.text}}>
                    {I18n.reason_for_refund}
                </p>

                <RadioButton
                    options={refundReasons}
                    className="billTypesWrapper"
                    selectedSize={refundReason}
                    handleChange={handleChangeRefundReason}
                    showCustomBtn
                    customPosition="before"
                />
            </div>
        </div>
    );
};

export default RefundReasonComponent;
