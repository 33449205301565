import {Model, Q} from '@nozbe/watermelondb';
import {
    BUSINESS_SCHEMA,
    COMPANY_SCHEMA,
    CUSTOMER_SCHEMA,
    DISCOUNT_SCHEMA,
    FLOOR_PLAN_SCHEMA,
    ITEM_SCHEMA,
    MODIFIER_SCHEMA,
    OPTION_SCHEMA,
    ORDER_SCHEMA,
    PAYMENT_SCHEMA,
    PURCHASE_ORDER_SCHEMA,
    PURCHASE_TAX_SCHEMA,
    RIDER_SCHEMA,
    SESSION_SCHEMA,
    USER_BUSINESS_SCHEMA,
    USER_SCEHMA,
    WAITER_SCHEMA,
} from '../schema';
import {field, text, relation, children, writer, date, lazy, json} from '@nozbe/watermelondb/decorators';
import {sanitizeOptionValues, uuid} from '../../constants';

export default class Business extends Model {
    static table = BUSINESS_SCHEMA;

    static associations = {
        account: {type: 'belongs_to', key: 'account_id'},
        user_business: {type: 'has_many', foreignKey: 'business_id'},
        waiter: {type: 'has_many', foreignKey: 'business_id'},
        rider: {type: 'has_many', foreignKey: 'business_id'},
        option: {type: 'has_many', foreignKey: 'business_id'},
        session: {type: 'has_many', foreignKey: 'business_id'},
        order: {type: 'has_many', foreignKey: 'business_id'},
        payment: {type: 'has_many', foreignKey: 'business_id'},
        floor_plan: {type: 'has_many', foreignKey: 'business_id'},
        purchase_tax: {type: 'has_many', foreignKey: 'business_id'},
        purchase_order: {type: 'has_many', foreignKey: 'business_id'},
        setting_config: {type: 'has_many', foreignKey: 'business_id'},
        product_business: {type: 'has_many', foreignKey: 'business_id'},
        discount_business: {type: 'has_many', foreignKey: 'business_id'},
        sales_tax_business: {type: 'has_many', foreignKey: 'business_id'},
    };

    @text('name') name;
    @text('kind') kind;
    @text('category') category;
    @text('type') type;
    @text('province') province;
    @text('zip_code') zip_code;
    @text('country') country;
    @text('city') city;
    @text('email') email;
    @text('phone') phone;
    @text('location_name') location_name;
    @text('location_type') location_type;
    @text('address') address;
    @field('is_active') is_active;
    @field('is_hq') is_hq;
    @date('last_active_at') last_active_at;
    @json('void_reason', val => sanitizeOptionValues(val)) void_reason;

    @children('user_business') user_business;
    @children('waiter') waiter;
    @children('rider') rider;
    @children('option') option;
    @children('session') session;
    @children('order') order;
    @children('payment') payment;
    @children('purchase_tax') purchase_tax;
    @children('purchase_order') purchase_order;
    @children('floor_plan') floor_plan;
    @children('setting_config') setting_config;
    @children('product_business') product_business;
    @children('discount_business') discount_business;
    @children('sales_tax_business') sales_tax_business;

    @relation('account', 'account_id') account;

    @lazy getActiveSession = this.session.extend(Q.where('is_active', true));
    @lazy getActiveOrders = user_id => this.order.extend(Q.on(USER_SCEHMA, 'id', user_id), Q.where('status', 'active'));
    @lazy getActiveAndIncompleteOrders = user_id => this.order.extend(Q.on(USER_SCEHMA, 'id', user_id), Q.where('status', Q.notEq('complete')));
    @lazy getActivePurchaseOrders = this.purchase_order.extend(Q.where('status', 'active'));
    @lazy getCreatedPurchaseOrders = this.purchase_order.extend(Q.where('status', 'created'));
    @lazy getWaiter = id => this.waiter.extend(Q.where('id', id));
    @lazy getRider = id => this.rider.extend(Q.where('id', id));
    @lazy getFloorPlan = id => this.floor_plan.extend(Q.where('id', id));
    @lazy getPurchaseTax = id => this.purchase_tax.extend(Q.where('id', id));
    @lazy getOrder = id => this.order.extend(Q.where('number', id));
    @lazy getPurchaseOrder = id => this.purchase_order.extend(Q.where('id', id));

    @lazy getActivePayments = this.payment.extend(Q.where('status', 'active'));
    @lazy getActiveOptions = this.setting_config.extend(Q.where('is_active', true), Q.sortBy('created_at', Q.asc));
    @lazy getOrders = this.order.extend(Q.sortBy('started_at', Q.desc));
    @lazy getOrderById = id => this.order.extend(Q.where('id', id));
    @lazy getActiveFloorPlan = business_id => this.floor_plan.extend(Q.on(BUSINESS_SCHEMA, 'id', business_id), Q.where('archive', Q.notEq(true)));

    @writer async updateDetails(details) {
        return await this.update(business => {
            business.name = details.name;
            business.is_hq = details.isHq;
            business.kind = details.kind;
            business.type = details.type;
            business.province = details.province;
            business.city = details.city;
            business.address = details.address;
            business.location_type = details.location_type;
            business.location_name = details.location_name;
            business.email = details.email;
            business.phone = details.phone;
            business.country = details.country;
            business.zip_code = details.zip;
        });
    }

    @writer async updateBusinessDetails(details) {
        return await this.update(business => {
            business.name = details.name;
            business.province = details.province;
            business.city = details.city;
            business.address = details.address;
        });
    }

    @writer async activateBusiness() {
        return await this.update(business => {
            business.is_active = true;
            business.last_active_at = new Date().getTime();
        });
    }

    @writer async deactivateBusiness() {
        return await this.update(business => {
            business.is_active = false;
        });
    }

    @writer async updateVoidReasons(data) {
        return await this.update(business => {
            business.void_reason = [...business.void_reason, data];
        });
    }

    @writer async createSession(details) {
        return await this.collections.get(SESSION_SCHEMA).create(session => {
            session.business.set(this);
            session.user.set(details.user);
            session._raw.id = uuid();
            session.is_active = details.is_active;
            session.opening_balance = details.opening_balance;
            session.started_at = details.started_at;
        });
    }

    @writer async createOption(details) {
        return await this.collections.get(OPTION_SCHEMA).create(option => {
            option.business.set(this);
            option._raw.id = uuid();
            option.name = details.name;
            option.type = details.type;
            option.values = details.values;
        });
    }

    @writer async createOrder(val) {
        return await this.collections.get(ORDER_SCHEMA).create(order => {
            order.business.set(this);
            order.user.set(val?.user);
            order.session.set(val?.session);
            order._raw.id = uuid();
            order.number = val?.number;
            order.status = 'active';
            order.started_at = new Date().getTime();
        });
    }

    @writer async createFloorPlan(details) {
        return await this.collections.get(FLOOR_PLAN_SCHEMA).create(floorPlan => {
            floorPlan.business.set(this);
            floorPlan._raw.id = uuid();
            floorPlan.name = details.name;
            floorPlan.type = details.type;
            floorPlan.componentType = details.componentType;
            floorPlan.created_at = new Date().getTime();
            floorPlan.archive = false;
        });
    }

    @writer async createItem(details) {
        return await this.collections.get(ITEM_SCHEMA).create(item => {
            item.business.set(this);
            details.category && item.category.set(details.category);
            item._raw.id = uuid();
            item.name = details.name;
            item.description = details.description;
            item.image = details.image;
            item.barcode = details.barcode;
            item.selling_price = details.selling_price;
            item.cost_price = details.cost_price;
            item.profit = details.profit;
            item.margin = details.margin;
            item.quantity = details.quantity;
            item.low_stock = details.low_stock;
            item.is_taxable = details.is_taxable;
            item.is_trackable = details.is_trackable;
        });
    }

    @writer async createWaiter(details) {
        return await this.collections.get(WAITER_SCHEMA).create(waiter => {
            waiter.business.set(this);
            waiter._raw.id = uuid();
            waiter.name = details.name;
            waiter.phone_number = details.phone;
            waiter.email = details.email;
            waiter.province = details.province;
            waiter.city = details.city;
            waiter.dob = details.dob;
            waiter.address = details.address;
            waiter.created_at = new Date().getTime();
            waiter.archive = false;
            waiter.draft = details.draft;
        });
    }

    @writer async createRider(details) {
        return await this.collections.get(RIDER_SCHEMA).create(rider => {
            rider.business.set(this);
            rider._raw.id = uuid();
            rider.name = details.name;
            rider.phone_number = details.phone;
            rider.email = details.email;
            rider.province = details.province;
            rider.city = details.city;
            rider.dob = details.dob;
            rider.address = details.address;
            rider.created_at = new Date().getTime();
            rider.archive = false;
            rider.draft = details.draft;
        });
    }

    @writer async createPurchaseTax(details) {
        return await this.collections.get(PURCHASE_TAX_SCHEMA).create(tax => {
            tax.business.set(this);
            tax._raw.id = uuid();
            tax.name = details.name;
            tax.rate = details.rate;
            tax.archive = false;
            tax.created_at = new Date().getTime();
        });
    }

    @writer async createPO(number, poDetails) {
        return await this.collections.get(PURCHASE_ORDER_SCHEMA).create(po => {
            po.business.set(this);
            poDetails?.company && po.company.set(poDetails.company);
            po._raw.id = uuid();

            po.created_at = new Date().getTime();
            po.status = poDetails.status;
            po.number = number;
            po.is_delivered = false;
            po.reference = poDetails.reference;
            po.amount = poDetails.amount;
            poDetails.purchase_order_date && (po.po_date = poDetails.purchase_order_date);
            poDetails.expected_on && (po.po_expected_date = poDetails.expected_on);
            po.shipping_method = poDetails.shipping_method;
            po.archive = false;
            po.notes = poDetails.notes;
            po.notememo = poDetails.notememo;
            po.purchase_order_discount = poDetails.purchase_order_discount;

            // details.reference && (po.reference = details.reference);
        });
    }

    @writer async createPayment(details) {
        return await this.collections.get(PAYMENT_SCHEMA).create(payment => {
            payment.business.set(this);
            details.order?.customer?.id && payment.customer.set(details.order?.customer);
            details?.voucher && payment.voucher.set(details?.voucher);
            payment.order.set(details.order);
            payment._raw.id = uuid();
            payment.payment_method = details.payment_method;
            payment.card_number = details.card_number;
            payment.amount = details.total;
            payment.status = details.status;
            payment.type = details?.type;
            payment.completed_at = details.completed_at;
            payment.expiry_date = details.expiry_date;
            payment.started_at = details.started_at;
            payment.received_amount = details.received_amount;
            payment.change = details.change;
            payment.txns_number = details.txns_number;
            payment.invoice_number = details.invoice_number;
            payment.device_type = details.device_type;
            payment.payment_intent = details.payment_intent;
            payment.created_at = new Date().getTime();
        });
    }

    @writer async createUserBusinessRole(details) {
        return await this.collections.get(USER_BUSINESS_SCHEMA).create(userBusiness => {
            userBusiness._raw.id = uuid();
            userBusiness.business.set(this);
            userBusiness.user.set(details.user);
            userBusiness.role.set(details.userRole);
        });
    }
}
