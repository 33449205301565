import {useState} from 'react';
import {Col, Row} from 'react-bootstrap';
import {useNavigate} from 'react-router-dom';
import Icon from '../../../../assets/icons';
import {useAuthContext, useLanguageContext, useThemeContext} from '../../../../context';
import {AppModal, Button, CustomContainer} from '../../../common';
import Otp from '../../../common/Otp';
import {loginDevice, validateDevicePin} from '../../../../api';
import Cookies from 'js-cookie';

const KdsLogin = () => {
    const navigate = useNavigate();

    const {I18n} = useLanguageContext();
    const {theme} = useThemeContext();
    const [isLoading, setIsLoading] = useState(false);
    const [otp, setOtp] = useState('');
    const [toggle, setToggle] = useState(false);

    const handleLogin = async () => {
        try {
            setIsLoading(true);
            const response = await loginDevice({secret_key: otp});
            Cookies.set('secret_key', response?.data?.secret_key);
            Cookies.set('device_id', response?.data?.id);

            if (response.success) navigate('/kitchendisplay');
        } catch (error) {
            console.log('error', error);
        } finally {
            setIsLoading(false);
        }
    };

    const handleEnterPress = event => {
        if (event.key === 'Enter') {
            handleLogin();
        }
    };

    const handleClose = event => {
        setToggle(false);
    };

    return (
        <>
            <AppModal className="createOptionModal" toggle={toggle} handleClose={handleClose} title="Oscar"></AppModal>
            <CustomContainer handlePress={handleEnterPress}>
                <Row className="justify-content-md-center">
                    <Col md={9}>
                        <div className="logoBox">
                            <Icon name={'oscarLogo'} fill={'white'} viewBox={'0 0 115 110'} width={'115'} height={'110'} />
                            <p className="marTop10 fontSize16 textCenter" style={{color: theme.white}}>
                                {I18n.kitchen_display}
                            </p>
                        </div>
                    </Col>

                    <Col md={9}>
                        <div className="marTop10">
                            <p className="marBot40 fontSize22 textCenter" style={{color: theme.white}}>
                                {I18n.sign_in_with_device_code}
                            </p>
                            <div className="otpWrapper">
                                <Otp otp={otp} setOtp={setOtp} numInputs={12} placeHolder={'*'} type="text" />
                            </div>

                            <div className="flex horizontalCenter marTop20">
                                <Button
                                    className={`width40 fontWeight600 fontSize16 cursorPointer`}
                                    title={I18n.continue}
                                    disabled={false}
                                    saveLoad={isLoading ? true : false}
                                    handleClick={handleLogin}
                                />
                            </div>
                            <div className="flex horizontalCenter marTop30">
                                <p className="fontSize14 textCenter width80" style={{color: theme.white}}>
                                    {I18n.device_code_are_created_for_each_device}
                                </p>
                            </div>
                        </div>
                    </Col>
                </Row>
            </CustomContainer>
        </>
    );
};

export default KdsLogin;
