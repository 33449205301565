import React from 'react'

import { useLanguageContext, useThemeContext } from '../../../context';
import { oscaronline } from '../../../assets/images/image';
import DashboardDataBox from '../DashboardDataBox';
import Button from '../Button';
import { toast } from 'react-toastify';
import { DEVICE_HEIGHT } from '../../../constants';


const ExpandYourOnline = () => {
  const { I18n } = useLanguageContext();
  const { theme } = useThemeContext();


  return (
    <DashboardDataBox title='expand_your_online_presence_with_oscar'>
      <div className='onlinePresenceInner'>
        <p className='fontSize20 fontWeight600 white'>
          {`🔔 ${I18n.oscar_online_coming_soon}`}
        </p>
        <p className='fontSize14 fontWeight400 white'>
          {I18n.with_Oscar_Online_build_your_custom_website_and_start_receiving_online_orders_effortlessly}
        </p>
        <div className='imageBox' style={{
          height: DEVICE_HEIGHT - 580,
        }}>
          <img src={oscaronline} alt="oscaronline" />
        </div>
      </div>
    </DashboardDataBox>
  )
}

export default ExpandYourOnline
