import {RESTAURANT, RETAIL} from '../../../constants';

export const SUB_NAV_LIST = {
    suppliers: [
        // {name: 'Vendor', route: 'vendor', allowedBusinessTypes: [RETAIL, RESTAURANT]},
        {key: 'company', name: 'Company', route: 'company', allowedBusinessTypes: [RETAIL, RESTAURANT]},
    ],
    itemLibrary: [
        {key: 'items', name: 'Items', route: 'items', allowedBusinessTypes: [RETAIL, RESTAURANT]},
        {key: 'category', name: 'Categories', route: 'category', allowedBusinessTypes: [RETAIL, RESTAURANT]},
        {key: 'modifiers', name: 'Modifiers', route: 'modifiers', allowedBusinessTypes: [RESTAURANT]},
        {key: 'deals', name: 'Deals', route: 'deals', allowedBusinessTypes: [RESTAURANT]},
    ],
    teams: [
        {
            key: 'team',
            name: 'Team member',
            route: 'team',
            allowedBusinessTypes: [RETAIL, RESTAURANT],
        },
        {
            key: 'waiters',
            name: 'Waiters',
            route: 'waiters',
            allowedBusinessTypes: [RESTAURANT],
        },
        {
            key: 'riders',
            name: 'Riders',
            route: 'riders',
            allowedBusinessTypes: [RESTAURANT],
        },
    ],

    taxes: [
        {name: 'tax', route: 'tax', allowedBusinessTypes: [RETAIL, RESTAURANT]},
        // {name: 'payment method tax', route: 'payment-method-tax'},
    ],
};

export const SETTING_SUB_NAV_LIST = {
    personalInformation: [{key: 'signin-security', name: 'Sign in & security', route: 'settings/signin-security', allowedBusinessTypes: [RETAIL, RESTAURANT]}],
    businessInformation: [
        {key: 'about-business', name: 'About my business', route: 'settings/about-business', allowedBusinessTypes: [RETAIL, RESTAURANT]},
        {key: 'preferences', name: 'Preferences', route: 'settings/preferences', allowedBusinessTypes: [RETAIL, RESTAURANT]},
        {key: 'email-notifications', name: 'Email notifications', route: 'settings/email-notifications', allowedBusinessTypes: [RETAIL, RESTAURANT]},
        {key: 'loyalty-program', name: 'Loyalty program', route: 'settings/loyalty-program', allowedBusinessTypes: [RETAIL, RESTAURANT]},
        {key: 'receipt-customization', name: 'Receipt customization', route: 'settings/receipt-customization', allowedBusinessTypes: [RETAIL, RESTAURANT]},
    ],
    appSettings: [{key: 'app-settings', name: 'App settings', route: 'settings/app-settings', allowedBusinessTypes: [RETAIL, RESTAURANT]}],
};
