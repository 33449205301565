import React, {useState} from 'react';
import {useAuthContext, useLanguageContext, useThemeContext} from '../../../../../context';
import {DEVICE_HEIGHT, formatDate} from '../../../../../constants';
import {Button, MessageModal} from '../../../../common';
import StatusBox from '../../../../common/StatusBox';
import ChangeEmailModal from '../../../../common/ChangeEmailModal';
import ChangePasswordModal from '../../../../common/ChangePasswordModal';
import ChangePhoneModal from '../../../../common/ChangePhoneModal';
import {toast} from 'react-toastify';

const SignInSecurity = () => {
    const {I18n} = useLanguageContext();
    const {theme} = useThemeContext();
    const {account, userLevelSettings} = useAuthContext();

    const [emailModal, setEmailModal] = useState(false);
    const [passwordModal, setPasswordModal] = useState(false);
    const [phoneModal, setPhoneModal] = useState(false);

    const handleChangeEmail = () => {
        setEmailModal(true);
    };

    const handleChangePassword = () => {
        setPasswordModal(true);
    };

    const handleChangePhone = () => {
        setPhoneModal(true);
    };

    return (
        <div className="pad20">
            <div className="settingsWrapper borderRadiusBottomleftBottomRigt pad20" fluid style={{background: theme.blackBg, height: DEVICE_HEIGHT - 80}}>
                <>
                    <p className="marTop10 marBot20 fontSize18 fontWeight600" style={{color: theme.white}}>
                        {I18n.signin_security}
                    </p>
                    <div className="divider width100 marTop10" />
                </>

                <>
                    <div className="flex verticalCenter marTop15 gap10">
                        <p className=" marBot0 fontSize16 fontWeight400" style={{color: theme.white}}>
                            {I18n.email}
                        </p>
                        {/* <div className="settingsChip">
                            <StatusBox status="complete" labelChip={true} />
                        </div> */}
                    </div>
                    <p className="marTop3 marBot10 fontSize14 fontWeight400" style={{color: theme.darkGrayTwo}}>
                        {account?.email}
                    </p>
                    <Button title="Change email" handleClick={handleChangeEmail} className={`changeEmailBtn  fontWeight400 fontSize12 cursorPointer`} />
                    <div className="divider width100 marTop20" />
                </>

                <>
                    <p className="marTop15 marBot0 fontSize16 fontWeight400" style={{color: theme.white}}>
                        {I18n.password}
                    </p>
                    <p className="marTop3 marBot10 fontSize14 fontWeight400" style={{color: theme.darkGrayTwo}}>
                        {`Last changed ${formatDate(userLevelSettings?.password_updated_at)}`}
                    </p>
                    <Button title="Change password" handleClick={handleChangePassword} className={`changeEmailBtn  fontWeight400 fontSize12 cursorPointer`} />
                    <div className="divider width100 marTop20" />
                </>

                <>
                    <p className="marTop15 marBot0 fontSize16 fontWeight400" style={{color: theme.white}}>
                        {I18n.phone}
                    </p>
                    <p className="marTop3 marBot10 fontSize14 fontWeight400" style={{color: theme.darkGrayTwo}}>
                        {account?.phone_number}
                    </p>
                    <Button title="Change phone" handleClick={handleChangePhone} className={`changeEmailBtn  fontWeight400 fontSize12 cursorPointer`} />
                </>
            </div>

            <ChangeEmailModal visible={emailModal} setVisible={setEmailModal} />
            <ChangePasswordModal visible={passwordModal} setVisible={setPasswordModal} />
            <ChangePhoneModal visible={phoneModal} setVisible={setPhoneModal} />
        </div>
    );
};

export default SignInSecurity;
