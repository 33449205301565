import {useEffect, useRef, useState} from 'react';
import ReactPDF from '@react-pdf/renderer';
import GeneralDetails from './GeneralDetails';
import SalesDetails from './SalesDetails';
import BreakDownDetail from './BreakDownDetail';
import InsightDetails from './InsightDetails';
import Cashjournal from './Cashjournal';
import NonCashjournal from './NonCashjournal';
import {Col, Container, Row} from 'react-bootstrap';
import {Header, Loading, SesssionReportReceipt} from '../../../common';
import {useAuthContext, useLanguageContext, useThemeContext} from '../../../../context';
import {DEVICE_HEIGHT} from '../../../../constants';
import {useLocation, useNavigate} from 'react-router-dom';
import {useReactToPrint} from 'react-to-print';
import {getSessionReport, Sync} from '../../../../api';
import {getLatestClosedSession} from '../../../../pos-core/database/helpers';
import SessionReportPDFComponent from '../OscarPDF/SessionReportPDFComponent';

const SessionReport = () => {
    const {I18n} = useLanguageContext();
    const {theme} = useThemeContext();
    const [loading, setLoading] = useState(true);
    const {business, user} = useAuthContext();
    const [data, setData] = useState('');
    const {state} = useLocation();

    const {business_id, session_id, from, user_name} = state || {};
    let navigate = useNavigate();
    const componentRef = useRef();

    const handleEnterPress = event => {
        if (event?.key === 'Enter') {
            handlePrint();
        }
    };

    const handleSkip = () => {
        navigate(-1);
    };

    const handleExportPDF = () => {
        const pdfBlob = ReactPDF.pdf(<SessionReportPDFComponent reportReceipt={data} />);
        pdfBlob.toBlob().then(blob => {
            const url = window.URL.createObjectURL(blob);
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', state?.from === 'x_report_listing' ? 'x_Report.pdf' : 'Z_Report.pdf');
            document.body.appendChild(link);
            link.click();
            link.parentNode.removeChild(link);
        });
    };

    const handlePrint = useReactToPrint({
        content: () => componentRef?.current,
    });

    const fetchZReport = async () => {
        try {
            let params = {business_id, session_id};
            if (state?.from === 'z_report_listing') {
                params.report_type = 'z_report';
            } else if (state?.from === 'x_report_listing') {
                params.report_type = 'x_report';
            } else {
                let session = await getLatestClosedSession(user?.id);
                if (!session?.length) return;
                params = {
                    business_id: business?.id,
                    session_id: session[0]?.id,
                    report_type: 'z_report',
                };
            }
            const response = await getSessionReport(params);
            if (response?.status) {
                setData(response.data);
            }
        } catch (error) {
            console.log('Error occure when fetch Session report--->', error);
        } finally {
            setLoading(false);
        }
    };

    const handleSync = async () => {
        await Sync(user?.email);
        await fetchZReport();
        await setLoading(false);
    };

    useEffect(() => {
        handleSync();
    }, [user?.email]);

    let isSessionReport = state?.from === 'z_report_listing' || state?.from === 'x_report_listing';
    return loading ? (
        <Loading generateReport={loading} />
    ) : (
        <Container fluid style={{backgroundColor: theme.topBarBG}}>
            <div style={{display: 'none'}}>
                <SesssionReportReceipt ref={componentRef} reportReceipt={data} />
            </div>
            <Row onKeyDown={handleEnterPress}>
                <Col md={12}>
                    {isSessionReport ? (
                        <Header
                            type="draftHeader"
                            title={`${user_name}  ${state?.from === 'z_report_listing' ? 'Z report' : 'X report'}`}
                            skipCta={{
                                title: I18n.print,
                                action: () => handlePrint(),
                            }}
                            backgroundColor={theme.topBarBG}
                            saveCta={{
                                title: I18n.export_pdf,
                                action: () => handleExportPDF(),
                            }}
                        />
                    ) : (
                        <Header
                            type="draftHeader"
                            removeBackButton={true}
                            skipCta={{
                                title: I18n.skip,
                                action: handleSkip,
                            }}
                            backgroundColor={theme.topBarBG}
                            saveCta={{
                                title: I18n.print_Z_report,
                                action: () => handlePrint(),
                            }}
                        />
                    )}

                    {!isSessionReport && (
                        <div className="signupTextBox">
                            <h3 className="fontSize24  textCenter fontWeight600 marBot20 marTop0" style={{color: theme.text}}>
                                {I18n.current_shift_Z_Report}
                            </h3>
                        </div>
                    )}
                </Col>
            </Row>
            <Row
                className="zreportContainer"
                style={{
                    height: !isSessionReport ? DEVICE_HEIGHT - 170 : DEVICE_HEIGHT - 120,
                }}>
                <Col md={6}>
                    <GeneralDetails data={data.general_details} />
                </Col>
                <Col md={6}>
                    <SalesDetails data={data.sales_details} />
                </Col>
                <Col md={6}>
                    <BreakDownDetail data={data.payment_breakdown} />
                </Col>
                <Col md={6}>
                    <InsightDetails data={data.insights} />
                </Col>
                <Col md={6}>
                    <Cashjournal data={data.cash_journal} />
                </Col>
                <Col md={6}>
                    <NonCashjournal data={data.non_cash_journal} />
                </Col>
            </Row>
        </Container>
    );
};
export default SessionReport;
