/* eslint-disable react-hooks/rules-of-hooks */
import {DEVICE_HEIGHT, RESTAURANT, RETAIL} from '../../../constants';
import Offcanvas from 'react-bootstrap/Offcanvas';
import {useNavigate} from 'react-router-dom';
import HamburgerItem from './HamburgerItem';
import {SETTING_SUB_NAV_LIST, SUB_NAV_LIST} from './subNavData';
import MessageModal from '../MessageModal';
import Nav from 'react-bootstrap/Nav';
import {useState} from 'react';
import {useLanguageContext, useSessionContext, useThemeContext, useAuthContext, useMenuContext} from '../../../context';

const HamburgerMenu = () => {
    let navigate = useNavigate();

    const {isVisible, setIsVisible} = useMenuContext();
    const {session, setSession} = useSessionContext();
    const {handleLogoutInDB, hanldlePermission, business, getNestedRoute} = useAuthContext();
    const {I18n} = useLanguageContext();
    const {theme} = useThemeContext();
    const [selectedRoute, setSelectedRoute] = useState('');

    const [logoutModal, setLogoutModal] = useState(false);
    const [subRoute, setSubRoute] = useState('');

    const MERGED_SETTING_SUB_NAV_LIST = Object.values(SETTING_SUB_NAV_LIST || {}).flat();

    const toggleMenu = () => {
        setIsVisible(false);
        setSubRoute('');
    };

    const handleSelect = route => {
        setSelectedRoute(route);
        if (SUB_NAV_LIST[route]) {
            route == subRoute ? setSubRoute('') : setSubRoute(route);
            return;
        } else if (route == 'logout') setLogoutModal(true);
        else navigate(`/${route}`);
        setIsVisible(false);
        setSubRoute('');
    };

    const handleLogout = async () => {
        setLogoutModal(false);
        await handleLogoutInDB();
        navigate('/login', {replace: true});
    };

    return (
        <>
            <Offcanvas
                className="navigationSiderbar"
                backdrop={true}
                show={isVisible}
                onHide={toggleMenu}
                style={{
                    backgroundColor: theme.background,
                    height: DEVICE_HEIGHT - 40,
                }}>
                <Offcanvas.Body>
                    <Nav onSelect={route => handleSelect(route)} className="sidePaneNav" variant="pills" activeKey="1">
                        {hanldlePermission('dashboard', [RETAIL, RESTAURANT]) && (
                            <HamburgerItem hamburgerIcon="dashboardIcon" hamburgerTitle={I18n.dashboard} eventKey="dashboard" selectedRoute={selectedRoute} />
                        )}
                        {hanldlePermission('pos', [RETAIL]) && (
                            <HamburgerItem hamburgerIcon="posNewIcon" hamburgerTitle={I18n.nav_pos} eventKey="pos" selectedRoute={selectedRoute} />
                        )}
                        {hanldlePermission('pos-cockpit', [RESTAURANT]) && (
                            <HamburgerItem hamburgerIcon="posNewIcon" hamburgerTitle={I18n.nav_pos} eventKey="pos-cockpit" selectedRoute={selectedRoute} />
                        )}

                        {hanldlePermission('purchaseorder', [RETAIL, RESTAURANT]) && (
                            <HamburgerItem hamburgerIcon="purchaseOrderNewIon" hamburgerTitle={I18n.purchase_order} eventKey="purchaseorder" selectedRoute={selectedRoute} />
                        )}
                        {hanldlePermission('reports', [RETAIL, RESTAURANT]) && (
                            <HamburgerItem hamburgerIcon="reportsIcon" hamburgerTitle={I18n.reports} eventKey="report" selectedRoute={selectedRoute} />
                        )}
                        <hr className="sidebarNavSeperator" />
                        {hanldlePermission('', [RETAIL, RESTAURANT], SUB_NAV_LIST['itemLibrary']) && (
                            <HamburgerItem
                                hamburgerSubIcon="downIcon"
                                hamburgerTitle={I18n.item_library}
                                eventKey="itemLibrary"
                                subRoute={subRoute === 'itemLibrary' ? subRoute : null}
                                selectedRoute={selectedRoute}
                                businessType={business.type}
                                hanldlePermission={hanldlePermission}
                            />
                        )}
                        {hanldlePermission('orders', [RETAIL, RESTAURANT]) && (
                            <HamburgerItem hamburgerTitle={I18n.orders_history} eventKey="orders" selectedRoute={selectedRoute} />
                        )}
                        {hanldlePermission('customers', [RETAIL, RESTAURANT]) && (
                            <HamburgerItem hamburgerTitle={I18n.customers} eventKey="customers" selectedRoute={selectedRoute} />
                        )}
                        {hanldlePermission('', [RETAIL, RESTAURANT], SUB_NAV_LIST['suppliers']) && (
                            <HamburgerItem
                                hamburgerTitle={I18n.suppliers}
                                hamburgerSubIcon="downIcon"
                                eventKey="suppliers"
                                subRoute={subRoute === 'suppliers' ? subRoute : null}
                                selectedRoute={selectedRoute}
                                businessType={business.type}
                                hanldlePermission={hanldlePermission}
                            />
                        )}
                        {hanldlePermission('floor-plans', [RESTAURANT]) && <HamburgerItem hamburgerTitle={I18n.floor_plans} eventKey="floor-plans" selectedRoute={selectedRoute} />}
                        {hanldlePermission('discounts', [RETAIL, RESTAURANT]) && (
                            <HamburgerItem hamburgerTitle={I18n.discounts} eventKey="discounts" selectedRoute={selectedRoute} />
                        )}
                        {hanldlePermission('tax', [RETAIL, RESTAURANT]) && <HamburgerItem hamburgerTitle={I18n.taxes} eventKey="tax" selectedRoute={selectedRoute} />}
                        {hanldlePermission('', [RETAIL, RESTAURANT], SUB_NAV_LIST['teams']) && (
                            <HamburgerItem
                                hamburgerTitle={I18n.teams}
                                eventKey="teams"
                                selectedRoute={selectedRoute}
                                hamburgerSubIcon={'downIcon'}
                                subRoute={subRoute === 'teams' ? subRoute : null}
                                businessType={business.type}
                                hanldlePermission={hanldlePermission}
                            />
                        )}
                        {hanldlePermission('beneficiary', [RETAIL, RESTAURANT]) && (
                            <HamburgerItem hamburgerTitle={I18n.beneficiaries} eventKey="beneficiary" selectedRoute={selectedRoute} />
                        )}
                        {hanldlePermission('device', [RESTAURANT]) && <HamburgerItem hamburgerTitle={I18n.devices} eventKey="device" selectedRoute={selectedRoute} />}
                        {hanldlePermission('counter', [RETAIL, RESTAURANT]) && <HamburgerItem hamburgerTitle={I18n.counter} eventKey="counters" selectedRoute={selectedRoute} />}
                        {hanldlePermission('setting', [RETAIL, RESTAURANT]) && (
                            <HamburgerItem
                                hamburgerTitle={I18n.settings}
                                eventKey={getNestedRoute(MERGED_SETTING_SUB_NAV_LIST, [RETAIL, RESTAURANT])?.route}
                                selectedRoute={selectedRoute}
                            />
                        )}
                        {hanldlePermission('location', [RETAIL, RESTAURANT]) && <HamburgerItem hamburgerTitle={I18n.location} eventKey="location" selectedRoute={selectedRoute} />}
                        <HamburgerItem hamburgerTitle={I18n.nav_logout} eventKey="logout" />
                    </Nav>
                </Offcanvas.Body>
            </Offcanvas>

            <MessageModal
                setToggle={setLogoutModal}
                className="messageModal"
                toggle={logoutModal}
                title={I18n.logout}
                description={I18n.do_you_really_want_to_logout}
                subDescription={I18n.your_session_is_active}
                secondaryCta={{
                    title: I18n.cancel,
                    action: () => setLogoutModal(false),
                }}
                primaryCta={{
                    title: I18n.logout,
                    action: handleLogout,
                    isDelete: true,
                }}
            />
        </>
    );
};

export default HamburgerMenu;
