import React from 'react';
import DashboardDataBox from '../DashboardDataBox';
import MetricsCard from '../MetricsCard';
import DashboardCardNonIdeal from '../NonIdeal/DashboardCardNonIdeal';
import { useNavigate } from 'react-router-dom';
import { useAuthContext } from '../../../context';
import { RETAIL } from '../../../constants';
import { Col, Row } from 'react-bootstrap';

const KeyMetrics = ({ metricsData, isPosPermission, title, type }) => {
    let navigate = useNavigate();
    const { business } = useAuthContext();

    const handleAction = () => {
        if (business?.type === RETAIL) {
            navigate('/pos');
        } else {
            navigate('/restaurant-pos');
        }
    };



    return (
        <DashboardDataBox title={title}>
            {metricsData?.length > 0 ? (
                <div className='keyMetricMainConatiner'>
                    <Row>
                        {metricsData?.map((item, index) => (
                            <Col key={index} lg={3} md={3} sm={3} >
                                <MetricsCard item={item} />
                            </Col>
                        ))}
                    </Row>
                </div>

            ) : (
                <DashboardCardNonIdeal
                    data={{
                        title: 'oscar_can_provide_insights_to_help_grow_their_business',
                        subTitle: 'oscar_nonideal_title',
                        actionTitle: 'start_selling',
                        handleAction: handleAction,
                        showCta: isPosPermission?.canCrud,
                    }}
                />
            )}
        </DashboardDataBox>
    );
};

export default KeyMetrics;
