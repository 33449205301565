import {useAuthContext, useLanguageContext, useThemeContext} from '../../../../../context';
import TableBody from '../../../../common/TableContainer/TableBody';
import withObservables from '@nozbe/with-observables';
import {capitalizeFirstAlphabet, formatDate, formatNum, SERVICE} from '../../../../../constants';
import {useNavigate} from 'react-router-dom';
import {Dropdown} from '../../../../common';
import OrderCompletionReceipt from '../../../../common/OrderCompletionReceipt';
import {useEffect, useRef, useState} from 'react';
import {useReactToPrint} from 'react-to-print';

const List = ({order, customer, payment, table, isRestaurant, key}) => {
    let navigate = useNavigate();
    const {I18n} = useLanguageContext();
    const {theme} = useThemeContext();
    const {business, user} = useAuthContext();
    const componentRef = useRef();

    const [orderReceiptData, setOrderReceiptData] = useState({});

    const handlePrint = useReactToPrint({
        content: () => componentRef.current,
    });

    const tableAction = [
        {
            title: I18n.print_receipt,
            action: () => handleAction('print_receipt'),
        },
        {
            title: I18n.order_details,
            action: () => handleAction('details'),
        },
    ];

    const handleAction = async type => {
        switch (type) {
            case 'print_receipt':
                handlePrint();
                break;
            case 'details':
                navigateToNextScreen();
                break;
        }
    };

    function navigateToNextScreen() {
        navigate(`/orders/${order.number}`);
    }

    const prepareReceiptData = async () => {
        const account = await business.account.fetch();
        const customerData = await order?.customer.fetch();
        let orderLines = await order?.order_line.fetch();
        let voucher = await order?.voucher?.fetch();

        if (voucher?.length === 1) {
            voucher = voucher[0];
        }

        const services = orderLines?.filter(ol => ol.line_type == SERVICE);
        orderLines = orderLines?.filter(ol => ol.line_type !== SERVICE);

        const orderReceipt = {
            account,
            business,
            order,
            orderLines,
            customerData,
            user,
            table,
            showAmountSection: true,
            services,
            voucherCode: voucher?.barcode,
        };
        setOrderReceiptData(orderReceipt);
    };

    useEffect(() => {
        prepareReceiptData();
    }, [order]);

    return (
        <>
            <TableBody key={key} className={`${isRestaurant ? 'eightColumn' : 'sevenColumn'} cursorPointer`} style={{borderColor: theme.posRightBg}} onClick={navigateToNextScreen}>
                <div className="BoxWidth justifyStart itemListBox">
                    <p className="fontSize14" style={{color: theme.barclaysBlue}}>
                        {order.number}
                    </p>
                </div>
                <div className="BoxWidth justifyCenter">
                    <p className="fontSize14 OneLineTruncate" style={{color: theme.white}}>
                        {formatDate(order.started_at)}
                    </p>
                </div>
                {isRestaurant && (
                    <div className="BoxWidth justifyCenter">
                        <p className="fontSize14 OneLineTruncate" style={{color: theme.white}}>
                            {order.type || '-'}
                        </p>
                    </div>
                )}
                <div className="BoxWidth justifyCenter">
                    <p className="fontSize14 OneLineTruncate" style={{color: theme.white}}>
                        {customer?.name || '-'}
                    </p>
                </div>

                <div className="BoxWidth justifyCenter">
                    <p className="fontSize14 OneLineTruncate" style={{color: order?.is_refunded ? theme.brightOrange : theme.white}}>
                        {order?.is_refunded ? 'Refund' : capitalizeFirstAlphabet(order?.status) || '-'}
                    </p>
                </div>

                <div className="BoxWidth justifyCenter">
                    <p className="fontSize14 OneLineTruncate" style={{color: theme.white}}>
                        {payment?.length > 1 ? 'Splitted Bill' : capitalizeFirstAlphabet(payment[0]?.payment_method) || '-'}
                    </p>
                </div>

                <div className="BoxWidth justifyCenter">
                    <p className="fontSize14 OneLineTruncate" style={{color: theme.white}}>
                        Rs. {formatNum(order.total)}
                    </p>
                </div>

                <div
                    className="BoxWidth justifyCenter"
                    onClick={e => {
                        e.stopPropagation();
                    }}>
                    <div className="listActionBox">
                        <Dropdown
                            dropDown={{
                                name: 'threeDotIcon',
                                fill: theme.barclaysBlue,
                                width: 18,
                                height: 4,
                                viewBox: '0 0 18 4',
                                option: tableAction,
                            }}
                        />
                    </div>
                </div>
                <div style={{display: 'none'}}>
                    <OrderCompletionReceipt ref={componentRef} orderReceiptData={orderReceiptData} />
                </div>
            </TableBody>
        </>
    );
};

const enhance = withObservables(['order'], ({order}) => ({
    customer: order.customer.observe(),
    user: order.user.observe(),
    payment: order.payment.observe(),
    table: order.dine_in_table.observe(),
}));

export default enhance(List);
