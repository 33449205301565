import React from 'react';
import { useLanguageContext, useThemeContext } from '../../../context';
import ItemsList from './ItemsList';
import DashboardDataBox from '../DashboardDataBox';
import TableHeader from '../TableContainer/TableHeader';
import DashboardListNonIdeal from '../NonIdeal/DashboardListNonIdeal';

const ProfitMargin = ({ handleNavigate, data }) => {
    const { theme } = useThemeContext();
    const { I18n } = useLanguageContext();

    return (
        <DashboardDataBox disableViewButton={!data?.length > 0} title="profit_margins" ViewMoreBtn={true} handleView={handleNavigate}>
            {data?.length > 0 ? (
                <>
                    <TableHeader className={`nineColumn`} style={{ borderColor: theme.inputBorder }}>
                        <div className="BoxWidth justifyStart">
                            <p className="fontSize16 OneLineTruncate" style={{ color: theme.white }}>
                                {I18n.item}
                            </p>
                        </div>

                        <div className="BoxWidth justifyCenter">
                            <p className="fontSize16 OneLineTruncate" style={{ color: theme.white }}>
                                {I18n.cost_price}
                            </p>
                        </div>

                        <div className="BoxWidth justifyCenter">
                            <p className="fontSize16 OneLineTruncate" style={{ color: theme.white }}>
                                {I18n.selling_price}
                            </p>
                        </div>
                        <div className="BoxWidth justifyCenter">
                            <p className="fontSize16 OneLineTruncate" style={{ color: theme.white }}>
                                {I18n.margin}

                            </p>
                        </div>
                        <div className="BoxWidth justifyCenter">
                            <p className="fontSize16 OneLineTruncate" style={{ color: theme.white }}>
                                {I18n.profit_per_unit}
                            </p>
                        </div>
                        <div className="BoxWidth justifyCenter">
                            <p className="fontSize16 OneLineTruncate" style={{ color: theme.white }}>
                                {I18n.units_sold}
                            </p>
                        </div>

                        <div className="BoxWidth justifyCenter">
                            <p className="fontSize16 OneLineTruncate" style={{ color: theme.white }}>
                                {I18n.total_cost}
                            </p>
                        </div>

                        <div className="BoxWidth justifyCenter">
                            <p className="fontSize16 OneLineTruncate" style={{ color: theme.white }}>
                                {I18n.total_revenue}
                            </p>
                        </div>
                        <div className="BoxWidth justifyCenter">
                            <p className="fontSize16 OneLineTruncate" style={{ color: theme.white }}>
                                {I18n.total_profit}
                            </p>
                        </div>
                    </TableHeader>

                    {data?.map((item, index) => {
                        return <ItemsList data={item} key={index} />;
                    })}
                </>
            ) : (
                <DashboardListNonIdeal title="youve_not_done_any_transactions_yet" />
            )}
        </DashboardDataBox>
    );
};

export default ProfitMargin;
