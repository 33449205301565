import { useEffect, useState } from 'react';
import { useAuthContext, useLanguageContext, useThemeContext } from '../../../../../../../context';
import { GeneralReport, NonIdealScreen } from '../../../../../../common';
import CategorySaleReport from '../../../../../../common/CategorySaleReport';
import DiscountSaleReport from '../../../../../../common/DiscountSaleReport';
import ItemSalesReport from '../../../../../../common/ItemSalesReport';
import PaymentMethodReport from '../../../../../../common/PaymentMethodReport';
import SalesSummary from '../../../../../../common/SalesSummary';
import TaxesReport from '../../../../../../common/TaxesReport';
import { getAllSessionListing, getDashboardGenerlreport, getSalesReportAnalytics, getTopAndLowSellingProducts, handleDownloadCSV } from '../../../../../../../api';
import { BASE_URL, dateFilterMapping, formateIdForApi, removeTime } from '../../../../../../../constants';
import API_ENDPOINTS from '../../../../../../../api/endpoints';
import ZReportListing from '../../../../../../common/ZReportListing';
import XReportListing from '../../../../../../common/XReportListing';

const SaleReport = ({ reportDropDownOption, selectedLocation, selectedDateRange, isPosPermission }) => {
    const { I18n } = useLanguageContext();
    const { theme } = useThemeContext();
    const [hasMore, setHasMore] = useState(true);
    const [currentPage, setCurrentPage] = useState(1);
    const [totalpages, setTotalPages] = useState(0);
    const [search, setSearch] = useState('');
    const [data, setData] = useState({});
    const [filter, setFilter] = useState(I18n.top_to_low);
    const [loading, setLoading] = useState(false);
    const { business } = useAuthContext();
    const locationString = formateIdForApi(selectedLocation, business?.id);


    useEffect(() => {
        if (reportDropDownOption?.value) {
            setData({});
            setCurrentPage(1);
            setTotalPages(0);
            getSaleReportData();
        }
    }, [reportDropDownOption, selectedLocation, selectedDateRange, filter, search]);

    useEffect(() => {
        if (currentPage <= totalpages) {
            getSaleReportData();
        }
    }, [currentPage]);

    async function getSaleReportData() {
        try {
            setLoading(true);
            const commonParams = {
                start_date: selectedDateRange?.start_date,
                end_date: selectedDateRange?.end_date,
                date_range: dateFilterMapping[selectedDateRange?.label || 'Today'],
                business_ids: locationString,
            };

            let response = null;
            const reportType = reportDropDownOption?.value;

            if (['sale_summary', 'payment_summary', 'category_summary', 'discount_summary', 'tax_summary'].includes(reportType)) {
                setData({});
                setCurrentPage(1);
                setTotalPages(0);
                const params = {
                    ...commonParams,
                    report_type: reportType,
                };
                response = await getSalesReportAnalytics(params);
            } else if (reportType === 'item_sales') {
                const params = {
                    ...commonParams,
                    page: currentPage,
                    limit: 20,
                    topSellingItems: filter == I18n.top_to_low,
                };

                response = await getTopAndLowSellingProducts(params);
            } else if (reportType === 'general_report') {
                const params = {
                    start_date: removeTime(selectedDateRange?.start_date),
                    end_date: removeTime(selectedDateRange?.end_date),
                    report_type: reportDropDownOption?.reportKey,
                    scope: 'business',
                    entity_ids: locationString,
                    page: currentPage,
                    limit: 20,
                };

                response = await getDashboardGenerlreport(params);
            } else if (reportType === 'z_report' || reportType === 'x_report') {
                const params = {
                    start_date: removeTime(selectedDateRange.start_date),
                    end_date: removeTime(selectedDateRange?.end_date),
                    business_ids: locationString,
                    page: currentPage,
                    limit: 20,
                    is_active: reportType === 'x_report',
                    search: search,
                };

                response = await getAllSessionListing(params);
            }
            if (response?.success || response?.status) {
                if (reportType === 'item_sales') {
                    setTotalPages(response?.total_pages);
                    setData(prevData => {
                        const prevItems = prevData?.items || [];
                        const newItems = response?.items || [];
                        const mergedItems = [...prevItems, ...newItems];
                        return { items: mergedItems };
                    });
                    setLoading(false);
                    setHasMore(currentPage < response?.total_pages);
                } else if (reportType === 'z_report') {
                    setTotalPages(response?.total_pages);
                    setData(prevData => {
                        const prevItems = prevData?.data || [];
                        const newItems = response?.data || [];
                        const mergedItems = [...prevItems, ...newItems];
                        return { data: mergedItems };
                    });
                    setLoading(false);
                } else if (reportType === 'x_report') {
                    setTotalPages(response?.total_pages);
                    setData(prevData => {
                        const prevItems = prevData?.data || [];
                        const newItems = response?.data || [];
                        const mergedItems = [...prevItems, ...newItems];
                        return { data: mergedItems };
                    });
                    setLoading(false);
                } else if (reportType === 'general_report') {
                    setTotalPages(response?.total_pages);
                    setData(prevData => {
                        const prevItems = prevData?.data || [];
                        const newItems = response?.data || [];
                        const mergedItems = [...prevItems, ...newItems];

                        return { data: mergedItems, headers: response?.headers };
                    });
                    setLoading(false);
                } else {
                    setData(response.data);
                    setLoading(false);
                }
            } else {
                console.log('No data found or request failed:', response);
            }
        } catch (err) {
            console.log('Error fetching sales report data:', err);
        }
    }

    const handleExportCSV = async () => {
        const reportType = reportDropDownOption?.value;
        let dataForapi = {};
        const commonParams = {
            start_date: selectedDateRange?.start_date,
            end_date: selectedDateRange?.end_date,
            date_range: dateFilterMapping[selectedDateRange?.label || 'Today'],
            business_ids: locationString,
        };

        if (reportType === 'item_sales') {
            dataForapi = {
                ...commonParams,
                topSellingItems: filter === I18n.top_to_low,
                endPoint: API_ENDPOINTS?.GET_TOP_AND_LOW_SELLING_PRODUCTS_EXPORT_CSV,
            };
        } else if (['sale_summary', 'payment_summary', 'category_summary', 'discount_summary', 'tax_summary'].includes(reportType)) {
            dataForapi = {
                ...commonParams,
                report_type: reportType,
                export: 'true',
                endPoint: API_ENDPOINTS?.GET_SALES_REPORT_ANALYTICS,
            };
        }
        try {
            const url = new URL(`${BASE_URL}${dataForapi.endPoint}`);

            url.searchParams.append('start_date', dataForapi.start_date);
            url.searchParams.append('end_date', dataForapi.end_date);
            url.searchParams.append('date_range', dataForapi.date_range);
            url.searchParams.append('business_ids', dataForapi.business_ids);
            dataForapi.report_type && url.searchParams.append('report_type', dataForapi.report_type);
            reportType === 'item_sales' && url.searchParams.append('topSellingItems', dataForapi.topSellingItems);
            dataForapi.export && url.searchParams.append('export', dataForapi.export);

            handleDownloadCSV(url);
        } catch (error) {
            console.log('Error downloading the Excel file:', error);
        }
    };

    const reportComponents = {
        sale_summary: <SalesSummary data={data} loading={loading} handleExportCSV={handleExportCSV} />,
        payment_summary: <PaymentMethodReport data={data} loading={loading} handleExportCSV={handleExportCSV} />,
        item_sales: (
            <ItemSalesReport
                filter={filter}
                setFilter={setFilter}
                setHasMore={setHasMore}
                setCurrentPage={setCurrentPage}
                totalpages={totalpages}
                data={data}
                loading={loading}
                setData={setData}
                hasMore={hasMore}
                handleExportCSV={handleExportCSV}
            />
        ),
        general_report: (
            <GeneralReport
                setHasMore={setHasMore}
                setCurrentPage={setCurrentPage}
                totalpages={totalpages}
                currentPage={currentPage}
                data={data}
                loading={loading}
                selectedDateRange={selectedDateRange}
                locationString={locationString}
                reportDropDownOption={reportDropDownOption}
            />
        ),
        category_summary: <CategorySaleReport data={data} loading={loading} handleExportCSV={handleExportCSV} />,
        tax_summary: <TaxesReport data={data} loading={loading} handleExportCSV={handleExportCSV} />,
        discount_summary: <DiscountSaleReport data={data} loading={loading} handleExportCSV={handleExportCSV} />,
        z_report: (
            <ZReportListing
                setCurrentPage={setCurrentPage}
                totalpages={totalpages}
                data={data?.data}
                loading={loading}
                setData={setData}
                setSearch={setSearch}
                currentPage={currentPage}
            />
        ),
        x_report: (
            <XReportListing
                currentPage={currentPage}
                setCurrentPage={setCurrentPage}
                totalpages={totalpages}
                data={data?.data}
                loading={loading}
                setData={setData}
                setSearch={setSearch}
            />
        ),
    };
    const selectedReport = reportComponents[reportDropDownOption?.value] || (
        <div className="onlineNonIdeal">
            <NonIdealScreen
                isPosPermission={isPosPermission}
                heading={I18n.taxes}
                subHeading={I18n.no_activity_zero_transactions_recorded}
                name="taxNewIcon"
                fill={theme.white}
                isMultiStep={false}
            />
        </div>
    );

    return selectedReport;
};

export default SaleReport;
