import React from 'react';
import { useThemeContext } from '../../../context';
import TableBody from '../TableContainer/TableBody';
import { firstLetterCaptilize, formatNum } from '../../../constants';

const ItemsList = ({ data }) => {
    const { theme } = useThemeContext();
    const { member_name, member_email, role, total_sales, transactions_handled, avg_transaction_value, revenue_contribution } = data || {};

    return (
        <TableBody className={`sevenColumn `} style={{ borderColor: theme.inputBorder }}>
            <div className="BoxWidth justifyStart">
                <p title={firstLetterCaptilize(member_name) || "--"} className="fontSize14 fontWeight400 OneLineTruncate cursorPointer " style={{ color: theme.white }}>
                    {firstLetterCaptilize(member_name) || "--"}
                </p>
            </div>
            <div className="BoxWidth justifyCenter">
                <p title={member_email || "--"} className="fontSize14 fontWeight400 OneLineTruncate cursorPointer " style={{ color: theme.white }}>
                    {member_email || "--"}
                </p>
            </div>
            <div className="BoxWidth justifyCenter">
                <p title={firstLetterCaptilize(role) || "--"} className="fontSize14 fontWeight400 OneLineTruncate cursorPointer " style={{ color: theme.white }}>
                    {firstLetterCaptilize(role) || "--"}
                </p>
            </div>
            <div className="BoxWidth justifyCenter">
                <p title={`Rs. ${formatNum(total_sales || 0)}`} className="fontSize14 fontWeight400 OneLineTruncate cursorPointer " style={{ color: theme.white }}>
                    {`Rs. ${formatNum(total_sales || 0)}`}
                </p>
            </div>
            <div className="BoxWidth justifyCenter">
                <p title={formatNum(transactions_handled || 0)} className="fontSize14 fontWeight400 OneLineTruncate cursorPointer " style={{ color: theme.white }}>
                    {formatNum(transactions_handled || 0)}
                </p>
            </div>
            <div className="BoxWidth justifyCenter">
                <p title={`Rs. ${formatNum(avg_transaction_value || 0)}`} className="fontSize14 fontWeight400 OneLineTruncate cursorPointer " style={{ color: theme.white }}>
                    {`Rs. ${formatNum(avg_transaction_value || 0)}`}
                </p>
            </div>
            <div className="BoxWidth justifyCenter">
                <p title={`${formatNum(revenue_contribution || 0)}%`} className="fontSize14 fontWeight400 OneLineTruncate cursorPointer " style={{ color: theme.white }}>
                    {`${formatNum(revenue_contribution || 0)}%`}
                </p>
            </div>
        </TableBody>
    );
};

export default ItemsList;
