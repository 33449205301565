import {Model, Q} from '@nozbe/watermelondb';
import {field, text, relation, children, writer, date, lazy} from '@nozbe/watermelondb/decorators';
import {VOUCHER_SCHEMA} from '../schema';

export default class Voucher extends Model {
    static table = VOUCHER_SCHEMA;

    static associations = {
        order: {type: 'belongs_to', key: 'order_id'},
        payment: {type: 'has_many', foreignKey: 'voucher_id'},
    };

    @field('total_amount') total_amount;
    @field('remaining_amount') remaining_amount;
    @text('barcode') barcode;
    @text('status') status;
    @date('created_at') created_at;
    @date('expires_at') expires_at;

    @children('payment') payment;

    @relation('order', 'order_id') order;

    @writer async updateVoucher(details) {
        return await this.update(vouch => {
            vouch.remaining_amount = details.remaining_amount;
            vouch.status = details.status;
        });
    }
}
