import React, {useState} from 'react';
import ValidatePassword from '../ValidatePassword';
import NewAppModal from '../NewAppModal';
import {useAuthContext, useLanguageContext, useThemeContext} from '../../../context';
import ChangeEmailComponent from '../ChangeEmailComponent';
import {updateEmail, verifyUserPassword, verifyOldEmailOtp, verifyEmailOtp, Sync} from '../../../api';
import {validateChangeEmailForm} from '../../../constants';
import OtpVerificationComponent from '../OtpVerificationComponent';
import {toast} from 'react-toastify';

const ChangeEmailModal = ({visible, setVisible}) => {
    const {I18n} = useLanguageContext();
    const {theme} = useThemeContext();

    const {account, user} = useAuthContext();

    const [password, setPassword] = useState('');
    const [email, setEmail] = useState('');
    const [confirmEmail, setConfirmEmail] = useState('');
    const [otp, setOtp] = useState('');
    const [requestId, setRequestId] = useState('');
    const [errors, setErrors] = useState({});
    const [loading, setLoading] = useState(false);

    const [step, setStep] = useState(0);

    const hanldeClose = () => {
        setStep(0);
        setVisible(null);
        setPassword('');
        setEmail('');
        setConfirmEmail('');
        setOtp('');
        setErrors({});
    };

    const handleSave = async () => {
        if (step === 0) {
            try {
                setLoading(true);
                const payload = {
                    email: account.email,
                    password,
                };
                const response = await verifyUserPassword(payload);
                if (response.status) {
                    setStep(1);
                }
            } catch (error) {
                console.log('error', error);
                setErrors(error?.response?.data?.msg);
            } finally {
                setLoading(false);
            }
        }
        if (step === 1) {
            try {
                setLoading(true);
                const payload = {
                    email: account?.email,
                    new_email: email,
                    confirm_email: confirmEmail,
                    account_id: account?.id,
                    password,
                };

                const formErrors = validateChangeEmailForm(payload);
                setErrors(formErrors);
                if (Object.keys(formErrors).length > 0) return;

                const response = await updateEmail(payload);
                if (response.status) {
                    setRequestId(response.request_id);
                    setStep(2);
                }
            } catch (error) {
                console.log('error', error);
                setErrors({confirm_new_email_address: error?.response?.data?.msg?.email});
            } finally {
                setLoading(false);
            }
        }
        if (step === 2) {
            try {
                setLoading(true);
                const otpPayload = {
                    request_id: requestId,
                    account_id: account?.id,
                    otp,
                };
                const response = await verifyOldEmailOtp(otpPayload);

                if (response.status) {
                    setOtp('');
                    setStep(3);
                }
            } catch (error) {
                console.log('error', error);
                setErrors({
                    message: error?.response?.data?.msg,
                });
            } finally {
                setLoading(false);
            }
        }
        if (step === 3) {
            try {
                setLoading(true);
                const otpPayload = {
                    request_id: requestId,
                    account_id: account?.id,
                    otp,
                };
                const response = await verifyEmailOtp(otpPayload);

                if (response.status) {
                    await Sync(user.email);
                    setStep(0);
                    hanldeClose();
                    toast.success('Account email address changed successfully');
                }
            } catch (error) {
                console.log('error', error);
                setErrors({
                    message: error?.response?.data?.msg,
                });
            } finally {
                setLoading(false);
            }
        }
    };

    return (
        <div>
            <NewAppModal
                className="deliveryFloorModal modal-backdrop-custom"
                toggle={visible}
                backCta={{
                    name: 'backArrowIcon',
                    fill: theme.barclaysBlue,
                    width: '18',
                    height: '18',
                    viewBox: '0 0 18 18',
                }}
                handleClose={() => hanldeClose()}
                primaryCta={{
                    title: step === 0 ? I18n.continue : step === 1 ? I18n.confirm : step === 2 ? I18n.verify_otp : step === 3 ? I18n.verify_otp : I18n.continue,
                    action: () => handleSave(),
                    saveLoad: loading,
                }}
                cancelCta={{
                    title: I18n.cancel,
                    action: () => hanldeClose(),
                }}
                title={I18n.back}>
                {step === 0 ? (
                    <ValidatePassword password={password} setPassword={setPassword} errors={errors} setErrors={setErrors} />
                ) : step === 1 ? (
                    <ChangeEmailComponent email={email} setEmail={setEmail} confirmEmail={confirmEmail} setConfirmEmail={setConfirmEmail} errors={errors} setErrors={setErrors} />
                ) : step === 2 || step === 3 ? (
                    <OtpVerificationComponent otp={otp} setOtp={setOtp} step={step} sentTo={step === 2 ? account?.email : email} setErrors={setErrors} errors={errors} />
                ) : null}
            </NewAppModal>
        </div>
    );
};

export default ChangeEmailModal;
