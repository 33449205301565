import {Col, Row, Spinner} from 'react-bootstrap';
import {useThemeContext, useLanguageContext} from '../../../context';
import CustomContainer from '../CustomContainer';
import {firstLetterCaptilize, formatTime} from '../../../constants';
import PriceCheckerFooter from '../PricecheckerFooter';

const Loading = ({heading, subHeading, preHeading, light, generateReport = false, text, checkinTime = '', ...rest}) => {
    const {theme} = useThemeContext();
    const {I18n} = useLanguageContext();

    return (
        <>
            <CustomContainer className={!light ? 'loadingScreenMain' : 'loadingScreenMain-Light'} {...rest}>
                <Row>
                    <Col>
                        <div className="loadingScreenInner">
                            {checkinTime && (
                                <p className="fontSize34 fontWeight400 marBot35" style={{color: theme.darkGrayTwo}}>
                                    {formatTime(checkinTime)}
                                </p>
                            )}

                            <Spinner animation="border" variant={checkinTime ? 'light' : 'brightGreen'} className="loaderCircle marBot25" />

                            {preHeading && (
                                <h2 className="fontSize20 fontWeight400 marTop5 marBot10" style={{color: theme.text}}>
                                    {preHeading}
                                </h2>
                            )}
                            {checkinTime ? (
                                <p className="fontSize18 fontWeight400 marBot0 marTop10" style={{color: theme.text}}>
                                    {firstLetterCaptilize(I18n.clocking_in)}
                                </p>
                            ) : generateReport ? (
                                <h1 className="fontSize24 fontWeight700 marBot5" style={{color: theme.text}}>
                                    {firstLetterCaptilize(I18n.generating_report_please_wait)}
                                </h1>
                            ) : (
                                <h1 className="fontSize24 fontWeight700 marBot5" style={!light ? {color: theme.text} : {color: '#000'}}>
                                    {firstLetterCaptilize(I18n.loading_with_dot)}
                                </h1>
                            )}

                            {subHeading && (
                                <h2 className="fontSize20 fontWeight400 marTop5 marBot5" style={{color: theme.text}}>
                                    {subHeading}
                                </h2>
                            )}

                            {text && (
                                <h3 className="fontSize16 fontWeight300 marTop5 marBot5" style={{color: theme.text}}>
                                    {text}
                                </h3>
                            )}
                            {light ? <PriceCheckerFooter /> : null}
                        </div>
                    </Col>
                </Row>
            </CustomContainer>
        </>
    );
};

export default Loading;
