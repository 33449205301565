import React, {useState} from 'react';
import NewAppModal from '../NewAppModal';
import {useAuthContext, useLanguageContext, useThemeContext} from '../../../context';
import TextField from '../TextField';
import {toSnakeCase, validateNewPassword} from '../../../constants';
import {updateAccountPassword} from '../../../api';
import {toast} from 'react-toastify';

const ChangePasswordModal = ({visible, setVisible}) => {
    const {I18n} = useLanguageContext();
    const {theme} = useThemeContext();
    const {account} = useAuthContext();

    const [currentPassword, setCurrentPassword] = useState({viewAble: false, value: ''});
    const [newPassword, setNewPassword] = useState({viewAble: false, value: ''});
    const [confirmPassword, setConfirmPassword] = useState({viewAble: false, value: ''});
    const [loading, setLoading] = useState(false);
    const [errors, setErrors] = useState({});

    const hanldeClose = () => {
        setVisible(null);
        setCurrentPassword({viewAble: false, value: ''});
        setNewPassword({viewAble: false, value: ''});
        setConfirmPassword({viewAble: false, value: ''});
        setErrors({});
    };

    const handleSave = async () => {
        try {
            setLoading(true);
            const payload = {
                current_password: currentPassword.value,
                new_password: newPassword.value,
                confirm_password: confirmPassword.value,
                account_id: account?.id,
            };

            const formErrors = validateNewPassword(payload);
            setErrors(formErrors);
            if (Object.keys(formErrors).length > 0) return;

            const response = await updateAccountPassword(payload);
            if (response?.status) {
                hanldeClose();
                toast.success(response?.msg);
            }
        } catch (error) {
            console.log('Error', error);
            setErrors(error?.response?.data?.msg);
        } finally {
            setLoading(false);
        }
    };

    const onChange = async (label, val) => {
        if (label == I18n.current_password) setCurrentPassword(prev => ({...prev, value: val}));
        if (label == I18n.new_password) setNewPassword(prev => ({...prev, value: val}));
        if (label == I18n.confirm_password) setConfirmPassword(prev => ({...prev, value: val}));

        setErrors(prevErrors => {
            const updatedErrors = {...prevErrors};
            if (updatedErrors[toSnakeCase(label)]) {
                delete updatedErrors[toSnakeCase(label)];
            }
            return updatedErrors;
        });
    };

    return (
        <div>
            <NewAppModal
                className="deliveryFloorModal modal-backdrop-custom"
                toggle={visible}
                backCta={{
                    name: 'backArrowIcon',
                    fill: theme.barclaysBlue,
                    width: '18',
                    height: '18',
                    viewBox: '0 0 18 18',
                }}
                handleClose={() => hanldeClose()}
                primaryCta={{
                    title: I18n.confirm,
                    action: () => handleSave(),
                    saveLoad: loading,
                }}
                cancelCta={{
                    title: I18n.cancel,
                    action: () => hanldeClose(),
                }}
                title={I18n.back}>
                <div className="flex horizontalCenter width100">
                    <div className="flex width50" style={{flexDirection: 'column'}}>
                        <p className="fontSize20 marBot5 fontWeight400" style={{color: theme.white}}>
                            {I18n.create_new_password}
                        </p>
                        <div className="flex spaceBetweenCenter">
                            <p className="fontSize14 marBot20 fontWeight400" style={{color: theme.lightGrayTwo}}>
                                {I18n.for_account_protection_your_account_password_is_required}
                            </p>
                        </div>

                        <TextField
                            label={I18n.current_password}
                            placeholder={I18n.current_password}
                            onChange={onChange}
                            value={currentPassword?.value}
                            required={true}
                            error={errors.current_password}
                            type={currentPassword?.viewAble ? 'text' : 'password'}
                            suffix={currentPassword?.viewAble ? 'showEyeNewIcon' : 'hideEyeNewIcon'}
                            suffix_fill={theme.white}
                            suffix_width={'18'}
                            suffix_height={'18'}
                            suffix_viewBox={'0 0 18 18'}
                            suffixClick={() => setCurrentPassword(prev => ({...prev, viewAble: !prev.viewAble}))}
                        />
                        <TextField
                            label={I18n.new_password}
                            placeholder={I18n.new_password}
                            onChange={onChange}
                            value={newPassword?.value}
                            required={true}
                            error={errors.new_password}
                            type={newPassword?.viewAble ? 'text' : 'password'}
                            suffix={newPassword?.viewAble ? 'showEyeNewIcon' : 'hideEyeNewIcon'}
                            suffix_fill={theme.white}
                            suffix_width={'18'}
                            suffix_height={'18'}
                            suffix_viewBox={'0 0 18 18'}
                            suffixClick={() => setNewPassword(prev => ({...prev, viewAble: !prev.viewAble}))}
                        />
                        <TextField
                            label={I18n.confirm_password}
                            placeholder={I18n.confirm_password}
                            onChange={onChange}
                            value={confirmPassword?.value}
                            required={true}
                            error={errors.confirm_password}
                            type={confirmPassword?.viewAble ? 'text' : 'password'}
                            suffix={confirmPassword?.viewAble ? 'showEyeNewIcon' : 'hideEyeNewIcon'}
                            suffix_fill={theme.white}
                            suffix_width={'18'}
                            suffix_height={'18'}
                            suffix_viewBox={'0 0 18 18'}
                            suffixClick={() => setConfirmPassword(prev => ({...prev, viewAble: !prev.viewAble}))}
                        />
                    </div>
                </div>
            </NewAppModal>
        </div>
    );
};

export default ChangePasswordModal;
