import React from 'react';
import Icon from '../../../../assets/icons';
import {formatNum} from '../../../../constants';

const BarcodeProduct = ({productDetails}) => {
    const LocalUrl = JSON.parse(localStorage.getItem('siteUrl'));
    return (
        <div className="price-display-container">
            {/* Product Display */}
            <div className="product-card">
                {productDetails?.image_url ? <img src={`${LocalUrl}${productDetails?.image_url}`} alt={productDetails?.name} className="product-image" /> : null}
                <div className={productDetails?.image_url ? 'product-info' : 'product-info-without-img'}>
                    <h3>{productDetails?.name || 'No product found'}</h3>
                    <h2>{productDetails?.price ? `Rs. ${formatNum(productDetails?.price)}` : '--'} </h2>
                </div>
            </div>

            {/* Scan Another Button */}
            <div className="scan-barcode-div">
                <Icon name={'scanicon'} fill={'white'} viewBox={'0 0 50 50'} width={'50'} height={'50'} />
                <p>Scan the item barcode to check price</p>
            </div>
        </div>
    );
};

export default BarcodeProduct;
