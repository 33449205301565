import React from 'react';
import ItemsList from './ItemsList';
import { useLanguageContext, useThemeContext } from '../../../context';
import TableHeader from '../TableContainer/TableHeader';
import DashboardDataBox from '../DashboardDataBox';
import DashboardListNonIdeal from '../NonIdeal/DashboardListNonIdeal';

const LocationWiseSales = ({ handleNavigate, data }) => {
    const { theme } = useThemeContext();
    const { I18n } = useLanguageContext();

    return (
        <DashboardDataBox disableViewButton={!data?.length > 0} title="location_wise_sales" ViewMoreBtn={true} handleView={handleNavigate}>
            {data?.length > 0 ? (
                <>
                    <TableHeader className={`sixColumn`} style={{ borderColor: theme.inputBorder }}>
                        <div className="BoxWidth justifyStart">
                            <p className="fontSize16 OneLineTruncate" style={{ color: theme.white }}>
                                {I18n.area}
                            </p>
                        </div>

                        <div className="BoxWidth justifyCenter">
                            <p className="fontSize16 OneLineTruncate" style={{ color: theme.white }}>
                                {I18n.orders}
                            </p>
                        </div>
                        <div className="BoxWidth justifyCenter">
                            <p className="fontSize16 OneLineTruncate" style={{ color: theme.white }}>
                                {I18n.sales}
                            </p>
                        </div>
                        <div className="BoxWidth justifyCenter">
                            <p className="fontSize16 OneLineTruncate" style={{ color: theme.white }}>
                                {I18n.tax_Collected}
                            </p>
                        </div>
                        <div className="BoxWidth justifyCenter">
                            <p className="fontSize16 OneLineTruncate" style={{ color: theme.white }}>
                                {I18n.gross_sales}

                            </p>
                        </div>
                        <div className="BoxWidth justifyCenter">
                            <p className="fontSize16 OneLineTruncate" style={{ color: theme.white }}>
                                {I18n.cogs}

                            </p>
                        </div>
                    </TableHeader>

                    {data?.map((item, index) => {
                        return <ItemsList data={item} index={index} />;
                    })}
                </>
            ) : (
                <DashboardListNonIdeal title="youve_not_done_any_transactions_yet" />
            )}
        </DashboardDataBox>
    );
};

export default LocationWiseSales;
