import React from 'react'
import { device_image_banner } from '../../../assets/images/image';
import { useLanguageContext, } from '../../../context';

const DashboardBanner = () => {
  const { I18n } = useLanguageContext();



  return (
    <div className='dashboardBannerMain'>
      <div className='dashBannerLeft'>
        <h1 className='fontSize24 fontWeight600 white'>
          {I18n.effortless_payments_solutions}
        </h1>

        <div className='dashboardBannerListData'>
          <ul>
            <li>
              <span className='dashboardListIcon' />
              <span>{I18n.accept_digital_payments}</span>
            </li>
            <li>
              <span className='dashboardListIcon' />
              <span>{I18n.lowest_rates_in_pakistan}</span>
            </li>
            <li>
              <span className='dashboardListIcon' />
              <span>{I18n.Automated_reconciliation}</span>
            </li>
            <li>
              <span className='dashboardListIcon' />
              <span>{I18n.user_friendly_payments_dashboard}</span>
            </li>
          </ul>
        </div>

      </div>
      <div className='dashBannerRight'>
        <img src={device_image_banner} alt="" />
      </div>
    </div>

  )
}

export default DashboardBanner;
