import React, {useEffect, useState} from 'react';
import {useLanguageContext, useThemeContext} from '../../../context';
import NewAppModal from '../../common/NewAppModal';
import RadioButton from '../RadioButton';
import {INTEGRATIONS, toSnakeCase, validateIntegrationsForm} from '../../../constants';
import TextField from '../TextField';
import {toast} from 'react-toastify';

const IntegrationModal = ({toggle, setToggle}) => {
    const {I18n} = useLanguageContext();
    const {theme} = useThemeContext();

    const [integrations, setIntegrations] = useState(INTEGRATIONS);
    const [integrationType, setIntegrationType] = useState();
    const [selectedIntegrationType, setSelectedIntegrationType] = useState();
    const [posId, setPosId] = useState();
    const [ntn, setNtn] = useState();
    const [errors, setErrors] = useState({});

    function handleClose(event) {
        setToggle(false);
        setIntegrationType('');
        setSelectedIntegrationType('');
        setPosId('');
        setNtn('');
        setErrors({});
    }

    const handleChange = async e => {
        const value = e?.target?.value || e;
        const selectedIntegration = integrations.find(item => item.title === value);
        setIntegrationType(selectedIntegration);
    };

    const hanldeContinue = async () => {
        if (selectedIntegrationType) {
            const formPayload = {
                posId,
                ntn,
            };

            const formErrors = validateIntegrationsForm(formPayload, selectedIntegrationType);
            setErrors(formErrors);

            if (Object.keys(formErrors).length > 0) return;

            const payload = {
                integration: selectedIntegrationType?.integrationType?.value,
                posId,
                ntn,
            };
            localStorage.setItem('integrations', JSON.stringify(payload));
            handleClose();

            let toastMessage =
                selectedIntegrationType?.integrationType.value === 'PRA configuration'
                    ? 'PRA configured successfully'
                    : integrationType.value === 'FBR configuration'
                    ? 'FBR configured successfully'
                    : 'SRB configured successfully';

            toast.success(toastMessage);
        } else {
            const temp = {
                integrationType,
                fieldLabel: integrationType.value === 'PRA configuration' ? 'PRA POS ID' : integrationType.value === 'FBR configuration' ? 'FBR POS ID' : 'SRB POS ID',
                header:
                    integrationType.value === 'PRA configuration'
                        ? 'Setup PRA configuration'
                        : integrationType.value === 'FBR configuration'
                        ? 'Setup FBR configuration'
                        : 'Setup SRB configuration',
            };
            setSelectedIntegrationType(temp);
        }
    };

    const addlabelsToIntegrationsTypes = () => {
        const modifiedTypes = integrations?.map(item => ({
            ...item,
            label: (
                <div className="integrationsModalWrapper">
                    <p className="marBot0 marTop0 fontSize14 fontWeight400 white">{item.title}</p>
                </div>
            ),
            value: item.title,
        }));

        setIntegrations(modifiedTypes);
    };

    const onChange = async (label, val) => {
        if (label === I18n.srb_tax_payer_ntn) {
            setNtn(val);
        } else {
            label = 'pos_id';
            setPosId(val);
        }

        setErrors(prevErrors => {
            const updatedErrors = {...prevErrors};
            if (updatedErrors[toSnakeCase(label)]) {
                delete updatedErrors[toSnakeCase(label)];
            }
            return updatedErrors;
        });
    };

    useEffect(() => {
        addlabelsToIntegrationsTypes();
    }, []);

    return (
        <>
            <NewAppModal
                className="deliveryFloorModal modal-backdrop-custom"
                toggle={toggle}
                backCta={{
                    name: 'backArrowIcon',
                    fill: theme.barclaysBlue,
                    width: '18',
                    height: '18',
                    viewBox: '0 0 18 18',
                }}
                handleClose={() => handleClose()}
                primaryCta={{
                    title: selectedIntegrationType ? I18n.confirm : I18n.continue,
                    action: () => hanldeContinue(),
                }}
                cancelCta={{
                    title: I18n.cancel,
                    action: () => handleClose(),
                }}
                title={I18n.back}>
                <div className="flex horizontalCenter width100">
                    <div className="flex width50" style={{flexDirection: 'column'}}>
                        {selectedIntegrationType ? (
                            <>
                                <p className="fontSize20 marBot10 fontWeight400" style={{color: theme.white}}>
                                    {selectedIntegrationType.header}
                                </p>
                                <TextField
                                    label={selectedIntegrationType.fieldLabel}
                                    placeholder={selectedIntegrationType.fieldLabel}
                                    onChange={onChange}
                                    value={posId}
                                    required={true}
                                    error={errors?.pos_id}
                                />

                                {selectedIntegrationType?.integrationType?.value === 'SRB configuration' && (
                                    <TextField
                                        label={I18n.srb_tax_payer_ntn}
                                        placeholder={I18n.srb_tax_payer_ntn}
                                        onChange={onChange}
                                        value={ntn}
                                        required={true}
                                        error={errors?.['srb_tax_payer_ntn']}
                                    />
                                )}
                            </>
                        ) : (
                            <>
                                <p className="fontSize20 marBot0 fontWeight400" style={{color: theme.white}}>
                                    {I18n.integrations}
                                </p>
                                <p className="fontSize14 marTop10 fontWeigght400" style={{color: theme.darkGrayTwo}}>
                                    {I18n.manage_your_business_integrations}
                                </p>

                                <RadioButton options={integrations} className="billTypesWrapper" selectedSize={integrationType?.title} handleChange={handleChange} showCustomBtn />
                                {integrationType && (
                                    <div className="marTop10">
                                        <p className="marBot0 fontSize14 marLeft5 fontWeight500" style={{color: theme.white}}>
                                            {integrationType?.helperText}
                                        </p>
                                        <p className="marBot0 fontSize14 marLeft5 fontWeight400" style={{color: theme.darkGrayTwo}}>
                                            {I18n.please_follow_this_link}
                                            <span style={{color: theme.barclaysBlue, marginLeft: '4px'}}>{integrationType?.link}</span>
                                        </p>
                                    </div>
                                )}
                            </>
                        )}
                    </div>
                </div>
            </NewAppModal>
        </>
    );
};

export default IntegrationModal;
