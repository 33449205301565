import React from 'react';
import {useLanguageContext, useThemeContext} from '../../../context';
import {DEVICE_HEIGHT} from '../../../constants';
import emailVerification from '../../../assets/images/emailVerified.png';

export const VerifyEmail = () => {
    const {I18n} = useLanguageContext();
    const {theme} = useThemeContext();
    return (
        <div className="pad30" style={{background: '#1B1F2B', height: '100vh', width: '100%'}}>
            <div className="verifiedEmailWrapper borderRadiusBottomleftBottomRigt pad20" fluid style={{background: theme.blackBg, height: DEVICE_HEIGHT - 80}}>
                <img src={emailVerification} />
                <p className="marTop20 fontSize24 fontWeight500" style={{color: theme.white}}>
                    🎉 Congratulations! Your Email is Verified
                </p>
                <p className="marTop10 fontSize20 fontWeight400 textCenter" style={{color: theme.lightGrayTwo}}>
                    Thank you for verifying your email. Everything is now set up, and we’re excited to keep <br />
                    you informed about what’s next!
                </p>
            </div>
        </div>
    );
};
