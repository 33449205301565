import {Outlet} from 'react-router-dom';
import {useSessionContext} from '../../../context';
import {RequireSession} from '../../../services';
import {useEffect, useState} from 'react';
import {PosPinModal} from '../../common';
import RequireOrder from '../../../services/RequireOrder';

const PosPin = () => {
    const {sessionUser} = useSessionContext();
    const [pinModalToggle, setPinModalToggle] = useState(false);

    const checkForInactivity = () => {
        let expiryTime = localStorage?.getItem('expiryTime');
        if (expiryTime < Date.now()) {
            setPinModalToggle(true);
        }
    };

    const updateExpiryTime = () => {
        let expiry = Date.now() + 3600 * 1000;
        localStorage?.setItem('expiryTime', expiry);
    };

    useEffect(() => {
        sessionUser ? setPinModalToggle(false) : setPinModalToggle(true);
    }, []);

    useEffect(() => {
        let interval = setInterval(() => {
            checkForInactivity();
        }, 3600 * 1000);

        return () => clearInterval(interval);
    }, []);

    useEffect(() => {
        updateExpiryTime();
        window.addEventListener('mousemove', updateExpiryTime);
        window.addEventListener('click', updateExpiryTime);
        window.addEventListener('keypress', updateExpiryTime);
        window.addEventListener('scroll', updateExpiryTime);

        return () => {
            window.removeEventListener('mousemove', updateExpiryTime);
            window.removeEventListener('click', updateExpiryTime);
            window.removeEventListener('keypress', updateExpiryTime);
            window.removeEventListener('scroll', updateExpiryTime);
        };
    }, []);

    return (
        <RequireOrder>
            <PosPinModal setToggle={setPinModalToggle} toggle={pinModalToggle} />
            <RequireSession setPinModalToggle={setPinModalToggle}>
                <Outlet />
            </RequireSession>
        </RequireOrder>
    );
};

export default PosPin;
