import React, {useState} from 'react';
import NewAppModal from '../NewAppModal';
import {useLanguageContext, useThemeContext} from '../../../context';
import {toSnakeCase} from '../../../constants';
import TextField from '../TextField';

const CreditModal = ({visible, setVisible, handleAddVoucher}) => {
    const {theme} = useThemeContext();
    const {I18n} = useLanguageContext();

    const [voucherCode, setVoucherCode] = useState('');
    const [errors, setErrors] = useState({});

    const onChange = async (label, val) => {
        if (label == I18n.enter_store_credit_receipt_barcode) setVoucherCode(val);

        setErrors(prevErrors => {
            const updatedErrors = {...prevErrors};
            if (updatedErrors[toSnakeCase(label)]) {
                delete updatedErrors[toSnakeCase(label)];
            }
            return updatedErrors;
        });
    };

    const hanldeClose = () => {
        setErrors({});
        setVoucherCode('');
        setVisible(null);
    };

    const handleSave = async () => {
        if (!voucherCode) {
            setErrors({enter_store_credit_receipt_barcode: 'Please enter store credit receipt barcode'});
            return;
        }

        handleAddVoucher(voucherCode);
    };

    return (
        <div>
            <NewAppModal
                className="deliveryFloorModal modal-backdrop-custom"
                toggle={visible}
                backCta={{
                    name: 'backArrowIcon',
                    fill: theme.barclaysBlue,
                    width: '18',
                    height: '18',
                    viewBox: '0 0 18 18',
                }}
                handleClose={() => hanldeClose()}
                primaryCta={{
                    title: I18n.confirm,
                    action: () => handleSave(),
                }}
                cancelCta={{
                    title: I18n.cancel,
                    action: () => hanldeClose(),
                }}
                title={I18n.back}>
                <div className="flex horizontalCenter width100">
                    <div className="flex width50" style={{flexDirection: 'column'}}>
                        <p className="fontSize20 marBot20" style={{color: theme.white}}>
                            {I18n.redeem_store_credit}
                        </p>

                        <TextField
                            label={I18n.enter_store_credit_receipt_barcode}
                            placeholder={I18n.enter_store_credit_receipt_barcode}
                            onChange={onChange}
                            value={voucherCode}
                            required={true}
                            error={errors.enter_store_credit_receipt_barcode}
                        />
                    </div>
                </div>
            </NewAppModal>
        </div>
    );
};

export default CreditModal;
