import {createContext, useContext, useState} from 'react';

export const SessionContext = createContext({
    session: null,
    setSession: () => {},
    handleCreateSession: () => {},
    handleCloseSession: () => {},
    sessionUser: null,
    setSessionUser: () => {},
});

export const SessionProvider = ({children}) => {
    const [session, setSession] = useState(null);
    const [sessionUser, setSessionUser] = useState(null);

    const handleCreateSession = params => {
        return new Promise((resolve, reject) => {
            params.business
                .createSession(params)
                .then(res => {
                    setSession(res);

                    resolve(res);
                })
                .catch(err => {
                    console.log('err in handleCreateSession', err);
                    reject(err);
                });
        });
    };

    const handleCloseSession = balance => {
        return new Promise((resolve, reject) => {
            session
                ?.closeSession(balance)
                .then(() => {
                    setSession();
                    setSessionUser();
                    resolve();
                })
                .catch(err => {
                    console.log('err in handleCloseSession', err);
                    reject(err);
                });
        });
    };

    return (
        <SessionContext.Provider
            value={{
                session,
                setSession,
                handleCreateSession,
                handleCloseSession,
                sessionUser,
                setSessionUser,
            }}>
            {children}
        </SessionContext.Provider>
    );
};

export const useSessionContext = () => useContext(SessionContext);
