import React, {useEffect, useRef, useState} from 'react';
import {useAuthContext, useLanguageContext, useThemeContext} from '../../../../../context';
import {DEVICE_HEIGHT} from '../../../../../constants';
import {FormCheck} from 'react-bootstrap';
import {Button, MessageModal, TextField} from '../../../../common';
import CustomizeReceiptComponent from '../../../../common/CustomizeReceiptComponent';
import newImagePlaceholder from '../../../../../assets/images/newImagePlaceholder.svg';
import Icon from '../../../../../assets/icons';
import {updateSettings} from '../../../../../api';
import {toast} from 'react-toastify';
import {useReactToPrint} from 'react-to-print';

const ReceiptCustomization = () => {
    const {I18n} = useLanguageContext();
    const {theme} = useThemeContext();
    const {businessSettings, fetchSettings} = useAuthContext();
    const componentRef = useRef();

    const [businessLogo, setBusinessLogo] = useState({id: '', enabled: false, value: ''});
    const [header, setHeader] = useState({id: '', enabled: false, value: ''});
    const [footer, setFooter] = useState({id: '', enabled: false, value: ''});
    const [businessWebsite, setBusinessWebsite] = useState({id: '', enabled: false, value: ''});
    const [businessAddress, setBusinessAddress] = useState({id: '', enabled: false, value: ''});
    const [taxId, setTaxId] = useState({id: '', enabled: false, value: ''});
    const [companyRegistry, setCompanyRegistry] = useState({id: '', enabled: false, value: ''});
    const [loading, setLoading] = useState(false);
    const [isChanged, setIsChanged] = useState(false);
    const [confirmatiomModal, setConfirmationModal] = useState(false);
    const receiptCustomization = businessSettings?.['receipt_customization'];

    const onChange = (label, val) => {
        setIsChanged(true);
        if (label == I18n.header) setHeader(prev => ({...prev, value: val}));
        else if (label == I18n.footer) setFooter(prev => ({...prev, value: val}));
        else if (label == I18n.business_website) setBusinessWebsite(prev => ({...prev, value: val}));
        else if (label == I18n.business_address) setBusinessAddress(prev => ({...prev, value: val}));
        else if (label == I18n.tax_id) setTaxId(prev => ({...prev, value: val}));
        else if (label == I18n.company_registry) setCompanyRegistry(prev => ({...prev, value: val}));
    };

    const handlePrint = useReactToPrint({
        content: () => componentRef.current,
    });

    const handlePrintReceipt = e => {
        e.stopPropagation();
        handlePrint();
    };

    const handleImage = files => {
        const file = files[0];
        if (!file) {
            setBusinessLogo(prev => ({...prev, value: ''}));
            return;
        }
        const validFormats = ['image/jpeg', 'image/jpg', 'image/png'];
        if (!validFormats.includes(file.type)) {
            setBusinessLogo(prev => ({...prev, value: ''}));
            toast.error('Error uploading image');
            return;
        }

        try {
            let reader = new FileReader();
            reader.readAsDataURL(file);
            reader.onload = () => setBusinessLogo(prev => ({...prev, value: {img: reader.result, name: file.name, size: file.size}}));
        } catch (error) {
            setBusinessLogo(prev => ({...prev, value: ''}));
            toast.error('Error uploading image');
            console.log(error.message);
        }
    };

    const handleUpload = e => {
        setIsChanged(true);
        handleImage(e.target.files);
    };

    const handleRemoveImage = () => {
        setIsChanged(true);
        setBusinessLogo(prev => ({...prev, value: ''}));
    };

    const handleSaveReceipt = async () => {
        try {
            setLoading(true);
            const payload = {
                settings: [
                    {
                        id: businessLogo.id,
                        scope: 'business',
                        setting_value: {
                            status: businessLogo.enabled,
                            url: businessLogo.value.img,
                        },
                    },
                    {
                        id: header.id,
                        scope: 'business',
                        setting_value: {
                            status: header.enabled,
                            value: header.value,
                        },
                    },
                    {
                        id: footer.id,
                        scope: 'business',
                        setting_value: {
                            status: footer.enabled,
                            value: footer.value,
                        },
                    },
                    {
                        id: businessWebsite.id,
                        scope: 'business',
                        setting_value: {
                            status: businessWebsite.enabled,
                            value: businessWebsite.value,
                        },
                    },
                    {
                        id: businessAddress.id,
                        scope: 'business',
                        setting_value: {
                            status: businessAddress.enabled,
                            value: businessAddress.value,
                        },
                    },
                    {
                        id: companyRegistry.id,
                        scope: 'business',
                        setting_value: {
                            status: companyRegistry.enabled,
                            value: companyRegistry.value,
                        },
                    },
                    {
                        id: taxId.id,
                        scope: 'business',
                        setting_value: {
                            status: taxId.enabled,
                            value: taxId.value,
                        },
                    },
                ],
            };
            const response = await updateSettings(payload);
            if (response.status) {
                toast.success('Changes updated successfully');
                setIsChanged(false);
                fetchSettings();
            }
        } catch (error) {
            console.log('Error', error);
        } finally {
            setLoading(false);
            if (confirmatiomModal) setConfirmationModal(false);
        }
    };

    const handleCancel = () => {
        setInitialValues();
        setIsChanged(false);
    };

    const handleDiscardModal = () => {
        setConfirmationModal(false);
    };

    const handleChange = (e, customization) => {
        setIsChanged(true);
        if (customization.name === 'Business logo') {
            setBusinessLogo(prev => ({...prev, enabled: e.target.checked}));
        } else if (customization.name === 'Header') {
            setHeader(prev => ({...prev, enabled: e.target.checked}));
        } else if (customization.name === 'Footer') {
            setFooter(prev => ({...prev, enabled: e.target.checked}));
        } else if (customization.name === 'Business website') {
            setBusinessWebsite(prev => ({...prev, enabled: e.target.checked}));
        } else if (customization.name === 'Business address') {
            setBusinessAddress(prev => ({...prev, enabled: e.target.checked}));
        } else if (customization.name === 'Tax ID') {
            setTaxId(prev => ({...prev, enabled: e.target.checked}));
        } else if (customization.name === 'Company registry') {
            setCompanyRegistry(prev => ({...prev, enabled: e.target.checked}));
        }
    };

    const handleCheck = customization => {
        if (customization.name === 'Business logo') {
            return businessLogo.enabled;
        } else if (customization.name === 'Header') {
            return header.enabled;
        } else if (customization.name === 'Footer') {
            return footer.enabled;
        } else if (customization.name === 'Business website') {
            return businessWebsite.enabled;
        } else if (customization.name === 'Business address') {
            return businessAddress.enabled;
        } else if (customization.name === 'Tax ID') {
            return taxId.enabled;
        } else if (customization.name === 'Company registry') {
            return companyRegistry.enabled;
        }
    };

    const handleValue = customization => {
        if (customization.name === 'Header') {
            return header.value;
        } else if (customization.name === 'Business logo') {
            return businessLogo.value;
        } else if (customization.name === 'Footer') {
            return footer.value;
        } else if (customization.name === 'Business website') {
            return businessWebsite.value;
        } else if (customization.name === 'Business address') {
            return businessAddress.value;
        } else if (customization.name === 'Tax ID') {
            return taxId.value;
        } else if (customization.name === 'Company registry') {
            return companyRegistry.value;
        }
    };

    const setInitialValues = () => {
        if (receiptCustomization) {
            receiptCustomization.forEach(customization => {
                if (customization.name === 'Business logo') {
                    setBusinessLogo({id: customization.id, enabled: customization.setting_value?.status, value: {img: customization.setting_value?.url}});
                } else if (customization.name === 'Header') {
                    setHeader({id: customization.id, enabled: customization.setting_value?.status, value: customization.setting_value?.value});
                } else if (customization.name === 'Footer') {
                    setFooter({id: customization.id, enabled: customization.setting_value?.status, value: customization.setting_value?.value});
                } else if (customization.name === 'Business website') {
                    setBusinessWebsite({id: customization.id, enabled: customization.setting_value?.status, value: customization.setting_value?.value});
                } else if (customization.name === 'Business address') {
                    setBusinessAddress({id: customization.id, enabled: customization.setting_value?.status, value: customization.setting_value?.value});
                } else if (customization.name === 'Tax ID') {
                    setTaxId({id: customization.id, enabled: customization.setting_value?.status, value: customization.setting_value?.value});
                } else if (customization.name === 'Company registry') {
                    setCompanyRegistry({id: customization.id, enabled: customization.setting_value?.status, value: customization.setting_value?.value});
                }
            });
        }
    };

    useEffect(() => {
        setInitialValues();
    }, [receiptCustomization]);

    return (
        <div className="pad20 flex">
            <div className="settingsWrapper borderRadiusBottomleftBottomRigt flex width100" style={{background: theme.blackBg, height: DEVICE_HEIGHT - 80}}>
                <div className="pad20 width70">
                    <>
                        <div className="spaceBetweenCenter">
                            <p className="marTop10 marBot0 fontSize18 fontWeight600" style={{color: theme.white}}>
                                {I18n.customize_your_receipt}
                            </p>
                            {isChanged && (
                                <div className="flex gap10 ">
                                    <Button title="Cancel" handleClick={e => handleCancel(e)} className={`cancelBtn  fontWeight400 fontSize14 cursorPointer`} />
                                    <Button
                                        saveLoad={loading}
                                        disabled={loading}
                                        title="Save"
                                        handleClick={e => handleSaveReceipt(e)}
                                        className={`saveBtn  fontWeight400 fontSize14 cursorPointer`}
                                    />
                                </div>
                            )}
                        </div>

                        <p className="marTop5 fontSize14 fontWeight400" style={{color: theme.darkGrayTwo}}>
                            {I18n.personalize_your_receipt}
                        </p>
                        <div className="divider width100 marTop10" />
                    </>

                    {receiptCustomization?.map(customization => {
                        return (
                            <div key={customization.id}>
                                <p className="marTop15 marBot0 fontSize16 fontWeight400" style={{color: theme.white}}>
                                    {customization.name}
                                </p>
                                <p className="marTop3 marBot10 fontSize14 fontWeight400" style={{color: theme.darkGrayTwo}}>
                                    {customization.description}
                                </p>
                                <div className="flex gap15">
                                    <FormCheck type="switch" id="stock_available" onChange={e => handleChange(e, customization)} checked={handleCheck(customization)} />
                                    <p className="marTop3 marBot0 fontSize14 fontWeight400" style={{color: theme.white}}>
                                        {handleCheck(customization) ? I18n.enabled : I18n.disabled}
                                    </p>
                                </div>
                                {handleCheck(customization) &&
                                    (customization.name === 'Business logo' ? (
                                        <div className="marTop20 width50 imageWrapper spaceBetweenCenter">
                                            <div className="flex">
                                                <span className="itemListImage">
                                                    <img src={businessLogo?.value?.img || newImagePlaceholder} style={{width: 30, height: 30, objectFit: 'cover'}} />
                                                </span>
                                                <div style={{display: 'flex', justifyContent: 'center', flexDirection: 'column'}}>
                                                    <p className="marBot0 fontSize12 fontWeight400 white">Business logo</p>
                                                    {businessLogo?.value?.name && <p className="marBot0 fontSize12 fontWeight400 darkGrayTwo">{businessLogo?.value?.name}</p>}
                                                </div>
                                            </div>

                                            {businessLogo?.value?.img ? (
                                                <div className="cursorPointer">
                                                    <Icon name="delete2Icon" fill={theme.red} width="18" height="18" viewBox={'0 0 18 18'} onClick={() => handleRemoveImage()} />
                                                </div>
                                            ) : (
                                                <div className="addLogoBtn cursorPointer" onClick={() => document.getElementById('upload-logo').click()}>
                                                    <p className="fontSize12 fontWeight400" style={{color: theme.barclaysBlue, position: 'relative', bottom: '8px'}}>
                                                        {I18n.add_logo}
                                                    </p>
                                                    <input id="upload-logo" name="image" type="file" onChange={handleUpload} accept=".jpeg, .jpg, .png" style={{display: 'none'}} />
                                                </div>
                                            )}
                                        </div>
                                    ) : (
                                        <div className="marTop20 width50">
                                            <TextField
                                                onChange={onChange}
                                                label={customization?.name}
                                                placeholder={customization?.name}
                                                value={handleValue(customization)}
                                                hideInputError={true}
                                                maxLength={100}
                                            />
                                        </div>
                                    ))}

                                <div className="marTop20 divider width100" />
                            </div>
                        );
                    })}
                </div>

                <div className="receiptWrapper width30">
                    <div className="spaceBetweenCenter">
                        <p className="marTop3 marBot0 fontSize14 fontWeight400" style={{color: theme.white}}>
                            {I18n.receipt_preview}
                        </p>

                        <Button title="Print" handleClick={e => handlePrintReceipt(e)} className={`printReceiptBtn   fontWeight400 fontSize14 cursorPointer`} />
                    </div>
                    <div className="marTop20">
                        <CustomizeReceiptComponent
                            businessLogo={businessLogo}
                            header={header}
                            footer={footer}
                            businessWebsite={businessWebsite}
                            businessAddress={businessAddress}
                            taxId={taxId}
                            companyRegistry={companyRegistry}
                            ref={componentRef}
                        />
                    </div>
                </div>
            </div>
            <MessageModal
                className="messageModal"
                setToggle={setConfirmationModal}
                toggle={confirmatiomModal}
                description={I18n.you_have_unsaved_changes}
                subDescription={I18n.if_you_leave_without_saving}
                primaryCta={{
                    title: I18n.save_changes,
                    action: handleSaveReceipt,
                    backgroundColor: theme.brightGreen,
                    borderColor: theme.brightGreen,
                    txtColor: theme.white,
                    loading: loading,
                }}
                secondaryCta={{
                    title: I18n.discard,
                    action: handleDiscardModal,
                }}
            />
        </div>
    );
};

export default ReceiptCustomization;
