import React from 'react';
import {useAuthContext, useLanguageContext, useThemeContext} from '../../../context';
import DashboardDataBox from '../DashboardDataBox';
import Button from '../Button';
import {DEVICE_HEIGHT, WALLET_CARD_DATA} from '../../../constants';
import ActivateWalletCard from '../ActivateWalletCard';
import {Col, Row} from 'react-bootstrap';
import {green} from '@mui/material/colors';
import {useNavigate} from 'react-router-dom';

const ActiveOscarWallet = ({handleChange = () => {}}) => {
    const {theme} = useThemeContext();
    const {I18n} = useLanguageContext();
    const {userLevelSettings} = useAuthContext();
    let navigate = useNavigate();

    // const handleRoute = () => {
    //     console.log(' handleRoute 🦽 :');
    // };

    return (
        <DashboardDataBox title="activate_your_Oscar_wallet">
            <h1 className="fontSize20 fontWeight600 white">{I18n.oscar_wallet}</h1>
            <p
                className="fontSize14 fontWeight400 marBot20"
                style={{
                    color: theme.darkGrayTwo,
                }}>
                {I18n.experience_seamless_money_transfer}
            </p>

            <Row>
                {WALLET_CARD_DATA.map((item, ind) => {
                    return (
                        <Col md={6} key={ind}>
                            <ActivateWalletCard item={item} />
                        </Col>
                    );
                })}
            </Row>
            {/* <Button
                type="secondary"
                className={`fontSize18 cursorPointer marTop20`}
                backgroundColor={theme.brightGreen}
                txtColor={theme.white}
                title={I18n[userLevelSettings?.wallet_activation_status ? 'access_your_wallet' : 'request_wallet_access']}
                handleClick={handleRoute}
            /> */}
        </DashboardDataBox>
    );
};

export default ActiveOscarWallet;
