import React, {useState} from 'react';
import TextField from '../TextField';
import {useLanguageContext, useThemeContext} from '../../../context';
import {toSnakeCase} from '../../../constants';

const ValidatePassword = ({password, setPassword, errors, setErrors}) => {
    const {theme} = useThemeContext();
    const {I18n} = useLanguageContext();

    const [viewPassword, setViewPassword] = useState(false);

    const onChange = async (label, val) => {
        if (label == I18n.password) setPassword(val);

        setErrors(prevErrors => {
            const updatedErrors = {...prevErrors};
            if (updatedErrors[toSnakeCase(label)]) {
                delete updatedErrors[toSnakeCase(label)];
            }
            return updatedErrors;
        });
    };

    return (
        <div className="flex horizontalCenter width100">
            <div className="flex width50" style={{flexDirection: 'column'}}>
                <p className="fontSize20 marBot5 fontWeight400" style={{color: theme.white}}>
                    {I18n.account_password_required}
                </p>
                <div className="flex spaceBetweenCenter">
                    <p className="fontSize14 marBot20 fontWeight400" style={{color: theme.lightGrayTwo}}>
                        {I18n.for_account_protection_your_account_password_is_required}
                    </p>
                </div>

                <TextField
                    label={I18n.password}
                    placeholder={I18n.password}
                    onChange={onChange}
                    value={password}
                    required={true}
                    error={errors?.password}
                    type={viewPassword ? 'text' : 'password'}
                    suffix={viewPassword ? 'showEyeNewIcon' : 'hideEyeNewIcon'}
                    suffix_fill={theme.white}
                    suffix_width={'18'}
                    suffix_height={'18'}
                    suffix_viewBox={'0 0 18 18'}
                    suffixClick={() => setViewPassword(prev => !prev)}
                />
            </div>
        </div>
    );
};

export default ValidatePassword;
