import React from 'react';
import { useLanguageContext, useThemeContext } from '../../../context';
import TableBody from '../TableContainer/TableBody';
import { formatNum } from '../../../constants';

const List = ({ data, index }) => {
    const { theme } = useThemeContext();
    const { I18n } = useLanguageContext();
    const { discount_amount, discount_name, discount_rate, discount_type, orders_applied } = data || {};

    return (
        <TableBody className={`fourColumn cursorPointer`} style={{ borderColor: theme.inputBorder }}>
            <div className="BoxWidth justifyStart">
                <p className="fontSize14 OneLineTruncate" style={{ color: theme.white }}>
                    {discount_name}
                </p>
            </div>
            <div className="BoxWidth justifyCenter">
                <p className="fontSize14 OneLineTruncate" style={{ color: theme.white }}>
                    {discount_type === '%' ? `${discount_rate}%` : `Rs.${discount_rate}`}
                </p>
            </div>
            <div className="BoxWidth justifyCenter">
                <p className="fontSize14 OneLineTruncate" style={{ color: theme.white }}>
                    {orders_applied}
                </p>
            </div>

            <div className="BoxWidth justifyEnd">
                <p className="fontSize14 OneLineTruncate" style={{ color: theme.white }}>
                    Rs. {formatNum(discount_amount) || 0}
                </p>
            </div>
        </TableBody>
    );
};

export default List;
