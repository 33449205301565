import React, { useEffect, useState } from 'react';
import { Container } from 'react-bootstrap';
import { Loading, TabBox } from '../../../../common';
import { useAuthContext, useLanguageContext, useThemeContext } from '../../../../../context';
import usePermission from '../../../../../hooks/usePermission';
import { DEVICE_HEIGHT, formatDateWithTime, formateIdForApi, payment_key_metrics } from '../../../../../constants';
import SalesOverview from './Sales';
import WalletOverview from './Payment';
import { useLocation } from 'react-router-dom';

const NewDashboardComponent = () => {
    const { I18n } = useLanguageContext();
    const { theme } = useThemeContext();
    const { business } = useAuthContext();
    const { state } = useLocation();
    const [tabValue, setTabVal] = useState();
    const [tabIndex, setTabIndex] = useState(0);
    const [selectedDateRange, setSelectedDateRange] = useState({ start_date: formatDateWithTime(new Date()), end_date: formatDateWithTime(new Date()) });
    const [visible, setVisible] = useState({ reports: false, location: true, dateSelector: true });
    const [selectedLocation, setSelectedLocation] = useState({
        [business?.id]: {
            business_name: business?.location_name,
            selected: true,
        },
    });

    const tabs = [];
    const salesPermission = usePermission('sales-overview');
    const walletPermission = usePermission('wallet-overview');

    if (salesPermission?.canView) {
        tabs.push(I18n.sales);
    }
    if (walletPermission?.canView) {
        tabs.push(I18n.wallet);
    }

    useEffect(() => {
        if (state?.screenName === 'wallet') {
            const ind = tabs.indexOf('Wallet');
            setTabVal(tabs[ind]);
            setTabIndex(ind);
        } else if (state?.screenName === 'sales') {
            const ind = tabs.indexOf('Sales');
            setTabVal(tabs[ind]);
            setTabIndex(ind);
        } else {
            setTabVal(tabs[0]);
        }
    }, [salesPermission, walletPermission]);

    const handleChange = (event, newValue) => {
        setTabVal(event.target.innerText);
        setTabIndex(newValue);
    };

    return (
        <div
            className="dashboardBox"
            style={{
                background: theme.topBarBG,
                height: DEVICE_HEIGHT - 45,
            }}>
            <Container fluid>
                <TabBox tabValue={tabIndex} tabs={tabs} handleChange={handleChange} />
                {tabValue === 'Sales' ? (
                    <SalesOverview
                        selectedDateRange={selectedDateRange}
                        setSelectedDateRange={setSelectedDateRange}
                        selectedLocation={selectedLocation}
                        setSelectedLocation={setSelectedLocation}
                        initialTab={state?.tab === 'transactions' ? 1 : state?.tab === 'online' ? 2 : state?.tab === 'report' ? 3 : 0}
                        visible={visible}
                        setVisible={setVisible}
                    />
                ) : tabValue === 'Wallet' ? (
                    <WalletOverview
                        selectedDateRange={selectedDateRange}
                        setSelectedDateRange={setSelectedDateRange}
                        dashboardData={payment_key_metrics}
                        selectedLocation={selectedLocation}
                        setSelectedLocation={setSelectedLocation}
                        initialTab={state?.tab === 'balance' ? 1 : state?.tab === 'activity' ? 2 : 0}
                        visible={visible}
                        setVisible={setVisible}
                    />
                ) : null}
            </Container>
        </div>
    );
};
export default NewDashboardComponent;