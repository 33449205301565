import React from 'react';
import {useLanguageContext, useThemeContext} from '../../../../context';

const InsightDetails = ({data}) => {
    const {I18n} = useLanguageContext();
    const {theme} = useThemeContext();

    return (
        <div className="reportDetailsBox">
            <h3 className="fontSize14  fontWeight700 marBot15 marTop0" style={{color: theme.white}}>
                {I18n.insights}
            </h3>
            {data?.map((item, index) => {
                return (
                    <div key={index} className="repotDetails">
                        <p className="fontSize14  fontWeight400 marBot0 marTop0" style={{color: theme.lightGray}}>
                            {I18n[item.title]}
                        </p>
                        <p className="fontSize14  fontWeight400 marBot0 marTop0" style={{color: theme.lightGray}}>
                            {item?.value}
                        </p>
                    </div>
                );
            })}
        </div>
    );
};

export default InsightDetails;
