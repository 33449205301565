import {useEffect, useState} from 'react';
import {Col, Row} from 'react-bootstrap';
import ProgressBar from 'react-bootstrap/ProgressBar';
import {useLocation, useNavigate} from 'react-router-dom';
import {Sync, getAllCountry, getCatalogsData} from '../../../../api';
import {ONBOARDING_KEYS, UNITS, fetchFileData, formatNum, toSnakeCase, validateBusinessDetails} from '../../../../constants';
import {useAuthContext, useLanguageContext, useSessionContext, useThemeContext} from '../../../../context';
import {BackHeader, Button, CustomContainer, FooterLogo, Loading, TextField} from '../../../common';
import {bulkCreateCatalogs, bulkCreateCategory} from '../../../../pos-core/database/helpers';

const BusinessDetails = () => {
    const navigate = useNavigate();

    const {user, business, setBusiness, handleIncrementCounter, account} = useAuthContext();
    const {handleCreateSession} = useSessionContext();
    const {I18n} = useLanguageContext();
    const {theme} = useThemeContext();
    const {state} = useLocation();

    const [isLoading, setIsLoading] = useState(false);
    const [activeIndex, setActiveIndex] = useState(0);
    const [data, setData] = useState([]);
    const [errors, setErrors] = useState({});
    const [provinceList, setProvinceList] = useState([]);
    const [cityList, setCityList] = useState([]);
    const [locationList, setLocationList] = useState([]);
    const [formData, setFormData] = useState({
        name: '',
        kind: '',
        type: '',
        province: '',
        city: '',
        address: '',
        location_type: '',
        location_name: 'Headquarter',
    });

    const businessDetails = [
        {
            id: 'initialInfo',
            title: 'Tell us a little about your business',
            subHeader: 'We’ll help you get started based on your selection',
            fields: [
                {
                    label: I18n.what_kind_of_business_are_you,
                    type: 'select',
                    options: [{name: 'Single store'}, {name: 'Franchise'}],
                    stateKey: 'kind',
                },
            ],
        },
        {
            id: 'about',
            title: 'Tell us a little about your business',
            subHeader: 'Every business is unique. We want to hear about yours.',
            fields: [
                {
                    label: I18n.what_is_your_business_type,
                    type: 'select',
                    options: [{name: 'Retail'}, {name: 'Restaurant'}],
                    stateKey: 'type',
                },
                {
                    label: I18n.what_is_your_business_name,
                    type: 'text',
                    stateKey: 'name',
                },
            ],
        },
        {
            id: 'locationInfo',
            title: 'Setup your location',
            subHeader: 'Please setup your location and business address',
            fields: [
                {
                    label: I18n.location_name,
                    type: 'text',
                    stateKey: 'location_name',
                    disabled: true,
                },
                {
                    label: I18n.location_type,
                    type: 'select',
                    options: [{name: 'Physical location'}, {name: 'Mobile location'}],
                    stateKey: 'location_type',
                },
            ],
        },
        {
            id: 'location',
            title: 'Setup your location',
            subHeader: 'What’s your business location?',
            fields: [
                {
                    label: I18n.province,
                    type: 'select',
                    options: provinceList,
                    stateKey: 'province',
                },
                {
                    label: I18n.city,
                    type: 'select',
                    options: cityList,
                    stateKey: 'city',
                },
                {
                    label: I18n.address,
                    type: 'text',
                    stateKey: 'address',
                },
            ],
        },
    ];

    const toggleIndex = val => setActiveIndex(val);

    const onChange = (label, value) => {
        handleChange(label, value);
    };

    const handleNext = async () => {
        const formErrors = validateBusinessDetails(formData, activeIndex);
        console.log('formErrors', formErrors);
        setErrors(formErrors);
        if (Object.keys(formErrors).length > 0) return;

        if (activeIndex < 3) {
            if (activeIndex === 0 && formData?.kind.name === 'Single store') {
                const temp = data?.map(item => {
                    if (item.id === 'locationInfo') {
                        return {
                            ...item,
                            fields: item.fields.map(field => ({...field, disabled: false})),
                        };
                    } else {
                        return item;
                    }
                });
                setData(temp);
                setFormData(prev => ({...prev, location_name: ''}));
            }
            setActiveIndex(x => x + 1);
        } else {
            handleSave();
        }
    };

    const handleSave = async () => {
        try {
            setIsLoading(true);
            let paramsForBusiness = {
                ...formData,
                isHq: true,
                phone: account.phone_number,
                email: account.email,
                kind: formData.kind?.name,
                location_type: formData.location_type?.name,
                country: state?.country,
                city: formData.city?.name,
                province: formData.province?.name,
                type: formData.type?.name,
            };
            console.log('paramsForBusiness', paramsForBusiness);
            business.updateDetails(paramsForBusiness).then(async res => {
                setBusiness(res);
            });

            let apiResponse = await getCatalogsData({
                // catalog_name: 'MedicineCatalog',
                catalog_name: 'null',
            });
            if (apiResponse.status === 200) {
                const catalogData = apiResponse?.data?.response;
                const uniqueCategories = new Set(catalogData.map(item => item.category?.toLowerCase()));
                const uniqueCategoriesArray = [...uniqueCategories];
                await bulkCreateCategory(uniqueCategoriesArray, account);

                const allCategories = await account?.category?.fetch();
                const aggregatedData = catalogData?.map(item => {
                    const productCategory = allCategories?.find(category => category?.name?.toLowerCase() === item?.category?.toLowerCase());
                    const {name, price, cost_per_item, add_qty, low_stock_alert, barcode, unit} = item;

                    let selling = Number(price) || 0;
                    let costing = Number(cost_per_item) || 0;
                    let totalProfit = selling - costing;
                    let totalMargin = (totalProfit / costing) * 100;
                    let profit = totalProfit <= 0 ? '0' : totalProfit;
                    let margin = totalMargin <= 0 || totalMargin == Infinity ? '0' : formatNum(totalMargin);
                    let itemUnit = UNITS?.find(itemUnit => itemUnit?.name?.toLowerCase() === unit?.toLowerCase())?.name;

                    let productObj = {
                        cost_price: Number(cost_per_item) || 0,
                        is_taxable: false,
                        is_trackable: false,
                        name,
                        rate: 0,
                        secondary_unit: '',
                        unit: itemUnit || '',
                        barcode: barcode || '',
                        low_stock: Number(low_stock_alert),
                        margin: Number(margin),
                        profit: Number(profit),
                        quantity: Number(add_qty) || 0,
                        selling_price: Number(price) || 0,
                        tax: '',
                        category: productCategory,
                    };
                    return productObj;
                });
                await bulkCreateCatalogs(aggregatedData, account);
            }
            await Sync(user.email);

            // let paramsForSession = {
            //     business,
            //     user,
            //     is_active: true,
            //     opening_balance: 0,
            //     started_at: new Date().getTime(),
            // };

            // handleCreateSession(paramsForSession);
            handleIncrementCounter('session');

            setIsLoading(false);
            navigate('/onboarding');
        } catch (error) {
            console.log('something went wrong', error.message);
        }
    };

    const handleEnterPress = event => {
        if (event.key === 'Enter') {
            handleNext();
        }
    };

    const handleChange = (key, value) => {
        const getKeyName = ONBOARDING_KEYS[key];
        setFormData(prev => ({...prev, [getKeyName]: value}));

        if (getKeyName === 'province') {
            setFormData(prev => ({...prev, city: ''}));
            const cityData = locationList[0].provinces.find(item => item.provinceName === value.name)?.cities;
            if (cityData?.length > 0) {
                const data = cityData.map(item => ({name: item}));
                setCityList(data);
            }
        }

        setErrors(prevErrors => {
            const updatedErrors = {...prevErrors};
            if (updatedErrors[toSnakeCase(getKeyName)]) {
                delete updatedErrors[toSnakeCase(getKeyName)];
            }

            return updatedErrors;
        });
    };

    const renderFields = () => {
        return data[activeIndex]?.fields.map(field => (
            <TextField
                key={field.label}
                onChange={onChange}
                label={field.label}
                placeholder={field.label}
                value={formData[field.stateKey]}
                data={field.options}
                inputType={field.type}
                type="text"
                disabled={field.disabled}
                error={errors[field.stateKey]}
                {...(field.type === 'select' && {
                    suffix: 'downIcon2',
                    suffix_fill: theme.white,
                    suffix_width: '24',
                    suffix_height: '24',
                    suffix_viewBox: '0 0 18 18',
                })}
            />
        ));
    };
    useEffect(() => {
        setData(businessDetails);
    }, [cityList, provinceList]);

    useEffect(() => {
        const fetchCountries = async () => {
            try {
                const res = await getAllCountry();
                if (res?.success) {
                    setLocationList(res.data);
                }
            } catch (error) {
                console.log(error.message);
            }
        };
        fetchCountries();
    }, []);

    useEffect(() => {
        if (locationList.length > 0) {
            const selectedCountry = locationList.find(item => item.countryName === state?.country);
            setProvinceList(selectedCountry ? selectedCountry?.provinces?.map(item => ({name: item.provinceName})) : []);
        }
    }, [locationList]);

    return (
        <>
            <CustomContainer handlePress={handleEnterPress} className="signupCont">
                <Row className="justify-content-md-center">
                    <Col md={3} className="progressbarMainBox">
                        <div className="progressbarMainBoxInner">
                            <ProgressBar onClick={() => toggleIndex(0)} now={activeIndex == 0 ? 100 : 0} />
                            <ProgressBar onClick={() => toggleIndex(1)} now={activeIndex == 1 ? 100 : 0} />
                            <ProgressBar onClick={() => toggleIndex(2)} now={activeIndex == 2 ? 100 : 0} />
                            <ProgressBar onClick={() => toggleIndex(3)} now={activeIndex == 3 ? 100 : 0} />
                        </div>
                    </Col>
                </Row>

                <Row className="justify-content-md-center">
                    <Col md={5}>
                        <div className="loginFormBox">
                            <div>
                                <h1 className="fontSize24 fontWeight700 " style={{color: theme.text}}>
                                    {data[activeIndex]?.title}
                                </h1>
                                <p className="fontSize14 fontWeight400 darkGrayTwo marBot0">{data[activeIndex]?.subHeader}</p>
                            </div>

                            <div className="singupFields businessCreationFields">{renderFields()}</div>
                            <div className="marTop15">
                                <Button
                                    className={`width100 fontWeight600 fontSize16 cursorPointer`}
                                    title={activeIndex < 3 ? I18n.continue : I18n.finish}
                                    handleClick={handleNext}
                                />
                            </div>
                        </div>
                    </Col>
                </Row>
            </CustomContainer>

            {isLoading && <Loading heading={I18n.starting_pos} subHeading={I18n.start_selling} preHeading={I18n.thank_you} />}
        </>
    );
};

export default BusinessDetails;
