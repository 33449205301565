import {useEffect, useState} from 'react';
import {DEVICE_HEIGHT} from '../../../../../constants';
import {useAuthContext, useLanguageContext, useThemeContext} from '../../../../../context';
import {FormCheck} from 'react-bootstrap';
import RadioButton from '../../../../common/RadioButton';
import MarginBreakDownComponent from '../../../../common/MarginBreakDownComponent';
import {updateModule} from '../../../../../pos-core/database/helpers';
import {Button, MessageModal} from '../../../../common';
import {updateSettings} from '../../../../../api';
import {toast} from 'react-toastify';

const AppSettings = () => {
    const {userRole, businessSettings, fetchSettings} = useAuthContext();
    const {I18n} = useLanguageContext();
    const {theme} = useThemeContext();

    const [showOpenItem, setShowOpenItem] = useState({status: false, id: ''});
    const [allowPriceEdit, setAllowPriceEdit] = useState({status: false, id: ''});
    const [mandateCustomerDetails, setMandateCustomerDetails] = useState({status: false, id: ''});
    const [showDiscountButton, setShowDiscountButton] = useState({status: false, id: ''});
    const [marginReports, setMarginReports] = useState({status: false, id: ''});
    const [enableStockList, setEnableStockList] = useState({status: false, id: ''});
    const [counterManagement, setCounterManagement] = useState({status: false, id: ''});
    const [itemService, setItemService] = useState({value: false, id: ''});
    const [loading, setLoading] = useState(false);
    const [isChanged, setIsChanged] = useState(false);
    const [confirmatiomModal, setConfirmationModal] = useState(false);

    const appSettings = businessSettings?.['app_settings'];

    const handleModulePermission = async () => {
        const module = await userRole?.find(mod => mod.name === 'counter');
        const param = counterManagement.status
            ? {
                  create: true,
                  update: true,
                  delete: true,
                  read: true,
              }
            : {
                  create: false,
                  update: false,
                  delete: false,
                  read: false,
              };

        await updateModule(module, param);
    };

    const handleSaveAppSettings = async () => {
        try {
            setLoading(true);
            const payload = {
                settings: [
                    {
                        id: showOpenItem.id,
                        scope: 'business',
                        setting_value: {
                            status: showOpenItem.status,
                        },
                    },
                    {
                        id: allowPriceEdit.id,
                        scope: 'business',
                        setting_value: {
                            status: allowPriceEdit.status,
                        },
                    },
                    {
                        id: mandateCustomerDetails.id,
                        scope: 'business',
                        setting_value: {
                            status: mandateCustomerDetails.status,
                        },
                    },
                    {
                        id: showDiscountButton.id,
                        scope: 'business',
                        setting_value: {
                            status: showDiscountButton.status,
                        },
                    },
                    {
                        id: marginReports.id,
                        scope: 'business',
                        setting_value: {
                            status: marginReports.status,
                        },
                    },
                    {
                        id: counterManagement.id,
                        scope: 'business',
                        setting_value: {
                            status: counterManagement.status,
                        },
                    },
                    {
                        id: itemService.id,
                        scope: 'business',
                        setting_value: [
                            {
                                label: 'Items',
                                is_selected: itemService.value?.toLowerCase() === 'items',
                            },
                            {
                                label: 'Services',
                                is_selected: itemService.value?.toLowerCase() === 'services',
                            },
                        ],
                    },
                    {
                        id: enableStockList.id,
                        scope: 'business',
                        setting_value: {
                            status: enableStockList.status,
                        },
                    },
                ],
            };
            const response = await updateSettings(payload);
            if (response.status) {
                toast.success('Changes updated successfully');
                setIsChanged(false);
                handleModulePermission();
                fetchSettings();
            }
        } catch (error) {
            console.log('Error', error);
        } finally {
            setLoading(false);
            if (confirmatiomModal) setConfirmationModal(false);
        }
    };

    const handleCancel = () => {
        setConfirmationModal(true);
    };

    const handleDiscardModal = () => {
        setConfirmationModal(false);
    };

    const handleChange = (e, appSetting) => {
        setIsChanged(true);
        if (appSetting.name === 'Show open item keypad') {
            setShowOpenItem(prev => ({...prev, status: e}));
        } else if (appSetting.name === 'Select item or service library') {
            setItemService(prev => ({...prev, value: e}));
        } else if (appSetting.name === 'Allow price edit') {
            setAllowPriceEdit(prev => ({...prev, status: e}));
        } else if (appSetting.name === 'Mandate customer details') {
            setMandateCustomerDetails(prev => ({...prev, status: e}));
        } else if (appSetting.name === 'Show discount button') {
            setShowDiscountButton(prev => ({...prev, status: e}));
        } else if (appSetting.name === 'Margin and reports') {
            setMarginReports(prev => ({...prev, status: e}));
        } else if (appSetting.name === 'Enable counter management to link your payment device') {
            setCounterManagement(prev => ({...prev, status: e}));
        } else if (appSetting.name === 'Enable stock list') {
            setEnableStockList(prev => ({...prev, status: e}));
        }
    };

    const handleCheck = appSetting => {
        if (appSetting.name === 'Show open item keypad') {
            return showOpenItem.status;
        } else if (appSetting.name === 'Select item or service library') {
            return itemService.value;
        } else if (appSetting.name === 'Allow price edit') {
            return allowPriceEdit.status;
        } else if (appSetting.name === 'Mandate customer details') {
            return mandateCustomerDetails.status;
        } else if (appSetting.name === 'Show discount button') {
            return showDiscountButton.status;
        } else if (appSetting.name === 'Margin and reports') {
            return marginReports.status;
        } else if (appSetting.name === 'Enable counter management to link your payment device') {
            return counterManagement.status;
        } else if (appSetting.name === 'Enable stock list') {
            return enableStockList.status;
        }
    };

    const setInitialValues = () => {
        if (appSettings) {
            appSettings.forEach(appSetting => {
                if (appSetting.name === 'Show open item keypad') {
                    setShowOpenItem({id: appSetting.id, status: appSetting.setting_value.status});
                } else if (appSetting.name === 'Select item or service library') {
                    const selectedType = appSetting.setting_value.find(x => x.is_selected);
                    setItemService({id: appSetting.id, value: selectedType?.label?.toLowerCase()});
                } else if (appSetting.name === 'Allow price edit') {
                    setAllowPriceEdit({id: appSetting.id, status: appSetting.setting_value.status});
                } else if (appSetting.name === 'Mandate customer details') {
                    setMandateCustomerDetails({id: appSetting.id, status: appSetting.setting_value.status});
                } else if (appSetting.name === 'Show discount button') {
                    setShowDiscountButton({id: appSetting.id, status: appSetting.setting_value.status});
                } else if (appSetting.name === 'Margin and reports') {
                    setMarginReports({id: appSetting.id, status: appSetting.setting_value.status});
                } else if (appSetting.name === 'Enable counter management to link your payment device') {
                    setCounterManagement({id: appSetting.id, status: appSetting.setting_value.status});
                } else if (appSetting.name === 'Enable stock list') {
                    setEnableStockList({id: appSetting.id, status: appSetting.setting_value.status});
                }
            });
        }
    };

    useEffect(() => {
        setInitialValues();
    }, [appSettings]);

    return (
        <div className="pad20" style={{backgroundColor: theme.topBarBG}}>
            <div className="appSettingsWrapper borderRadiusBottomleftBottomRigt pad20" fluid style={{background: theme.blackBg, height: DEVICE_HEIGHT - 80}}>
                <div className="spaceBetweenCenter">
                    <p className="marTop10 marBot0 fontSize18 fontWeight600" style={{color: theme.white}}>
                        {I18n.app_settings}
                    </p>

                    {isChanged && (
                        <div className="flex gap10 ">
                            <Button title="Cancel" handleClick={e => handleCancel(e)} className={`cancelBtn  fontWeight400 fontSize14 cursorPointer`} />
                            <Button
                                saveLoad={loading}
                                disabled={loading}
                                title="Save"
                                handleClick={e => handleSaveAppSettings(e)}
                                className={`saveBtn  fontWeight400 fontSize14 cursorPointer`}
                            />
                        </div>
                    )}
                </div>

                {appSettings?.map(opt => {
                    return (
                        <div key={opt.id}>
                            <p className="fontSize14 marBot10 marTop30 fontWeight500" style={{color: theme.white}}>
                                {opt.name}
                            </p>

                            {opt.setting_type === 'radio' ? (
                                <div>
                                    <RadioButton
                                        options={opt?.setting_value?.map(item => ({...item, value: item.label.toLowerCase()}))}
                                        className="selectItemService"
                                        selectedSize={handleCheck(opt)}
                                        handleChange={e => handleChange(e.target.value, opt)}
                                    />
                                </div>
                            ) : (
                                <>
                                    <div className="marTop20 flex gap15">
                                        <FormCheck type="switch" id="stock_available" onChange={e => handleChange(e.target.checked, opt)} checked={handleCheck(opt)} />

                                        <p className="marTop3 marBot0 fontSize14 fontWeight400" style={{color: theme.white}}>
                                            {handleCheck(opt) ? I18n.enabled : I18n.disabled}
                                        </p>
                                    </div>
                                    {opt.name === 'Margin and reports' && handleCheck(opt) ? <MarginBreakDownComponent /> : null}
                                </>
                            )}

                            <div className="divider width100 marTop20" />
                        </div>
                    );
                })}
            </div>
            <MessageModal
                className="messageModal"
                setToggle={setConfirmationModal}
                toggle={confirmatiomModal}
                description={I18n.you_have_unsaved_changes}
                subDescription={I18n.if_you_leave_without_saving}
                primaryCta={{
                    title: I18n.save_changes,
                    action: handleSaveAppSettings,
                    backgroundColor: theme.brightGreen,
                    borderColor: theme.brightGreen,
                    txtColor: theme.white,
                    loading: loading,
                }}
                secondaryCta={{
                    title: I18n.discard,
                    action: handleDiscardModal,
                }}
            />
        </div>
    );
};

export default AppSettings;
