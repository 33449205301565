import {Col, Row} from 'react-bootstrap';
import {useAuthContext, useLanguageContext, useThemeContext} from '../../../context';
import {useEffect, useState} from 'react';
import {PosAppModal} from '../V2';
import {DEVICE_HEIGHT, formatDateWithTime, generateVoucherCode, getOrderLevelFields, STORE_CREDIT, toSnakeCase, uuid} from '../../../constants';
import {bulkCreateOrderLines, bulkUpdateOrderLinesCal} from '../../../pos-core/database/helpers';
import ItemList from './ItemList';
import RefundReasonComponent from './RefundReasonComponent';
import TransactionSuccessfulModal from '../TransactionSuccessfulModal';
import {get_FBR_PRA_InvoiceNumber} from '../../../api';
import {toast} from 'react-toastify';

const RefundItemModal = ({toggle, setToggle, order, order_lines}) => {
    const {handleCreateOrder} = useAuthContext();
    const {I18n} = useLanguageContext();
    const {theme} = useThemeContext();
    const [cloneItems, setCloneItems] = useState();
    const [selectedItems, setSelectedItems] = useState({});
    const [refundType, setRefundType] = useState('Cash');
    const [refundReason, setRefundReason] = useState('Returned goods');
    const [restockItems, setRestockItems] = useState(true);
    const [successModal, setSuccessModal] = useState(false);
    const [refundedOrderDetails, setRefundedOrderDetails] = useState({});
    const [invoiceNumber, setInvoiceNumber] = useState('');

    const configuredIntegration = JSON.parse(localStorage.getItem('integrations'));

    const [step, setStep] = useState(1);
    const [errors, setErrors] = useState({});

    const handleClose = event => {
        setRestockItems(true);
        setSelectedItems({});
        setRefundType('Cash');
        setRefundReason('Returned goods');
        setStep(1);
        setErrors({});
        setToggle(false);
    };

    const handleConfirm = async () => {
        if (step === 1) {
            if (Object.keys(selectedItems).length === 0) {
                setErrors({message: 'Please select an item first'});
                return;
            }
            setStep(2);
        } else {
            const type = 'refund';
            let invoiceType = '';
            let invoiceNum = '';
            let voucherCode = generateVoucherCode();
            const refundLineItems = Object.keys(selectedItems).map(item => cloneItems.find(cloneItem => cloneItem.id === item));
            const {subTotal, total, totalItemLevelDiscount, totalDiscount, totalInclusive, totalExclusive} = await getOrderLevelFields(order, refundLineItems);

            try {
                let createdOrder = await handleCreateOrder({createService: false});
                if (configuredIntegration) {
                    if (configuredIntegration?.integration === 'FBR configuration') {
                        invoiceType = 'fbr';
                        let totalItemsQty = 0;
                        const modifiedLines = await Promise.all(
                            refundLineItems.map(async line => {
                                totalItemsQty += line.quantity;
                                const item = await line.product.fetch();
                                return {
                                    ItemCode: item.barcode,
                                    ItemName: item.name,
                                    Quantity: line.quantity,
                                    PCTCode: '00000000',
                                    TaxRate: line.tax_rate || 0,
                                    SaleValue: parseFloat((line.base_price * line.quantity).toFixed(2)),
                                    TotalAmount: parseFloat((line.base_price * line.quantity + line.aggregate_tax).toFixed(2)),
                                    TaxCharged: parseFloat(line.aggregate_tax.toFixed(2)),
                                    Discount: parseFloat(line.aggregate_discount.toFixed(2)),
                                    FurtherTax: 0.0,
                                    InvoiceType: 3,
                                    RefUSIN: order?.number,
                                };
                            }),
                        );

                        const payload = {
                            InvoiceNumber: '',
                            POSID: Number(configuredIntegration?.posId),
                            USIN: createdOrder?.number,
                            DateTime: formatDateWithTime(new Date()),
                            TotalBillAmount: parseFloat(order?.total.toFixed(2)),
                            TotalQuantity: totalItemsQty,
                            TotalSaleValue: parseFloat(subTotal.toFixed(2)),
                            TotalTaxCharged: parseFloat((totalInclusive + totalExclusive).toFixed(2)),
                            Discount: parseFloat((totalDiscount + totalItemLevelDiscount).toFixed(2)),
                            FurtherTax: 0.0,
                            PaymentMode: toSnakeCase(refundType) === STORE_CREDIT ? 3 : 1,
                            RefUSIN: order?.number,
                            InvoiceType: 3,
                            Items: modifiedLines,
                        };

                        const response = await get_FBR_PRA_InvoiceNumber(payload);
                        console.log('response', response);

                        if (response.Code == '100') {
                            setInvoiceNumber(response?.InvoiceNumber);
                            invoiceNum = response?.InvoiceNumber;
                        } else {
                            toast.error(response?.Errors);
                            return;
                        }
                    }
                }

                const payload = {
                    payment_method: toSnakeCase(refundType),
                    total: -Number(total),
                    amount: Number(0),
                    change: Number(0),
                    sub_total: -Number(subTotal),
                    total_tax: -Number(totalInclusive + totalExclusive),
                    total_item_level_discount: Number(totalItemLevelDiscount),
                    order_level_discount: Number(totalDiscount),
                    parent_number: order?.number,
                    type: order?.type,
                    status: 'complete',
                    is_refunded: true,
                    refunded_reason: refundReason,
                    invoice_type: invoiceType,
                    invoice_number: invoiceNum,
                };

                const newlyCreatedOrder = await createdOrder.updateRefundedOrder(payload);
                const updatedLines = await bulkCreateOrderLines(newlyCreatedOrder, refundLineItems, type);
                await bulkUpdateOrderLinesCal(updatedLines);

                if (restockItems) {
                    for (const line of refundLineItems) {
                        const prod = await line.product.fetch();
                        const prodBus = await prod.product_business.fetch();
                        prodBus[0]?.addProductQuantity(line.quantity);
                    }
                }

                if (toSnakeCase(refundType) === STORE_CREDIT) {
                    const storeCreditPayload = {
                        total_amount: total,
                        remaining_amount: total,
                        barcode: voucherCode,
                        status: 'active',
                    };

                    await newlyCreatedOrder.createVoucher(storeCreditPayload);
                }

                setRefundedOrderDetails({order: newlyCreatedOrder, orderLines: updatedLines, voucherCode});
                setSuccessModal(true);
            } catch (error) {
                console.log('Error creating orders and lines:', error);
            }
        }
    };

    const handleUpdateItemQty = (item, val, type) => {
        const updatedItems = cloneItems?.map(x => {
            if (x.id === item.id)
                return {
                    ...(x._raw ? x._raw : x),
                    quantity: type === 'increment' ? Number(x.quantity + 1) : Number(x.quantity - 1),
                    product: x.product,
                };
            else {
                return x;
            }
        });
        setCloneItems(updatedItems);
    };

    const handleSelectAll = () => {
        let list = cloneItems;
        if (Object.keys(selectedItems)?.length !== list.length) {
            const newSelectedItems = {};
            list?.forEach(val => {
                newSelectedItems[val?.id] = val?.id;
            });
            setSelectedItems(newSelectedItems);
        } else {
            setSelectedItems({});
        }
    };

    const handleChecked = val => {
        const id = val?.id;
        setSelectedItems(items => {
            const updatedItems = {...items};
            if (updatedItems[id]) {
                delete updatedItems[id];
            } else {
                updatedItems[id] = id;
            }
            return updatedItems;
        });
    };

    useEffect(() => {
        setCloneItems(order_lines);
    }, [toggle]);

    useEffect(() => {
        if (Object.keys(errors).length > 0) {
            const timeoutId = setTimeout(() => {
                setErrors({});
            }, 3000);
            return () => clearTimeout(timeoutId);
        }
    }, [errors]);

    return (
        <>
            <PosAppModal
                className="selectCustomerModal refundModal"
                toggle={toggle}
                handleClose={handleClose}
                title={step === 1 ? I18n.refund_items : I18n.select_refund_method_and_reason}
                toastBox={
                    Object?.values(errors).length > 0
                        ? {
                              errorToast: true,
                              message: errors.message,
                          }
                        : null
                }
                backCta={{
                    name: 'backArrowIcon',
                    fill: theme.barclaysBlue,
                    width: '18',
                    height: '18',
                    viewBox: '0 0 18 18',
                }}
                bottomSaveCta={{
                    title: step === 1 ? I18n.continue : I18n.confirm,
                    action: () => handleConfirm(),
                }}
                bottomCancelCta={{
                    title: I18n.cancel,
                    action: () => handleClose(),
                }}>
                <Row className="justify-content-md-center">
                    <Col>
                        <div
                            className="selectCustomerListBox"
                            style={{
                                height: DEVICE_HEIGHT - 250,
                            }}>
                            {step === 1 ? (
                                <ItemList
                                    handleSelectAll={handleSelectAll}
                                    selectedItems={selectedItems}
                                    cloneItems={cloneItems}
                                    order_lines={order_lines}
                                    handleUpdateItemQty={handleUpdateItemQty}
                                    handleChecked={handleChecked}
                                    restockItems={restockItems}
                                    setRestockItems={setRestockItems}
                                />
                            ) : (
                                <RefundReasonComponent refundType={refundType} refundReason={refundReason} setRefundType={setRefundType} setRefundReason={setRefundReason} />
                            )}
                        </div>
                    </Col>
                </Row>
            </PosAppModal>
            <TransactionSuccessfulModal
                setToggle={setSuccessModal}
                toggle={successModal}
                order={refundedOrderDetails?.order}
                orderLines={refundedOrderDetails?.orderLines}
                voucherCode={refundedOrderDetails?.voucherCode}
                isRestaurantPos={false}
                activeOrders={[]}
                invoiceNumber={invoiceNumber}
                type="refund"
            />
        </>
    );
};

export default RefundItemModal;
