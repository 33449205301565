import {useAuthContext, useThemeContext} from '../../../context';
import {Accordion, Nav} from 'react-bootstrap';
import {SUB_NAV_LIST, SETTING_SUB_NAV_LIST} from './subNavData';
import Icon from '../../../assets/icons';
const HamburgerItem = ({hamburgerIcon, hamburgerSubIcon, hamburgerTitle, eventKey, subRoute, selectedRoute, businessType, hanldlePermission, subNavList, from = 'primaryMenu'}) => {
    const {theme} = useThemeContext();
    const {setPersistData} = useAuthContext();

    const handleRemovePersistData = () => {
        setPersistData(null);
    };

    const SUB_NAV = from === 'primaryMenu' ? SUB_NAV_LIST : SETTING_SUB_NAV_LIST;

    return (
        <Nav.Item onClick={handleRemovePersistData}>
            <Nav.Link eventKey={eventKey} className={`${subRoute && SUB_NAV?.[subRoute] ? 'listOpen' : ''} ${selectedRoute == eventKey ? 'active' : ''}`}>
                {hamburgerIcon && (
                    <span className="marRight10 menuIcon">
                        <Icon name={hamburgerIcon} viewBox={'0 0 18 18'} fill={'white'} height={'20'} width={'20'} />
                    </span>
                )}
                <span className="fontSize14 menuTitle" style={{color: theme.white}}>
                    {hamburgerTitle}
                </span>
                {hamburgerSubIcon && (
                    <span className="subMenuArrowIcon">
                        <Icon name={hamburgerSubIcon} viewBox={'0 0 12 12'} fill={'white'} />
                    </span>
                )}
            </Nav.Link>
            {subRoute && SUB_NAV[subRoute] ? (
                <Accordion className="navSubmenuWrapper" defaultActiveKey="0">
                    <Accordion.Item eventKey="0">
                        {SUB_NAV[subRoute]
                            .filter(subRoute => hanldlePermission(subRoute.key, subRoute.allowedBusinessTypes))
                            .map((item, index) => {
                                return (
                                    <Accordion.Body key={index}>
                                        <Nav.Item>
                                            <Nav.Link
                                                eventKey={item.route}
                                                style={{
                                                    textDecoration: 'none',
                                                    color: '#ffff',
                                                    padding: '0px',
                                                }}>
                                                {item.name}
                                            </Nav.Link>
                                        </Nav.Item>
                                    </Accordion.Body>
                                );
                            })}
                    </Accordion.Item>
                </Accordion>
            ) : null}
        </Nav.Item>
    );
};
export default HamburgerItem;
