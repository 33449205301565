import React, {useState} from 'react';
import {Col, Row} from 'react-bootstrap';
import {useAuthContext, useLanguageContext, useThemeContext} from '../../../../../../context';
import ActivateWalletCard from '../../../../../common/ActivateWalletCard';
import {DEVICE_HEIGHT, RESTAURANT, RETAIL, toSnakeCase, validateActivateBusinessForm, WALLET_CARD_DATA} from '../../../../../../constants';
import {Button, TextField} from '../../../../../common';
import {walletActivationRequest} from '../../../../../../api';
import {toast} from 'react-toastify';

const ActivateWallet = () => {
    const {I18n} = useLanguageContext();
    const {theme} = useThemeContext();
    const {account} = useAuthContext();

    const [walletDetails, setWalletDetails] = useState({
        email_address: account?.email,
        phone_number: account?.phone_number,
    });
    const [loading, setLoading] = useState(false);

    const [errors, setErrors] = useState({});

    const onChange = (label, val) => {
        setWalletDetails(prev => ({...prev, [toSnakeCase(label)]: val}));

        setErrors(prevErrors => {
            const updatedErrors = {...prevErrors};
            if (updatedErrors[toSnakeCase(label)]) {
                delete updatedErrors[toSnakeCase(label)];
            }
            return updatedErrors;
        });
    };

    const handleAction = async () => {
        try {
            setLoading(true);
            const formErrors = validateActivateBusinessForm(walletDetails);
            console.log('formErrors', formErrors);
            setErrors(formErrors);
            if (Object.keys(formErrors).length > 0) return;

            const response = await walletActivationRequest(walletDetails);
            if (response.status) {
                toast.success('Your form has been submitted successfully');
                setWalletDetails({
                    first_name: '',
                    last_name: '',
                    account_title: '',
                    business_type: '',
                    email_address: '',
                    phone_number: '',
                });
            }
        } catch (error) {
            setErrors(error?.response?.data?.msg);
        } finally {
            setLoading(false);
        }
    };

    return (
        <div className="activateWalletWrapper marTop20" style={{minHeight: DEVICE_HEIGHT - 160}}>
            <Row className="justify-content-md-center">
                <Col md={7}>
                    <div className="marRight40">
                        <p className="marBot0 fontSize24 fontWeight600" style={{color: theme.white}}>
                            {I18n.oscar_wallet}
                        </p>
                        <p className="marTop10 marBot0 fontSize16 fontWeight400" style={{color: theme.darkGrayTwo}}>
                            {I18n.experience_seamless_money_transfer}
                        </p>

                        <Row>
                            {WALLET_CARD_DATA.map((item, ind) => {
                                return (
                                    <Col md={6} key={ind}>
                                        <ActivateWalletCard item={item} />
                                    </Col>
                                );
                            })}
                        </Row>
                    </div>
                </Col>
                <Col md={5}>
                    <div className="activateWalletRightSection">
                        <p className="marBot0 fontSize18 fontWeight600" style={{color: theme.white}}>
                            {I18n.ready_to_activate_your_business_wallet}
                        </p>

                        <p className="marTop10 marBot20 fontSize14 fontWeight400" style={{color: theme.darkGrayTwo}}>
                            {I18n.start_your_journey_to_fast_secure_transactions}
                        </p>

                        <Row>
                            <Col md={6} lg={6} xl={6} xxl={12}>
                                <TextField
                                    onChange={onChange}
                                    error={errors['first_name']}
                                    label={I18n.first_name}
                                    placeholder={I18n.first_name}
                                    value={walletDetails?.first_name}
                                    required={true}
                                    autoFocus={true}
                                />
                            </Col>
                            <Col md={6} lg={6} xl={6} xxl={12}>
                                <TextField
                                    onChange={onChange}
                                    error={errors['last_name']}
                                    label={I18n.last_name}
                                    placeholder={I18n.last_name}
                                    value={walletDetails?.last_name}
                                    required={true}
                                />
                            </Col>

                            <Col md={6} lg={6} xl={6} xxl={12}>
                                <TextField
                                    onChange={onChange}
                                    error={errors['account_title']}
                                    label={I18n.account_title}
                                    placeholder={I18n.account_title}
                                    value={walletDetails?.account_title}
                                    required={true}
                                />
                            </Col>
                            <Col md={6} lg={6} xl={6} xxl={12}>
                                <TextField
                                    error={errors['business_type']}
                                    label={I18n.business_type}
                                    placeholder={I18n.business_type}
                                    data={[RETAIL, RESTAURANT]}
                                    value={walletDetails.business_type}
                                    onChange={onChange}
                                    inputType="select"
                                    type="text"
                                    suffix="downIcon2"
                                    suffix_fill={theme.white}
                                    suffix_width={'24'}
                                    suffix_height={'24'}
                                    suffix_viewBox={'0 0 18 18'}
                                />
                            </Col>

                            <Col md={12} lg={12}>
                                <TextField
                                    onChange={onChange}
                                    error={errors['email_address']}
                                    label={I18n.email_address}
                                    placeholder={I18n.email_address}
                                    value={walletDetails?.email_address}
                                    disabled={true}
                                />
                            </Col>

                            <Col md={12} lg={12}>
                                <TextField
                                    onChange={onChange}
                                    error={errors['phone_number']}
                                    label={I18n.phone_number}
                                    placeholder={I18n.phone_number}
                                    value={walletDetails?.phone_number}
                                    maxLength={11}
                                    disabled={true}
                                />
                            </Col>

                            <Col md={12} lg={12}>
                                <Button
                                    type="secondary"
                                    className={`fontWeight500 fontSize20 cursorPointer width100`}
                                    backgroundColor={theme.brightGreen}
                                    txtColor={theme.white}
                                    title={I18n.request_a_call_back}
                                    handleClick={handleAction}
                                    saveLoad={loading}
                                    disabled={loading}
                                />
                            </Col>
                        </Row>
                    </div>
                </Col>
            </Row>
        </div>
    );
};

export default ActivateWallet;
