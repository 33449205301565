const API_ENDPOINTS = {
    LOGIN: '/users/login/',
    LOGOUT: '/users/logout/',
    REGISTER: '/users/register/',
    CHECK_EMAIL: '/users/unique/',
    SYNC: '/sync/v1/sync/',
    CHECK_PHONE_NUMBER: '/users/uniquephone/',
    UPLOAD_IMAGE: '/sync/v1/upload/',
    UPDATE_PASSWORD: '/users/update-member-password/',
    GET_CATALOGS: 'products/uploadCatalog',
    CREATE_DEVICE: '/kds/createDevice/',
    UPDATE_PIN: '/kds/updatePin/',
    UPDATE_ODOO_CONFIG: '/kds/updateOdooConfig/',
    LOGIN_DEVICE: 'kds/loginDevice/',
    VALIDATE_PIN: 'kds/loginDevicePin/',
    GET_KDS_ORDER: '/kds/order/',
    UPDATE_KDS_ORDER: '/kds/orderUpdateDelete/',
    WEB_SOCKET_URL: process.env.REACT_APP_WEB_SOCKET_URL,
    KITCHEN_NOTIFICATIONS: '/kds/kitchenNotifications/',
    UPDATE_DEVICE_CONFIG: '/kds/deviceConfigUpdate/',
    SEND_EMAIL: '/kds/emailSecretKey/',
    SEND_USER_CRED: '/users/sendEmail/',
    GET_COUNTRY: '/marketplace/all/countryProvinceCities/',
    SEND_OTP: '/users/SendOtp/',
    VERIFY_EMAIL: '/users/VerifyEmail/',
    FORGOT_PASSWORD: '/users/ForgetPassword/',
    DASHBOARD_ANALYTICS: '/analytics/',
    POST_BUSINEES_MARGIN: '/business/mentor/margin/',
    GET_MARGIN_REPORT: '/business/mentor/ledger-report',
    GET_MARGIN_ACTIVATION: '/business/mentor/activated-margin-date/',
    GET_TOP_AND_LOW_SELLING_PRODUCTS: '/analytics/topLowSellingProducts/',
    GET_LOW_AND_OUT_OF_STOCK_PRODUCTS_And_EXORT_CSV: '/analytics/lowOutOfStockProducts/',
    GET_LOCTION_WISE_SALES_AND_EXPORT_CSV: '/analytics/locationWiseSellingView/',
    GET_TOP_AND_LOW_SELLING_PRODUCTS_EXPORT_CSV: '/analytics/exportTopLowSellingProducts/',
    GET_SALES_REPORT_ANALYTICS: '/analytics/salesReport',
    GET_SALES_TRANSACTIONS_LIST: '/transactions/list/payments/',
    GET_Wallet_ACTIVITY: '/wallet/wallet-activity/',
    GET_WALLET_ACTIVITY_DETAILS: '/wallet/single-payment/',
    GET_TRANSACTION_BREAK_DOWN: '/wallet/payment-breakdown/',
    GET_BANKS: '/wallet/get/banks',
    FETCH_ACCOUNT_TITLE: '/wallet/titlefetch',
    SEND_WALLET_OTP: '/wallet/sendotp/',
    CREATE_BENEFICIARY: '/wallet/beneficiary/',
    GET_BENEFICIARIES: '/wallet/beneficiary',
    DELETE_BENEFICIARY: '/wallet/beneficiary/',
    PAYOUT_BENEFICIARY: '/wallet/payout/',
    GET_WALLET_CURRENT_BALANCE: '/wallet/current-balance',
    GET_WALLET_BALANCE: '/wallet/wallet-balance',
    GET_WALLET_ACTIVITY_EXPORT_CSV: '/wallet/wallet-activity/',
    GET_SALES_TRANSATION_EXPORT_CSV: '/transactions/list/payments/',
    CREATE_AND_UPDATE_COUNTER: '/counter/',
    GET_ALL_COUNTER: '/counter/all',
    GET_SESSION_REPORT: '/business/session-report/',
    GET_ALL_SESSION_LISTING: '/business/sessions/',
    VERIFY_PASSWORD: '/users/verify-password/',
    UPDATE_EMAIL: '/users/update-email/',
    UPDATE_ACCOUNT_PASSWORD: '/users/update-password/',
    UPDATE_ACCOUNT_PHONE: '/users/update-phone/',
    VERIFY_PHONE_OTP: '/users/verify-phone/',
    VERIFY_OLD_PHONE: 'users/verify-old-phone/',
    SETTINGS: '/settings/',
    BUSINESS_USERS_SETTINGS: '/users/me',
    WALLET_ACTIVATE: '/wallet/activate/',
    FISCALIZATION_SERVICE: 'http://localhost:8524/api/IMSFiscal/GetInvoiceNumberByModel',
    SRB_SERVICE: 'http://localhost:8282/SalesInvoiceService',
    VERIFY_OLD_EMAIL: 'users/verify-old-email/',
    VERIFY_USER_EMAIL: 'users/verify-email/',
    DASHBOARD_SALES_ANALYTICS: '/analytics/insights/sales',
    DASHBOARD_PAYMENT_ANALYTICS: 'analytics/insights/payments',
    DASHBOARD_PRODUCT_ANALYTICS: 'analytics/insights/products',
    DASHBOARD_Business_ANALYTICS: 'analytics/insights/business',
    DASHBOARD_Users_ANALYTICS: 'analytics/insights/users',
    DASHBOARD_INSIGHTS_DETAILS: 'analytics/insights/detail',
    DASHBOARD_GENERAL_REPORTS: 'business/dashboard-report',
};
export default API_ENDPOINTS;
