import React from 'react';
import { DEVICE_HEIGHT, formatNum, TopSell } from '../../../constants';
import { useLanguageContext, useThemeContext } from '../../../context';
import ReportsBoxHeader from '../Header/ReportsBoxHeader';
import NonIdealScreen from '../NonIdeal';
import TableHeader from '../TableContainer/TableHeader';
import TableFooter from '../TableFooter';
import List from './List';
import Loading from '../Loading';
const DiscountSaleReport = ({ data, handleExportCSV, loading }) => {
    const { I18n } = useLanguageContext();
    const { theme } = useThemeContext();

    const handleExport = async () => {
        await handleExportCSV();
    };
    return loading ? (
        <div className="reportsWrapper">
            <div className="reportsWrapperInner">
                <Loading />
            </div>
        </div>
    ) : data?.discounts?.length > 0 ? (
        <div className="reportsWrapper">
            <div className="reportsWrapperInner">
                <ReportsBoxHeader
                    title="discounts"
                    primaryCta={{
                        title: 'export_csv',
                        action: handleExport,
                    }}
                />

                <TableHeader className={`fourColumn`} style={{ borderColor: theme.inputBorder }}>
                    <div className="BoxWidth justifyStart">
                        <p className="fontSize16 OneLineTruncate" style={{ color: theme.white }}>
                            {I18n.discounts}
                        </p>
                    </div>

                    <div className="BoxWidth justifyCenter">
                        <p className="fontSize16 OneLineTruncate" style={{ color: theme.white }}>
                            {I18n.discount_rs_percentage}
                        </p>
                    </div>

                    <div className="BoxWidth justifyCenter">
                        <p className="fontSize16 OneLineTruncate" style={{ color: theme.white }}>
                            {I18n.discounts_applied}
                        </p>
                    </div>

                    <div className="BoxWidth justifyEnd">
                        <p className="fontSize16 OneLineTruncate" style={{ color: theme.white }}>
                            {I18n.discount_amount}
                        </p>
                    </div>
                </TableHeader>

                <div className='tableListMainBox' style={{ height: DEVICE_HEIGHT - 490, overflow: 'auto' }}>
                    {data?.discounts?.map((item, index) => {
                        return <List data={item} index={index} />;
                    })}
                </div>
                <TableFooter title="total_discount_amount" value={formatNum(data?.total || 0)} />
            </div>
        </div>
    ) : (
        <div className="onlineNonIdeal reportDashboardBox">
            <NonIdealScreen heading={I18n.discounts} subHeading={I18n.no_activity_zero_transactions_recorded} name="discountReportIcon" fill={theme.white} isMultiStep={false} viewBox='0 0 100 100' />
        </div>
    );
};

export default DiscountSaleReport;
