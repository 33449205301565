import React from 'react';
import OtpInput from 'react-otp-input';

const Otp = ({otp, setOtp, numInputs, placeHolder, focus = false, type = 'number'}) => {
    return (
        <OtpInput
            inputStyle={{
                width: '60px',
                height: '60px',
                fontWeight: 'bold',
            }}
            value={otp}
            onChange={setOtp}
            numInputs={numInputs}
            inputType={type}
            shouldAutoFocus={focus}
            skipDefaultStyles={false}
            renderInput={props => <input {...props} placeholder={placeHolder || ''} />}
        />
    );
};

export default Otp;
