import {useEffect, useState} from 'react';
import {Navigate, useLocation} from 'react-router-dom';
import {Loading} from '../../components/common';
import {useAuthContext, useSessionContext} from '../../context';

const RequireSession = ({children}) => {
    const location = useLocation();

    const {business, user} = useAuthContext();
    const {session, setSession} = useSessionContext();

    const [isLoading, setIsLoading] = useState(session ? false : true);

    useEffect(() => {
        handleSession();
    }, []);

    const handleSession = async () => {
        try {
            setIsLoading(true);
            const activeSessions = await user?.getUserActiveSession?.fetch();
            if (activeSessions?.length > 0) setSession(activeSessions[0]);
            else setSession('');
        } catch (error) {
            console.error('Error fetching active sessions--->', error);
        } finally {
            setIsLoading(false);
        }
    };

    return isLoading ? <Loading /> : session ? children : <Navigate to="/session" state={{from: location}} replace />;
};

export default RequireSession;
