import React from 'react';

const MetricsCard = ({item}) => {
    return (
        <div className="metricsBoxInner">
            <p className="fontSiz14 marBot0 fontweight400 darkGrayTwo OneLineTruncate">{item?.name}</p>
            <p className="fontSiz14 marBot0 fontWeight600 white OneLineTruncate">{item?.value}</p>
        </div>
    );
};

export default MetricsCard;
