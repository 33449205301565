import React, {useEffect, useState} from 'react';
import {Col, Container, Row} from 'react-bootstrap';
import withObservables from '@nozbe/with-observables';
import {Header, HoldOrderModal, NonIdealScreen, SearchBox, ToastBox} from '../../../../../common';
import {useAuthContext, useLanguageContext, useThemeContext} from '../../../../../../context';
import TableHeader from '../../../../../common/TableContainer/TableHeader';
import {DEVICE_HEIGHT, cockpitOrders, formatSingleDigitNum} from '../../../../../../constants';
import List from './List';
import {observeCockpitActiveOrders, observeHeldOrderCount} from '../../../../../../pos-core/database/helpers';
import {useNavigate} from 'react-router-dom';
import SelectFloorPlanModal from '../../../../../common/SelectFloorPlanModal';
import {observeActiveFloorPlan} from '../../../../../../pos-core/database/helpers/floor_plan';
import {useOrderFilter, useSearch} from '../../../../../../hooks';
import useFilter from '../../../../../../hooks/useFilter';
import Icon from '../../../../../../assets/icons';
import WebSocketService from '../../../../../../services/WebSocket';
import API_ENDPOINTS from '../../../../../../api/endpoints';
import {Sync} from '../../../../../../api';

const Cockpit = ({floorData, activeOrder, orders, tableDetails, floorPlans, setTabComponent, setTabVal}) => {
    const {I18n} = useLanguageContext();
    const {theme} = useThemeContext();
    const {handleCreateOrder, business, renderToast, setRenderToast, user, businessSettings} = useAuthContext();
    const [holdOrderModal, setHoldOrderModal] = useState(false);
    const [heldOrderCount, setHeldOrderCount] = useState();
    const [selectFloorPlanModal, setSelectFloorPlanModal] = useState(false);
    const {text, setText, searchList} = useSearch(handleSearch);
    const {filteredList, setOption, option} = useFilter(handleFilter);
    const [sortColumn, setSortColumn] = useState(null);
    const [sortOrder, setSortOrder] = useState(null);
    const [showMessage, setShowMessage] = useState({
        visible: false,
        message: '',
    });

    let navigate = useNavigate();
    const preferences = businessSettings?.['preferences'];

    const messagesMap = {
        [I18n.order_placed_successfully]: I18n.order_placed_successfully,
        [I18n.order_updated_successfully]: I18n.order_updated_successfully,
        [I18n.order_completed_successfully]: I18n.order_completed_successfully,
        [I18n.order_saved_successfully]: I18n.order_saved_successfully,
        [I18n.floor_plan_created_successfully]: I18n.floor_plan_created_successfully,
    };

    const handleSort = column => {
        if (sortColumn === column) {
            if (sortOrder === 'asc') {
                setSortOrder('desc');
            } else if (sortOrder === 'desc') {
                setSortOrder(null);
                setSortColumn(null);
            } else {
                setSortOrder('asc');
            }
        } else {
            setSortColumn(column);
            setSortOrder('asc');
        }
    };

    const sortedOrders = () => {
        if (!sortOrder) return orders;
        return [...orders].sort((a, b) => {
            let valueA, valueB;
            if (sortColumn === 'order_number') {
                valueA = a.number;
                valueB = b.number;
            } else if (sortColumn === 'order_time') {
                valueA = new Date(a.started_at);
                valueB = new Date(b.started_at);
            } else if (sortColumn === 'order_status') {
                valueA = a.status;
                valueB = b.status;
            }

            if (sortOrder === 'asc') {
                return valueA > valueB ? 1 : -1;
            } else if (sortOrder === 'desc') {
                return valueA < valueB ? 1 : -1;
            }
            return 0;
        });
    };

    const onChange = (label, val) => {
        setText(val);
    };

    function handleSearch(val) {
        return orders?.filter(x => x.number.includes(val));
    }

    const handleCreateNewOrder = async () => {
        if (floorData.id === 'cockpit') {
            setSelectFloorPlanModal(true);
            return;
        }

        const payload = {
            floor_plan: floorData,
            dine_in_table: tableDetails?.[0],
            started_at: new Date().getTime(),
            type: floorData.type,
            no_of_guests: '0',
        };
        if (!activeOrder) {
            const createdOrder = await handleCreateOrder();
            await createdOrder.updateFloorAndTable(payload);
        } else {
            await activeOrder.updateFloorAndTable(payload);
        }
        navigate('/restaurant-pos');
    };

    const handleHoldOder = () => {
        setHoldOrderModal(prev => !prev);
    };

    const handleHeldOrderCount = async () => {
        let count = await observeHeldOrderCount(business?.id);
        setHeldOrderCount(count);
    };

    function handleFilter(selected) {
        setText('');
        setOption(selected);
        if (selected === I18n.all || selected === '') {
            return orders;
        } else {
            return orders?.filter(x => x?.type?.replace(/\s+/g, '').toLowerCase().includes(selected.replace(/\s+/g, '').toLowerCase()));
        }
    }

    const filterOption = [
        {
            title: I18n.all,
            action: () => handleFilter(I18n.all),
            checked: option == I18n.all || option === '' ? true : false,
        },
        {
            title: I18n.dine_in,
            action: () => handleFilter(I18n.dine_in),
            checked: option == I18n.dine_in ? true : false,
        },
        {
            title: I18n.take_away,
            action: () => handleFilter(I18n.take_away),
            checked: option == I18n.take_away ? true : false,
        },
        {
            title: I18n.delivery,
            action: () => handleFilter(I18n.delivery),
            checked: option == I18n.delivery ? true : false,
        },
    ];

    useEffect(() => {
        handleHeldOrderCount();

        if (showMessage.visible) {
            const timeoutId = setTimeout(() => {
                setShowMessage({
                    visible: false,
                    message: '',
                });
                setRenderToast('');
            }, 3000);
            return () => clearTimeout(timeoutId);
        }
    }, [showMessage]);

    useEffect(() => {
        if (renderToast?.includes('updated')) {
            setShowMessage({
                visible: true,
                message: renderToast,
            });
        } else if (messagesMap?.[renderToast]) {
            setShowMessage({
                visible: true,
                message: messagesMap[renderToast],
            });
        }
    }, [renderToast]);

    useEffect(() => {
        const kds_setting = preferences?.find(pref => pref.name === 'Enable KDS');

        const handleWebSocketMessage = async data => {
            try {
                if (data.status) {
                    await Sync(user.email);
                }
            } catch (error) {
                console.log('error', error);
            }
        };

        if (kds_setting?.setting_value?.status && kds_setting?.setting_value?.key) {
            const socket_id = kds_setting?.setting_value?.key;
            const webSocketUrl = `${API_ENDPOINTS.WEB_SOCKET_URL}${socket_id}/`;

            WebSocketService.connect(webSocketUrl, handleWebSocketMessage);
        }
    }, []);

    return (
        <>
            <div className="marTop20">
                <Header
                    type="posCockpit"
                    search={{
                        label: I18n.search_by_order_number,
                        onChange: onChange,
                    }}
                    centerCta={{
                        title: `${I18n.saved_order} ${heldOrderCount ? formatSingleDigitNum(heldOrderCount) : ''}`,
                        action: handleHoldOder,
                        backgroundColor: heldOrderCount ? theme.barclaysBlue : theme.topBarBG,
                        borderColor: heldOrderCount ? theme.barclaysBlue : theme.inputBorder,
                        textColor: heldOrderCount ? theme.white : theme.barclaysBlue,
                    }}
                    rightCta={{title: I18n.new_order2, action: handleCreateNewOrder}}
                    leftCta={{
                        title: option || I18n.all,
                        name: 'downIcon',
                        fill: theme.white,
                        width: '12',
                        height: '9',
                        viewBox: '0 0 8 5',
                        option: filterOption,
                    }}
                />
                <div className="restaurantOrderListContainer" style={{height: DEVICE_HEIGHT - 160}}>
                    <Container fluid>
                        <Row>
                            <Col style={{padding: '0px'}}>
                                <TableHeader className={`tenColumn borderTop`}>
                                    <div className="BoxWidth justifyCenter" onClick={() => handleSort('order_number')}>
                                        <p className="fontSize16 fontWeight600 OneLineTruncate cursorPointer" style={{color: theme.white}}>
                                            {I18n.order_number}
                                            <span className="marLeft5">
                                                <Icon
                                                    name={
                                                        sortColumn === 'order_number'
                                                            ? sortOrder === 'asc'
                                                                ? 'ascendingIcon'
                                                                : sortOrder === 'desc'
                                                                ? 'descendingIcon'
                                                                : 'sortIcon'
                                                            : 'sortIcon'
                                                    }
                                                    viewBox="0 0 8 16"
                                                />
                                            </span>
                                        </p>
                                    </div>
                                    <div className="BoxWidth justifyCenter" onClick={() => handleSort('order_time')}>
                                        <p className="fontSize16 fontWeight600 OneLineTruncate cursorPointer" style={{color: theme.white}}>
                                            {I18n.order_time}
                                            <span className="marLeft5">
                                                <Icon
                                                    name={
                                                        sortColumn === 'order_time'
                                                            ? sortOrder === 'asc'
                                                                ? 'ascendingIcon'
                                                                : sortOrder === 'desc'
                                                                ? 'descendingIcon'
                                                                : 'sortIcon'
                                                            : 'sortIcon'
                                                    }
                                                    viewBox="0 0 8 16"
                                                />
                                            </span>
                                        </p>
                                    </div>
                                    <div className="BoxWidth justifyCenter">
                                        <p className="fontSize16 fontWeight600 OneLineTruncate" style={{color: theme.white}}>
                                            {I18n.order_typee}
                                        </p>
                                    </div>
                                    <div className="BoxWidth justifyCenter">
                                        <p className="fontSize16 fontWeight600 OneLineTruncate" style={{color: theme.white}}>
                                            {I18n.table}
                                        </p>
                                    </div>
                                    <div className="BoxWidth justifyCenter">
                                        <p className="fontSize16 fontWeight600 OneLineTruncate" style={{color: theme.white}}>
                                            {I18n.customer}
                                        </p>
                                    </div>
                                    <div className="BoxWidth justifyCenter">
                                        <p className="fontSize16 fontWeight600 OneLineTruncate" style={{color: theme.white}}>
                                            {I18n.waiter}
                                        </p>
                                    </div>
                                    <div className="BoxWidth justifyCenter">
                                        <p className="fontSize16 fontWeight600 OneLineTruncate" style={{color: theme.white}}>
                                            {I18n.rider}
                                        </p>
                                    </div>
                                    <div className="BoxWidth justifyCenter" onClick={() => handleSort('order_status')}>
                                        <p className="fontSize16 fontWeight600 OneLineTruncate cursorPointer" style={{color: theme.white}}>
                                            {I18n.status}

                                            <span className="marLeft5">
                                                <Icon
                                                    name={
                                                        sortColumn === 'order_status'
                                                            ? sortOrder === 'asc'
                                                                ? 'ascendingIcon'
                                                                : sortOrder === 'desc'
                                                                ? 'descendingIcon'
                                                                : 'sortIcon'
                                                            : 'sortIcon'
                                                    }
                                                    viewBox="0 0 8 16"
                                                />
                                            </span>
                                        </p>
                                    </div>
                                    <div className="BoxWidth justifyCenter">
                                        <p className="fontSize16 fontWeight600 OneLineTruncate" style={{color: theme.white}}>
                                            {I18n.amount}
                                        </p>
                                    </div>
                                    <div className="BoxWidth justifyCenter" />
                                </TableHeader>

                                {orders?.length > 0 ? (
                                    <div className="tableListMainBox" style={{height: DEVICE_HEIGHT - 280}}>
                                        {(text ? searchList : option ? filteredList : sortedOrders())?.map((val, index) => (
                                            <List order={val} key={index} />
                                        ))}
                                    </div>
                                ) : (
                                    <NonIdealScreen paragraph={I18n.nothing_found_create_order} name="noOrderFound" isMultiStep={false} viewBox="0 0 100 100" />
                                )}
                            </Col>
                            <ToastBox
                                className="addSuccessfullyToast"
                                ToastVisiable={showMessage.visible}
                                bodyPara={showMessage.message}
                                setShowMessage={setShowMessage}
                                showIcon={true}
                            />
                        </Row>
                    </Container>
                </div>
            </div>
            <HoldOrderModal
                setToggle={setHoldOrderModal}
                toggle={holdOrderModal}
                setShowMessage={setShowMessage}
                handlehandleSelect={handleHoldOder}
                handleHeldOrderCount={handleHeldOrderCount}
                isRestaurantPos={true}
            />
            <SelectFloorPlanModal
                toggle={selectFloorPlanModal}
                setToggle={setSelectFloorPlanModal}
                floorPlans={floorPlans}
                setTabComponent={setTabComponent}
                setTabVal={setTabVal}
            />
        </>
    );
};

const enhance = withObservables(['floorData'], ({floorData}) => {
    const business_id = localStorage.getItem('business_id');
    return floorData.id === 'cockpit'
        ? {
              orders: observeCockpitActiveOrders(business_id),
              floorPlans: observeActiveFloorPlan(),
          }
        : {
              orders: floorData.getActiveFloorOrders(business_id).observeWithColumns(['status']),
              tableDetails: floorData.getTables.observe(),
          };
});

export default enhance(Cockpit);
