import React, {useEffect, useState} from 'react';
import NewAppModal from '../NewAppModal';
import {useLanguageContext, useThemeContext} from '../../../context';
import TextField from '../TextField';
import {toSnakeCase, validateBusinessDetailsForm} from '../../../constants';
import {Col, Row} from 'react-bootstrap';
import {toast} from 'react-toastify';
import {getAllCountry} from '../../../api';
import Loading from '../Loading';

const BusinessDetailsModal = ({visible, setVisible, business}) => {
    const {I18n} = useLanguageContext();
    const {theme} = useThemeContext();

    const [name, setName] = useState('');
    const [province, setProvince] = useState('');
    const [city, setCity] = useState('');
    const [address, setAddress] = useState('');
    const [data, setData] = useState([]);
    const [provincesList, setProvincesList] = useState([]);
    const [citiesList, setCitiesList] = useState([]);
    const [loading, setLoading] = useState(false);

    const [errors, setErrors] = useState({});

    const hanldeClose = () => {
        setName('');
        setProvince('');
        setCity('');
        setAddress('');
        setErrors({});
        setVisible(null);
    };

    const handleSave = async () => {
        const businessDetails = {
            name,
            province,
            city,
            address,
        };

        const formErrors = validateBusinessDetailsForm(businessDetails);
        setErrors(formErrors);
        if (Object.keys(formErrors).length > 0) return;

        await business.updateBusinessDetails(businessDetails);
        hanldeClose();
        toast.success('Business updated successfully');
    };

    const onChange = async (label, val) => {
        if (label == I18n.business_name) setName(val);
        else if (label == I18n.province) {
            setProvince(val);
            setCity('');
            const selectedProvince = provincesList.find(item => item?.provinceName === val);
            setCitiesList(selectedProvince ? selectedProvince.cities : []);
        } else if (label == I18n.city) setCity(val);
        else if (label == I18n.address) setAddress(val);

        setErrors(prevErrors => {
            const updatedErrors = {...prevErrors};
            if (updatedErrors[toSnakeCase(label)]) {
                delete updatedErrors[toSnakeCase(label)];
            }
            return updatedErrors;
        });
    };

    const fetchCountries = async () => {
        setLoading(true);
        try {
            const res = await getAllCountry();
            if (res?.success) {
                setData(res?.data);
                const selectedCountry = res?.data?.find(item => item.countryName === business?.country);
                setProvincesList(selectedCountry ? selectedCountry.provinces : []);
            }
        } catch (error) {
            console.log(error.message);
        } finally {
            setLoading(false);
        }
    };

    const setInitialValues = () => {
        setName(business?.name);
        setProvince(business?.province);
        setCity(business?.city);
        setAddress(business?.address);
    };

    useEffect(() => {
        if (business) {
            setInitialValues();
        }

        if (data.length === 0) {
            fetchCountries();
        }
    }, [visible]);

    return loading ? (
        <Loading />
    ) : (
        <div>
            <NewAppModal
                className="deliveryFloorModal modal-backdrop-custom"
                toggle={visible}
                backCta={{
                    name: 'backArrowIcon',
                    fill: theme.barclaysBlue,
                    width: '18',
                    height: '18',
                    viewBox: '0 0 18 18',
                }}
                handleClose={() => hanldeClose()}
                primaryCta={{
                    title: I18n.confirm,
                    action: () => handleSave(),
                }}
                cancelCta={{
                    title: I18n.cancel,
                    action: () => hanldeClose(),
                }}
                title={I18n.back}>
                <div className="flex horizontalCenter width100">
                    <div className="flex width50" style={{flexDirection: 'column'}}>
                        <Row className="justify-content-md-center">
                            <Col md={12}>
                                <p className="fontSize20 marBot5 fontWeight400" style={{color: theme.white}}>
                                    Business details
                                </p>
                            </Col>
                            <Col md={12}>
                                <TextField
                                    label={I18n.business_name}
                                    placeholder={I18n.business_name}
                                    onChange={onChange}
                                    value={name}
                                    required={true}
                                    error={errors.business_name}
                                    maxLength={100}
                                />
                            </Col>
                            <Col md={12}>
                                <TextField label={I18n.address} placeholder={I18n.address} onChange={onChange} value={address} error={errors.address} maxLength={300} />
                            </Col>
                            <Col md={6}>
                                <TextField
                                    label={I18n.province}
                                    placeholder={I18n.province}
                                    onChange={onChange}
                                    value={province}
                                    required={true}
                                    error={errors.province}
                                    data={provincesList?.map(item => item.provinceName)}
                                    inputType="select"
                                    type="text"
                                    suffix="downIcon2"
                                    suffix_fill={theme.white}
                                    suffix_width={'24'}
                                    suffix_height={'24'}
                                    suffix_viewBox={'0 0 18 18'}
                                />
                            </Col>

                            <Col md={6}>
                                <TextField
                                    label={I18n.city}
                                    placeholder={I18n.city}
                                    onChange={onChange}
                                    value={city}
                                    required={true}
                                    error={errors.city}
                                    data={citiesList}
                                    inputType="select"
                                    type="text"
                                    suffix="downIcon2"
                                    suffix_fill={theme.white}
                                    suffix_width={'24'}
                                    suffix_height={'24'}
                                    suffix_viewBox={'0 0 18 18'}
                                />
                            </Col>
                        </Row>
                    </div>
                </div>
            </NewAppModal>
        </div>
    );
};

export default BusinessDetailsModal;
