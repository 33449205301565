import {toast} from 'react-toastify';
import React, {useState} from 'react';
import CustomTextField from './inputFiled';
import {useNavigate} from 'react-router-dom';
import {Button, Loading} from '../../../common';
import {getPriceCheckerProduct} from '../../../../api';
import {useLanguageContext, useAuthContext} from '../../../../context';
import PriceCheckerFooter from '../../../common/PricecheckerFooter';

const PriceChecker = () => {
    const navigate = useNavigate();
    const [Url, setUrl] = useState('');
    const {I18n} = useLanguageContext();
    const {handelupdateProductData} = useAuthContext();
    const [ShowLoading, setShowLoading] = useState(false);

    const onChange = value => {
        setUrl(value);
    };

    const isButtonDisabled = () => {
        let ButtonDisabled = Url.length <= 5;
        return ButtonDisabled;
    };

    const GetProductData = async () => {
        localStorage.setItem('siteUrl', JSON.stringify(Url));
        setShowLoading(true);
        try {
            const finalUrl = `${Url}/api/v1/price_checker_products`;
            const response = await getPriceCheckerProduct(finalUrl);
            handelupdateProductData(response);
            navigate('/barcode-scanner');
        } catch (error) {
            toast.error('Please provide a valid URL');
        } finally {
            setShowLoading(false);
        }
    };

    const handleSubmit = event => {
        event.preventDefault();
        if (!isButtonDisabled()) {
            GetProductData();
        }
    };

    return (
        <>
            {!ShowLoading ? (
                <form onSubmit={handleSubmit} className="card-price-chacker">
                    <div className="container-price-checker">
                        <h2>Please enter your site URL</h2>
                        <p>Connect your system with Oscar, you need to add your site URL</p>
                        <CustomTextField label={I18n.enter_url} value={Url} onChange={onChange} required />
                        <Button type="submit" title={'Continue'} disabled={isButtonDisabled()} />
                        <PriceCheckerFooter />
                    </div>
                </form>
            ) : (
                <Loading light={true} />
            )}
        </>
    );
};

export default PriceChecker;
