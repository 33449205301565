import React from 'react';
import { useThemeContext } from '../../../context';
import TableBody from '../TableContainer/TableBody';
import { firstLetterCaptilize, formatNum } from '../../../constants';

const ItemsList = ({ data }) => {
    const { theme } = useThemeContext();
    const { product_name, avg_cost_price, avg_selling_price, margin_percent, profit_per_unit, qty_sold, total_profit, total_revenue, total_cogs } = data || {};

    return (
        <TableBody className={`nineColumn`} style={{ borderColor: theme.inputBorder }}>
            <div className="BoxWidth justifyStart">
                <p title={firstLetterCaptilize(product_name) || '--'} className="fontSize14 OneLineTruncate cursorPointer" style={{ color: theme.white }}>
                    {firstLetterCaptilize(product_name) || '--'}
                </p>
            </div>
            <div className="BoxWidth justifyCenter">
                <p title={`Rs. ${formatNum(avg_cost_price || 0)}`} className="fontSize14 OneLineTruncate cursorPointer" style={{ color: theme.white }}>
                    {`Rs. ${formatNum(avg_cost_price || 0)}`}
                </p>
            </div>
            <div className="BoxWidth justifyCenter">
                <p title={`Rs. ${formatNum(avg_selling_price || 0)}`} className="fontSize14 OneLineTruncate cursorPointer" style={{ color: theme.white }}>
                    {`Rs. ${formatNum(avg_selling_price || 0)}`}
                </p>
            </div>
            <div className="BoxWidth justifyCenter">
                <p title={`${formatNum(margin_percent || 0)}%`} className="fontSize14 OneLineTruncate cursorPointer" style={{ color: theme.white }}>
                    {`${formatNum(margin_percent || 0)}%`}
                </p>
            </div>
            <div className="BoxWidth justifyCenter">
                <p title={`Rs. ${formatNum(profit_per_unit || 0)}`} className="fontSize14 OneLineTruncate cursorPointer" style={{ color: theme.white }}>
                    {`Rs. ${formatNum(profit_per_unit || 0)}`}
                </p>
            </div>

            <div className="BoxWidth justifyCenter">
                <p title={formatNum(qty_sold || 0)} className="fontSize14 OneLineTruncate cursorPointer" style={{ color: theme.white }}>
                    {formatNum(qty_sold || 0)}
                </p>
            </div>
            <div className="BoxWidth justifyCenter">
                <p title={formatNum(total_cogs || 0)} className="fontSize14 OneLineTruncate cursorPointer" style={{ color: theme.white }}>
                    {formatNum(total_cogs || 0)}
                </p>
            </div>
            <div className="BoxWidth justifyCenter">
                <p title={`${formatNum(total_revenue || 0)}%`} className="fontSize14 OneLineTruncate cursorPointer" style={{ color: theme.white }}>
                    {`${formatNum(total_revenue || 0)}%`}
                </p>
            </div>

            <div className="BoxWidth justifyCenter">
                <p title={`${formatNum(total_profit || 0)}%`} className="fontSize14 OneLineTruncate cursorPointer" style={{ color: theme.white }}>
                    {`${formatNum(total_profit || 0)}%`}
                </p>
            </div>
        </TableBody>
    );
};

export default ItemsList;
