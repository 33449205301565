import React, {useState} from 'react';
import {useAuthContext, useLanguageContext, useThemeContext} from '../../../../../context';
import {DEVICE_HEIGHT} from '../../../../../constants';
import Icon from '../../../../../assets/icons';
import BusinessDetailsModal from '../../../../common/BusinessDetailsModal';

const AboutBusiness = () => {
    const {I18n} = useLanguageContext();
    const {theme} = useThemeContext();
    const {business, account} = useAuthContext();

    const [detailsModal, setDetailsModal] = useState(false);

    return (
        <div className="pad20">
            <div className="settingsWrapper borderRadiusBottomleftBottomRigt pad20" fluid style={{background: theme.blackBg, height: DEVICE_HEIGHT - 80}}>
                <>
                    <p className="marTop10 marBot20 fontSize18 fontWeight600" style={{color: theme.white}}>
                        {I18n.about_my_business}
                    </p>
                    <div className="divider width100 marTop10" />
                </>
                <div className="aboutBusiness">
                    <div className="spaceBetweenCenter">
                        <p className="marBot0 fontSize14 fontWeight400" style={{color: theme.lightGrayTwo}}>
                            {I18n.business_name}
                        </p>
                        <p className="marBot0 fontSize14 fontWeight400 cursorPointer" style={{color: theme.barclaysBlue}} onClick={() => setDetailsModal(true)}>
                            <span>
                                <Icon name="editIcon" fill={theme.barclaysBlue} width="18" height="18" />
                            </span>
                            {I18n.edit}
                        </p>
                    </div>
                    <p className="marTop10 marBot20 fontSize20 fontWeight500" style={{color: theme.white}}>
                        {business?.name}
                    </p>
                    <div className="flex gap50">
                        <div>
                            <p className="marBot5 fontSize14 fontWeight400 max-width" style={{color: theme.lightGrayTwo}}>
                                {I18n.business_owner}
                            </p>
                            <p className="marBot0 fontSize16 fontWeight400 max-width" style={{color: theme.white}}>
                                {`${account?.first_name} ${account?.last_name}`}
                            </p>
                        </div>

                        <div>
                            <p className="marBot5 fontSize14 fontWeight400 max-width" style={{color: theme.lightGrayTwo}}>
                                {I18n.business_location}
                            </p>
                            <p className="marBot0 fontSize16 fontWeight400 max-width" style={{color: theme.white}}>
                                {business?.kind}
                            </p>
                        </div>
                        <div>
                            <p className="marBot5 fontSize14 fontWeight400 max-width" style={{color: theme.lightGrayTwo}}>
                                {I18n.business_type}
                            </p>
                            <p className="marBot0 fontSize16 fontWeight400 max-width" style={{color: theme.white}}>
                                {business?.type}
                            </p>
                        </div>
                        <div>
                            <p className="marBot5 fontSize14 fontWeight400" style={{color: theme.lightGrayTwo}}>
                                {I18n.address}
                            </p>
                            <p className="marBot0 fontSize16 fontWeight400" style={{color: theme.white}}>
                                {business?.address || '-'}
                            </p>
                        </div>
                    </div>
                </div>
            </div>
            <BusinessDetailsModal visible={detailsModal} setVisible={setDetailsModal} business={business} />
        </div>
    );
};

export default AboutBusiness;
