import React from 'react';
import {useThemeContext} from '../../../../context';
import Tooltip from '@mui/material/Tooltip';
import Button from '../../Button';

const TooltipComponent = ({children, yesCta, titleName, noCta, desc, toggle}) => {
    const {theme} = useThemeContext();

    return (
        <Tooltip
            open={toggle}
            title={
                <div className="tooltipConatiner">
                    <p className="fontSize14 fontWeight600 marBot5">{titleName}</p>
                    <p
                        style={{
                            color: theme.darkGrayTwo,
                        }}
                        className="fontSize12 fontWeight400">
                        {desc}
                    </p>

                    <div className="tooltipButtonBox">
                        {noCta && (
                            <Button
                                className={`fontSize12 cursorPointer width100`}
                                backgroundColor={theme.blackBg}
                                txtColor={theme.white}
                                title={noCta.title}
                                handleClick={noCta.action}
                            />
                        )}
                        {yesCta && <Button className={`fontSize12 cursorPointer width100`} title={yesCta.title} handleClick={yesCta.action} />}
                    </div>
                </div>
            }>
            <div className="tooltipChildren">{children}</div>
        </Tooltip>
    );
};

export default TooltipComponent;
