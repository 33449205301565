import React from 'react';
import { useThemeContext } from '../../../context';
import TableBody from '../TableContainer/TableBody';
import { firstLetterCaptilize, formatNum } from '../../../constants';

const ItemsList = ({ data }) => {
    const { theme } = useThemeContext();
    const { product_name, qty_sold, avg_selling_price, margin, amount, total_sales_contrib } = data || {};

    return (
        <TableBody className={`sixColumn`} style={{ borderColor: theme.inputBorder }}>
            <div className="BoxWidth justifyStart">
                <p title={firstLetterCaptilize(product_name) || '--'} className="fontSize14 OneLineTruncate cursorPointer" style={{ color: theme.white }}>
                    {firstLetterCaptilize(product_name) || '--'}
                </p>
            </div>
            <div className="BoxWidth justifyCenter">
                <p title={qty_sold || 0} className="fontSize14 OneLineTruncate cursorPointer" style={{ color: theme.white }}>
                    {qty_sold || 0}
                </p>
            </div>
            <div className="BoxWidth justifyCenter">
                <p title={`Rs. ${formatNum(avg_selling_price || 0)}`} className="fontSize14 OneLineTruncate cursorPointer" style={{ color: theme.white }}>
                    {`Rs. ${formatNum(avg_selling_price || 0)}`}
                </p>
            </div>
            <div className="BoxWidth justifyCenter">
                <p title={`Rs. ${formatNum(amount || 0)}`} className="fontSize14 OneLineTruncate cursorPointer" style={{ color: theme.white }}>
                    {`Rs. ${formatNum(amount || 0)}`}
                </p>
            </div>
            <div className="BoxWidth justifyCenter">
                <p title={`${margin || 0} %`} className="fontSize14 OneLineTruncate cursorPointer" style={{ color: theme.white }}>
                    {margin || 0} %
                </p>
            </div>

            <div className="BoxWidth justifyEnd">
                <p title={`${total_sales_contrib || 0} %`} className="fontSize14 OneLineTruncate cursorPointer" style={{ color: theme.white }}>
                    {total_sales_contrib || 0} %
                </p>
            </div>
        </TableBody>
    );
};

export default ItemsList;
