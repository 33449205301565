import React from 'react';
import Icon from '../../../assets/icons';
import {useLanguageContext, useThemeContext} from '../../../context';

const AddKDS = ({setAddDeviceModal, item, setDeviceData}) => {
    const {theme} = useThemeContext();
    const {I18n} = useLanguageContext();

    const handleClick = () => {
        if (item) {
            setDeviceData(item);
            setAddDeviceModal(true);
        } else {
            setAddDeviceModal(true);
        }
    };

    return (
        <div className="addDeviceBox cursorPointer " onClick={handleClick}>
            <Icon name={item ? 'deviceIcon' : 'plusIcon'} width={item ? '34' : '30'} height={item ? '24' : '30'} viewBox={item ? '0 0 34 24' : '0 0 30 30'} />
            <p className="fontSize12 marBot0" style={{color: item ? theme.blackFont : theme.barclaysBlue}}>
                {item ? item?.name : I18n.add_kds_device}
            </p>

            {item && (
                <p className="fontSize12 marTop0 marBot0" style={{color: item ? theme.blackFont : theme.barclaysBlue}}>
                    {item.location}
                </p>
            )}

            {item && (
                <p className="fontSize12 marBot0" style={{color: theme.brightGreen}}>
                    {item.key_expired ? 'Paired' : 'Unpaired'}
                </p>
            )}
        </div>
    );
};

export default AddKDS;
