import {Model} from '@nozbe/watermelondb';
import {field, children, writer, relation} from '@nozbe/watermelondb/decorators';
import {PRODUCT_BUSINESS_SCHEMA} from '../schema';

export default class ProductBusiness extends Model {
    static table = PRODUCT_BUSINESS_SCHEMA;

    static associations = {
        business: {type: 'belongs_to', key: 'business_id'},
        product: {type: 'belongs_to', key: 'product_id'},
    };

    @field('quantity') quantity;

    @relation('business', 'business_id') business;
    @relation('product', 'product_id') product;

    @writer async updateQuantity(details) {
        return await this.update(productBusiness => {
            productBusiness.quantity = details.quantity;
        });
    }

    @writer async addProductQuantity(quantity) {
        return await this.update(productBusiness => {
            productBusiness.quantity += quantity;
        });
    }
}
