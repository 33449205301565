import React, {useState} from 'react';
import {CustomContainer, Header, TextField} from '../../../../../common';
import {useLanguageContext, useThemeContext} from '../../../../../../context';
import {Col, Row} from 'react-bootstrap';
import {toSnakeCase, validatePRAForm} from '../../../../../../constants';
import {useNavigate} from 'react-router-dom';

const SetupPRA = () => {
    const {I18n} = useLanguageContext();
    const {theme} = useThemeContext();
    let navigate = useNavigate();

    const [praPosId, setPraPosId] = useState();
    const [taxInformation, setTaxInformation] = useState();
    const [posNumber, setPosNumber] = useState();
    const [praHost, setPraHost] = useState();

    const [errors, setErrors] = useState({});

    const handleBack = () => {
        navigate('/settings/integrations');
    };

    const handleCreate = () => {
        const payload = {
            praPosId,
            taxInformation,
            posNumber,
            praHost,
        };

        const formErrors = validatePRAForm(payload);
        console.log('Form errors');
        setErrors(formErrors);
        if (Object.keys(formErrors).length > 0) return;

        console.log('handle create', payload);
    };

    const onChange = (label, val) => {
        if (label == I18n.pra_pos_id) setPraPosId(val);
        else if (label == I18n.tax_information) setTaxInformation(val);
        else if (label == I18n.pos_number) setPosNumber(val);
        else if (label == I18n.pra_host) setPraHost(val);

        setErrors(prevErrors => {
            const updatedErrors = {...prevErrors};
            if (updatedErrors[toSnakeCase(label)]) {
                delete updatedErrors[toSnakeCase(label)];
            }
            return updatedErrors;
        });
    };

    return (
        <CustomContainer className="addScreenMainBox createCustomerContainer">
            <Header
                type="draftHeader"
                title={I18n.back}
                backAction={handleBack}
                saveCta={{
                    title: I18n.save,
                    action: () => handleCreate(),
                }}
            />

            <Row className="justify-content-md-center">
                <Col md={6}>
                    <div className="signupTextBox">
                        <h3 className="fontSize24  textCenter fontWeight600 marBot20 marTop0" style={{color: theme.text}}>
                            Setup PRA configuration
                        </h3>
                        <h3 className="fontSize20 fontWeight400 marBot15 marLeft15 marTop0" style={{color: theme.text}}>
                            PRA configuration details
                        </h3>
                    </div>

                    <div className="itemFields">
                        <div className="intventoryFormGridOne">
                            <TextField
                                onChange={onChange}
                                error={errors['pra_pos_id']}
                                label={I18n.pra_pos_id}
                                placeholder={I18n.pra_pos_id}
                                value={praPosId}
                                required={true}
                                autoFocus={true}
                            />
                        </div>

                        <div className="intventoryFormGridTwo">
                            <div className="priceSectionInputLeft">
                                <TextField
                                    onChange={onChange}
                                    label={I18n.tax_information}
                                    placeholder={I18n.tax_information}
                                    value={taxInformation}
                                    required={true}
                                    error={errors['tax_information']}
                                />
                            </div>
                            <div className="priceSectionInputRight">
                                <TextField
                                    onChange={onChange}
                                    label={I18n.pos_number}
                                    placeholder={I18n.pos_number}
                                    value={posNumber}
                                    required={true}
                                    error={errors['pos_number']}
                                />
                            </div>
                        </div>

                        <div className="intventoryFormGridOne">
                            <TextField onChange={onChange} error={errors['pra_host']} label={I18n.pra_host} placeholder={I18n.pra_host} value={praHost} required={true} />
                        </div>

                        <div className="divider width100 marTop10" />
                    </div>
                </Col>
            </Row>
        </CustomContainer>
    );
};

export default SetupPRA;
