import {useEffect, useState} from 'react';
import {useThemeContext} from '../../../context';
import {Button} from '../../common';

const QtyIncreaseDecrase = ({handleToggle, toggle, handleUpdateItemQty, item, inputRef, initialQuantity, trigger, disableInput = false}) => {
    const {theme} = useThemeContext();
    const {quantity, id} = item;
    const [itemQuantity, setItemQuantity] = useState();

    const onChange = event => {
        const value = parseInt(event.target.value);
        setItemQuantity(value);

        if (trigger === 'onChange') {
            const type = 'onChange';
            const checkedValue = value > 0 ? value : item?.quantity;
            handleUpdateItemQty(item, checkedValue, type);
        }
    };

    const onBlur = e => {
        if (trigger === 'onBlur') {
            const type = 'onBlur';
            // const checkedValue = itemQuantity > 0 ? itemQuantity : item?.quantity;
            handleUpdateItemQty(item, itemQuantity, type);
        }
    };

    useEffect(() => {
        setItemQuantity(item?.quantity);
    }, [item?.quantity, toggle]);

    return (
        <>
            {toggle ? (
                <div className="qtyIdealConatiner">
                    <Button
                        className="marBot0 "
                        type="iconButton"
                        icon_name="minusIcon"
                        disabled={quantity == 1}
                        icon_fill={theme.white}
                        icon_width={'18'}
                        icon_height={'18'}
                        icon_viewBox={'0 0 18 18'}
                        handleClick={() => handleUpdateItemQty(item, 1, 'decrement')}
                    />
                    <input
                        type="number"
                        value={itemQuantity}
                        onChange={onChange}
                        onBlur={onBlur}
                        ref={inputRef}
                        className="updateQtyinput marBot0"
                        onWheel={e => e.target.blur()}
                        onKeyDown={e => {
                            if (e.key === 'ArrowUp' || e.key === 'ArrowDown') {
                                e.preventDefault();
                            }
                        }}
                        disabled={disableInput}
                    />

                    <Button
                        className="marBot0"
                        type="iconButton"
                        icon_name="add2Icon"
                        icon_fill={theme.white}
                        icon_width={'18'}
                        icon_height={'18'}
                        icon_viewBox={'0 0 18 18'}
                        handleClick={() => handleUpdateItemQty(item, 1, 'increment')}
                        disabled={initialQuantity && quantity === initialQuantity}
                    />
                </div>
            ) : (
                <p
                    onClick={() => handleToggle(true)}
                    className="marBot0"
                    style={{
                        color: item.void ? theme.disableGray : theme.white,
                    }}>
                    {`x${quantity}`}
                </p>
            )}
        </>
    );
};

export default QtyIncreaseDecrase;
