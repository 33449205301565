import {Database} from '@nozbe/watermelondb';
import LokiJSAdapter from '@nozbe/watermelondb/adapters/lokijs';
import {mySchema} from './schema';
import {Models} from './models';
import {PLATFORM} from '../constants';

let adapter;

if (PLATFORM == 'mobile') {
    // FOR MOBILE
    // adapter = new SQLiteAdapter({
    //     dbName: 'WatermelonDB',
    //     schema: mySchema,
    //     migrations,
    // });
} else {
    // FOR WEB
    adapter = new LokiJSAdapter({
        schema: mySchema,
        useWebWorker: false,
        useIncrementalIndexedDB: true,
    });
}

//  Then, make a Watermelon database from it!
export const database = new Database({
    adapter,
    modelClasses: Models,
});
