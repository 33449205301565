import React from 'react';
import Button from '../Button';
import { useLanguageContext, useThemeContext } from '../../../context';
import DropdownItem from '../Dropdown';
import SearchBox from '../SearchBox';

const ReportsBoxHeader = ({ primaryCta, title, Headingtitle, rightCta, search }) => {
    const { theme } = useThemeContext();
    const { I18n } = useLanguageContext();
    return (
        <div className="reportHeaderWrapper">
            <h2 class="fontSize24 fontWeight600 white">{Headingtitle || I18n[title] || ""}</h2>
            <div className='reportHeaderRightWrapper'>
                {rightCta && (
                    <DropdownItem
                        type="radioList"
                        dropDown={{
                            title: rightCta.title,
                            name: rightCta.name,
                            fill: rightCta.fill,
                            width: rightCta.width,
                            height: rightCta.height,
                            viewBox: rightCta.viewBox,
                            option: rightCta.option,
                        }}
                    />
                )}
                {search && <div className="topBarRightSide">
                    <SearchBox type="pos" {...search} />
                </div>}
                {primaryCta && <Button type="primaryButton" className={`marTop0 cursorPointer brightGreenBg`} title={I18n[primaryCta.title]} handleClick={primaryCta.action} saveLoad={primaryCta.saveLoad} />}
            </div>
        </div>
    );
};

export default ReportsBoxHeader;
