import React from 'react';
import { useLanguageContext, useThemeContext } from '../../../context';
import TableBody from '../TableContainer/TableBody';
import { firstLetterCaptilize, formatNum } from '../../../constants';

const ItemsList = ({ data, index }) => {
    const { theme } = useThemeContext();

    const { business_name, orders, net_sales, gross_sales, tax_collected, total_cogs_contrib } = data || {};
    return (
        <TableBody className={`sixColumn `} style={{ borderColor: theme.inputBorder }}>
            <div key={index} className="BoxWidth justifyStart">
                <p title={firstLetterCaptilize(business_name) || '--'} className="fontSize14 OneLineTruncate cursorPointer " style={{ color: theme.white }}>
                    {firstLetterCaptilize(business_name) || '--'}
                </p>
            </div>
            <div className="BoxWidth justifyCenter">
                <p title={formatNum(orders || 0)} className="fontSize14 OneLineTruncate cursorPointer " style={{ color: theme.white }}>
                    {formatNum(orders || 0)}
                </p>
            </div>
            <div className="BoxWidth justifyCenter">
                <p title={`Rs. ${formatNum(net_sales || 0)}`} className="fontSize14 OneLineTruncate cursorPointer " style={{ color: theme.white }}>
                    {`Rs. ${formatNum(net_sales || 0)}`}
                </p>
            </div>
            <div className="BoxWidth justifyCenter">
                <p title={`Rs. ${formatNum(tax_collected || 0)}`} className="fontSize14 OneLineTruncate cursorPointer " style={{ color: theme.white }}>
                    {`Rs. ${formatNum(tax_collected || 0)}`}
                </p>
            </div>
            <div className="BoxWidth justifyCenter">
                <p title={`Rs. ${formatNum(gross_sales || 0)}`} className="fontSize14 OneLineTruncate cursorPointer " style={{ color: theme.white }}>
                    {`Rs. ${formatNum(gross_sales || 0)}`}
                </p>
            </div>
            <div className="BoxWidth justifyCenter">
                <p title={`${formatNum(total_cogs_contrib || 0)}%`} className="fontSize14 OneLineTruncate cursorPointer " style={{ color: theme.white }}>
                    {`${formatNum(total_cogs_contrib || 0)}%`}
                </p>
            </div>
        </TableBody>
    );
};

export default ItemsList;
