import React, {useState} from 'react';

const CustomTextField = ({label, value, onChange, required = false, error = false}) => {
    const [isFocused, setIsFocused] = useState(false);

    return (
        <div className={`custom-textfield ${isFocused || value ? 'focused' : ''} ${error ? 'error' : ''}`}>
            <div className="input-container">
                <label className="floating-label">
                    {label} {required && '*'}
                </label>
                <input
                    type="text"
                    value={value}
                    required={required}
                    onChange={e => onChange(e.target.value)}
                    className="custom-input"
                    onFocus={() => setIsFocused(true)}
                    onBlur={() => setIsFocused(false)}
                />
            </div>
        </div>
    );
};

export default CustomTextField;
