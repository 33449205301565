import {Container, Col, Row} from 'react-bootstrap';
import {useAuthContext, useLanguageContext, useSessionContext, useThemeContext} from '../../../context';
import AppModal from '../AppModal';
import {Button, Calculator, TextField} from '../../common';
import {DEVICE_HEIGHT, firstLetterCaptilize, RESTAURANT} from '../../../constants';
import {useSessionUser} from '../../../hooks';
import Loading from '../Loading';
import {useEffect, useState} from 'react';
import bcryptjs from 'bcryptjs';
import {useNavigate} from 'react-router-dom';
import {validateDevicePin} from '../../../api';
import Cookies from 'js-cookie';
import useFocus from '../../../hooks/useFocus';
import {toast} from 'react-toastify';

const PosPinModal = ({toggle, setToggle, isKdsPin}) => {
    const {I18n} = useLanguageContext();
    const {theme} = useThemeContext();
    const navigate = useNavigate();

    const {isLoading, localUser} = useSessionUser();
    const {setSessionUser, handleCreateSession, session, setSession} = useSessionContext();
    const {handleKdsLogin, business, user, handleIncrementCounter} = useAuthContext();
    const [viewPassword, setViewPassword] = useState(false);
    const [pin, setPin] = useState('');
    const [errors, setErrors] = useState({});
    const {textBoxRef, handleFocus} = useFocus();
    const [loading, setLoading] = useState(false);

    const fetchSession = async () => {
        try {
            setLoading(true);
            const activeSessions = await user?.getUserActiveSession?.fetch();
            if (activeSessions?.length > 0) setSession(activeSessions[0]);
            else setSession('');
        } catch (error) {
            console.error('Error fetching active sessions:', error);
        } finally {
            setLoading(false);
        }
    };
    const handleClockIn = async () => {
        setLoading(true);
        let paramsForSession = {
            business,
            user,
            is_active: true,
            opening_balance: 0,
            started_at: new Date().getTime(),
        };
        await handleCreateSession(paramsForSession);
        await handleIncrementCounter('session');
        setTimeout(() => {
            navigate('/session');
            setLoading(false);
        }, 2000);
    };

    const decrypt = () => {
        if (pin === localUser?.pin) {
            if (!session) {
                handleClockIn();
            }
            setToggle(false);
            setSessionUser(localUser);
            setPin('');
            setErrors({pin: ''});
        } else {
            setErrors({
                pin: I18n.invalid_pin,
            });
        }
    };

    // For KDS
    const validatePin = async value => {
        try {
            let device_id = Cookies.get('device_id');
            const pinObject = {
                pin: value,
                device_id: device_id,
            };

            const response = await validateDevicePin(pinObject);

            if (response.success) {
                await handleKdsLogin(response.data);

                setToggle(false);
                setPin('');
                setErrors({
                    pin: '',
                });
                navigate('/kitchendisplay');
            } else {
                console.log('wrong pin');
            }
        } catch (error) {
            console.log('error', error);
            setErrors({
                pin: I18n.invalid_pin,
            });
        }
    };

    const onChange = (label, val) => {
        setPin(val);
        setErrors({pin: ''});
    };

    const handleSubmit = () => {
        if (pin.length === 4) {
            if (isKdsPin) {
                validatePin(pin);
            } else {
                decrypt();
            }
        } else {
            setErrors({
                pin: !pin.length ? I18n.please_enter_your_pin : I18n.invalid_pin,
            });
        }
    };

    const handleEnterPress = event => {
        if (event?.key === 'Enter') handleSubmit();
    };

    useEffect(() => {
        fetchSession();
    }, []);

    useEffect(() => {
        setTimeout(() => {
            handleFocus();
        }, 1000);
    }, []);

    const handleForgotPin = () => {
        toast.info(I18n.coming_soon);
    };

    return isLoading || loading ? (
        <Loading checkinTime={session?.started_at} />
    ) : (
        <AppModal className="createOptionModal" toggle={toggle} title={localUser?.name} disableCloseIcon={true}>
            <Container>
                <Row className="justify-content-md-center">
                    <Col md={5}>
                        <div className="pinModalContainer" onKeyDown={handleEnterPress}>
                            <div className="itemFields posPinField">
                                <div className="signupTextBox">
                                    <h3 className="fontSize24  textCenter fontWeight600 marBot10 marTop0" style={{color: theme.text}}>
                                        {`Hi ${firstLetterCaptilize(localUser?.first_name || 'user')}, Enter your pin`}
                                    </h3>
                                    <h3 className="fontSize14 fontWeight400 marBot20 marTop0 textCenter" style={{color: theme.darkGrayTwo}}>
                                        {I18n.enter_pin_to_start_using_Oscar}
                                    </h3>
                                </div>
                                <TextField
                                    inputRef={textBoxRef}
                                    autoFocus={true}
                                    onChange={onChange}
                                    error={errors?.pin}
                                    value={pin}
                                    onlyNumbers={true}
                                    label={I18n.enter_pin_code}
                                    placeholder={I18n.enter_pin_code}
                                    maxLength={4}
                                    required={true}
                                    type={viewPassword ? 'text' : 'password'}
                                    suffix={viewPassword ? 'showEyeNewIcon' : 'hideEyeNewIcon'}
                                    suffix_fill={theme.inputText}
                                    suffix_width={'18'}
                                    suffix_height={'18'}
                                    suffix_viewBox={'0 0 18 18'}
                                    suffixClick={() => setViewPassword(prev => !prev)}
                                />
                                <div className="pinCodeCalculatorBox" style={{height: DEVICE_HEIGHT * 0.34}}>
                                    <Calculator columnFour={false} setState={setPin} maxLength={4} />
                                </div>
                                <Button className={`marTop25 marRight10 cursorPointer width100`} title={!session ? I18n.start_pos : I18n.resume_sales} handleClick={handleSubmit} />
                                <p className="fontSize14 fontWeight400 textCenter  marTop15" style={{color: theme.barclaysBlue}} onClick={handleForgotPin}>
                                    {I18n.forgot_pin_code}
                                </p>
                            </div>
                        </div>
                    </Col>
                </Row>
            </Container>
        </AppModal>
    );
};

export default PosPinModal;
