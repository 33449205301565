import {Col, Container, Row} from 'react-bootstrap';
import {Button} from '../../common';
import Icon from '../../../assets/icons';
import {useLanguageContext, useThemeContext} from '../../../context';
import {useNavigate} from 'react-router-dom';
import {firstLetterCaptilize} from '../../../constants';

const DraftHeader = ({title, saveCta, skipCta, buttonTitle, primaryCta, backgroundColor, borderColor, isDisabled = false, removeBackButton = false, para, backAction, ...rest}) => {
    let navigate = useNavigate();

    const {I18n} = useLanguageContext();
    const {theme} = useThemeContext();

    const handleBack = () => navigate(-1);

    return (
        <section
            className="addScreenTopBar noborder"
            style={{
                backgroundColor: backgroundColor ? backgroundColor : theme.blackBg,
                borderColor: borderColor ? borderColor : theme.background,
            }}>
            <Container fluid>
                <Row>
                    <Col md={6}>
                        {!removeBackButton && (
                            <div className="backInnerBox">
                                <p onClick={backAction ? backAction : handleBack} className="marBot0 white">
                                    <span className="backBoxIcon cursorPointer">
                                        <Icon name="backArrowIcon" fill={theme.barclaysBlue} width={'18'} height={'18'} viewBox={'0 0 18 18'} />
                                    </span>
                                    <span className="marLeft10 fontSize20 fontWeight400">{firstLetterCaptilize(title || I18n.back)}</span>
                                </p>
                            </div>
                        )}
                    </Col>

                    <Col md={6}>
                        <div className="closeButtonBox">
                            {para && <span className="marRight50 fontSize18 white bold">{para}</span>}
                            {primaryCta && (
                                <Button
                                    type="primaryButton"
                                    className={`headerbuttonStyle marTop0 marRight15 cursorPointer`}
                                    title={primaryCta.title}
                                    backgroundColor="transparent"
                                    borderColor={theme.barclaysBlue}
                                    handleClick={primaryCta.action}
                                />
                            )}
                            {skipCta && (
                                <Button
                                    disabled={isDisabled}
                                    className={`headerbuttonStyle marTop0 marRight15 cursorPointer`}
                                    title={skipCta.title}
                                    handleClick={skipCta.action}
                                    saveLoad={skipCta.saveLoad}
                                    backgroundColor={theme.midnightBlue}
                                    borderColor={theme.darkSlateBlue}
                                    {...rest}
                                />
                            )}

                            {saveCta && (
                                <Button
                                    disabled={isDisabled}
                                    className={`headerbuttonStyle marTop0 marRight15 cursorPointer`}
                                    title={saveCta.title}
                                    handleClick={saveCta.action}
                                    saveLoad={saveCta.saveLoad}
                                    {...rest}
                                />
                            )}
                        </div>
                    </Col>
                </Row>
            </Container>
        </section>
    );
};

export default DraftHeader;
