import {DEVICE_HEIGHT, getKeyByRoute, RESTAURANT, RETAIL} from '../../../constants';
import Offcanvas from 'react-bootstrap/Offcanvas';
import {useLocation, useNavigate} from 'react-router-dom';
import HamburgerItem from './HamburgerItem';
import {SETTING_SUB_NAV_LIST} from './subNavData';
import Nav from 'react-bootstrap/Nav';
import {useEffect, useRef, useState} from 'react';
import {useLanguageContext, useThemeContext, useAuthContext} from '../../../context';

const SettingsMenu = () => {
    let navigate = useNavigate();
    let sidebarRef = useRef();
    const {pathname} = useLocation();

    const {hanldlePermission, business} = useAuthContext();
    const {I18n} = useLanguageContext();
    const {theme} = useThemeContext();
    const [selectedRoute, setSelectedRoute] = useState('');
    const [subRoute, setSubRoute] = useState('');

    const sider = document.querySelector('.navigationSiderbar');

    const handleSelect = route => {
        if (SETTING_SUB_NAV_LIST[route]) {
            setSelectedRoute(route);
            route == subRoute ? setSubRoute('') : setSubRoute(route);
            return;
        } else navigate(`/${route}`);
    };

    useEffect(() => {
        if (sider) sider.removeAttribute('tabindex');
    }, [sider, selectedRoute]);

    useEffect(() => {
        const currentItem = getKeyByRoute(pathname);
        if (currentItem) {
            setSelectedRoute(currentItem);
            setSubRoute(currentItem);
        }
    }, []);

    return (
        <>
            <Offcanvas
                ref={sidebarRef}
                className="navigationSiderbar"
                backdrop={false}
                show={true}
                onHide={() => console.log('Clicked')}
                style={{
                    backgroundColor: theme.background,
                    height: DEVICE_HEIGHT - 40,
                }}>
                <Offcanvas.Body>
                    <Nav onSelect={route => handleSelect(route)} className="sidePaneNav" variant="pills" activeKey="1">
                        {hanldlePermission('', [RETAIL, RESTAURANT], SETTING_SUB_NAV_LIST['personalInformation']) && (
                            <HamburgerItem
                                hamburgerSubIcon="downIcon"
                                hamburgerTitle="Personal Information"
                                eventKey="personalInformation"
                                subRoute={subRoute === 'personalInformation' ? subRoute : null}
                                selectedRoute={selectedRoute}
                                businessType={business.type}
                                hanldlePermission={hanldlePermission}
                                from="settingsMenu"
                            />
                        )}
                        {hanldlePermission('', [RETAIL, RESTAURANT], SETTING_SUB_NAV_LIST['businessInformation']) && (
                            <HamburgerItem
                                hamburgerSubIcon="downIcon"
                                hamburgerTitle="Business Informationn"
                                eventKey="businessInformation"
                                subRoute={subRoute === 'businessInformation' ? subRoute : null}
                                selectedRoute={selectedRoute}
                                businessType={business.type}
                                hanldlePermission={hanldlePermission}
                                from="settingsMenu"
                            />
                        )}
                        {hanldlePermission('', [RETAIL, RESTAURANT], SETTING_SUB_NAV_LIST['appSettings']) && (
                            <HamburgerItem
                                hamburgerSubIcon="downIcon"
                                hamburgerTitle="App settings"
                                eventKey="appSettings"
                                subRoute={subRoute === 'appSettings' ? subRoute : null}
                                selectedRoute={selectedRoute}
                                businessType={business.type}
                                hanldlePermission={hanldlePermission}
                                from="settingsMenu"
                            />
                        )}
                    </Nav>
                </Offcanvas.Body>
            </Offcanvas>
        </>
    );
};

export default SettingsMenu;
