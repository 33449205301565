import React from 'react';
import UploadMarginBreaKdown from './UploadMarginBreaKdown';
import DownloadMarginReport from './DownloadMarginReport';

const MarginBreakDownComponent = () => {
    return (
        <div className="marTop20">
            <UploadMarginBreaKdown />
            <DownloadMarginReport />
        </div>
    );
};

export default MarginBreakDownComponent;
