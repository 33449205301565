import List from './List';
import { useLanguageContext, useThemeContext } from '../../../context';
import ReportsBoxHeader from '../Header/ReportsBoxHeader';
import { DEVICE_HEIGHT } from '../../../constants';
import NonIdealScreen from '../NonIdeal';
import TableHeader from '../TableContainer/TableHeader';
import Loading from '../Loading';
import InfiniteScroll from 'react-infinite-scroll-component';
import { useNavigate } from 'react-router-dom';
import { useMemo } from 'react';
import debounce from 'lodash.debounce';

const ZReportListing = ({ data, totalpages, loading, setSearch, currentPage, setCurrentPage }) => {
    const { I18n } = useLanguageContext();
    const { theme } = useThemeContext();
    let navigate = useNavigate();

    const fetchMoreData = () => {

        if (currentPage < totalpages) {
            setCurrentPage(prevPage => {
                const nextPage = prevPage + 1;
                return nextPage;
            });
        }
    };

    const onChange = (label, value) => {
        setSearch(value);
    };

    const debouncedResults = useMemo(() => debounce(onChange, 500), []);

    const handleNavigate = (session_id, business_id, user_name) => {
        navigate('/session-report', { state: { session_id, business_id, from: 'z_report_listing', user_name } });
    };

    return <div className="reportsWrapper zreportConatiner">
        <div className="reportsWrapperInner">
            <ReportsBoxHeader
                title="z_report"
                search={{
                    type: 'pos',
                    placeholder: I18n.search_by_user_name,
                    prefix: {
                        name: 'search2Icon',
                        fill: theme.white,
                    },
                    onChange: debouncedResults,
                }}
            />
            {loading ? (
                <Loading />
            ) : data?.length > 0 ? (
                <>

                    <TableHeader className={`sevenColumn`} style={{ borderColor: theme.inputBorder, marginRight: '20px' }}>
                        <div className="BoxWidth justifyStart">
                            <p className="fontSize16 OneLineTruncate" style={{ color: theme.white }}>
                                {I18n.user}
                            </p>
                        </div>

                        <div className="BoxWidth justifyCenter">
                            <p className="fontSize16 OneLineTruncate" style={{ color: theme.white }}>
                                {I18n.location}
                            </p>
                        </div>

                        <div className="BoxWidth justifyCenter">
                            <p className="fontSize16 OneLineTruncate" style={{ color: theme.white }}>
                                {I18n.start_date}
                            </p>
                        </div>
                        <div className="BoxWidth justifyCenter">
                            <p className="fontSize16 OneLineTruncate" style={{ color: theme.white }}>
                                {I18n.end_date}
                            </p>
                        </div>
                        <div className="BoxWidth justifyCenter">
                            <p className="fontSize16 OneLineTruncate" style={{ color: theme.white }}>
                                {I18n.shift_start}
                            </p>
                        </div>
                        <div className="BoxWidth justifyCenter">
                            <p className="fontSize16 OneLineTruncate" style={{ color: theme.white }}>
                                {I18n.shift_end}
                            </p>
                        </div>
                        <div className="BoxWidth justifyCenter">
                            <p className="fontSize16 OneLineTruncate" style={{ color: theme.white }}>
                                {I18n.action}
                            </p>
                        </div>
                    </TableHeader>
                    <div id="scrollableDiv" style={{ height: DEVICE_HEIGHT - 395, overflow: 'auto' }}>
                        <InfiniteScroll
                            className="infiniteScrollBar"
                            dataLength={data?.length}
                            hasMore={currentPage < totalpages}
                            next={fetchMoreData}
                            loader={
                                <div className="reportsWrapper">
                                    <div className="reportsWrapperInner">
                                        <Loading />
                                    </div>
                                </div>
                            }
                            scrollableTarget="scrollableDiv">
                            {data?.length > 0 &&
                                data?.map((item, index) => {
                                    return <List data={item} key={index} handleNavigate={handleNavigate} />;
                                })}
                        </InfiniteScroll>
                    </div>
                </>
            ) : <div className="onlineNonIdeal reportDashboardBox">
                <NonIdealScreen
                    customHeight={325}
                    heading={I18n.z_report}
                    subHeading={I18n.it_looks_like_there_are_no_activities_to_generate_dece}
                    name="zReportIcon"
                    fill={theme.white}
                    isMultiStep={false}
                    viewBox="0 0 100 100"
                />
            </div>}
        </div>
    </div>

};

export default ZReportListing;
