import React from 'react';
import TextField from '../TextField';
import {useAuthContext, useLanguageContext, useThemeContext} from '../../../context';
import {toSnakeCase} from '../../../constants';

const ChangeEmailComponent = ({email, setEmail, confirmEmail, setConfirmEmail, errors, setErrors}) => {
    const {theme} = useThemeContext();
    const {I18n} = useLanguageContext();
    const {account} = useAuthContext();

    const onChange = async (label, val) => {
        if (label == I18n.new_email_address) setEmail(val);
        if (label == I18n.confirm_new_email_address) setConfirmEmail(val);

        setErrors(prevErrors => {
            const updatedErrors = {...prevErrors};
            if (updatedErrors[toSnakeCase(label)]) {
                delete updatedErrors[toSnakeCase(label)];
            }
            return updatedErrors;
        });
    };

    return (
        <div className="flex horizontalCenter width100">
            <div className="flex width50" style={{flexDirection: 'column'}}>
                <p className="fontSize20 marBot5 fontWeight400" style={{color: theme.white}}>
                    {I18n.change_email_address}
                </p>
                <div className="flex spaceBetweenCenter width70">
                    <p className="fontSize14 marBot20 fontWeight400" style={{color: theme.lightGrayTwo}}>
                        {I18n.enter_a_new_email_address_that_you_like_to_associate}
                        <span className="fontWeight600" style={{color: theme.white}}>
                            {I18n.you_will_no_longer_be_able_to_login_with} {account?.email}
                        </span>
                    </p>
                </div>

                <TextField
                    label={I18n.new_email_address}
                    placeholder={I18n.new_email_address}
                    onChange={onChange}
                    value={email}
                    required={true}
                    error={errors?.new_email_address}
                />
                <TextField
                    label={I18n.confirm_new_email_address}
                    placeholder={I18n.confirm_new_email_address}
                    onChange={onChange}
                    value={confirmEmail}
                    required={true}
                    error={errors?.confirm_new_email_address}
                />
            </div>
        </div>
    );
};

export default ChangeEmailComponent;
