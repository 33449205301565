import React, {useEffect, useState} from 'react';
import {useAuthContext, useLanguageContext, useThemeContext} from '../../../../../context';
import {DEVICE_HEIGHT} from '../../../../../constants';
import {FormCheck} from 'react-bootstrap';
import {Button, MessageModal} from '../../../../common';
import {updateSettings} from '../../../../../api';
import {toast} from 'react-toastify';

const EmailSmsNotifications = () => {
    const {I18n} = useLanguageContext();
    const {theme} = useThemeContext();
    const {businessSettings, fetchSettings} = useAuthContext();

    const [dailySalesSummary, setDailySalesSummary] = useState({status: false, id: ''});
    const [monthlySalesSummary, setMonthlySalesSummary] = useState({status: false, id: ''});
    const [inventoryAlerts, setInventoryAlerts] = useState({status: false, id: ''});
    const [loading, setLoading] = useState(false);
    const [isChanged, setIsChanged] = useState(false);
    const [confirmatiomModal, setConfirmationModal] = useState(false);
    const emailNotifications = businessSettings?.['email_notifications'];

    const handleSaveNotifications = async () => {
        try {
            setLoading(true);
            const payload = {
                settings: [
                    {
                        id: dailySalesSummary.id,
                        scope: 'business',
                        setting_value: {
                            status: dailySalesSummary.status,
                        },
                    },
                    {
                        id: monthlySalesSummary.id,
                        scope: 'business',
                        setting_value: {
                            status: monthlySalesSummary.status,
                        },
                    },
                    {
                        id: inventoryAlerts.id,
                        scope: 'business',
                        setting_value: {
                            status: inventoryAlerts.status,
                        },
                    },
                ],
            };
            const response = await updateSettings(payload);
            if (response.status) {
                toast.success('Changes updated successfully');
                setIsChanged(false);
                fetchSettings();
            }
        } catch (error) {
            console.log('Error', error);
        } finally {
            setLoading(false);
            if (confirmatiomModal) setConfirmationModal(false);
        }
    };

    const handleCancel = () => {
        setInitialValues();
        setIsChanged(false);
    };

    const handleDiscardModal = () => {
        setConfirmationModal(false);
    };

    const handleChange = (e, emailNotification) => {
        setIsChanged(true);
        if (emailNotification.name === 'Daily sales summary') {
            setDailySalesSummary(prev => ({...prev, status: e}));
        } else if (emailNotification.name === 'Monthly sales summary') {
            setMonthlySalesSummary(prev => ({...prev, status: e}));
        } else if (emailNotification.name === 'Inventory alerts') {
            setInventoryAlerts(prev => ({...prev, status: e}));
        }
    };

    const handleCheck = emailNotification => {
        if (emailNotification.name === 'Daily sales summary') {
            return dailySalesSummary.status;
        } else if (emailNotification.name === 'Monthly sales summary') {
            return monthlySalesSummary.status;
        } else if (emailNotification.name === 'Inventory alerts') {
            return inventoryAlerts.status;
        }
    };

    const setInitialValues = () => {
        if (emailNotifications) {
            emailNotifications.forEach(emailNotification => {
                if (emailNotification.name === 'Daily sales summary') {
                    setDailySalesSummary({id: emailNotification.id, status: emailNotification.setting_value.status});
                } else if (emailNotification.name === 'Monthly sales summary') {
                    setMonthlySalesSummary({id: emailNotification.id, status: emailNotification.setting_value.status});
                } else if (emailNotification.name === 'Inventory alerts') {
                    setInventoryAlerts({id: emailNotification.id, status: emailNotification.setting_value.status});
                }
            });
        }
    };

    useEffect(() => {
        setInitialValues();
    }, [emailNotifications]);

    return (
        <div className="pad20">
            <div className="settingsWrapper borderRadiusBottomleftBottomRigt pad20" fluid style={{background: theme.blackBg, height: DEVICE_HEIGHT - 80}}>
                <>
                    <div className="spaceBetweenCenter">
                        <p className="marTop10 marBot10 fontSize18 fontWeight600" style={{color: theme.white}}>
                            {I18n.email_notifications}
                        </p>
                        {isChanged && (
                            <div className="flex gap10 ">
                                <Button title="Cancel" handleClick={e => handleCancel(e)} className={`cancelBtn  fontWeight400 fontSize14 cursorPointer`} />
                                <Button
                                    saveLoad={loading}
                                    disabled={loading}
                                    title="Save"
                                    handleClick={e => handleSaveNotifications(e)}
                                    className={`saveBtn  fontWeight400 fontSize14 cursorPointer`}
                                />
                            </div>
                        )}
                    </div>
                    <div className="divider width100 marTop10" />
                </>

                {emailNotifications?.map(notification => {
                    return (
                        <div key={notification.id}>
                            <p className="marTop15 marBot0 fontSize16 fontWeight400" style={{color: theme.white}}>
                                {notification.name}
                            </p>

                            <p className="marTop3 marBot10 fontSize14 fontWeight400" style={{color: theme.darkGrayTwo}}>
                                {notification.description}
                            </p>
                            <div className="flex gap15">
                                <FormCheck type="switch" id="stock_available" onChange={e => handleChange(e.target.checked, notification)} checked={handleCheck(notification)} />
                                <p className="marTop3 marBot0 fontSize14 fontWeight400" style={{color: theme.white}}>
                                    {handleCheck(notification) ? I18n.enabled : I18n.disabled}
                                </p>
                            </div>

                            <div className="divider width100 marTop20" />
                        </div>
                    );
                })}
            </div>
            <MessageModal
                className="messageModal"
                setToggle={setConfirmationModal}
                toggle={confirmatiomModal}
                description={I18n.you_have_unsaved_changes}
                subDescription={I18n.if_you_leave_without_saving}
                primaryCta={{
                    title: I18n.save_changes,
                    action: handleSaveNotifications,
                    backgroundColor: theme.brightGreen,
                    borderColor: theme.brightGreen,
                    txtColor: theme.white,
                    loading: loading,
                }}
                secondaryCta={{
                    title: I18n.discard,
                    action: handleDiscardModal,
                }}
            />
        </div>
    );
};

export default EmailSmsNotifications;
