import { Col, Row } from 'react-bootstrap';
import React, { useState } from 'react';
import { DashboardHeader } from '../../../../../common';
import SaleOnline from './SalesOnline';
import SaleReport from './SaleReport';
import usePermission from '../../../../../../hooks/usePermission';
import TransactionList from '../../../Payments/Transactions/TransactionList';
import SalesDashboard from './SalesDashboard';

const SalesOverview = ({ selectedDateRange, setSelectedDateRange, selectedLocation, initialTab = 0, setSelectedLocation, visible, setVisible }) => {
    const [primaryTabValue, setPrimaryTabValue] = useState(initialTab);
    const [reportDropDownOption, setReportDropDownOption] = useState({ name: 'Sales Summary', value: 'sale_summary' });
    const isPosPermission = usePermission('pos');

    return (
        <>
            <Row>
                <Col md={12}>
                    <DashboardHeader
                        selectedLocation={selectedLocation}
                        setSelectedLocation={setSelectedLocation}
                        setSelectedDateRange={setSelectedDateRange}
                        selectedDateRange={selectedDateRange}
                        reportDropDownOption={reportDropDownOption}
                        setReportDropDownOption={setReportDropDownOption}
                        setPrimaryTabValue={setPrimaryTabValue}
                        primaryTabValue={primaryTabValue}
                        visible={visible}
                        setVisible={setVisible}
                    />
                </Col>
            </Row>


            <Row>
                {primaryTabValue === 0 ? (
                    <SalesDashboard selectedLocation={selectedLocation} selectedDateRange={selectedDateRange} isPosPermission={isPosPermission} />
                ) : primaryTabValue === 1 ? (
                    <TransactionList selectedLocation={selectedLocation} selectedDateRange={selectedDateRange} />
                ) : primaryTabValue === 2 ? (
                    <SaleOnline />
                ) : (
                    <SaleReport
                        isPosPermission={isPosPermission}
                        selectedDateRange={selectedDateRange}
                        selectedLocation={selectedLocation}
                        reportDropDownOption={reportDropDownOption}
                    />
                )}
            </Row>
        </>
    );
};

export default SalesOverview;