import React from 'react';
import TableHeader from '../TableContainer/TableHeader';
import CheckBox from '../CheckBox';
import {useThemeContext} from '../../../context';
import {QtyIncreaseDecrase} from '../V2';
import withObservables from '@nozbe/with-observables';
import {of, switchMap} from 'rxjs';
import newImagePlaceholder from '../../../assets/images/newImagePlaceholder.svg';

const List = ({item, handleUpdateItemQty, selectedItems, handleChecked, order_lines, product_template}) => {
    const {theme} = useThemeContext();
    const getInitialQuantity = order_lines.find(ol => ol.id === item?.id)?.quantity;

    return (
        <div className="width100">
            <TableHeader className={`fourColumn`} style={{borderColor: theme.posRightBg}}>
                <div className="BoxWidth fontWeight400 justifyStart refundScreenCheckbox">
                    <CheckBox
                        checkboxLabel={
                            <>
                                <span className="marLeft15 itemListImage">
                                    <img src={product_template?.image || newImagePlaceholder} style={{height: 40, width: 40, resizeMode: 'cover', borderRadius: '5px'}} />
                                </span>
                                <p className="marBot0 fontSize16" style={{color: theme.text}}>
                                    {item?.name}
                                </p>
                            </>
                        }
                        onChange={() => handleChecked(item)}
                        checked={selectedItems[item?.id]}
                    />
                </div>
                <div className="BoxWidth fontWeight400 justifyCenter">
                    <QtyIncreaseDecrase item={item} toggle={true} handleUpdateItemQty={handleUpdateItemQty} initialQuantity={getInitialQuantity} disableInput={true} />
                </div>

                <div className="BoxWidth fontWeight400 justifyCenter">
                    <p className="fontSize16 OneLineTruncate" style={{color: theme.white}}>
                        Rs. {item?.selling_price}
                    </p>
                </div>
                <div className="BoxWidth fontWeight400 justifyCenter">
                    <p className="fontSize16 OneLineTruncate" style={{color: theme.white}}>
                        Rs. {item?.selling_price * item?.quantity}
                    </p>
                </div>
            </TableHeader>
        </div>
    );
};

const enhance = withObservables(['item'], ({item}) => ({
    product_template: item.product.observe().pipe(
        switchMap(product => {
            if (!item.is_open) {
                return product.product_template.observe();
            } else {
                return of(null);
            }
        }),
    ),
}));
export default enhance(List);
