import React from 'react';
import TableHeader from '../TableContainer/TableHeader';
import CheckBox from '../CheckBox';
import List from './List';
import {useLanguageContext, useThemeContext} from '../../../context';

const ItemList = ({handleSelectAll, selectedItems, cloneItems, order_lines, handleUpdateItemQty, handleChecked, restockItems, setRestockItems}) => {
    const {I18n} = useLanguageContext();
    const {theme} = useThemeContext();

    return (
        <div>
            <div className="spaceBetweenCenter">
                <p className="fontSize18 fontWeight600 marBot0" style={{color: theme.text}}>
                    {I18n.select_items_for_refund}
                </p>

                <div className="restockWrapper">
                    <CheckBox
                        checkboxLabel={
                            <p className="marBot0 fontSize16" style={{color: theme.text}}>
                                {I18n.restock_items}
                            </p>
                        }
                        onChange={e => setRestockItems(e.target.checked)}
                        checked={restockItems}
                    />
                </div>
            </div>

            <div className="width100">
                <TableHeader className={`fourColumn`} style={{borderColor: theme.posRightBg}}>
                    <div className="BoxWidth fontWeight600 justifyStart refundScreenCheckbox">
                        <CheckBox
                            checkboxLabel={
                                <p className="marBot0 fontSize16" style={{color: theme.text}}>
                                    {I18n.select_all_item}
                                </p>
                            }
                            onChange={() => handleSelectAll()}
                            checked={Object.keys(selectedItems)?.length === cloneItems?.length}
                        />
                    </div>
                    <div className="BoxWidth fontWeight600 justifyCenter">
                        <p className="fontSize16 OneLineTruncate" style={{color: theme.white}}>
                            {I18n.quantity}
                        </p>
                    </div>

                    <div className="BoxWidth fontWeight600 justifyCenter">
                        <p className="fontSize16 OneLineTruncate" style={{color: theme.white}}>
                            {I18n.rate}
                        </p>
                    </div>
                    <div className="BoxWidth fontWeight600 justifyCenter">
                        <p className="fontSize16 OneLineTruncate" style={{color: theme.white}}>
                            {I18n.amount}
                        </p>
                    </div>
                </TableHeader>
                <div className="tableListMainBox orderListBodyBox">
                    {cloneItems?.map((val, index) => (
                        <List
                            key={index}
                            item={val}
                            handleUpdateItemQty={handleUpdateItemQty}
                            selectedItems={selectedItems}
                            handleChecked={handleChecked}
                            order_lines={order_lines}
                        />
                    ))}
                </div>
            </div>
        </div>
    );
};

export default ItemList;
