import React, {useEffect, useState} from 'react';
import {useLanguageContext, useThemeContext} from '../../../context';
import {formatTimeInSec} from '../../../constants';
import Otp from '../Otp';

const OtpVerificationComponent = ({otp, setOtp, handleResend, step, sentTo, errors, setErrors}) => {
    const {I18n} = useLanguageContext();
    const {theme} = useThemeContext();

    const [seconds, setSeconds] = useState(300);

    useEffect(() => {
        setErrors({});
    }, [otp]);

    useEffect(() => {
        const intervalId = setInterval(() => {
            setSeconds(seconds => {
                if (seconds <= 0) {
                    clearInterval(intervalId);
                    return 0;
                }
                return seconds - 1;
            });
        }, 1000);

        return () => clearInterval(intervalId);
    }, [seconds]);

    useEffect(() => {
        setSeconds(300);
    }, [step]);

    return (
        <div className="flex horizontalCenter width100">
            <div className="flex width50" style={{flexDirection: 'column'}}>
                <div className="marTop40 otpverifictionConatiner">
                    <p className="fontSize20 fontWeight400 marBot0" style={{color: theme.white}}>
                        {I18n.verify_otp}
                    </p>
                    <p style={{color: theme.darkGrayTwo}} className="fontSize16 fontWeight400">
                        {`${I18n.oTP_code_has_been_sent_to} ${sentTo}`}
                    </p>
                    <div className={`otpverification ${Object.keys(errors || {}).length > 0 ? 'otpverificationErrorState' : ''} `}>
                        <Otp otp={otp} setOtp={setOtp} numInputs={6} focus={true} />

                        <p className="inputError" style={{paddingLeft: '10px'}}>
                            {Object.keys(errors || {}).length > 0 ? errors?.message?.otp : ''}
                        </p>
                    </div>

                    <div className="spaceBetweenCenter marTop5" style={{padding: '0 10px'}}>
                        <p />
                        <p className="fontSize16 fontWeight400 marTop20" style={{color: theme.white}}>
                            {formatTimeInSec(seconds)}
                        </p>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default OtpVerificationComponent;
