import React, {useEffect, useState} from 'react';
import {useAuthContext, useLanguageContext, useThemeContext} from '../../../../../context';
import {DEVICE_HEIGHT} from '../../../../../constants';
import {FormCheck} from 'react-bootstrap';
import {Button, MessageModal} from '../../../../common';
import {updateSettings} from '../../../../../api';
import {toast} from 'react-toastify';
import {updateModule} from '../../../../../pos-core/database/helpers';

const Preferences = () => {
    const {I18n} = useLanguageContext();
    const {theme} = useThemeContext();
    const {businessSettings, fetchSettings, userRole} = useAuthContext();

    const [negativeInventory, setNegativeInventory] = useState({status: false, id: ''});
    const [allowTips, setAllowTips] = useState({status: false, id: ''});
    const [allowCharges, setAllowCharges] = useState({status: false, id: ''});
    const [allowStoreCredit, setAllowStoreCredit] = useState({status: false, id: ''});
    const [enableKds, setEnableKds] = useState({status: false, id: '', key: ''});
    const [loading, setLoading] = useState(false);
    const [isChanged, setIsChanged] = useState(false);
    const [confirmatiomModal, setConfirmationModal] = useState(false);

    const preferences = businessSettings?.['preferences'];

    const handleModulePermission = async () => {
        const module = await userRole?.find(mod => mod.name === 'device');
        const param = enableKds.status
            ? {
                  create: true,
                  update: true,
                  delete: true,
                  read: true,
              }
            : {
                  create: false,
                  update: false,
                  delete: false,
                  read: false,
              };

        await updateModule(module, param);
    };

    const handleSavePreferences = async () => {
        try {
            setLoading(true);
            const payload = {
                settings: [
                    {
                        id: negativeInventory.id,
                        scope: 'business',
                        setting_value: {
                            status: negativeInventory.status,
                        },
                    },
                    {
                        id: allowTips.id,
                        scope: 'business',
                        setting_value: {
                            status: allowTips.status,
                        },
                    },
                    {
                        id: allowCharges.id,
                        scope: 'business',
                        setting_value: {
                            status: allowCharges.status,
                        },
                    },
                    {
                        id: allowStoreCredit.id,
                        scope: 'business',
                        setting_value: {
                            status: allowStoreCredit.status,
                        },
                    },
                    {
                        id: enableKds.id,
                        scope: 'business',
                        setting_value: {
                            status: enableKds.status,
                            key: enableKds?.key,
                        },
                    },
                ]?.filter(setting => setting.id),
            };

            const response = await updateSettings(payload);
            if (response.status) {
                toast.success('Changes updated successfully');
                setIsChanged(false);
                handleModulePermission();
                fetchSettings();
            }
        } catch (error) {
            console.log('Error', error);
        } finally {
            setLoading(false);
            if (confirmatiomModal) setConfirmationModal(false);
        }
    };

    const handleCancel = () => {
        setInitialValues();
        setIsChanged(false);
    };

    const handleDiscardModal = () => {
        setConfirmationModal(false);
    };

    const handleChange = (e, preference) => {
        setIsChanged(true);
        if (preference.name === 'Negative inventory') {
            setNegativeInventory(prev => ({...prev, status: e}));
        } else if (preference.name === 'Allow tips') {
            setAllowTips(prev => ({...prev, status: e}));
        } else if (preference.name === 'Allow charges') {
            setAllowCharges(prev => ({...prev, status: e}));
        } else if (preference.name === 'Allow store credit') {
            setAllowStoreCredit(prev => ({...prev, status: e}));
        } else if (preference.name === 'Enable KDS') {
            setEnableKds(prev => ({...prev, status: e}));
        }
    };

    const handleCheck = preference => {
        if (preference.name === 'Negative inventory') {
            return negativeInventory.status;
        } else if (preference.name === 'Allow tips') {
            return allowTips.status;
        } else if (preference.name === 'Allow charges') {
            return allowCharges.status;
        } else if (preference.name === 'Allow store credit') {
            return allowStoreCredit.status;
        } else if (preference.name === 'Enable KDS') {
            return enableKds.status;
        }
    };
    const setInitialValues = () => {
        if (preferences) {
            console.log('IN PREF');
            preferences.forEach(preference => {
                if (preference.name === 'Negative inventory') {
                    setNegativeInventory({id: preference.id, status: preference.setting_value.status});
                } else if (preference.name === 'Allow tips') {
                    setAllowTips({id: preference.id, status: preference.setting_value.status});
                } else if (preference.name === 'Allow charges') {
                    setAllowCharges({id: preference.id, status: preference.setting_value.status});
                } else if (preference.name === 'Allow store credit') {
                    setAllowStoreCredit({id: preference.id, status: preference.setting_value.status});
                } else if (preference.name === 'Enable KDS') {
                    setEnableKds({id: preference.id, status: preference.setting_value.status, key: preference.setting_value.key});
                }
            });
        }
    };

    useEffect(() => {
        setInitialValues();
    }, [preferences]);

    return (
        <div className="pad20">
            <div className="settingsWrapper borderRadiusBottomleftBottomRigt pad20" fluid style={{background: theme.blackBg, height: DEVICE_HEIGHT - 80}}>
                <>
                    <div className="spaceBetweenCenter">
                        <p className="marTop10 marBot10 fontSize18 fontWeight600" style={{color: theme.white}}>
                            {I18n.preferences}
                        </p>

                        {isChanged && (
                            <div className="flex gap10 ">
                                <Button title="Cancel" handleClick={e => handleCancel(e)} className={`cancelBtn  fontWeight400 fontSize14 cursorPointer`} />
                                <Button
                                    saveLoad={loading}
                                    disabled={loading}
                                    title="Save"
                                    handleClick={e => handleSavePreferences(e)}
                                    className={`saveBtn  fontWeight400 fontSize14 cursorPointer`}
                                />
                            </div>
                        )}
                    </div>
                    <div className="divider width100 marTop10" />
                </>

                {preferences?.map(pref => {
                    return (
                        <div key={pref.id}>
                            <p className="marTop15 marBot0 fontSize16 fontWeight400" style={{color: theme.white}}>
                                {pref?.name}
                            </p>

                            <p className="marTop3 marBot10 fontSize14 fontWeight400" style={{color: theme.darkGrayTwo}}>
                                {pref?.description}
                            </p>
                            <div className="flex gap15">
                                <FormCheck type="switch" id="stock_available" onChange={e => handleChange(e.target.checked, pref)} checked={handleCheck(pref)} />
                                <p className="marTop3 marBot0 fontSize14 fontWeight400" style={{color: theme.white}}>
                                    {handleCheck(pref) ? I18n.enabled : I18n.disabled}
                                </p>
                            </div>
                            <div className="divider width100 marTop20" />
                        </div>
                    );
                })}
            </div>
            <MessageModal
                className="messageModal"
                setToggle={setConfirmationModal}
                toggle={confirmatiomModal}
                description={I18n.you_have_unsaved_changes}
                subDescription={I18n.if_you_leave_without_saving}
                primaryCta={{
                    title: I18n.save_changes,
                    action: handleSavePreferences,
                    backgroundColor: theme.brightGreen,
                    borderColor: theme.brightGreen,
                    txtColor: theme.white,
                    loading: loading,
                }}
                secondaryCta={{
                    title: I18n.discard,
                    action: handleDiscardModal,
                }}
            />
        </div>
    );
};

export default Preferences;
