import React from 'react';
import { useThemeContext } from '../../../context';
import TableBody from '../TableContainer/TableBody';
import { firstLetterCaptilize, formatNum } from '../../../constants';

const ItemsList = ({ data }) => {
    const { theme } = useThemeContext();
    const { name, seats, avg_table_turn_over_time, revenue_per_turnover, total_revenue_per_table } = data || {};

    return (
        <TableBody className={`fiveColumn `} style={{ borderColor: theme.inputBorder }}>
            <div className="BoxWidth justifyStart">
                <p title={firstLetterCaptilize(name) || "--"} className="fontSize14 fontWeight400 OneLineTruncate cursorPointer " style={{ color: theme.white }}>
                    {firstLetterCaptilize(name) || "--"}
                </p>
            </div>
            <div className="BoxWidth justifyCenter">
                <p title={formatNum(seats || 0)} className="fontSize14 fontWeight400 OneLineTruncate cursorPointer " style={{ color: theme.white }}>
                    {formatNum(seats || 0)}
                </p>
            </div>
            <div className="BoxWidth justifyCenter">
                <p title={`${avg_table_turn_over_time} mins`} className="fontSize14 fontWeight400 OneLineTruncate cursorPointer " style={{ color: theme.white }}>
                    {`${avg_table_turn_over_time} mins`}
                </p>
            </div>

            <div className="BoxWidth justifyCenter">
                <p title={`Rs. ${formatNum(revenue_per_turnover || 0)}`} className="fontSize14 fontWeight400 OneLineTruncate cursorPointer " style={{ color: theme.white }}>
                    {`Rs. ${formatNum(revenue_per_turnover || 0)}`}
                </p>
            </div>
            <div className="BoxWidth justifyCenter">
                <p title={`Rs. ${formatNum(total_revenue_per_table || 0)}`} className="fontSize14 fontWeight400 OneLineTruncate cursorPointer " style={{ color: theme.white }}>
                    {`Rs. ${formatNum(total_revenue_per_table || 0)}`}

                </p>
            </div>
        </TableBody>
    );
};

export default ItemsList;
