import React from 'react';
import Form from 'react-bootstrap/Form';

const RadioButton = ({options, className, selectedSize, handleChange, showCustomBtn, disable, customPosition = 'after'}) => {
    return (
        <Form className={className}>
            {options?.map(option => (
                <div key={option.value} className={`mb-3 ${showCustomBtn ? 'spaceBetweenCenter' : ''}`}>
                    {showCustomBtn && customPosition === 'before' && (
                        <div className="radioOuterCircleBefore" onClick={() => handleChange(option.value)}>
                            {option.value === selectedSize && <div className="radioInnerCircle"></div>}
                        </div>
                    )}
                    <Form.Check
                        type="radio"
                        id={option.value}
                        name="dynamicRadio"
                        value={option.value}
                        label={option.label}
                        checked={option.value === selectedSize}
                        onChange={handleChange}
                        disabled={disable}
                    />

                    {showCustomBtn && customPosition === 'after' && (
                        <div className="radioOuterCircleAfter" onClick={() => handleChange(option.value)}>
                            {option.value === selectedSize && <div className="radioInnerCircle"></div>}
                        </div>
                    )}
                </div>
            ))}
        </Form>
    );
};

export default RadioButton;
