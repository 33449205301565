import React from 'react';
import { firstLetterCaptilize, formatNum } from '../../../constants';
import { useThemeContext } from '../../../context';
import TableBody from '../TableContainer/TableBody';

const ItemsList = ({ data }) => {
    const { theme } = useThemeContext();
    const { name, tax_percentage, total_sales, taxable_amount, period, transaction } = data || {};

    return (
        <TableBody className={`sixColumn `} style={{ borderColor: theme.inputBorder }}>
            <div className="BoxWidth justifyStart">
                <p title={firstLetterCaptilize(name) || '--'} className="fontSize14 fontWeight400 OneLineTruncate cursorPointer" style={{ color: theme.white }}>
                    {firstLetterCaptilize(name) || '--'}
                </p>
            </div>
            <div className="BoxWidth justifyCenter">
                <p title={`${tax_percentage || 0} %`} className="fontSize14 fontWeight400 OneLineTruncate cursorPointer" style={{ color: theme.white }}>
                    {`${tax_percentage || 0} %`}
                </p>
            </div>
            <div className="BoxWidth justifyCenter">
                <p title={`Rs. ${formatNum(total_sales || 0)}`} className="fontSize14 fontWeight400 OneLineTruncate cursorPointer" style={{ color: theme.white }}>
                    {`Rs. ${formatNum(total_sales || 0)}`}
                </p>
            </div>
            <div className="BoxWidth justifyCenter">
                <p title={`Rs. ${formatNum(taxable_amount || 0)}`} className="fontSize14 fontWeight400 OneLineTruncate cursorPointer" style={{ color: theme.white }}>
                    {`Rs. ${formatNum(taxable_amount || 0)}`}
                </p>
            </div>
            <div className="BoxWidth justifyCenter">
                <p title={period || 0} className="fontSize14 fontWeight400 OneLineTruncate cursorPointer" style={{ color: theme.white }}>
                    {period || 0}
                </p>
            </div>
            <div className="BoxWidth justifyCenter">
                <p title={formatNum(transaction || 0)} className="fontSize14 fontWeight400 OneLineTruncate cursorPointer" style={{ color: theme.white }}>
                    {formatNum(transaction || 0)}
                </p>
            </div>
        </TableBody>
    );
};

export default ItemsList;
