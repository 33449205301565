import {useAuthContext, useLanguageContext} from '../../../../../context';
import {useOrder} from '../../../../../hooks';
import {Loading, OrderDetailCompnent} from '../../../../common';

const OrderDetails = () => {
    const {I18n} = useLanguageContext();
    const {business} = useAuthContext();

    const {isLoading, order} = useOrder(business);

    return isLoading ? <Loading /> : <OrderDetailCompnent order={order} />;
};

export default OrderDetails;
