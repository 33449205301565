import React from 'react';
import TextField from '../TextField';
import {useLanguageContext, useThemeContext} from '../../../context';
import {toSnakeCase} from '../../../constants';

const ChangePhoneComponent = ({phone, setPhone, errors, setErrors}) => {
    const {theme} = useThemeContext();
    const {I18n} = useLanguageContext();

    const onChange = async (label, val) => {
        if (label == I18n.new_phone_number) setPhone(val);

        setErrors(prevErrors => {
            const updatedErrors = {...prevErrors};
            if (updatedErrors[toSnakeCase(label)]) {
                delete updatedErrors[toSnakeCase(label)];
            }
            return updatedErrors;
        });
    };

    return (
        <div className="flex horizontalCenter width100">
            <div className="flex width50" style={{flexDirection: 'column'}}>
                <p className="fontSize20 marBot5 fontWeight400" style={{color: theme.white}}>
                    {I18n.change_phone_number}
                </p>
                <div className="flex spaceBetweenCenter width70">
                    <p className="fontSize14 marBot20 fontWeight400" style={{color: theme.lightGrayTwo}}>
                        {I18n.enter_a_new_phone_number_that_you_like_to_associate_with_your_account}
                    </p>
                </div>

                <TextField
                    label={I18n.new_phone_number}
                    placeholder={I18n.new_phone_number}
                    onChange={onChange}
                    value={phone}
                    maxLength={11}
                    required={true}
                    error={errors.new_phone_number}
                    onlyNumbers={true}
                />
            </div>
        </div>
    );
};

export default ChangePhoneComponent;
