import React, {useState} from 'react';
import {useLanguageContext, useThemeContext} from '../../../context';
import Icon from '../../../assets/icons';

const ActivateWalletCard = ({item}) => {
    const {theme} = useThemeContext();
    const {I18n} = useLanguageContext();

    const {iconName, iconHeight, iconWidth, iconViewBox, title, para} = item;

    return (
        <div className="walletCard marTop20">
            <div className="flex verticalCenter">
                <Icon name={iconName} fill={theme.brightGreen} width={iconWidth} height={iconHeight} viewBox={iconViewBox} />

                <p className="marLeft10 marTop10 marBot10 fontSize16 fontWeight500 OneLineTruncate" style={{color: theme.white}}>
                    {title}
                </p>
            </div>
            <p className="marTop10 fontSize14 fontWeight400 OneLineTruncate" style={{color: theme.darkGrayTwo}}>
                {para}
            </p>
        </div>
    );
};

export default ActivateWalletCard;
