import {Popover} from '@mui/material';
import React from 'react';

const BasicPopover = ({style, anchorEl, handleClose, children}) => {
    return (
        <Popover
            style={style}
            open={anchorEl}
            anchorEl={anchorEl}
            onClose={handleClose}
            anchorPosition={{
                top: 200,
            }}
            anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'left',
            }}>
            {children}
        </Popover>
    );
};

export default BasicPopover;
