import React from 'react';
import TableBody from '../../TableContainer/TableBody';
import { useThemeContext } from '../../../../context';

const ListItem = ({ data, colume_count }) => {
    const { theme } = useThemeContext();

    return (
        <TableBody className={colume_count} style={{ borderColor: theme.inputBorder }}>
            {Object.values(data).map((value, idx) => (
                <div title={value} key={idx} className={`BoxWidth ${idx === 0 ? 'justifyStart' : idx === Object?.values(data)?.length - 1 ? 'justifyEnd' : 'justifyCenter'} cursorPointer`}>
                    <p className="fontSize14 OneLineTruncate" style={{ color: theme.white }}>
                        {value}
                    </p>
                </div>
            ))}
        </TableBody>
    );
};

export default ListItem;
