import React from 'react';
import { useLanguageContext, useThemeContext } from '../../../context';
import TableBody from '../TableContainer/TableBody';
import { firstLetterCaptilize, formatNum } from '../../../constants';

const ItemsList = ({ data }) => {
    const { theme } = useThemeContext();
    const { name, morning, evening, total } = data || {};

    return (
        <TableBody className={`fourColumn`} style={{ borderColor: theme.inputBorder }}>
            <div className="BoxWidth justifyStart">
                <p title={firstLetterCaptilize(name)} className="fontSize14 fontWeight400 OneLineTruncate cursorPointer" style={{ color: theme.white }}>
                    {firstLetterCaptilize(name) || "--"}
                </p>
            </div>
            <div className="BoxWidth justifyCenter">
                <p title={`Rs. ${formatNum(morning || 0)}`} className="fontSize14 fontWeight400 OneLineTruncate cursorPointer" style={{ color: theme.white }}>
                    {`Rs. ${formatNum(morning || 0)}`}
                </p>
            </div>
            <div className="BoxWidth justifyCenter">
                <p title={`Rs. ${formatNum(evening || 0)}`} className="fontSize14 fontWeight400 OneLineTruncate cursorPointer" style={{ color: theme.white }}>
                    {`Rs. ${formatNum(evening || 0)}`}

                </p>
            </div>

            <div className="BoxWidth justifyCenter">
                <p title={`Rs. ${formatNum(total || 0)}`} className="fontSize14 fontWeight400 OneLineTruncate cursorPointer" style={{ color: theme.white }}>
                    {`Rs. ${formatNum(total || 0)}`}

                </p>
            </div>
        </TableBody>
    );
};

export default ItemsList;
