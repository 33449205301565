import React from 'react';
import {formatNum} from '../../../constants';
import {useLanguageContext, useThemeContext} from '../../../context';
import withObservables from '@nozbe/with-observables';

const AmountBox = ({subTotal, tax, order, givenPosDiscount, givenOrderDiscount, total, payments, services}) => {
    const {theme} = useThemeContext();
    const {I18n} = useLanguageContext();
    const isRefundedOrder = order?.is_refunded;

    return (
        <>
            <div className="amountDetailsBox">
                <div className="amountDetailsRow">
                    <p>{I18n.sub_total}</p>
                    <p>{`Rs. ${formatNum(order?.sub_total || 0)}`}</p>
                </div>
                <div className="amountDetailsRow">
                    <p>{I18n.total_tax}</p>
                    <p>{`Rs. ${formatNum(order?.total_tax || 0)}`}</p>
                </div>
                {services?.length > 0 &&
                    services?.map(service => (
                        <div className="amountDetailsRow" key={service.id}>
                            <p>{service.name}</p>
                            <p>{`Rs. ${formatNum(service.selling_price)}`}</p>
                        </div>
                    ))}
                <div className="amountDetailsRow">
                    <p>{I18n.discount}</p>
                    <p>{`Rs. ${formatNum((order?.total_item_level_discount || 0) + (order?.order_level_discount || 0))}`}</p>
                </div>
                {order?.credited_amount && (
                    <>
                        <div className="amountDetailsRow">
                            <p>Store credit</p>
                            <p>{`-Rs. ${formatNum(order?.credited_amount)}`}</p>
                        </div>
                    </>
                )}
                <div
                    className="amountDetailsRow"
                    style={{
                        borderBottom: `1px solid ${theme.darkgrayBorderbg}`,
                    }}>
                    <p style={{color: isRefundedOrder ? theme.brightOrange : theme.white}}>{isRefundedOrder ? 'Refund amount' : I18n.bill_amount}</p>
                    <p style={{color: isRefundedOrder ? theme.brightOrange : theme.white}}>{`Rs. ${formatNum(order?.total)}`}</p>
                </div>
                {!isRefundedOrder && (
                    <>
                        <div className="amountDetailsRow">
                            <p>{I18n.paid_by_customer}</p>
                            <p>{`Rs. ${formatNum(order?.received_amount)}`}</p>
                        </div>
                        <div className="amountDetailsRow">
                            <p>{I18n.change}</p>
                            <p>{`Rs. ${formatNum(order?.change || 0)}`}</p>
                        </div>
                    </>
                )}
            </div>
        </>
    );
};

const enhance = withObservables(['order'], ({order}) => ({
    payments: order.payment.observe(),
}));

export default enhance(AmountBox);
