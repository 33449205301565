import {Model, Q} from '@nozbe/watermelondb';
import {COUNTER_SCHEMA, USER_BUSINESS_SCHEMA, USER_SCEHMA} from '../schema';
import {field, text, relation, children, writer, date, lazy} from '@nozbe/watermelondb/decorators';
import {uuid} from '../../constants';

export default class User extends Model {
    static table = USER_SCEHMA;

    static associations = {
        account: {type: 'belongs_to', key: 'account_id'},
        user_business: {type: 'has_many', foreignKey: 'user_id'},
        session: {type: 'has_many', foreignKey: 'user_id'},
        order: {type: 'has_many', foreignKey: 'user_id'},
        counter: {type: 'has_many', foreignKey: 'user_id'},
    };

    @text('username') username;
    @text('first_name') first_name;
    @text('last_name') last_name;
    @text('salary_type') salary_type;
    @field('salary_amount') salary_amount;
    @text('pin') pin;
    @text('email') email;
    @text('phone_number') phone_number;
    @text('cnic') cnic;
    @text('cnic_front') cnic_front;
    @text('cnic_back') cnic_back;
    @text('image') image;
    @field('is_active') is_active;
    @field('archive') archive;
    @date('created_at') created_at;
    @date('last_visited') last_visited;

    @children('user_business') user_business;
    @children('session') session;
    @children('order') order;
    @children('counter') counter;

    @relation('account', 'account_id') account;

    @lazy getUserActiveSession = this.session.extend(Q.where('is_active', true));

    @writer async updateUser(userDetails) {
        try {
            return await this.batch(
                this.prepareUpdate(user => {
                    user.first_name = userDetails.first_name;
                    user.last_name = userDetails.last_name;
                    user.phone_number = userDetails.phone_number;
                    user.cnic = userDetails.cnic;
                    user.cnic_front = userDetails.cnic_front;
                    user.cnic_back = userDetails.cnic_back;
                    user.image = userDetails.image;
                }),
                userDetails.user_business?.[0].prepareUpdate(userBus => {
                    userBus.role.set(userDetails.role);
                }),
            );
        } catch (error) {
            console.log('error', error);
        }
    }

    @writer async activateUser() {
        return await this.update(user => {
            user.is_active = true;
        });
    }

    @writer async deactivateUser() {
        return await this.update(user => {
            user.is_active = false;
        });
    }

    @writer async archiveUser(status = true) {
        return await this.update(user => {
            user.archive = status;
        });
    }

    @writer async createCounter(details) {
        return await this.collections.get(COUNTER_SCHEMA).create(counter => {
            counter.user.set(this);
            counter._raw.id = uuid();
            counter.auth_session_count = details.auth_session_count;
            counter.pos_session_count = details.pos_session_count;
            counter.order_count = details.order_count;
        });
    }

    @writer async createUserBusinessRole(details) {
        return await this.collections.get(USER_BUSINESS_SCHEMA).create(userBusiness => {
            userBusiness.user.set(this);
            userBusiness.business.set(details.business);
            userBusiness.role.set(details.userRole);
            userBusiness._raw.id = uuid();
        });
    }

    @writer async updateImage(val) {
        return await this.update(user => {
            user.image = val;
        });
    }
}
