import {Col} from 'react-bootstrap';
import {Button, MessageModal} from '../../common';
import {useAuthContext, useLanguageContext, useSessionContext, useThemeContext} from '../../../context';
import {useInternet} from '../../../hooks';
import useTime from '../../../hooks/useTime';
import {Sync} from '../../../api';
import {useState} from 'react';
import Icon from '../../../assets/icons';
import IntegrationModal from '../IntegrationsModal';
import BasicPopover from '../Popover';
import {useLocation, useNavigate} from 'react-router-dom';
import {toast} from 'react-toastify';

const NavbarRight = ({type}) => {
    const {theme} = useThemeContext();
    const {I18n} = useLanguageContext();
    const {isOffline} = useInternet();
    const {currentTime} = useTime();
    const {user, business} = useAuthContext();
    const location = useLocation();
    const [syncing, setSyncing] = useState(false);
    const [toggle, setToggle] = useState(false);
    const [resetModal, setResetModal] = useState(false);
    const [anchorEl, setAnchorEl] = useState(null);
    const configuredIntegration = JSON.parse(localStorage.getItem('integrations'));

    const handleClick = event => {
        if (configuredIntegration) {
            setAnchorEl(event.currentTarget);
        } else {
            setToggle(true);
        }
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const handleResetModal = () => {
        setAnchorEl(null);
        setResetModal(true);
    };

    const handleCancel = () => {
        setResetModal(false);
    };

    const handleReset = () => {
        localStorage.removeItem('integrations');
        setResetModal(false);
    };
    const {session} = useSessionContext();
    let navigate = useNavigate();

    const url = ['', 'pos', 'pos/billing', 'restaurant-pos'];
    const handleSync = () => {
        if (syncing) return;
        setSyncing(true);

        setTimeout(() => {
            Sync(user?.email);
            setSyncing(false);
        }, 3000);
    };

    const handleClockout = async () => {
        try {
            const orders = await business.getActiveAndIncompleteOrders(user?.id)?.fetch();
            let hasActiveOrder = false,
                incompleteOrders = [];

            for (const order of orders) {
                if (order.status === 'active') {
                    let activeOrderLine = await order.order_line.fetch();
                    hasActiveOrder = activeOrderLine.length > 0;
                } else {
                    incompleteOrders.push(order);
                }
            }
            if (hasActiveOrder || incompleteOrders.length > 0) {
                toast.warn(I18n.close_all_orders_before_clocking_out);
            } else {
                if (session) {
                    navigate('/session', {
                        state: 'logout',
                    });
                }
            }
        } catch (error) {
            console.error('error in Closed POS --->', error);
        }
    };
    return (
        <>
            <Col md={6}>
                <div className="headerRigthSide header LeftActionButton">
                    {url?.includes(location?.pathname?.slice(1)) ? (
                        <>
                            <Button
                                txtColor={theme.blackBg}
                                borderColor={theme.offWhite}
                                backgroundColor={theme.offWhite}
                                className={`cursorPointer navBarbtn marBot0`}
                                title={I18n.close_pos}
                                handleClick={handleClockout}
                            />

                            <p className="topBarseperator  height20"></p>
                        </>
                    ) : null}

                    <span className={`cursorPointer`}>
                        <Icon name="integrationIcon" fill={configuredIntegration ? theme.brightGreen : theme.brightOrange} viewBox={'0 0 18 19'} onClick={handleClick} />
                    </span>
                    <BasicPopover handleClose={handleClose} anchorEl={anchorEl} style={{marginTop: '12px'}}>
                        <div className="integrationsPopover">
                            <span style={{position: 'absolute', right: '5px', cursor: 'pointer'}}>
                                <Icon name="crossIcon" fill={'#7D7FA7'} width={'15'} height={'15'} onClick={handleClose} />
                            </span>
                            <div style={{display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column', height: '100%'}}>
                                <p className="fontSize12 fontWeight400 marBot0" style={{color: theme.white}}>
                                    {`${configuredIntegration?.integration.split(' ')?.[0]} Integration`}
                                </p>
                                <span className="marLeft10 marTop5">
                                    <Icon name="tickCircleIcon" fill={theme.brightGreen} width={'50'} height={'50'} />
                                </span>

                                <p className="fontSize12 fontWeight400 marBot0" style={{color: theme.white}}>
                                    {configuredIntegration?.posId}
                                </p>
                                <Button title="Reset" handleClick={handleResetModal} className={`resetBtn  fontWeight400 fontSize12 cursorPointer`} />
                            </div>
                        </div>
                    </BasicPopover>
                    <p className="topBarseperator  height20"></p>

                    <Button
                        className="marBot0"
                        type="iconButton"
                        icon_name="printerNewIcon"
                        icon_fill={theme.brightGreen}
                        icon_width={'20'}
                        icon_height={'20'}
                        icon_viewBox={'0 0 18 18'}
                    />

                    <p className="topBarseperator  height20"></p>

                    <span className={`cursorPointer ${syncing ? 'rotating' : 'paused'}`} onClick={handleSync}>
                        <Icon name="syncIcon" viewBox={'0 0 18 19'} />
                    </span>
                    <p className="topBarseperator  height20"></p>

                    <Button
                        className="marBot0"
                        type="iconButton"
                        icon_name="wifiIcon2"
                        icon_fill={isOffline ? theme.wifiOff : theme.white}
                        icon_width={'20'}
                        icon_height={'20'}
                        icon_viewBox={'0 0 18 18'}
                    />
                    <p className="topBarseperator  height20"></p>

                    <Button
                        className="marBot0"
                        type="iconButton"
                        icon_name="bluetoothIcon"
                        icon_fill={theme.white}
                        icon_width={'10'}
                        icon_height={'16'}
                        icon_viewBox={'0 0 10 16'}
                    />

                    <p className="topBarseperator height20"></p>

                    <p className="fontSize14 fontWeight400 marBot0" style={{color: theme.white}}>
                        {currentTime}
                    </p>
                </div>
            </Col>

            <IntegrationModal toggle={toggle} setToggle={setToggle} />
            <MessageModal
                className="messageModal"
                setToggle={setResetModal}
                toggle={resetModal}
                description={`Reset ${configuredIntegration?.integration}`}
                subDescription={`Do you really want to remove ${configuredIntegration?.integration}?`}
                secondaryCta={{
                    title: I18n.cancel,
                    action: handleCancel,
                }}
                primaryCta={{
                    title: I18n.reset,
                    backgroundColor: theme.white,
                    borderColor: theme.red,
                    txtColor: theme.red,
                    action: handleReset,
                    isDelete: true,
                }}
            />
        </>
    );
};

export default NavbarRight;
