import React from 'react';
import { Document, Page, Text, View, StyleSheet, Image, Font } from '@react-pdf/renderer';
import { useLanguageContext } from '../../../../context';
import poweredbyOscar from '../../../../assets/images/poweredbyOscar.png';
import Poppins from '../../../../assets/fonts/Poppins/Poppins-Regular.ttf';
import { firstLetterCaptilize } from '../../../../constants';


Font.register({
    family: 'Poppins',
    fonts: [
        { src: Poppins },
    ],
});


const styles = StyleSheet.create({
    page: {
        padding: '20px 10px',
        fontSize: '12px',
        height: 'min-content',
        borderRadius: '10px',
        fontFamily: 'Poppins'
    },
    textCenter: {
        textAlign: 'center',
    },
    fontSize10: {
        fontSize: '10px',
    },
    fontSize12: {
        fontSize: '12px',
    },
    fontWeight400: {
        fontWeight: '400px',
    },
    fontWeight700: {
        fontWeight: '700px',
    },
    marginTop15: {
        marginTop: '15px',
    },
    marginTop5: {
        marginTop: '5px',
    },
    width100: {
        width: '100%',
    },
    width95: {
        width: '95%',
    },
    marBot0: {
        marginBottom: '0px',
    },
    reportHeadingBox: {
        width: '100%',
        marginTop: '20px',
    },
    reportHeading: {
        fontSize: '14px',
        fontWeight: '700px',
    },
    flexRow: {
        display: 'flex',
        flexDirection: 'row',
    },
    leftItem: {
        width: '50%',
        fontSize: '10px',
        fontWeight: '500px',
        textAlign: 'left',
        marginTop: "3px",
    },
    rightItem: {
        width: '50%',
        fontSize: '10px',
        fontWeight: '500px',
        textAlign: 'right',
        marginTop: "3px",
    },
    imageBox: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'center',
        width: '100%',
        marginTop: '10px',
    },
});

const SessionReportPDFComponent = ({ reportReceipt }) => {
    const { I18n } = useLanguageContext();

    return (
        <Document>
            <Page size={{ width: 300, height: 'auto' }} style={styles.page}>
                <View>
                    <View style={[styles.textCenter, styles.marginTop5]}>
                        <Text style={[styles.fontSize12, styles.fontWeight700]}>{firstLetterCaptilize(reportReceipt?.header_details?.business_name) || 'My Bussiness'}</Text>
                    </View>
                    <View style={[styles.textCenter, styles.marginTop5]}>
                        <Text style={[styles.fontSize12, styles.fontWeight700, styles.width95]}>{reportReceipt?.header_details?.business_address || 'ABC Street'}</Text>
                    </View>
                    <View style={{ width: '100%', marginBottom: '5px', marginTop: '5px', borderBottom: '1px solid #000' }} />
                    <View style={[styles.width100, styles.marginTop5]}>
                        <Text style={[styles.textCenter, styles.fontWeight700, , styles.fontSize12, styles.marBot0]}>
                            {reportReceipt?.header_details?.current_datetime || '---'}
                        </Text>
                    </View>
                    <View style={[styles.width100, styles.marginTop5]}>
                        <Text style={[styles.textCenter, styles.fontWeight700, styles.fontSize12, styles.marBot0]}>{firstLetterCaptilize(reportReceipt?.header_details?.report_name) || '---'}</Text>
                    </View>
                    <View style={[styles.width100, styles.marginTop5]}>
                        <Text style={[styles.textCenter, styles.fontWeight700, styles.fontSize12, styles.marBot0]}>{firstLetterCaptilize(reportReceipt?.header_details?.user) || '--'}</Text>
                    </View>
                    {/* general_details */}
                    <View style={styles.reportHeadingBox}>
                        <Text style={styles.reportHeading}>{I18n.general_Details}</Text>
                    </View>
                    <View style={{ marginBottom: '10px', width: '100%', borderBottom: '1px dashed #000' }} />
                    {reportReceipt?.general_details?.map((item, index) => {
                        return (
                            <View key={index} style={styles.flexRow}>
                                <Text style={styles.leftItem}>{I18n[item?.title]}</Text>
                                <Text style={styles.rightItem}>{item?.value || '---'} </Text>
                            </View>
                        );
                    })}
                    {/* sales_Details */}
                    <View style={styles.reportHeadingBox}>
                        <Text style={styles.reportHeading}>{I18n.sales_Details}</Text>
                    </View>
                    <View style={{ marginBottom: '10px', width: '100%', borderBottom: '1px dashed #000' }} />
                    {reportReceipt?.sales_details?.map((item, index) => {
                        return (
                            <View key={index} style={styles.flexRow}>
                                <Text style={styles.leftItem}>{I18n[item?.title]}</Text>
                                <Text style={styles.rightItem}>{item?.value} </Text>
                            </View>
                        );
                    })}
                    {/* payment_breakdown */}
                    <View style={styles.reportHeadingBox}>
                        <Text style={styles.reportHeading}>{I18n.payment_breakdown}</Text>
                    </View>
                    <View style={{ marginBottom: '10px', width: '100%', borderBottom: '1px dashed #000' }} />
                    {reportReceipt?.payment_breakdown?.map((item, index) => {
                        return (
                            <View key={index} style={styles.flexRow}>
                                <Text style={styles.leftItem}>{I18n[item?.title]}</Text>
                                <Text style={styles.rightItem}>{item?.value} </Text>
                            </View>
                        );
                    })}
                    {/* insights */}
                    <View style={styles.reportHeadingBox}>
                        <Text style={styles.reportHeading}>{I18n.insights}</Text>
                    </View>
                    <View style={{ marginBottom: '10px', width: '100%', borderBottom: '1px dashed #000' }} />
                    {reportReceipt?.insights?.map((item, index) => {
                        return (
                            <View key={index} style={styles.flexRow}>
                                <Text style={styles.leftItem}>{I18n[item?.title]}</Text>
                                <Text style={styles.rightItem}>{item?.value} </Text>
                            </View>
                        );
                    })}
                    {/* cash_journal */}
                    <View style={styles.reportHeadingBox}>
                        <Text style={styles.reportHeading}>{I18n.cash_journal}</Text>
                    </View>
                    <View style={{ marginBottom: '10px', width: '100%', borderBottom: '1px dashed #000' }} />
                    {reportReceipt?.cash_journal?.map((item, index) => {
                        return (
                            <View key={index} style={styles.flexRow}>
                                <Text style={styles.leftItem}>{I18n[item?.title]}</Text>
                                <Text style={styles.rightItem}>{item?.value} </Text>
                            </View>
                        );
                    })}
                    {/* non_cash_journal */}
                    <View style={styles.reportHeadingBox}>
                        <Text style={styles.reportHeading}>{I18n.non_Cash_journal}</Text>
                    </View>
                    <View style={{ marginBottom: '10px', width: '100%', borderBottom: '1px dashed #000' }} />
                    {reportReceipt?.non_cash_journal?.map((item, index) => {
                        return (
                            <View key={index} style={styles.flexRow}>
                                <Text style={styles.leftItem}>{item?.title}</Text>
                                <Text style={styles.rightItem}>{item?.value} </Text>
                            </View>
                        );
                    })}
                    <View style={{ marginTop: '20px', width: '100%', borderBottom: '1px dashed #000' }} />

                    {/* footer */}
                    <View style={styles.imageBox}>
                        <Image src={poweredbyOscar} style={{ width: '100px', height: '45px' }} />
                    </View>
                </View>
            </Page>
        </Document>
    );
};

export default SessionReportPDFComponent;
