import React from 'react';
import { useLanguageContext, useThemeContext } from '../../../context';
import ItemsList from './ItemsList';
import DashboardDataBox from '../DashboardDataBox';
import TableHeader from '../TableContainer/TableHeader';
import DashboardListNonIdeal from '../NonIdeal/DashboardListNonIdeal';

const TopSellingItems = ({ handleNavigate, data }) => {
    const { theme } = useThemeContext();
    const { I18n } = useLanguageContext();

    return (
        <DashboardDataBox
            ViewMoreBtn={true}
            disableViewButton={!data?.length > 0}
            title="top_selling_items"
            handleView={handleNavigate}
            icon={{
                name: 'arrowUpDirectionIcon',
                fill: theme.brightGreen,
            }}>
            {data?.length > 0 ? (
                <>
                    <TableHeader className={`sixColumn`} style={{ borderColor: theme.inputBorder }}>
                        <div className="BoxWidth justifyStart">
                            <p className="fontSize16 OneLineTruncate" style={{ color: theme.white }}>
                                {I18n.item}
                            </p>
                        </div>

                        <div className="BoxWidth justifyCenter">
                            <p className="fontSize16 OneLineTruncate" style={{ color: theme.white }}>
                                {I18n.qty}
                            </p>
                        </div>

                        <div className="BoxWidth justifyCenter">
                            <p className="fontSize16 OneLineTruncate" style={{ color: theme.white }}>
                                {I18n.sale_price}
                            </p>
                        </div>
                        <div className="BoxWidth justifyCenter">
                            <p className="fontSize16 OneLineTruncate" style={{ color: theme.white }}>
                                {I18n.amount}
                            </p>
                        </div>
                        <div className="BoxWidth justifyCenter">
                            <p className="fontSize16 OneLineTruncate" style={{ color: theme.white }}>
                                {I18n.margin}
                            </p>
                        </div>

                        <div className="BoxWidth justifyEnd">
                            <p className="fontSize16 OneLineTruncate" style={{ color: theme.white }}>
                                {I18n.contribution}
                            </p>
                        </div>
                    </TableHeader>

                    {data?.map((item, index) => {
                        return <ItemsList data={item} key={index} />;
                    })}
                </>
            ) : (
                <DashboardListNonIdeal title="youve_not_done_any_transactions_yet" />
            )}
        </DashboardDataBox>
    );
};

export default TopSellingItems;
