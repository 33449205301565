import React from 'react';
import { firstLetterCaptilize, formatNum } from '../../../constants';
import { useThemeContext } from '../../../context';
import TableBody from '../TableContainer/TableBody';

const ItemsList = ({ data }) => {
    const { theme } = useThemeContext();
    const { primary_item, correlated_item, order_count, avg_pair_amount, percent } = data || {};

    return (
        <TableBody className={`sixColumn `} style={{ borderColor: theme.inputBorder }}>
            <div className="BoxWidth justifyStart">
                <p title={firstLetterCaptilize(primary_item) || '--'} className="fontSize14 fontWeight400 OneLineTruncate cursorPointer" style={{ color: theme.white }}>
                    {firstLetterCaptilize(primary_item) || '--'}
                </p>
            </div>
            <div className="BoxWidth justifyCenter">
                <p title={firstLetterCaptilize(correlated_item) || '--'} className="fontSize14 fontWeight400 OneLineTruncate cursorPointer" style={{ color: theme.white }}>
                    {firstLetterCaptilize(correlated_item) || '--'}
                </p>
            </div>
            <div className="BoxWidth justifyCenter">
                <p title={`${formatNum(percent || 0)} %`} className="fontSize14 fontWeight400 OneLineTruncate cursorPointer" style={{ color: theme.white }}>
                    {`${formatNum(percent || 0)} %`}
                </p>
            </div>
            <div className="BoxWidth justifyCenter">
                <p title={formatNum(order_count || 0)} className="fontSize14 fontWeight400 OneLineTruncate cursorPointer" style={{ color: theme.white }}>
                    {formatNum(order_count || 0)}
                </p>
            </div>
            <div className="BoxWidth justifyCenter">
                <p title={formatNum(avg_pair_amount || 0)} className="fontSize14 fontWeight400 OneLineTruncate cursorPointer" style={{ color: theme.white }}>
                    {formatNum(avg_pair_amount || 0)}
                </p>
            </div>
        </TableBody>
    );
};

export default ItemsList;
