import React from 'react';
import Icon from '../../../assets/icons';
import {useLanguageContext} from '../../../context';

const SesssionReportReceipt = React.forwardRef((props, ref) => {
    const {reportReceipt} = props;
    const {I18n} = useLanguageContext();

    return (
        <div
            ref={ref}
            style={{
                padding: '20px 10px',
                background: '#ffff',
                height: 'min-content',
                fontSize: '12px',
            }}
            className="orderReceipt poppinsFont">
            <div className="textCenter">
                <p className="fontSize10 fontWeight700">{reportReceipt?.header_details?.business_name || 'My Bussiness'}</p>
            </div>
            <div className="textCenter ">
                <p className="fontSize10 width95 fontWeight700">{reportReceipt?.header_details?.business_address || 'ABC Street'}</p>
            </div>
            <div className="width100" style={{borderBottom: '1px solid #000'}} />
            <div className="width100 marTop15">
                <p className="fontSize10  textCenter  marBot0 fontWeight700">{reportReceipt?.header_details?.current_datetime || '---'}</p>
            </div>
            <div className="zReceicpetBoxUserName">
                <p className="fontSize12 marBot0 textCenter fontWeight400">{reportReceipt?.header_details?.report_name || '---'}</p>
            </div>
            <div className="zReceicpetBox">
                <p className="fontSize12 marBot0  fontWeight700">{reportReceipt?.header_details?.user || '--'}</p>
            </div>

            {/* general_details */}
            <div className="width100 marTop20">
                <p className="fontSize14 fontWeight700">{I18n.general_Details}</p>
            </div>
            <div className="marBot10" style={{width: '100%', borderBottom: '1px dashed #000'}} />
            {reportReceipt?.general_details?.map((item, index) => {
                return (
                    <div key={index} className="width100 flex">
                        <p className="width50 fontSize10 fontWeight500">{I18n[item?.title]}</p>
                        <p className="width50 fontSize10 fontWeight500 textRight">{item?.value || '---'} </p>
                    </div>
                );
            })}
            {/* sales_Details */}
            <div className="width100 marTop20">
                <p className="fontSize14 fontWeight700">{I18n.sales_Details}</p>
            </div>
            <div className="marBot10" style={{width: '100%', borderBottom: '1px dashed #000'}} />
            {reportReceipt?.sales_details?.map((item, index) => {
                return (
                    <div key={index} className="width100 flex">
                        <p className="width50 fontSize10 fontWeight500">{I18n[item?.title]}</p>
                        <p className="width50 fontSize10 fontWeight500 textRight">{item?.value} </p>
                    </div>
                );
            })}
            {/* payment_breakdown */}
            <div className="width100 marTop20">
                <p className="fontSize14 fontWeight700">{I18n.payment_breakdown}</p>
            </div>
            <div className="marBot10" style={{width: '100%', borderBottom: '1px dashed #000'}} />
            {reportReceipt?.payment_breakdown?.map((item, index) => {
                return (
                    <div key={index} className="width100 flex">
                        <p className="width50 fontSize10 fontWeight500">{I18n[item?.title]}</p>
                        <p className="width50 fontSize10 fontWeight500 textRight">{item?.value} </p>
                    </div>
                );
            })}
            {/* insights */}
            <div className="width100 marTop20">
                <p className="fontSize14 fontWeight700">{I18n.insights}</p>
            </div>
            <div className="marBot10" style={{width: '100%', borderBottom: '1px dashed #000'}} />
            {reportReceipt?.insights?.map((item, index) => {
                return (
                    <div key={index} className="width100 flex">
                        <p className="width50 fontSize10 fontWeight500">{I18n[item?.title]}</p>
                        <p className="width50 fontSize10 fontWeight500 textRight">{item?.value} </p>
                    </div>
                );
            })}
            {/* cash_journal */}
            <div className="width100 marTop20">
                <p className="fontSize14 fontWeight700">{I18n.cash_journal}</p>
            </div>
            <div className="marBot10" style={{width: '100%', borderBottom: '1px dashed #000'}} />
            {reportReceipt?.cash_journal?.map((item, index) => {
                return (
                    <div key={index} className="width100 flex">
                        <p className="width50 fontSize10 fontWeight500">{I18n[item?.title]}</p>
                        <p className="width50 fontSize10 fontWeight500 textRight">{item?.value} </p>
                    </div>
                );
            })}
            {/* non_cash_journal */}
            <div className="width100 marTop20">
                <p className="fontSize14 fontWeight700">{I18n.non_Cash_journal}</p>
            </div>
            <div className="marBot10" style={{width: '100%', borderBottom: '1px dashed #000'}} />
            {reportReceipt?.non_cash_journal?.map((item, index) => {
                return (
                    <div key={index} className="width100 flex">
                        <p className="width50 fontSize10 fontWeight500">{item?.title}</p>
                        <p className="width50 fontSize10 fontWeight500 textRight">{item?.value} </p>
                    </div>
                );
            })}
            <div className="marTop20" style={{width: '100%', borderBottom: '1px dashed #000'}} />
            {/* footer */}
            <div className="width100 marTop30">
                <div className="textCenter">
                    <Icon name={'powerByoscarIcon'} fill={'black'} viewBox={'0 0 105 36'} width={'105'} height={'36'} />
                </div>
            </div>
        </div>
    );
});

export default SesssionReportReceipt;
