import React, {useEffect} from 'react';
import {Outlet} from 'react-router-dom';
import SettingsMenu from '../../../../common/HamburgerMenu/SettingsMenu';
import {useAuthContext} from '../../../../../context';

const SettingsLayout = () => {
    const {businessSettings, fetchSettings} = useAuthContext();

    useEffect(() => {
        const fetchData = async () => {
            if (!businessSettings) {
                await fetchSettings();
            }
        };

        const timeoutId = setTimeout(() => {
            const sider = document.querySelector('.navigationSiderbar');
            if (sider) {
                console.log('Sider', sider);
                sider.removeAttribute('tabindex');
            }
        }, 1000);

        fetchData();

        return () => clearTimeout(timeoutId);
    }, [businessSettings, fetchSettings]);

    return (
        <div style={{display: 'flex'}}>
            <SettingsMenu />

            <div style={{marginLeft: '230px', background: '#1B1F2B', height: '100vh', width: '100%'}}>
                <Outlet />
            </div>
        </div>
    );
};

export default SettingsLayout;
