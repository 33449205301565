import React from 'react';
import { useAuthContext, useLanguageContext, useThemeContext } from '../../../../context';
import { Modal, ModalBody, ModalHeader, Container, Row, Col } from 'react-bootstrap';
import Icon from '../../../../assets/icons';
import Header from '../../Header';
import TableHeader from '../../TableContainer/TableHeader';
import { dateFilterMapping, DEVICE_HEIGHT, downloadCsvVReport, firstLetterCaptilize, formateIdForApi, formatNum, handleDashboardHeader, insightDetailMapping, removeTime } from '../../../../constants';
import ListItem from './ListItem';
import InfiniteScroll from 'react-infinite-scroll-component';
import Loading from '../../Loading';
import PaginationLoader from '../../PaginationLoader';
import { getDashboardInsightDetails } from '../../../../api';
import { useState } from 'react';

const ViewDataModal = ({ toggle, setToggle, allListData, selectedLocation, selectedDateRange, setCurrentPage, totalpages, title, setAllListData, currentPage }) => {
    const { I18n } = useLanguageContext();
    const { theme } = useThemeContext();
    const { business } = useAuthContext();
    const locationString = formateIdForApi(selectedLocation, business?.id);
    const [isExportLoading, setIsExportLoading] = useState(false)
    /**
     * Handles the closing of the modal by resetting the list data and toggle state.
     */
    function handleClose(event) {
        setToggle(false);
        setAllListData({ name: '', data: [] });
        setCurrentPage(0);
    }

    const handleExportCSV = async (val) => {
        try {
            setIsExportLoading(true)
            let params = {
                start_date: removeTime(selectedDateRange?.start_date),
                end_date: removeTime(selectedDateRange?.end_date),
                date_range: dateFilterMapping[selectedDateRange?.label || 'Today'],
                business_ids: locationString,
                insight_type: insightDetailMapping[val] || '',
                export: true
            };
            const response = await getDashboardInsightDetails(params);

            downloadCsvVReport(response, `${insightDetailMapping[val]}?.csv`);
            setIsExportLoading(false);
        } catch (error) {
            console.error('An error occurred while exporting CSV analytics:', error);
            setIsExportLoading(false);
        }
    };

    const colume_count =
        allListData?.name == 'top_selling_items' || allListData?.name == 'low_selling_items' || allListData?.name == 'tax_information'
            ? 'sixColumn'
            : title === 'profit_margins'
                ? 'nineColumn'
                : title === 'low_stock_items' || allListData?.name == 'out_of_stock_items'
                    ? 'fourColumn'
                    : allListData?.name == 'co_related_items'
                        ? 'fiveColumn'
                        : allListData?.name == 'team_metrics'
                            ? 'sevenColumn'
                            : 'fiveColumn';

    const headers = handleDashboardHeader(allListData?.name, I18n);

    const renderList = allListData?.data?.map(item => {
        if (title === 'top_selling_items' || title === 'low_selling_items') {
            return {
                col1: item.product_name,
                col2: item.qty_sold,
                col3: `Rs. ${formatNum(item.avg_selling_price)}`,
                col4: `Rs. ${item.total_cogs}`,
                col5: `${formatNum(item.margin)}%`,
                col6: `${formatNum(item.total_sales_contrib)}%`,
            };
        } else if (title === 'profit_margins') {
            return {
                col1: item.product_name,
                col2: `Rs. ${formatNum(item.avg_cost_price)}`,
                col3: `Rs. ${formatNum(item.avg_selling_price)}`,
                col4: `${formatNum(item.margin_percent)}%`,
                col5: `Rs. ${item.profit_per_unit}`,
                col6: formatNum(item.qty_sold),
                col7: `Rs. ${formatNum(item.total_cogs)}`,
                col8: `Rs. ${formatNum(item.total_revenue)}`,
                col9: `Rs. ${formatNum(item.total_profit)}`,
            };
        } else if (title === 'low_stock_items' || title === 'out_of_stock_items') {
            return {
                col1: firstLetterCaptilize(item.item_name),
                col2: formatNum(item.current_stock),
                col3: formatNum(item.low_stock_level),
                col4: formatNum(item.stock_gap),
            };
        } else if (title === 'tax_information') {
            return {
                col1: item.name,
                col2: `${formatNum(item.tax_percentage)}%`,
                col3: `Rs.${formatNum(item.total_sales)}`,
                col4: `Rs.${formatNum(item.taxable_amount)}`,
                col5: item.period,
                col6: formatNum(item.transaction),
            };
        } else if (title === 'co_related_items') {
            return {
                col1: firstLetterCaptilize(item.primary_item) || '--',
                col2: firstLetterCaptilize(item.correlated_item) || '--',
                col3: `${formatNum(item.percent)}%`,
                col4: formatNum(item.avg_pair_amount),
                col5: formatNum(item.order_count),
            };
        } else if (title === 'team_metrics') {
            return {
                col1: firstLetterCaptilize(item.member_name) || '--',
                col2: item.member_email || '--',
                col3: firstLetterCaptilize(item.role) || '--',
                col4: `Rs.${formatNum(item.total_sales)}`,
                col5: formatNum(item.transactions_handled),
                col6: `Rs.${formatNum(item.avg_transaction_value)}`,
                col7: `${item.revenue_contribution}%`,
            };
        } else if (title === 'table_turnover') {
            return {
                col1: item.name || '--',
                col2: item.seats || '--',
                col3: formatNum(item.avg_table_turn_over_time) || 0,
                col4: `Rs. ${formatNum(item.revenue_per_turnover)}`,
                col5: `Rs. ${formatNum(item.total_revenue_per_table)}`,
            };
        }
        {
            return {
                col1: '',
                col2: '',
                col3: '',
                col4: '',
                col5: '',
                col6: '',
            };
        }
    });

    /**
     * Fetches more data if there is more data to fetch.
     */
    const fetchMoreData = () => {

        if (currentPage < totalpages) {
            setCurrentPage(prevPage => {
                const nextPage = prevPage + 1;
                return nextPage;
            });
        }
    };

    return (
        <Modal dialogClassName={'animate-bottom'} className="viewDataModal" onHide={() => handleClose()} keyboard={false} show={toggle} centered scrollable={true}>
            <ModalHeader>
                <div className="modalHeaderLeftSide">
                    <span onClick={() => handleClose()} className="cursorPointer moddalheaderleftIconBox">
                        <Icon name="backArrowIcon" fill={theme.barclaysBlue} height="18" width="18" viewBox="0 0 18 18" />
                    </span>
                    <span className="marBot0 fontSize18  fontWeight400 cursorPointer" style={{ color: theme.white }} onClick={() => handleClose()}>
                        {I18n.back}
                    </span>
                </div>
            </ModalHeader>

            <ModalBody>
                <div className="ViewDataBox" style={{ backgroundColor: theme.blackBg }}>
                    <Header
                        type="search"
                        hideSearch={true}
                        title={I18n[allListData?.name]}
                        className={'borderRadiusTopleftTopRigt'}
                        rightCta={{
                            title: I18n.export_csv,
                            action: () => handleExportCSV(allListData?.name),
                            saveLoad: isExportLoading
                        }}
                    />
                    <Container fluid>
                        {allListData?.data?.length > 0 ? (
                            <Row className="justify-content-md-center">
                                <Col md={12}>
                                    <TableHeader className={colume_count} style={{ borderColor: theme.inputBorder }}>
                                        {headers?.map((header, idx) => (
                                            <div key={idx} title={header} className={`BoxWidth ${idx === 0 ? 'justifyStart' : idx === headers.length - 1 ? 'justifyEnd' : 'justifyCenter'} cursorPointer`}>
                                                <p className="fontSize16 OneLineTruncate" style={{ color: theme.white }}>
                                                    {header}
                                                </p>
                                            </div>
                                        ))}
                                    </TableHeader>
                                    <div
                                        id="scrollableDiv"
                                        className="selectCustomerListBox tableListMainBox"
                                        style={{
                                            height: DEVICE_HEIGHT - 240,
                                        }}>

                                        <InfiniteScroll
                                            className="infiniteScrollBar"
                                            dataLength={renderList.length}
                                            next={fetchMoreData}
                                            hasMore={currentPage < totalpages}
                                            loader={<PaginationLoader />}
                                            scrollableTarget="scrollableDiv">
                                            {renderList?.map((val, index) => <ListItem colume_count={colume_count} data={val} key={index} />)}
                                        </InfiniteScroll>

                                    </div>
                                </Col>
                            </Row>
                        ) : (
                            <Loading />
                        )}
                    </Container>
                </div>
            </ModalBody>
        </Modal>
    );
};

export default ViewDataModal;
