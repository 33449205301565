import React from 'react';
import { useLanguageContext, useThemeContext } from '../../../context';

const List = ({ data, key, header }) => {
    const { theme } = useThemeContext();
    const { I18n } = useLanguageContext();

    return (
        <tr className={`cursorPointer`} style={{ borderColor: theme.inputBorder }}>
            {header?.map((header, index) => {
                const key = header?.toLowerCase()?.replace(/ /g, '_');
                const value = data[key];
                return (
                    <td key={index} className="BoxWidth cursorPointer">
                        <p title={value} className="fontSize14 OneLineTruncate" style={{ color: theme.white }}>
                            {value || "-"}
                        </p>
                    </td>
                );
            })}
        </tr>
    );
};

export default List;
