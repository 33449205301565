import {useEffect, useState} from 'react';
import {Col, Container, Row} from 'react-bootstrap';
import {Header, Loading, MessageModal, NonIdealScreen, ToastBox} from '../../../../common';
import {useAuthContext, useLanguageContext, useThemeContext} from '../../../../../context';
import {useNavigate} from 'react-router-dom';
import {DEVICE_HEIGHT} from '../../../../../constants';
import {useSearch} from '../../../../../hooks';
import ListItem from './ListItem';
import {getallCounters, handleUpdateCounter} from '../../../../../api';
import {toast} from 'react-toastify';
import TableHeader from '../../../../common/TableContainer/TableHeader';
const CounterList = ({}) => {
    const navigate = useNavigate();

    const {I18n} = useLanguageContext();
    const {theme} = useThemeContext();
    const {text, setText, searchList} = useSearch(handleSearch);
    const {business} = useAuthContext();
    const [filter, setFilter] = useState(I18n.all_counters);
    const [loading, setloading] = useState(true);
    const [counterList, setCounterList] = useState([]);
    const [archiveList, setArchiveList] = useState([]);

    const filterOption = [
        {
            title: I18n.all_counters,
            action: () => handleFilter(I18n.all_counters),
            checked: filter == I18n.all_counters || filter === '' ? true : false,
        },
        {
            title: I18n.archived,
            action: () => handleFilter(I18n.archived),
            checked: filter == I18n.archived ? true : false,
        },
    ];

    const handleFilter = val => {
        setFilter(val);
    };

    const handleCounterCreation = () => navigate('/counter/create');

    function handleSearch(val) {
        const data = filter === I18n.archived ? archiveList : counterList;
        const values = val?.toLowerCase();
        return data.filter(x => x?.name?.toLowerCase().includes(values));
    }

    const onChange = (label, value) => setText(value);

    const fetchCounters = async () => {
        try {
            if (!loading) setloading(true);

            const params = {
                business_id: business.id,
            };

            const response = await getallCounters(params);

            if (response.status) {
                const counters = response?.counters || [];

                const {archivedCounters, activeCounters} = counters?.reduce(
                    (result, counter) => {
                        if (counter?.archive === true) {
                            result?.archivedCounters.push(counter);
                        } else {
                            result?.activeCounters.push(counter);
                        }
                        return result;
                    },
                    {archivedCounters: [], activeCounters: []},
                );

                setArchiveList(archivedCounters);
                setCounterList(activeCounters);
            }
        } catch (error) {
            toast.error('An error occurred while fetching counters.');
        } finally {
            setloading(false);
        }
    };

    useEffect(() => {
        fetchCounters();
    }, [filter]);

    const handleArchiveCounter = async item => {
        if (!loading) setloading(true);

        try {
            let params = {
                id: item.id,
                business_id: business.id,
                archive: !item.archive,
            };
            const response = await handleUpdateCounter(params);
            if (response?.status) {
                setloading(false);
                fetchCounters();
                toast.info(I18n[!item.archive ? 'counter_archived_successfully' : 'counter_unarchived_successfully']);
            }
        } catch (error) {
            console.log('An error occurred while archiving or unarchiving counters.');
        }
    };

    const renderList = text ? searchList : filter === I18n.archived ? archiveList : counterList;
    return loading ? (
        <Loading />
    ) : counterList?.length || archiveList?.length ? (
        <>
            <div className="pad20" style={{backgroundColor: theme.topBarBG}}>
                <Header
                    type="search"
                    title={`${I18n.counters} (${renderList.length || 0})`}
                    search={{
                        type: 'pos',
                        placeholder: I18n.search_counter_by_name,
                        prefix: {
                            name: 'search2Icon',
                            fill: theme.white,
                        },
                        onChange: onChange,
                    }}
                    leftCta={{
                        title: filter,
                        name: 'downIcon2',
                        fill: theme.white,
                        width: '20',
                        height: '20',
                        viewBox: '0 0 18 18',
                        option: filterOption,
                    }}
                    className={'borderRadiusTopleftTopRigt'}
                    rightCta={{
                        title: I18n.add_a_counter,
                        action: handleCounterCreation,
                    }}
                />

                <div className="tableListMainBox borderRadiusBottomleftBottomRigt" fluid style={{background: theme.blackBg, height: DEVICE_HEIGHT - 180}}>
                    <Container fluid>
                        <Row>
                            <Col>
                                <TableHeader className={`threeColumn`} style={{borderColor: theme.inputBorder}}>
                                    <div className="BoxWidth justifyStart">
                                        <p className="fontSize16 OneLineTruncate" style={{color: theme.white}}>
                                            {I18n.counter}
                                        </p>
                                    </div>
                                    <div className="BoxWidth justifyCenter">
                                        <p className="fontSize16 OneLineTruncate" style={{color: theme.white}}>
                                            {I18n.counter_code}
                                        </p>
                                    </div>

                                    <div className="BoxWidth justifyCenter">
                                        <p className="fontSize16 OneLineTruncate" style={{color: theme.white}}>
                                            {I18n.action}
                                        </p>
                                    </div>
                                </TableHeader>
                                <div style={{height: DEVICE_HEIGHT - 280}} className="tableListMainBox">
                                    {renderList?.length === 0 ? (
                                        <NonIdealScreen
                                            heading={text ? I18n.search : filter == I18n.archived ? I18n.archive_counter : I18n.counter}
                                            subHeading={
                                                text
                                                    ? I18n.we_couldnt_find_any_results_for_your_search
                                                    : filter == I18n.archived
                                                    ? I18n.no_archive_counter_found
                                                    : I18n.no_counter_found
                                            }
                                            name={text ? 'seacrhNotFoundIcon' : filter == I18n.archived ? 'archiveIcon' : 'counterIcon'}
                                            fill={theme.white}
                                            isMultiStep={false}
                                            tablenonIdeal={true}
                                        />
                                    ) : (
                                        renderList?.map((item, index) => <ListItem item={item} key={index} handleArchiveCounter={handleArchiveCounter} />)
                                    )}
                                </div>
                            </Col>
                        </Row>
                    </Container>
                </div>
            </div>
        </>
    ) : (
        <NonIdealScreen
            heading={I18n.create_new_counter}
            subHeading={I18n.assign_a_unique_PIN_to_each_counter_to_link_your_payment_devices_decs}
            name="counterIcon"
            fill={theme.white}
            secondaryCta={{
                title: I18n.create_a_counter,
                action: handleCounterCreation,
            }}
            isMultiStep={false}
        />
    );
};

export default CounterList;
