import React from 'react';
import { useLanguageContext, useThemeContext } from '../../../context';
import TableBody from '../TableContainer/TableBody';
import { firstLetterCaptilize } from '../../../constants';

const List = ({ data, key, handleNavigate }) => {
    const { theme } = useThemeContext();
    const { I18n } = useLanguageContext();
    const { business_name, start_date, start_time, session_id, business_id, close_date, close_time, user_name } = data || {};

    return (
        <TableBody key={key} className={`eightColumn cursorPointer`} style={{ borderColor: theme.inputBorder }}>
            <div className="BoxWidth justifyStart">
                <p className="fontSize14 OneLineTruncate" style={{ color: theme.white }}>
                    {firstLetterCaptilize(user_name || "--")}
                </p>
            </div>
            <div className="BoxWidth justifyCenter">
                <p className="fontSize14 OneLineTruncate" style={{ color: theme.white }}>
                    {firstLetterCaptilize(business_name || "--")}
                </p>
            </div>
            <div className="BoxWidth justifyCenter">
                <p className="fontSize14 OneLineTruncate" style={{ color: theme.white }}>
                    {start_date}
                </p>
            </div>
            <div className="BoxWidth justifyCenter">
                <p className="fontSize14 OneLineTruncate" style={{ color: theme.white }}>
                    {close_date}
                </p>
            </div>
            <div className="BoxWidth justifyCenter">
                <p className="fontSize14 OneLineTruncate" style={{ color: theme.white }}>
                    {start_time}
                </p>
            </div>
            <div className="BoxWidth justifyCenter">
                <p className="fontSize14 OneLineTruncate" style={{ color: theme.white }}>
                    {close_time}
                </p>
            </div>

            <div className="BoxWidth justifyCenter" onClick={() => { handleNavigate(session_id, business_id, user_name) }}>
                <p className="fontSize14 OneLineTruncate" style={{ color: theme.barclaysBlue }}>
                    {I18n.view_Z_report}
                </p>
            </div>
        </TableBody>
    );
};

export default List;
