import React from 'react';
import footer from '../../../assets/images/footer.png';

function PriceCheckerFooter() {
    return (
        <div className="scanner-footer">
            <a href="https://oscar.pk">
                <img src={footer} alt="Powered by Oscar" className="footer-logo" />
            </a>
        </div>
    );
}

export default PriceCheckerFooter;
