import withObservables from '@nozbe/with-observables';
import {useEffect, useState} from 'react';
import {Container, Row} from 'react-bootstrap';
import {useNavigate} from 'react-router-dom';
import {of, switchMap} from 'rxjs';
import {get_FBR_PRA_InvoiceNumber, getSRBInvoiceNumber, Sync} from '../../../../../api';
import {DEVICE_HEIGHT, formatDateWithTime, formatNum, handleUpdateProductQty, SERVICE, STORE_CREDIT} from '../../../../../constants';
import {useAuthContext, useLanguageContext, useThemeContext} from '../../../../../context';
import {useOrderCalculation, useSessionUser} from '../../../../../hooks';
import BarcodeReader from 'react-barcode-reader';
import useShortcut from '../../../../../hooks/useShortcut';
import {
    bulkUpdateOrderLinesCal,
    bulkUpdateOrderProductQuantity,
    getActiveSessionForUser,
    getActiveSplittedOrders,
    getActiveSplittedOrdersCount,
    observeActiveOrder,
    observeGetOrderById,
} from '../../../../../pos-core/database/helpers';
import {observeActivePaymentMethod} from '../../../../../pos-core/database/helpers/payment_method';
import {BillAmountBox, LeftPane, NonIdealScreen, PosDiscountModal, RightPane, ToastBox, TransactionSuccessfulModal} from '../../../../common';
import ItemNotesModal from '../../../../common/ItemNotesModal';
import SplitBillModal from '../../../../common/SplitBillModal';
import {ItemDiscountsModal, NewPosHeader, SelectedCustomerModal, VerificationModal, WalletPaymentModal} from '../../../../common/V2';
import OrderItem from '../../POS/CreateOrder/OrderLine';
import CardMethod from './CardMethod';
import CashMethod from './CashMethod';
import PaymentMethod from './PaymentMethod';
import WalletMethod from './WalletMethod';
import Icon from '../../../../../assets/icons';
import LoyaltyPointsModal from '../../../../common/V2/LoyaltyPointsModal';
import {toast} from 'react-toastify';
import CreditModal from '../../../../common/CreditModal';
import {getVoucherByCode} from '../../../../../pos-core/database/helpers/voucher';

const OrderPayment = ({orders, orderLines, customer, discount, sales_tax, payment_method, pending_payments, completed_payments, all_payments}) => {
    let order = orders[0];

    const services = orderLines?.filter(ol => ol.line_type == SERVICE);
    orderLines = orderLines?.filter(ol => ol.line_type !== SERVICE);

    const {theme} = useThemeContext();
    const {I18n} = useLanguageContext();
    const {business, user, persistData, setPersistData, businessSettings} = useAuthContext();
    const {localUser} = useSessionUser();
    const queryString = window.location.search;
    const parts = queryString?.split('id=');
    const orderNumber = parts[1];

    const isRestaurantPos = orderNumber ? true : false;
    const pendingPayment = pending_payments?.length > 0 ? pending_payments[0] : null;
    const isLastPayment = completed_payments?.length + 1 === all_payments?.length;

    const [selectedAction, setSelectedAction] = useState([]);
    const [posDiscountModal, setPosDiscountModal] = useState(false);
    const [selectCustomer, setSelectCustomer] = useState(false);
    const [successModal, setSuccessModal] = useState(false);
    const [posDiscount, setPosDiscount] = useState(discount || undefined);
    const [orderDiscountModal, setOrderDiscountModal] = useState(false);
    const [orderselectedItem, setOrderselectedItem] = useState({});
    const [selectedWallet, setSelectedWallet] = useState('');
    const [toggleWalletModal, setToggleWalletModal] = useState(false);
    const [toggleVerifyModal, settoggleVerifyModal] = useState(false);
    const [splitBillModal, setSplitBillModal] = useState(false);
    const [orderNotesModal, setOrderNotesModal] = useState(false);
    const [customerInfo, setCustomerInfo] = useState({});
    const [splitOrdersLength, setSplitOrdersLength] = useState(0);
    const [redeemPoints, setRedeemPoints] = useState(null);
    const [applyVoucher, setApplyVoucher] = useState(null);
    const [invoiceNumber, setInvoiceNumber] = useState(null);
    const [selectedpaymentMethodTax, setSelectedpaymentMethodTax] = useState([]);
    const [voucherDetails, setVoucherDetails] = useState();

    const loyaltyProgram = businessSettings?.['loyalty_program'];
    const configuredIntegration = JSON.parse(localStorage.getItem('integrations'));

    useEffect(() => {
        getSplitOrderDetails();
        setAmount(persistData?.orderAmount || 0);
    }, [persistData, successModal]);

    useEffect(() => {
        const handleFetchPaymentMatedTax = async () => {
            let data = await selectedAction?.sales_tax?.fetch();
            setSelectedpaymentMethodTax(data);
        };
        handleFetchPaymentMatedTax();
    }, [selectedAction]);

    const {subTotal, amount, setAmount, change, total, tax, givenPosDiscount, giveItemLevelDiscount, inclusive, exclusive, redeemAmount, storeCreditAmount, initialTotal} =
        useOrderCalculation({
            order,
            orderLines,
            discount,
            sales_tax,
            selectedpaymentMethodTax,
            isRestaurantPos,
            pendingPayment,
            services,
        });
    const navigate = useNavigate();

    useEffect(() => {
        if (payment_method && payment_method?.length > 0) {
            const cashMethod = payment_method.find(method => method.name === 'cash');
            setSelectedAction(cashMethod || null);
        }
    }, [payment_method]);

    const handlePayment = type => {
        setSelectedAction(type);
    };

    const handleProduct = (val, qty, type) => {
        handleUpdateProductQty(val, qty, type, orderLines, order, business.type);
    };

    const handlePosDiscount = async val => {
        setPosDiscount(val);
        await order.updatePosDiscount(val);
        setPosDiscountModal(false);
    };

    const handleDeletePosDiscount = async () => {
        setPosDiscountModal(false);
        setPosDiscount();
        await order.updatePosDiscount(null);
    };

    const togglePosDiscount = () => {
        setPosDiscountModal(prev => !prev);
    };

    const toggleSelectCustomer = async () => {
        if (amount) {
            setPersistData({orderAmount: amount});
        }
        setSelectCustomer(true);
    };

    const handleCreateCustomer = () => {
        navigate(`/customers/create?id=${order.number}`);
    };

    const handleSelectCustomer = async val => {
        const customerId = order?.customer?.id;
        let message = '';
        if (val == 'removeCustomer') {
            await order?.updateCustomer(null);
            message = I18n.remove_Customer;
        } else {
            message = customerId ? I18n.update_Customer : I18n.customer_added;
            await order?.updateCustomer(val);
        }

        setSelectCustomer(false);
        resetRedeemPoints();
        toast.success(message);
    };

    const handleDone = async (method, isSplittedBill) => {
        try {
            if (successModal || (method !== 'card' && amount < total)) return;
            let invoiceType = '';
            let invoiceNum = '';

            await bulkUpdateOrderLinesCal(orderLines);
            const filteredOrderLines = orderLines.filter(ord => !ord.is_open);

            if (configuredIntegration) {
                if (configuredIntegration?.integration === 'SRB configuration') {
                    let line = orderLines?.find(line => line.tax_rate);

                    const payload = {
                        posId: Number(configuredIntegration?.posId),
                        name: `${user?.first_name} ${user?.last_name}`,
                        ntn: configuredIntegration?.ntn,
                        invoiceDateTime: formatDateWithTime(new Date()),
                        invoiceType: 1,
                        invoiceID: order?.number,
                        rateValue: line?.tax_rate || 15,
                        saleValue: parseFloat(subTotal.toFixed(2)),
                        taxAmount: parseFloat(tax.toFixed(2)),
                        consumerName: 'N/A',
                        consumerNTN: 'N/A',
                        address: 'N/A',
                        tariffCode: 'N/A',
                        extraInf: 'N/A',
                        TransType: 'Test',
                    };

                    const response = await getSRBInvoiceNumber(JSON.stringify(payload));
                    console.log('response SRB', response);

                    if (response.resCode == '00') {
                        setInvoiceNumber(response?.srbInvoceId);
                        invoiceType = 'srb';
                        invoiceNum = response?.srbInvoceId;
                    } else {
                        toast.error(response?.err);
                        return;
                    }
                } else {
                    let totalItemsQty = 0;
                    const modifiedLines = await Promise.all(
                        filteredOrderLines.map(async line => {
                            totalItemsQty += line.quantity;

                            const item = await line.product.fetch();
                            return {
                                ItemCode: item.barcode,
                                ItemName: item.name,
                                Quantity: line.quantity,
                                PCTCode: '00000000',
                                TaxRate: line.tax_rate || 0,
                                SaleValue: parseFloat((line.base_price * line.quantity).toFixed(2)),
                                TotalAmount: parseFloat((line.base_price * line.quantity + line.aggregate_tax).toFixed(2)),
                                TaxCharged: parseFloat(line.aggregate_tax.toFixed(2)),
                                Discount: parseFloat(line.aggregate_discount.toFixed(2)),
                                FurtherTax: 0.0,
                                InvoiceType: 1,
                                RefUSIN: null,
                            };
                        }),
                    );

                    const payload = {
                        InvoiceNumber: '',
                        POSID: Number(configuredIntegration?.posId),
                        USIN: order?.number,
                        DateTime: formatDateWithTime(new Date()),
                        TotalBillAmount: parseFloat(order?.total.toFixed(2)),
                        TotalQuantity: totalItemsQty,
                        TotalSaleValue: parseFloat(subTotal.toFixed(2)),
                        TotalTaxCharged: parseFloat(tax.toFixed(2)),
                        Discount: parseFloat((givenPosDiscount + giveItemLevelDiscount).toFixed(2)),
                        FurtherTax: 0.0,
                        PaymentMode: method === 'card' ? 2 : 1,
                        RefUSIN: null,
                        InvoiceType: 1,
                        Items: modifiedLines,
                    };

                    const response = await get_FBR_PRA_InvoiceNumber(payload);
                    console.log('response', response);

                    if (response.Code == '100') {
                        setInvoiceNumber(response?.InvoiceNumber);
                        invoiceNum = response?.InvoiceNumber;

                        if (configuredIntegration?.integration === 'FBR configuration') {
                            invoiceType = 'fbr';
                        } else {
                            invoiceType = 'pra';
                        }
                    } else {
                        toast.error(response?.Errors);
                        return;
                    }
                }
            }

            await bulkUpdateOrderProductQuantity(filteredOrderLines, 'sale', business?.id);
            let paramsForOrder = {
                user: localUser,
                payment_method: isSplittedBill ? '' : method,
                total: isSplittedBill ? initialTotal : total,
                amount: isSplittedBill ? 0 : method === 'card' ? Number(total) : Number(amount),
                change: isSplittedBill ? 0 : method === 'card' ? Number(0) : Number(change),
                sub_total: subTotal,
                total_tax: tax,
                total_item_level_discount: giveItemLevelDiscount,
                order_level_discount: givenPosDiscount,
                invoice_number: invoiceNum,
                invoice_type: invoiceType,
            };

            let finalOrder = await order?.updateOrderCompletion(paramsForOrder);
            if (!order?.session_id) {
                let session = await getActiveSessionForUser(user?.id);
                session = session[0];
                await finalOrder?.updateOrderSession(session);
            }

            if (!isSplittedBill) {
                if (finalOrder?.credited_amount > 0) {
                    let storeCreditPaymentParams = {
                        order: finalOrder,
                        total: finalOrder?.credited_amount,
                        payment_method: STORE_CREDIT,
                        card_number: '',
                        received_amount: finalOrder?.credited_amount,
                        change: 0,
                        status: 'complete',
                        completed_at: new Date().getTime(),
                        created_at: new Date().getTime(),
                        started_at: new Date().getTime(),
                        voucher: voucherDetails,
                    };

                    await business.createPayment(storeCreditPaymentParams);
                    const remainingAmount = voucherDetails.remaining_amount - finalOrder?.credited_amount;

                    const voucherPayload = {
                        remaining_amount: remainingAmount,
                        status: remainingAmount > 0 ? 'active' : 'redeemed',
                    };
                    await voucherDetails.updateVoucher(voucherPayload);

                    if (total > 0) {
                        let payParams = {
                            order: finalOrder,
                            total: finalOrder?.total,
                            payment_method: method,
                            card_number: '',
                            received_amount: method === 'card' ? Number(total) : Number(amount),
                            change: method === 'card' ? Number(0) : Number(change),
                            status: 'complete',
                            completed_at: new Date().getTime(),
                            created_at: new Date().getTime(),
                            started_at: new Date().getTime(),
                        };

                        await business.createPayment(payParams);
                    }
                } else {
                    let payParams = {
                        order: finalOrder,
                        total: finalOrder?.total,
                        payment_method: method,
                        card_number: '',
                        received_amount: method === 'card' ? Number(total) : Number(amount),
                        change: method === 'card' ? Number(0) : Number(change),
                        status: 'complete',
                        completed_at: new Date().getTime(),
                        created_at: new Date().getTime(),
                        started_at: new Date().getTime(),
                    };

                    await business.createPayment(payParams);
                }
            }

            if (loyaltyProgram?.[0]?.setting_value?.status && customer) {
                const pointsRate = Number(loyaltyProgram?.[0]?.setting_value?.minimum_spend);
                const redeemedPoints = Number(order.redeemed_points || 0);
                const pointsEarned = Math.ceil(total / pointsRate);

                await order?.updateEarnedPoints(Number(pointsEarned));
                await customer?.updateCustomerPoints(pointsEarned, 'add');
                await customer?.updateCustomerPoints(redeemedPoints, 'subtract');
            }

            if (customer) {
                if (method === 'credit') {
                    await customer.updateCredit(Number(total), 'add');
                }
            }

            setSuccessModal({
                total,
                change,
                method,
            });

            if (posDiscount) {
                await posDiscount?.updateTotalGiven(givenPosDiscount);
            }
            await Sync(user.email);
            unbindShortcuts();
        } catch (error) {
            console.log('error', error);
            toast.error('Something went wrong.');
        }
    };

    const {unbindShortcuts} = useShortcut({
        keys: [{key: 'enter', action: handleDone}],
    });

    const handleItemDiscount = () => {
        setOrderDiscountModal(true);
    };

    const handleOrderDiscount = async (type, value) => {
        let discountAvailable = orderselectedItem?.discount;
        let discountObj = {
            value: Number(value),
            type,
        };

        await orderselectedItem?.updateItemDistcount(discountObj);
        setOrderDiscountModal(false);
        if (!discountAvailable) {
            toast.success(I18n.item_level_discount_added);
        }
    };

    const handleSelectedItem = item => {
        setOrderselectedItem(item);
    };

    const handleToggleAccountModal = () => {
        setToggleWalletModal(prev => !prev);
    };
    const handleSelectedWallet = val => {
        setSelectedWallet(val);
        handleToggleAccountModal();
    };

    const handleSaveAccountNo = val => {
        handleToggleAccountModal();
        settoggleVerifyModal(true);

        setTimeout(() => {
            handleToggleAccountModal();
            settoggleVerifyModal(false);
        }, '2000');
    };

    const hanldeSplitBill = () => {
        if (pendingPayment) return;
        setSplitBillModal(true);
    };

    const hanldeOrderNoteModal = async () => {
        setOrderNotesModal(true);
    };

    const updateOrderNotes = async orderNote => {
        await order.updateOrderNotes(orderNote);
    };

    const handleBack = () => {
        setPersistData(null);
        navigate(-1);
    };

    const handleRedeemPoints = async val => {
        await order.updateRedeemedPoints(Number(val));
    };

    const handleRedeem = () => {
        setRedeemPoints(true);
    };

    const resetRedeemPoints = async () => {
        setRedeemPoints(null);
        await handleRedeemPoints(0);
    };

    const getSplitOrderDetails = async () => {
        const orderNumber = order?.parent_number || order?.number;
        const activeSplittedOrders = await getActiveSplittedOrdersCount(orderNumber);
        setSplitOrdersLength(activeSplittedOrders);
    };

    const moreAction = [
        {
            title: 'Store credit',
            action: () => setApplyVoucher(true),
        },
    ];

    const handleRemoveStoreCredit = async () => {
        await order.updateOrderCreditAmount(0);
    };

    const handleAddVoucher = async voucherCode => {
        try {
            let voucher = await getVoucherByCode(voucherCode);
            if (voucher.length) {
                voucher = voucher[0];
                const remainingAmount = voucher.remaining_amount;
                if (remainingAmount > 0) {
                    const redeemAmount = Math.min(remainingAmount, total);
                    await order.updateOrderCreditAmount(redeemAmount);
                    setVoucherDetails(voucher);
                    setApplyVoucher(false);
                } else {
                    toast.error('Insufficient balance');
                }
            } else {
                toast.error('Redeemed or invalid barcode');
            }
        } catch (error) {
            console.log('error', error);
            toast.error('Something went wrong');
        }
    };

    const handleScan = e => {
        handleAddVoucher(e);
    };

    return (
        <section className="posBillingMain" style={{height: DEVICE_HEIGHT - 45}}>
            <Container fluid className="noPadding">
                <Row className="posSectionRow noMargin">
                    <LeftPane>
                        <NewPosHeader
                            title={{
                                name: I18n.order_details,
                            }}
                            customer={customer}
                            order={order}
                            handleRedeem={handleRedeem}
                            leftAction={{
                                title: customer?.name || I18n.add_customer,
                                icon_name: customer ? 'profileTicketIcon' : 'add2Icon',
                                textColor: theme.barclaysBlue,
                                action: toggleSelectCustomer,
                            }}
                            centerAction={
                                isRestaurantPos
                                    ? splitOrdersLength === 0 && !order.parent_number
                                        ? {
                                              title: 'Split bill',
                                              textColor: pendingPayment ? theme.seperatorTwo : theme.barclaysBlue,
                                              action: hanldeSplitBill,
                                          }
                                        : null
                                    : null
                            }
                            rightAction={
                                isRestaurantPos
                                    ? {
                                          title: discount?.name || I18n.order_discount,
                                          textColor: theme.barclaysBlue,
                                          action: togglePosDiscount,
                                      }
                                    : {
                                          title: order?.note ? I18n.note_added : I18n.order_note,
                                          textColor: theme.barclaysBlue,
                                          action: hanldeOrderNoteModal,
                                      }
                            }
                            backBtnCta={true}
                            backAction={handleBack}
                            showMoreIcon={{
                                tableAction: moreAction,
                            }}
                        />

                        {orderLines?.length ? (
                            <div
                                className="posrightSectionInner"
                                style={{
                                    height: DEVICE_HEIGHT - 105,
                                    backgroundColor: theme.newposRightBg,
                                }}>
                                <div className="cartItemList">
                                    {orderLines?.map((item, index) => (
                                        <OrderItem
                                            item={item}
                                            key={index}
                                            isOpenItem={item.is_open}
                                            handleSelectedItem={handleSelectedItem}
                                            handleProduct={handleProduct}
                                            handleItemDiscount={handleItemDiscount}
                                            restaurantPos={isRestaurantPos}
                                            paymentScreen={true}
                                        />
                                    ))}
                                </div>

                                <div
                                    className="pospaymentScreenAmountBox"
                                    style={{
                                        height: 'auto',
                                    }}>
                                    {order?.note && (
                                        <div className="orderNoteWrapper">
                                            <Icon name="pencilIcon" fill={theme.barclaysBlue} viewBox={'0 0 13 13'} onClick={() => hanldeOrderNoteModal()} />
                                            <p className="fontSize14 marBot0 marLeft5 OneLineTruncate" style={{color: theme.lightGrayTwo}}>
                                                {order?.note}
                                            </p>
                                        </div>
                                    )}
                                    <div>
                                        <BillAmountBox
                                            className={`width100`}
                                            subTotal={subTotal}
                                            total={total}
                                            tax={tax}
                                            giveItemLevelDiscount={giveItemLevelDiscount}
                                            givenPosDiscount={givenPosDiscount}
                                            redeemAmount={redeemAmount}
                                            storeCreditAmount={storeCreditAmount}
                                            handleRemoveStoreCredit={handleRemoveStoreCredit}
                                            services={services}
                                        />
                                    </div>
                                </div>
                            </div>
                        ) : (
                            <div
                                className="width100"
                                style={{
                                    backgroundColor: 'red',
                                    height: DEVICE_HEIGHT - 105,
                                }}>
                                <NonIdealScreen
                                    heading={I18n.session}
                                    backgroundColor={theme.topBarBG}
                                    subHeading={I18n.please_go_to_the_POS_and_add_items_to_the_list}
                                    name="goToPOSIcon"
                                    fill={theme.white}
                                    secondaryCta={{
                                        title: I18n.go_to_pos,
                                        action: () => navigate(-1),
                                    }}
                                    isMultiStep={false}
                                />
                            </div>
                        )}
                    </LeftPane>
                    <RightPane>
                        <NewPosHeader
                            title={{
                                name: `${I18n.select_payment_method} ${
                                    pendingPayment ? `(Amount Rs. ${pendingPayment?.amount}, payment ${completed_payments?.length + 1} of ${all_payments?.length})` : ''
                                }`,
                            }}
                        />
                        <div
                            style={{
                                height: DEVICE_HEIGHT - 105,
                                backgroundColor: theme.topBarBG,
                            }}>
                            <div className="paymentRightHead">
                                <PaymentMethod selected={selectedAction} payment_method={payment_method} handlePayment={handlePayment} />
                            </div>

                            <div className="paymentRightBody width100">
                                {selectedAction?.name === 'cash' || selectedAction?.name === 'credit' ? (
                                    <CashMethod
                                        paymentType={selectedAction}
                                        handleDone={handleDone}
                                        amount={amount}
                                        setAmount={setAmount}
                                        givenPosDiscount={givenPosDiscount}
                                        total={total}
                                        change={change}
                                        customer={customer}
                                        orderLines={orderLines}
                                        order={order}
                                        pendingPayment={pendingPayment}
                                        completed_payments={completed_payments}
                                        isLastPayment={isLastPayment}
                                        setSuccessModal={setSuccessModal}
                                    />
                                ) : selectedAction?.name === 'wallet' ? (
                                    <WalletMethod orderLines={orderLines} handleSelected={handleSelectedWallet} />
                                ) : (
                                    <CardMethod
                                        paymentType={selectedAction}
                                        order={order}
                                        orderLines={orderLines}
                                        givenPosDiscount={givenPosDiscount}
                                        total={total}
                                        change={change}
                                        handleDone={handleDone}
                                        customer={customer}
                                        pendingPayment={pendingPayment}
                                        completed_payments={completed_payments}
                                        isLastPayment={isLastPayment}
                                        setSuccessModal={setSuccessModal}
                                    />
                                )}
                            </div>
                        </div>
                    </RightPane>
                </Row>
            </Container>

            <div>
                <BarcodeReader onScan={handleScan} />
            </div>

            <ItemDiscountsModal
                setToggle={setOrderDiscountModal}
                selectedItem={orderselectedItem}
                handleSelectedItem={handleSelectedItem}
                toggle={orderDiscountModal}
                handleSave={handleOrderDiscount}
                showDiscountType
                title={I18n.item_level_discount}
            />
            <PosDiscountModal
                setToggle={setPosDiscountModal}
                toggle={posDiscountModal}
                handleSelect={handlePosDiscount}
                order={order}
                handleDeletePosDiscount={handleDeletePosDiscount}
            />

            <SelectedCustomerModal
                setToggle={setSelectCustomer}
                toggle={selectCustomer}
                handleSelect={handleSelectCustomer}
                order={order}
                handleCreateCustomer={handleCreateCustomer}
            />

            <WalletPaymentModal
                setToggle={setToggleWalletModal}
                toggle={toggleWalletModal}
                selectedWallet={selectedWallet}
                handleSave={handleSaveAccountNo}
                customerInfo={customerInfo}
            />

            <VerificationModal toggle={toggleVerifyModal} setToggle={settoggleVerifyModal} />

            <SplitBillModal toggle={splitBillModal} setToggle={setSplitBillModal} totalBill={total} order={order} business={business} />

            <TransactionSuccessfulModal
                setToggle={setSuccessModal}
                toggle={successModal}
                order={order}
                orderLines={orderLines}
                givenPosDiscount={givenPosDiscount}
                tax={exclusive}
                isRestaurantPos={isRestaurantPos}
                activeOrders={orders}
                pendingPayment={pendingPayment}
                completed_payments={completed_payments}
                customer={customer}
                splitOrdersLength={splitOrdersLength}
                invoiceNumber={invoiceNumber}
                services={services}
            />

            <ItemNotesModal order={order} visible={orderNotesModal} setVisible={setOrderNotesModal} saveNotes={updateOrderNotes} />

            <LoyaltyPointsModal visible={redeemPoints} setVisible={setRedeemPoints} handleRedeemPoints={handleRedeemPoints} customer={customer} order={order} />
            <CreditModal visible={applyVoucher} setVisible={setApplyVoucher} handleAddVoucher={handleAddVoucher} />
        </section>
    );
};

const enhance = withObservables(['orderNumber'], ({orderNumber}) => {
    return {
        orders: orderNumber ? observeGetOrderById(orderNumber) : observeActiveOrder(),
        orderLines: (orderNumber ? observeGetOrderById(orderNumber) : observeActiveOrder()).pipe(
            switchMap(order =>
                order?.length ? order[0].order_line.observeWithColumns(['quantity', 'archive', 'unit', 'discount', 'selling_price', 'order_line_modifiers']) : of(null),
            ),
        ),
        payment_method: observeActivePaymentMethod(),
        customer: (orderNumber ? observeGetOrderById(orderNumber) : observeActiveOrder()).pipe(switchMap(order => (order?.length ? order[0].customer.observe() : of(null)))),
        discount: (orderNumber ? observeGetOrderById(orderNumber) : observeActiveOrder()).pipe(switchMap(order => (order?.length ? order[0].discount.observe() : of(null)))),
        sales_tax: (orderNumber ? observeGetOrderById(orderNumber) : observeActiveOrder()).pipe(switchMap(order => (order?.length ? order[0].sales_tax.observe() : of(null)))),
        pending_payments: (orderNumber ? observeGetOrderById(orderNumber) : observeActiveOrder()).pipe(
            switchMap(order => (order?.length ? order[0].getPendingPayments().observe() : of(null))),
        ),
        completed_payments: (orderNumber ? observeGetOrderById(orderNumber) : observeActiveOrder()).pipe(
            switchMap(order => (order?.length ? order[0].getCompletedPayments().observe() : of(null))),
        ),
        all_payments: (orderNumber ? observeGetOrderById(orderNumber) : observeActiveOrder()).pipe(
            switchMap(order => (order?.length ? order[0].getAllPayments().observe() : of(null))),
        ),
    };
});

export default enhance(OrderPayment);
