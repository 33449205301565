import React, { useEffect, useState } from 'react';
import { Col } from 'react-bootstrap';
import {
    getDashboardBusinessAnalytics,
    getDashboardInsightDetails,
    getDashboardPaymentAnalytics,
    getDashboardProductAnalytics,
    getDashboardSalesAnalytics,
    getDashboardUsersAnalytics,
} from '../../../../../../api';
import { dateFilterMapping, formateIdForApi, formatNum, insightDetailMapping, ITEMS_PER_PAGE, removeTime, RESTAURANT } from '../../../../../../constants';
import { useAuthContext } from '../../../../../../context';
import {
    ActiveOscarWallet,
    CoRelatedItems,
    DashboardBanner,
    ExpandYourOnline,
    Graphcard,
    KeyMetrics,
    Loading,
    LowSellingItems,
    LowStockItems,
    OutofStockItems,
    ProfitMargin,
    ShiftSalesSummary,
    TableTurnOver,
    TaxInformation,
    TeamMatrix,
    TopSellingItems
} from '../../../../../common';
import LocationWiseSales from '../../../../../common/LocationWiseSales';
import ViewDataModal from '../../../../../common/V2/ViewDataModal';
const SalesDashboard = ({ selectedLocation, selectedDateRange, isPosPermission }) => {
    const { business } = useAuthContext();
    const [loading, setLoading] = useState(true);
    const locationString = formateIdForApi(selectedLocation, business?.id);
    const [viewDataModal, setViewDataModal] = useState(false);
    const [allListData, setAllListData] = useState({ name: '', data: [] });
    const [currentPage, setCurrentPage] = useState(0);
    const [totalpages, setTotalPages] = useState(0);
    const [salesAnalytics, setSalesAnalytics] = useState();
    const [paymentAnalytics, setPaymentAnalytics] = useState();
    const [productsAnalytics, setProductsAnalytics] = useState();
    const [businessAnalytics, setBusinessAnalytics] = useState();
    const [userAnalytics, setUserAnalytics] = useState();

    const [title, setTitle] = useState('');

    const [tabValue, setTabVal] = useState(0);

    const toggleViewDataModal = () => {
        setViewDataModal(prev => !prev);
    };

    function transformChannelData(salesData) {
        try {
            const labels = [];
            const salesValues = [];

            for (const channel of salesData) {
                labels.push(channel.name);
                salesValues.push(channel.sales);
            }
            return {
                showLegend: false,
                labels,
                values: salesValues,
                additionalText: [],
                tooltipData: labels.map((label, index) => ` ${label}: Rs. ${formatNum(salesValues[index] || 0)}`),
                stylesData: {
                    barColor: '#A5B0EE',
                    barBgColor: '#313547',
                    textColor: 'white',
                    secondaryTextColor: '#CECECE',
                    radius: 14,
                    barPercentage: 0.3,
                },
            };

        } catch (error) {
            console.error("Error transform Channel data:", error)
        }

    }

    function transformPaymentData(salesByPaymentMethod) {
        try {
            const labels = [];
            const salesValues = [];

            for (const [key, method] of Object.entries(salesByPaymentMethod)) {
                // labels.push(`${key.charAt(0).toUpperCase() + key.slice(1)} (${method.total_orders} orders)`);
                labels?.push(`${key?.charAt(0)?.toUpperCase() + key?.slice(1)}`);
                const sales = method?.sales;
                salesValues?.push(sales);
            }

            return {
                showLegend: false,
                labels,
                values: salesValues,
                additionalText: [''],
                tooltipData: labels.map((label, index) => ` ${label}: Rs. ${formatNum(salesValues[index] || 0)}`),
                stylesData: {
                    barColor: '#FFE6C1',
                    textColor: 'white',
                    secondaryTextColor: '#CECECE',
                },
            };

        } catch (error) {
            console.error("Error transform Payment data:", error)
        }

    }

    function transformDailyData(data) {
        try {
            const labels = Object?.keys(data);
            const values = Object?.values(data);

            return {
                showLegend: false,
                labels,
                values,
                tooltipData: labels?.map((label, index) => ` ${label}: ${formatNum(values[index] || 0)} Orders`),
                stylesData: {
                    barColor: '#A5B0EE',
                    textColor: 'white',
                    secondaryTextColor: '#CECECE',
                },
            };
        } catch (error) {
            console.error("Error transform Daily Data data:", error)
        }
    }

    function transformVoids(data) {
        try {
            const labels = Object?.keys(data);
            const values = Object?.values(data);
            return {
                showLegend: false,
                labels: Object?.keys(data),
                values: Object?.values(data),
                tooltipData: labels.map((label, index) => ` ${label}: Rs. ${formatNum(values[index] || 0)}`),
                stylesData: {
                    barColor: '#A5B0EE',
                    textColor: 'white',
                    secondaryTextColor: '#CECECE',
                },
            };

        } catch (error) {
            console.error("Error transform Voids data:", error)
        }
    }

    function transformHourData(salesData, tabValue) {
        try {
            const labels = [];
            const values = [];
            for (let hour = 1; hour <= 12; hour++) {
                if (tabValue === 0) {
                    labels?.push(hour?.toString());
                    values?.push(salesData[hour] || 0);
                } else if (tabValue === 1) {
                    const pmHour = hour + 12;
                    labels?.push(pmHour?.toString());
                    values?.push(salesData[pmHour] || 0);
                }
            }

            return {
                showLegend: false,
                labels: labels,
                values: values,
                additionalText: [''],
                tooltipData: labels.map((label, index) => ` Rs. ${formatNum(values[index] || 0)}`),
                stylesData: {
                    barColor: '#FFE6C1',
                    textColor: 'white',
                    secondaryTextColor: '#CECECE',
                },
                tabs: true,
            };
        } catch (error) {
            console.error("Error transforming hour data:", error)
        }


    }


    const handleGetAllData = async val => {
        if (!viewDataModal) setViewDataModal(true);
        setTitle(val);

        try {
            let params = {
                start_date: removeTime(selectedDateRange?.start_date),
                end_date: removeTime(selectedDateRange?.end_date),
                date_range: dateFilterMapping[selectedDateRange?.label || 'Today'],
                business_ids: locationString,
                page: currentPage || 1,
                limit: ITEMS_PER_PAGE || 20,
                insight_type: insightDetailMapping[val] || '',
            };
            let response = await getDashboardInsightDetails(params);

            if (response?.status) {
                setTotalPages(response?.total_pages || 0);
                setAllListData(prevState => ({
                    name: val,
                    data: [...(prevState?.data || []), ...response.data],
                }));
            }

        } catch (error) {
            console.error('Error fetching data:', error);
        }
    };

    useEffect(() => {
        if (currentPage) {
            if (currentPage <= totalpages && title) {
                handleGetAllData(title);
            }
        }
    }, [currentPage]);

    const handleGetSaleAnalytics = async params => {
        try {
            let respone = await getDashboardSalesAnalytics(params);

            if (respone?.status) {
                respone = respone?.data;
                let formateData = {
                    keyMetrics: respone?.aggregated_insights,
                    sales_summary: respone?.sales_summary,
                    sales_by_category: respone?.sales_by_category,
                    dailyData: transformDailyData(respone?.sales_by_day_of_week),
                    hourlyData: respone?.sales_by_hour,
                    tax_metrics: respone?.tax_metrics,
                    table_turn_over: respone?.table_turn_over,
                    channelData: transformChannelData(respone?.sales_by_channel),
                };

                setSalesAnalytics(formateData);
            } else {
                throw new Error('Failed to fetch sales analytics data.');
            }
        } catch (error) {
            console.error('Error in Get Sales Analytics:', error);
        }
    };

    const handleGetPaymentAnalytics = async params => {
        try {
            let respone = await getDashboardPaymentAnalytics(params);

            if (respone?.status) {
                respone = respone?.data;
                let formateData = {
                    paymentData: transformPaymentData(respone?.sales_by_payment_methods),
                };

                setPaymentAnalytics(formateData);
            } else {
                throw new Error('Failed to fetch Payment analytics data.');
            }
        } catch (error) {
            console.error('Error in Get Payment Analytics:', error);
        }
    };

    const handleGetProductsAnalytics = async params => {
        try {
            let respone = await getDashboardProductAnalytics(params);
            if (respone?.status) {
                respone = respone?.data;
                let formateData = {
                    top_selling_items: respone?.top_selling_items,
                    low_selling_items: respone?.low_selling_items,
                    low_stock_items: respone?.low_stock_items,
                    out_of_stock_items: respone?.out_of_stock_items,
                    correlated_products: respone?.correlated_products,
                    voids_by_day: transformVoids(respone?.voids_by_day),
                };
                setProductsAnalytics(formateData);
            } else {
                throw new Error('Failed to fetch product analytics data.');
            }
        } catch (error) {
            console.error('Error in Get product Analytics:', error);
        }
    };

    const handleGetBussinessAnalytics = async params => {
        try {
            let respone = await getDashboardBusinessAnalytics(params);
            if (respone?.status) {
                respone = respone?.data;
                let formateData = {
                    location_wise_sales: respone?.business_wise_sales,
                    profit_margins: respone?.profit_margins,
                };
                setBusinessAnalytics(formateData);
            } else {
                throw new Error('Failed to fetch Bussiness analytics data.');
            }
        } catch (error) {
            console.error('Erro in Get Bussiness Analytics:', error);
        }
    };

    const handleGetUserAnalytics = async params => {
        try {
            let respone = await getDashboardUsersAnalytics(params);

            if (respone?.status) {
                respone = respone?.data;
                let formateData = {
                    team_metrics: respone?.team_metrics,
                };
                setUserAnalytics(formateData);
            } else {
                throw new Error('Failed to fetch User analytics data.');
            }
        } catch (error) {
            console.error('Erro in Get User Analytics:', error);
        }
    };

    const fetchData = async () => {
        if (!loading) setLoading(true);
        const params = {
            start_date: removeTime(selectedDateRange?.start_date),
            end_date: removeTime(selectedDateRange?.end_date),
            date_range: dateFilterMapping[selectedDateRange?.label || 'Today'],
            business_ids: locationString,
        };

        const analyticsFunctions = [
            handleGetSaleAnalytics(params),
            handleGetPaymentAnalytics(params),
            handleGetProductsAnalytics(params),
            handleGetBussinessAnalytics(params),
            handleGetUserAnalytics(params),
        ];

        try {
            await Promise?.allSettled(analyticsFunctions);
        } catch (error) {
            console.error('Unexpected error when fetching analytics data:', error);
        }

        setLoading(false);
    };

    useEffect(() => {
        fetchData();
    }, [selectedDateRange, selectedLocation]);

    const is_restaurant = business?.type === RESTAURANT;
    return loading ? (
        <div className="reportsWrapper">
            <div className="reportsWrapperInner">
                <Loading />
            </div>
        </div>
    ) : (
        <>
            <Col md={12}>
                <DashboardBanner />
            </Col>
            <Col md={12}>
                <div className="saleKeyMetrics">
                    <KeyMetrics type={'sale_dashborard'} isPosPermission={isPosPermission} metricsData={salesAnalytics?.keyMetrics} title="key_metrics" />
                </div>
            </Col>

            <Col md={6}>
                <Graphcard type="bar" data={paymentAnalytics?.paymentData} title={'sales_by_payment_method'} />
            </Col>
            {is_restaurant && (
                <Col md={6}>
                    <Graphcard type="bar" data={salesAnalytics?.channelData} title={'sales_by_channel'} />
                </Col>
            )}
            <Col md={6}>
                <Graphcard type="donut" data={salesAnalytics?.sales_by_category} title="sales_by_category" />
            </Col>

            <Col md={6} className="onlinePresenceMainWrapper">
                <ExpandYourOnline />
            </Col>

            {/* <Col md={6}>
                <div className="unmacthedSalesWrapper">
                    <UnmatchedSales />
                </div>
            </Col> */}
            {/* <Col md={6}>
                <div className="UpgradedOscarPlan">
                    <UpgradedOscarPlan />
                </div>
            </Col> */}
            <Col md={6}>
                <div className="Walletonboarding">
                    <ActiveOscarWallet />
                </div>
            </Col>
            {is_restaurant && (
                <Col md={6}>
                    <Graphcard type="bar" data={productsAnalytics?.voids_by_day} title="voids" />
                </Col>
            )}
            <Col md={6}>
                <Graphcard type="bar" data={salesAnalytics?.dailyData} title="sales_by_day" />
            </Col>
            <Col md={6}>
                <div className="salesHourWrapper">
                    <Graphcard type="bar" tabValue={tabValue} setTabVal={setTabVal} data={transformHourData(salesAnalytics?.hourlyData, tabValue)} title="sales_by_hour" />
                </div>
            </Col>

            <Col md={12}>
                <ShiftSalesSummary data={salesAnalytics?.sales_summary} handleNavigate={() => handleGetAllData('sales_summary')} />
            </Col>

            <Col md={12}>
                <TopSellingItems data={productsAnalytics?.top_selling_items} handleNavigate={() => handleGetAllData('top_selling_items')} />
            </Col>
            <Col md={12}>
                <LowSellingItems data={productsAnalytics?.low_selling_items} handleNavigate={() => handleGetAllData('low_selling_items')} />
            </Col>

            <Col md={12}>
                <ProfitMargin data={businessAnalytics?.profit_margins} handleNavigate={() => handleGetAllData('profit_margins')} />
            </Col>

            <Col md={12}>
                <LowStockItems data={productsAnalytics?.low_stock_items} handleNavigate={() => handleGetAllData('low_stock_items')} />
            </Col>

            <Col md={12}>
                <OutofStockItems data={productsAnalytics?.out_of_stock_items} handleNavigate={() => handleGetAllData('out_of_stock_items')} />
            </Col>

            <Col md={12}>
                <CoRelatedItems data={productsAnalytics?.correlated_products} handleNavigate={() => handleGetAllData('co_related_items')} />
            </Col>
            {Object.keys(selectedLocation || {})?.length > 1 && (
                <Col md={12}>
                    <LocationWiseSales data={businessAnalytics?.location_wise_sales} handleNavigate={() => handleGetAllData('location_wise_sales')} />
                </Col>
            )}
            <Col md={12}>
                <TeamMatrix data={userAnalytics?.team_metrics} handleNavigate={() => handleGetAllData('team_metrics')} />
            </Col>
            <Col md={12}>
                <TaxInformation data={salesAnalytics?.tax_metrics} handleNavigate={() => handleGetAllData('tax_information')} />
            </Col>
            {is_restaurant && (
                <Col md={12}>
                    <TableTurnOver data={salesAnalytics?.table_turn_over} handleNavigate={() => handleGetAllData('table_turnover')} />
                </Col>
            )}

            <ViewDataModal
                setAllListData={setAllListData}
                setCurrentPage={setCurrentPage}
                allListData={allListData}
                totalpages={totalpages}
                setToggle={setViewDataModal}
                currentPage={currentPage}
                toggle={viewDataModal}
                title={title}
                selectedDateRange={selectedDateRange}
                selectedLocation={selectedLocation}
            />
        </>
    );
};

export default SalesDashboard;
